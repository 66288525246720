import { CrudModal } from "components/common/CrudModal";
import TextInput from "components/common/TextInput";
import { CustomerEnvironmentSla } from "model/Sla";
import { CustomerEnvironment } from "model/Environment";
import React, { FunctionComponent, useState } from "react";
import styled, { css } from "styled-components";
import { Select, Text } from "styles/common";

interface Props {
  originalEnvironment: CustomerEnvironment | undefined;
  slas: CustomerEnvironmentSla[];
  close(): void;
  add(environment: CustomerEnvironment): void;
  edit(environment: CustomerEnvironment): void;
}

const Container = styled.div``;

const InputContainer = styled.div`
  margin-bottom: 1rem;
`;

export const emptyNameErrorText = "Please fill in a name.";
export const emptyAbbreviationErrorText = "Please fill in an abbreviation.";

export const CustomerEnvironmentModal: FunctionComponent<Props> = ({
  originalEnvironment,
  slas,
  close,
  add,
  edit,
}) => {
  const [environment, setEnvironment] = useState<CustomerEnvironment>(
    originalEnvironment
      ? originalEnvironment
      : {
          id: 0,
          name: "",
          abbreviation: "",
          areas: [],
          sla: slas[0].id,
        }
  );

  const [nameError, setNameError] = useState(false);
  const [abbreviationError, setAbbreviationError] = useState(false);

  return (
    <CrudModal
      className="customer-environment-modal"
      title={originalEnvironment ? "Edit environment" : "Add environment"}
      content={
        <Container>
          <InputContainer>
            <TextInput
              className="name-input"
              label="Name"
              defaultValue={environment.name}
              mandatory
              error={nameError}
              errorText={emptyNameErrorText}
              onValueChange={(value) => {
                setNameError(false);
                setEnvironment({ ...environment, name: value });
              }}
            />
          </InputContainer>

          <InputContainer>
            <TextInput
              className="abbreviation-input"
              label="Abbreviation"
              defaultValue={environment.abbreviation}
              mandatory
              error={abbreviationError}
              errorText={emptyAbbreviationErrorText}
              onValueChange={(value) => {
                setNameError(false);
                setEnvironment({ ...environment, abbreviation: value });
              }}
            />
          </InputContainer>

          <InputContainer>
            <Text
              semibold
              extraStyling={css`
                margin-bottom: 0.3rem;
              `}
            >
              SLA
            </Text>
            <Select
              className="sla-select"
              defaultValue={environment.sla}
              fullWidth
              onChange={(e) => {
                setEnvironment({
                  ...environment,
                  sla: parseInt(e.target.value),
                });
              }}
              extraStyling={css`
                margin-bottom: 0.5rem;
              `}
            >
              {slas.map((sla: CustomerEnvironmentSla, index: number) => (
                <option key={index} value={sla.id}>
                  {sla.name}
                </option>
              ))}
            </Select>
          </InputContainer>
        </Container>
      }
      cancel={() => {
        close();
      }}
      action={() => {
        if (environment.name === "") {
          setNameError(true);
        }
        if (environment.abbreviation === "") {
          setAbbreviationError(true);
        }

        if (environment.name !== "" && environment.abbreviation !== "") {
          originalEnvironment ? edit(environment) : add(environment);
          close();
        }
      }}
      extraStyling={css`
        min-width: 25rem;
      `}
    />
  );
};
