import { IntegrationLogicalGroupLevelOne } from "model/LogicalGroup";
import { LocationGroupNodeData } from "model/Node";

export const convertLogicalGroupsToDiagramNodes = (
  data: IntegrationLogicalGroupLevelOne[]
) => {
  return data.map((locationGroup: IntegrationLogicalGroupLevelOne) => {
    const locationGroupData = {
      name: locationGroup.name,
      status: locationGroup.status,
      executionsPerTimeUnit: locationGroup.executionsPerTimeUnit,
      executionsTimeUnit: locationGroup.executionsTimeUnit,
      total: locationGroup.total,
      successes: locationGroup.successes,
      successesSlow: locationGroup.successesSlow,
      fails: locationGroup.fails,
    };

    return {
      id: locationGroup.id.toString(),
      type: "customNode",
      data: {
        ...locationGroupData,
        platformComponents: locationGroup.platformComponents.map(
          (platformComponent) => {
            return {
              name: platformComponent.name,
              status: platformComponent.status,
              type: platformComponent.type,
              area: platformComponent.area,
              location: platformComponent.location,
              total: platformComponent.total,
              successes: platformComponent.successes,
              fails: platformComponent.fails,
              applications: platformComponent.applications.map(
                (appId) => `application${appId}relation`
              ),
            };
          }
        ),
      } as LocationGroupNodeData,
      position: locationGroup.position
        ? locationGroup.position
        : { x: 0, y: 0 },
    };
  });
};
