import { action, makeObservable, observable } from "mobx";
import { ItOperationLocation } from "model/ItOperation";
import { ItOperationApi } from "services/apis/ItOperationApi";
import GeneralStore from "./GeneralStore";
import LoaderStore from "./LoaderStore";

class ItOperationStore {
  @observable
  itOperationLocations: ItOperationLocation[] | undefined = undefined;

  constructor(
    public api: ItOperationApi,
    private generalStore: GeneralStore,
    private loaderStore: LoaderStore
  ) {
    makeObservable(this);
  }

  @action
  getItOperationData = async () => {
    if (
      this.generalStore.currentEnvironment !== undefined &&
      this.generalStore.timeFrame
    ) {
      this.loaderStore.setLoading();

      const response = await this.api.getItOperationData(
        this.generalStore.currentEnvironment,
        this.generalStore.timeFrame
      );

      if (response.success) {
        const { data } = response;
        this.itOperationLocations = data;
      } else {
        //const { errorData } = response;
        console.log("Something went wrong when trying to fetch IT operations");
      }

      this.loaderStore.stopLoading();
    }
  };

  @action
  reset = () => {
    this.itOperationLocations = undefined;
  };
}

export default ItOperationStore;
