import React from "react";
import { UsageOfDate } from "model/Usage";
import styled from "styled-components";
import { List } from "components/common/List";
import { format } from "date-fns";
import { Text } from "styles/common";
import { Units } from "..";

interface Props {
  data: UsageOfDate[] | undefined;
  access: boolean;
  selectedMonth: number | undefined;
  onMonthSelect(rowId: number): void;
  retryDataLoading(): void;
}

const Container = styled.div<{ short?: boolean }>`
  display: flex;
  flex-direction: row;
  border-radius: 0.2rem;
  padding-right: 1rem;
  margin-right: 1rem;
  width: 20rem;
  min-width: 20rem;
  border-right: 1px solid #ececec;
`;

export const MonthsList = ({
  data,
  access,
  selectedMonth,
  onMonthSelect,
  retryDataLoading,
}: Props) => (
  <Container>
    <List
      className="months-list"
      headers={["Month", "Consumption"]}
      selectedRow={
        data && selectedMonth !== undefined ? selectedMonth : undefined
      }
      data={
        data
          ? data.map((monthData: UsageOfDate) => {
              return {
                cells: [
                  <Text semibold>
                    {format(new Date(monthData.date), "yyyy-MM")}
                  </Text>,
                  monthData.units ? (
                    <Text>
                      <Units>{monthData.units}</Units> units
                    </Text>
                  ) : (
                    <Text>-</Text>
                  ),
                ],
              };
            })
          : undefined
      }
      hover
      access={access}
      noDataText="You don't have any previous months yet."
      blockClicking={
        data ? data.map((month, i) => (month.units === undefined ? i : -1)) : []
      }
      onRowClick={(rowId: number) => data && onMonthSelect(rowId)}
      retryDataLoading={retryDataLoading}
    />
  </Container>
);
