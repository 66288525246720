import React, { FunctionComponent, useState } from "react";
import { List } from "components/common/List";
import { css } from "styled-components";
import { Button } from "styles/common";
import {
  SettingsChangeRequestContact,
  SimpleSettingsContact,
} from "model/Contact";
import { SettingChangeRequestContactModal } from "components/Settings/SettingChangeRequestContactModal";
import { DeleteButton, EditButton } from "components/common/List/common";

interface Props {
  contacts: SimpleSettingsContact[] | undefined;
  roles: string[] | undefined;
  changeRequestContacts: SettingsChangeRequestContact[] | undefined;
  viewAccess: boolean;
  editAccess: boolean;
  deleteAccess: boolean;
  editChangeRequestContacts(
    changeRequestContacts: SettingsChangeRequestContact[]
  ): void;
  retryDataLoading(): void;
}

const baseHeaders = ["Role", "Contact", "Comments"];

const SettingChangeRequestContactsList: FunctionComponent<Props> = ({
  contacts,
  roles,
  changeRequestContacts,
  viewAccess,
  editAccess,
  deleteAccess,
  editChangeRequestContacts,
  retryDataLoading,
}) => {
  const [showEditOrAddContactModal, setShowEditOrAddContactModal] =
    useState(false);
  const [selectedChangeRequestContact, setSelectedChangeRequestContact] =
    useState<SettingsChangeRequestContact | undefined>(undefined);

  const getChangeRequestContactCells = () =>
    contacts && roles && changeRequestContacts
      ? changeRequestContacts.map(
          (changeRequestContact: SettingsChangeRequestContact) => {
            const baseCells = [
              changeRequestContact.role,
              changeRequestContact.contact.contact,
              changeRequestContact.comments
                ? changeRequestContact.comments
                : "-",
            ];

            const editButton = (
              <EditButton
                className="edit-button"
                onClick={() => {
                  setSelectedChangeRequestContact(changeRequestContact);
                  setShowEditOrAddContactModal(true);
                }}
              />
            );

            const deleteButton = (
              <DeleteButton
                className="delete-button"
                onClick={() => {
                  editChangeRequestContacts([
                    ...changeRequestContacts.filter(
                      (c) => c !== changeRequestContact
                    ),
                  ]);
                }}
              />
            );

            return {
              cells:
                editAccess && deleteAccess
                  ? [...baseCells, editButton, deleteButton]
                  : editAccess
                  ? [...baseCells, editButton]
                  : deleteAccess
                  ? [...baseCells, deleteButton]
                  : baseCells,
            };
          }
        )
      : undefined;

  return (
    <>
      {editAccess && roles && contacts && (
        <Button
          className="add-button"
          extraStyling={css`
            margin-bottom: 1rem;
          `}
          onClick={() => {
            setShowEditOrAddContactModal(true);
          }}
        >
          Add contact
        </Button>
      )}
      <List
        headers={
          editAccess && deleteAccess
            ? [...baseHeaders, "", ""]
            : editAccess || deleteAccess
            ? [...baseHeaders, ""]
            : baseHeaders
        }
        data={getChangeRequestContactCells()}
        customColumnWidths={[4, 4, 6, 1, 1]}
        hover
        access={viewAccess}
        noDataText={
          editAccess
            ? "You haven't defined any change request contacts yet."
            : "There are no change request contacts defined yet."
        }
        smallEmpty
        retryDataLoading={retryDataLoading}
      />

      {contacts && roles && showEditOrAddContactModal && (
        <SettingChangeRequestContactModal
          contacts={contacts}
          roles={roles}
          originalContact={selectedChangeRequestContact}
          close={() => {
            setSelectedChangeRequestContact(undefined);
            setShowEditOrAddContactModal(false);
          }}
          add={(newContact: SettingsChangeRequestContact) =>
            editChangeRequestContacts([
              ...(changeRequestContacts ? changeRequestContacts : []),
              newContact,
            ])
          }
          edit={(editedContact: SettingsChangeRequestContact) =>
            changeRequestContacts &&
            editChangeRequestContacts(
              changeRequestContacts.map((c) =>
                c.id === editedContact.id ? editedContact : c
              )
            )
          }
        />
      )}
    </>
  );
};

export default SettingChangeRequestContactsList;
