import { CrudModal } from "components/common/CrudModal";
import TextInput from "components/common/TextInput";
import { CustomerPriceTierBase } from "model/Customer";
import React, { FunctionComponent, useState } from "react";
import styled, { css } from "styled-components";
import { $statusFail, Text } from "styles/common";

interface Props {
  priceTiers: CustomerPriceTierBase[];
  close(): void;
  edit(priceTiers: CustomerPriceTierBase[]): void;
}

interface PriceTierProps {
  priceTier: CustomerPriceTierBase;
  isFirstTier: boolean;
  changePriceTier(priceTier: CustomerPriceTierBase): void;
  calculatePriceTierValues(): void;
}

const Container = styled.div``;

const Error = styled(Text)`
  font-size: 0.8rem;
  margin: -0.5rem 0 0.75rem 0;
`;

const TierContainer = styled.div`
  display: flex;
  flex-dirction: row;
  gap: 1rem;
  align-items: center;
  gap: 1.5rem;
  margin-bottom: 1.5rem;
`;

const TierTitle = styled(Text)`
  margin-bottom: 0.5rem;
  width: 10rem;
`;

const FromContainer = styled.div`
  width: 8rem;
`;

const UntilContainer = styled.div`
  width: 8rem;
`;

export const emptyErrorText = "Please fill in all values";
export const mainErrorText =
  "The ranges of different tiers may not overlap and have to be ascending";

const PriceTier: FunctionComponent<PriceTierProps> = ({
  priceTier,
  isFirstTier,
  changePriceTier,
  calculatePriceTierValues,
}) => {
  const [emptyError, setEmptyError] = useState(false);

  return (
    <TierContainer>
      <TierTitle dark semibold>
        {priceTier.name}
      </TierTitle>

      <FromContainer>
        <TextInput
          className="from-input"
          defaultValue={priceTier.from.toString()}
          viewMode={isFirstTier}
          numeric
          mandatory={!isFirstTier}
          error={emptyError}
          errorText={emptyErrorText}
          onValueChange={(value) => {
            setEmptyError(value === "");

            const newPriceTier = {
              ...priceTier,
              from: parseInt(value),
            };

            changePriceTier(newPriceTier);
          }}
          onBlur={(value) => {
            value !== "" && calculatePriceTierValues();
          }}
          extraStyling={css`
            margin: 0;
          `}
        />
      </FromContainer>

      <UntilContainer>
        <TextInput
          className="until-input"
          defaultValue={
            priceTier.until !== null ? priceTier.until.toString() : "-"
          }
          viewMode
          numeric
          onValueChange={() => {}}
          onBlur={() => {}}
          extraStyling={css`
            margin: 0;
          `}
        />
      </UntilContainer>
    </TierContainer>
  );
};

export const CustomerPriceTiersModal: FunctionComponent<Props> = ({
  priceTiers,
  close,
  edit,
}) => {
  const [editedPriceTiers, setEditedPriceTiers] =
    useState<CustomerPriceTierBase[]>(priceTiers);

  const [mainError, setMainError] = useState(false);

  const checkFilledIn = () => {
    let empty = false;

    editedPriceTiers.forEach((tier, index) => {
      const isFirst = index === 0;
      const isLast = index === editedPriceTiers.length - 1;

      if (!isFirst && isNaN(tier.from)) {
        empty = true;
      }
      if (!isLast && tier.until !== null && isNaN(tier.until)) {
        empty = true;
      }
    });

    return !empty;
  };

  const checkValidRanges = () => {
    let error = false;

    editedPriceTiers.forEach((tier, index) => {
      const isFirst = index === 0;

      // Check if tier overlaps with previous tier
      if (!isFirst && tier.from <= editedPriceTiers[index - 1].from + 1) {
        error = true;
      }
    });

    return !error;
  };

  return (
    <CrudModal
      className="customer-price-tiers-modal"
      title={"Edit price tier ranges"}
      content={
        <Container>
          <TierContainer>
            <TierTitle small semibold>
              Tier
            </TierTitle>
            <FromContainer>
              <Text small semibold>
                From
              </Text>
            </FromContainer>
            <UntilContainer>
              <Text small semibold>
                Until
              </Text>
            </UntilContainer>
          </TierContainer>

          {editedPriceTiers.map((tier, index) => {
            return (
              <PriceTier
                key={index}
                priceTier={tier}
                isFirstTier={index === 0}
                changePriceTier={(editedTier) => {
                  setMainError(false);

                  editedPriceTiers[index] = editedTier;
                  setEditedPriceTiers(editedPriceTiers);
                }}
                calculatePriceTierValues={() => {
                  setEditedPriceTiers(
                    editedPriceTiers.map((tier, tierIndex) => {
                      if (tierIndex === index - 1) {
                        let newUntilValue = editedPriceTiers[index].from - 1;
                        if (newUntilValue <= tier.from) {
                          newUntilValue = newUntilValue + 1;
                        }

                        return {
                          ...tier,
                          until: newUntilValue,
                        };
                      } else return tier;
                    })
                  );
                }}
              />
            );
          })}

          {mainError && (
            <Error
              className="main-error-text"
              small
              semibold
              color={$statusFail}
            >
              {mainErrorText}
            </Error>
          )}
        </Container>
      }
      cancel={() => {
        close();
      }}
      action={() => {
        const filledIn = checkFilledIn();
        const validRanges = checkValidRanges();

        if (filledIn && validRanges) {
          edit(editedPriceTiers);
          close();
        } else {
          !validRanges && setMainError(true);
        }
      }}
    />
  );
};
