import Checkbox from "components/common/Checkbox";
import { CrudModal } from "components/common/CrudModal";
import TextInput from "components/common/TextInput";
import {
  SettingsIncidentsApplicationOrGroupContact,
  SimpleSettingsContact,
} from "model/Contact";
import React, { FunctionComponent, useState } from "react";
import { ignoreText, ignoreValue } from "screens/settings/ContactSchedule";
import styled, { css } from "styled-components";
import { $statusFail, Select, Text } from "styles/common";

interface Props {
  contacts: SimpleSettingsContact[];
  contactAreas: string[];
  originalContact: SettingsIncidentsApplicationOrGroupContact | undefined;
  supportWindows: string[];
  close(): void;
  add(contact: SettingsIncidentsApplicationOrGroupContact): void;
  edit(contact: SettingsIncidentsApplicationOrGroupContact): void;
}

const AddContactContentContainer = styled.div``;

const InputContainer = styled.div`
  margin-bottom: 1rem;
`;

const Error = styled(Text)`
  margin: 0 0 0.4rem 0rem;
  font-size: 0.8rem;
`;

export const emptyNameErrorText = "Please fill in a name.";
export const contactsErrorText =
  "Please fill in at least either a technical or a functional contact";

export const SettingApplicationGroupContactModal: FunctionComponent<Props> = ({
  contacts,
  contactAreas,
  supportWindows,
  originalContact,
  close,
  add,
  edit,
}) => {
  const [contact, setContact] =
    useState<SettingsIncidentsApplicationOrGroupContact>({
      id: originalContact ? originalContact.id : 0,
      area: originalContact ? originalContact.area : contactAreas[0],
      name: originalContact ? originalContact.name : "",
      supportWindow: originalContact
        ? originalContact.supportWindow
        : supportWindows[0],
      technicalContact: originalContact
        ? originalContact.technicalContact
        : {
            contact: undefined,
            chase: false,
          },
      functionalContact: originalContact
        ? originalContact.functionalContact
        : {
            contact: undefined,
            chase: false,
          },
    });

  const [nameError, setNameError] = useState(false);
  const [contactsError, setContactsError] = useState(false);

  const renderContactsError = (
    <Error className="contacts-error-text" small semibold color={$statusFail}>
      {contactsErrorText}
    </Error>
  );

  return (
    <CrudModal
      title={originalContact ? "Edit contact" : "Add contact"}
      content={
        <AddContactContentContainer>
          <InputContainer>
            <Text
              semibold
              extraStyling={css`
                margin-bottom: 0.3rem;
              `}
            >
              Area
            </Text>
            {contactAreas.length > 1 ? (
              <Select
                className="area-select"
                defaultValue={contact.area}
                fullWidth
                onChange={(e) => {
                  setContact({ ...contact, area: e.target.value });
                }}
              >
                {contactAreas.map((a, index) => (
                  <option key={index} value={a}>
                    {a}
                  </option>
                ))}
              </Select>
            ) : (
              <Text>{contactAreas[0]}</Text>
            )}
          </InputContainer>

          <InputContainer>
            <TextInput
              className="name-input"
              label="Name"
              defaultValue={contact.name}
              mandatory
              error={nameError}
              errorText={emptyNameErrorText}
              onValueChange={(value) => {
                setNameError(false);
                setContact({ ...contact, name: value });
              }}
            />
          </InputContainer>

          <InputContainer>
            <Text
              semibold
              extraStyling={css`
                margin-bottom: 0.3rem;
              `}
            >
              Support window
            </Text>
            {supportWindows.length > 1 ? (
              <Select
                className="support-window-select"
                defaultValue={contact.supportWindow}
                fullWidth
                onChange={(e) => {
                  setContact({ ...contact, supportWindow: e.target.value });
                }}
              >
                {supportWindows.map((sw, index) => (
                  <option key={index} value={sw}>
                    {sw}
                  </option>
                ))}
              </Select>
            ) : (
              <Text>{supportWindows[0]}</Text>
            )}
          </InputContainer>

          <InputContainer>
            <Text
              semibold
              extraStyling={css`
                margin-bottom: 0.3rem;
              `}
            >
              Technical contact
            </Text>
            <Select
              className="technical-contact-select"
              defaultValue={
                contact.technicalContact.contact
                  ? contact.technicalContact.contact.id
                  : -1
              }
              fullWidth
              onChange={(e) => {
                setContactsError(false);
                const selectedContact = contacts.find(
                  (contact: SimpleSettingsContact) =>
                    contact.id === parseInt(e.target.value)
                );

                setContact({
                  ...contact,
                  technicalContact: {
                    ...contact.technicalContact,
                    contact: selectedContact,
                    chase:
                      selectedContact === undefined
                        ? false
                        : contact.technicalContact.chase,
                  },
                });
              }}
              extraStyling={css`
                margin-bottom: 0.5rem;
              `}
            >
              <option value={ignoreValue}>{ignoreText}</option>
              {contacts.map((c: SimpleSettingsContact, index: number) => (
                <option key={index} value={c.id}>
                  {c.contact} ({c.role})
                </option>
              ))}
            </Select>
            {contactsError && renderContactsError}
            <Checkbox
              label={"Chase"}
              value={contact.technicalContact.chase}
              name={`checkbox-chase-technical-contact-${contact.id}`}
              disabled={contact.technicalContact.contact === undefined}
              onValueChange={() =>
                setContact({
                  ...contact,
                  technicalContact: {
                    ...contact.technicalContact,
                    chase: !contact.technicalContact.chase,
                  },
                })
              }
            />
          </InputContainer>

          <InputContainer>
            <Text
              semibold
              extraStyling={css`
                margin-bottom: 0.3rem;
              `}
            >
              Functional contact
            </Text>
            <Select
              className="functional-contact-select"
              defaultValue={
                contact.functionalContact.contact
                  ? contact.functionalContact.contact.id
                  : -1
              }
              fullWidth
              onChange={(e) => {
                setContactsError(false);
                const selectedContact = contacts.find(
                  (c: SimpleSettingsContact) =>
                    c.id === parseInt(e.target.value)
                );

                setContact({
                  ...contact,
                  functionalContact: {
                    ...contact.functionalContact,
                    contact: selectedContact,
                    chase:
                      selectedContact === undefined
                        ? false
                        : contact.functionalContact.chase,
                  },
                });
              }}
              extraStyling={css`
                margin-bottom: 0.5rem;
              `}
            >
              <option value={ignoreValue}>{ignoreText}</option>
              {contacts.map((c: SimpleSettingsContact, index: number) => (
                <option key={index} value={c.id}>
                  {c.contact} ({c.role})
                </option>
              ))}
            </Select>
            {contactsError && renderContactsError}
            <Checkbox
              label={"Chase"}
              value={contact.functionalContact.chase}
              name={`checkbox-chase-functional-contact-${contact.id}`}
              disabled={contact.functionalContact.contact === undefined}
              onValueChange={() =>
                setContact({
                  ...contact,
                  functionalContact: {
                    ...contact.functionalContact,
                    chase: !contact.functionalContact.chase,
                  },
                })
              }
            />
          </InputContainer>
        </AddContactContentContainer>
      }
      cancel={() => {
        close();
      }}
      action={() => {
        const emptyName = contact.name === "";
        const notOneOfTwoContacts =
          contact.technicalContact.contact === undefined &&
          contact.functionalContact.contact === undefined;

        if (emptyName) {
          setNameError(true);
        }
        if (notOneOfTwoContacts) {
          setContactsError(true);
        }

        if (!emptyName && !notOneOfTwoContacts) {
          originalContact ? edit(contact) : add(contact);
          close();
        }
      }}
      extraStyling={css`
        min-width: 25rem;
      `}
    />
  );
};
