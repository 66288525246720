import { Icon } from "model/Icon";
import React, { FunctionComponent } from "react";
import { icons } from "assets/icons/index";
import styled, { css } from "styled-components";
import { ExtraStylingType } from "model/Styles";

export interface IconProps {
  id?: string;
  name: Icon;
  height?: string;
  color?: string;
  stroke?: string;
  extraStyling?: ExtraStylingType;
  className?: string;
  disabled?: boolean;
  onClick?: (e: any) => void;
}

type StylingProps = Pick<
  IconProps,
  "height" | "extraStyling" | "onClick" | "disabled"
>;

const Container = styled.div<StylingProps>`
  height: ${(props) => props.height};
  line-height: ${(props) => props.height};
  ${(props) =>
    !!props.onClick &&
    !props.disabled &&
    css`
      cursor: pointer;
    `}
  ${(props) => props.extraStyling};
`;

const IconComponent: FunctionComponent<IconProps> = ({
  id = "",
  name,
  height = "1rem",
  color = "#000",
  stroke = "none",
  extraStyling = css``,
  className = "",
  disabled = false,
  onClick = undefined,
}) => {
  const IconName = icons[name];
  return (
    <Container
      className={className}
      id={id}
      height={height}
      disabled={disabled}
      onClick={onClick}
      extraStyling={extraStyling}
    >
      <IconName height={height} fill={color} stroke={stroke} />
    </Container>
  );
};

export default IconComponent as FunctionComponent<IconProps>;
