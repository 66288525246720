import { TotalExecutions } from "model/Integration";
import {
  IntegrationLogicalGroup,
  IntegrationLogicalGroupLevelOneData,
  LogicalGroupLayoutPositionSave,
} from "model/LogicalGroup";
import { GeneralTimeFrame } from "model/Time";
import { formatAPIDate } from "utils/Date";
import request, { ApiResponse } from "../../utils/ApiHandlers";
import { BaseConfig } from "../../utils/Variables";

export class IntegrationApi {
  private static instance: IntegrationApi;

  private constructor() {}

  static getInstance(fresh?: boolean) {
    if (!IntegrationApi.instance || fresh) {
      IntegrationApi.instance = new IntegrationApi();
    }
    return IntegrationApi.instance;
  }

  getTotalExecutionsData = async (
    environmentId: number,
    timeFrame: GeneralTimeFrame
  ): Promise<ApiResponse<TotalExecutions[]>> =>
    request.get(
      `${BaseConfig.getBaseApiUrl()}dashboard/executions/${environmentId}?timeScale=${
        timeFrame.timeJump
      }&startDate=${formatAPIDate(timeFrame.start)}&endDate=${formatAPIDate(
        timeFrame.end
      )}`
    );

  getIntegrationData = async (
    environmentId: number,
    timeFrame: GeneralTimeFrame
  ): Promise<ApiResponse<IntegrationLogicalGroup[]>> =>
    request.get(
      `${BaseConfig.getBaseApiUrl()}integrations/overview/${environmentId}?startDate=${formatAPIDate(
        timeFrame.start
      )}&endDate=${formatAPIDate(timeFrame.end)}`
    );

  getFlowDiagramData = async (
    logicalGroupId: number,
    environmentId: number,
    timeFrame: GeneralTimeFrame
  ): Promise<ApiResponse<IntegrationLogicalGroupLevelOneData>> =>
    request.get(
      `${BaseConfig.getBaseApiUrl()}integrations/workflow/${logicalGroupId}?deploymentEnvironmentId=${environmentId}&startDate=${formatAPIDate(
        timeFrame.start
      )}&endDate=${formatAPIDate(timeFrame.end)}`
    );

  saveFlowDiagramLayouting = async (
    parentLogicalGroupId: number,
    layout: LogicalGroupLayoutPositionSave[]
  ): Promise<ApiResponse<void>> =>
    request.put(
      `${BaseConfig.getBaseApiUrl()}integrations/position/${parentLogicalGroupId}`,
      JSON.stringify(layout)
    );
}

export default IntegrationApi.getInstance();
