import { ReportMonth } from "model/Report";
import { formatAPIDate } from "utils/Date";
import request, { ApiResponse } from "../../utils/ApiHandlers";
import { BaseConfig } from "../../utils/Variables";

export class ReportApi {
  private static instance: ReportApi;

  private constructor() {}

  static getInstance(fresh?: boolean) {
    if (!ReportApi.instance || fresh) {
      ReportApi.instance = new ReportApi();
    }
    return ReportApi.instance;
  }

  getReportStartDate = async (
    customerId: number
  ): Promise<ApiResponse<string>> =>
    request.get(
      `${BaseConfig.getBaseApiUrl()}report/start-date?customerId=${customerId}`
    );

  getReportOfMonth = async (
    customerId: number,
    month: Date
  ): Promise<ApiResponse<ReportMonth>> =>
    request.get(
      `${BaseConfig.getBaseApiUrl()}report?customerId=${customerId}&date=${formatAPIDate(
        month
      )}`
    );
}

export default ReportApi.getInstance();
