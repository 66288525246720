import styled, { css } from "styled-components";
import { $blue5, $grey, regularText } from "styles/common";

export const Container = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
`;

export const PageTop = styled.div`
  flex: 1 0 auto;
`;

export const HeaderBackground = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: -1;
`;

export const HeaderBackgroundBig = styled.div`
  background-image: url(/static/media/header-bg-large.b1744234.svg);
  height: 130px;
  position: relative;
  overflow: hidden;
  background-size: 100% 150%;
`;

export const HeaderBackgroundSmall = styled.div`
  position: absolute;
  top: 10px;
  right: 0;
  width: 458px;
`;

export const Header = styled.div`
  background-color: ${$blue5};
  max-height: 60px;
  height: calc(60px - 0.644rem - 0.644rem);
  padding: 0.644rem 1.5rem;
  display: flex;
  flex-direction: row;
  align-items: center;

  p {
    font-size: 0.9375rem;
  }
`;

export const LogoContainer = styled.a`
  margin-right: 1.5rem;
`;

export const Logo = styled.img`
  vertical-align: middle;
`;

export const Navigation = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  flex: 1;
`;

export const NavigationItem = styled.p<{ active?: boolean }>`
  color: #fff;
  padding: 0.25rem 0px;
  margin-left: 1rem;
  margin-right: 1rem;
  border-bottom: 2px solid transparent;
  color: rgb(249, 251, 252);
  cursor: pointer;

  &:hover {
    color: #fff;
    border-bottom: 2px solid #fff;
  }

  ${(props) =>
    props.active &&
    css`
      color: #fff;
      border-bottom: 2px solid #fff;
    `}
`;

export const HeaderActions = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 2rem;
`;

export const UserButton = styled.button`
  border: none;
  padding: 0px 25px 0px 10px;
  overflow: hidden;
  margin-right: 5px;
  border-radius: 32px;
  opacity: 1;
  position: relative;
  min-height: 32px;
  display: flex;
  flex-direction: row;
  align-items: center;
  transition: all 0.2s ease-in;

  p,
  svg {
    transition: all 0.2s ease-in;
  }

  // &:hover {
  //   background-color: rgb(238, 246, 251);

  //   p,
  //   svg {
  //     color: rgb(95, 119, 135);
  //     fill: rgb(95, 119, 135);
  //   }
  // }
`;

export const UserName = styled.p`
  flex-wrap: wrap;
  text-align: left;
  vertical-align: middle;
  margin-right: 10px;
  padding-left: 10px;
  color: #fff;
`;

// export const CartButton = styled.a`
//   padding: 8px 10px;
//   color: rgb(249, 251, 252);
//   overflow: hidden;
//   margin-right: 5px;
//   border-radius: 32px;
//   opacity: 1;
//   position: relative;
//   transition: all 0.2s ease-in;

//   svg {
//     transition: all 0.2s ease-in;
//   }

//   &:hover {
//     background-color: rgb(238, 246, 251);

//     svg {
//       fill: rgb(95, 119, 135);
//     }
//   }
// `;

// export const NotificationsButton = styled.button`
//   border: none;
//   padding: 0px 25px 0px 10px;
//   overflow: hidden;
//   margin-right: 5px;
//   border-radius: 32px;
//   opacity: 1;
//   position: relative;
//   transition: all 0.2s ease-in;

//   svg {
//     transition: all 0.2s ease-in;
//   }

//   &:hover {
//     background-color: rgb(238, 246, 251);

//     svg {
//       fill: rgb(95, 119, 135);
//     }
//   }
// `;

export const PageTitle = styled.div`
  color: #fff;
  font-size: 1.875rem;
  ${regularText}
  padding: calc(35px - 0.9375rem) 0;
  margin: 0;
  overflow: hidden;
  line-height: 1.875rem;
  text-overflow: ellipsis;
  white-space: nowrap;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
`;

export const Content = styled.div`
  max-width: 1440px;
  width: calc(100% - 30px);
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
`;

export const Footer = styled.footer`
  color: #354a59;
  background-color: #dee7eb;
  margin-top: 2.5rem;
  flex-shrink: 0;
`;

export const FooterTop = styled.div`
  max-width: 1140px;
  width: calc(100% - 30px);
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
`;

// export const FooterContact = styled.div`
//   flex: 0 0 66.66667%;
//   max-width: 66.66667%;
//   position: relative;
//   width: calc(100% - 30px);
//   padding-right: 15px;
//   padding-left: 15px;
//   display: flex;
//   flex-direction: row;
//   align-items: center;
//   box-sizing: border-box;
// `;

// export const ContactImg = styled.img`
//   margin: 1.5rem 1.875rem 1.5rem 0;
//   border-radius: 50%;
//   width: 70px;
//   height: 70px;
//   display: inline-block;
//   vertical-align: middle;
//   align-self: start;
//   border: 4px solid ${$grey};
//   box-sizing: border-box;
// `;

// export const Contact = styled.div`
//   display: flex;
//   flex-direction: column;
//   flex: 1 1;
// `;

// export const ContactInfo = styled.div`
//   display: flex;
//   flex-direction: row;
// `;

// export const ContactInfoLeft = styled.div`
//   width: 100%;
// `;

// export const ContactTitle = styled.h1`
//   ${semiBoldText}
//   font-size: 1.12rem;
//   text-transform: none;
//   margin-bottom: 0.25rem;
// `;

// export const ContactName = styled.p`
//   font-size: 1.25rem;
//   ${boldText}
//   margin-right: 1.875rem;
// `;

// export const ContactFunction = styled.p`
//   font-style: italic;
//   font-size: 0.75rem;
//   margin-bottom: 0.25rem;
//   margin-right: 1.875rem;
// `;

// export const ContactEmail = styled.div`
//   display: flex;
//   flex-direction: row;
//   width: 100%;
// `;

// export const EmailIcon = styled.div`
//   width: 16px;
// `;

// export const Email = styled.a`
//   margin-left: 0.75rem;
//   color: #354a59;
//   font-size: 0.875rem;
//   word-break: break-all;
// `;

export const FooterBottom = styled.div`
  font-size: 0.875rem;
  width: 100%;
  display: block;
  padding: 0.625rem 0;
  line-height: 1;
  border-top: 1px solid hsla(0, 0%, 100%, 0.1);
  text-align: center;
  background-color: ${$grey};
  box-sizing: border-box;
`;

export const FooterBottomContent = styled.div`
  max-width: 1140px;
  opacity: 0.7;
  display: flex;
  flex-direction: row;
  margin: 0 auto;
  justify-content: center;
`;

export const seperatorStyling = css`
  &:after {
    content: "|";
    margin: 0 0.75rem;
  }
`;

export const FooterBottomText = styled.p<{ seperator?: boolean }>`
  font-size: 0.875rem;

  ${(props) => props.seperator && seperatorStyling}
`;

export const FooterBottomLink = styled.a<{ seperator?: boolean }>`
  font-size: 0.875rem;
  color: #354a59;
  text-decoration: underline;
  opacity: 0.7;

  ${(props) => props.seperator && seperatorStyling}
`;

// export const renderArrowIcon = (
//   <IconComponent
//     name={Icon.arrowdown}
//     height="10px"
//     color="#fff"
//     extraStyling={css`
//       position: absolute;
//       right: 10px;
//       top: 45%;
//       color: rgb(255, 255, 255);
//       height: 10px;
//       margin-top: -5px;
//     `}
//   />
// );

export const noClaimsCardStyling = css`
  margin-top: 2rem;
  height: 15rem;
`;
