import { CrudModal } from "components/common/CrudModal";
import TextInput from "components/common/TextInput";
import {
  SettingsChangeRequestContact,
  SimpleSettingsContact,
} from "model/Contact";
import React, { FunctionComponent, useState } from "react";
import styled, { css } from "styled-components";
import { Select, Text } from "styles/common";

interface Props {
  contacts: SimpleSettingsContact[];
  roles: string[];
  originalContact: SettingsChangeRequestContact | undefined;
  close(): void;
  add(contact: SettingsChangeRequestContact): void;
  edit(contact: SettingsChangeRequestContact): void;
}

const AddContactContentContainer = styled.div``;

const InputContainer = styled.div`
  margin-bottom: 1rem;
`;

export const SettingChangeRequestContactModal: FunctionComponent<Props> = ({
  contacts,
  roles,
  originalContact,
  close,
  add,
  edit,
}) => {
  const [contact, setContact] = useState<SettingsChangeRequestContact>({
    id: originalContact ? originalContact.id : 0,
    role: originalContact ? originalContact.role : roles[0],
    contact: originalContact ? originalContact.contact : contacts[0],
    comments: originalContact ? originalContact.comments : undefined,
  });

  return (
    <CrudModal
      title={originalContact ? "Edit contact" : "Add contact"}
      content={
        <AddContactContentContainer>
          <InputContainer>
            <Text
              semibold
              extraStyling={css`
                margin-bottom: 0.3rem;
              `}
            >
              Role
            </Text>
            {roles.length > 1 ? (
              <Select
                className="role-select"
                defaultValue={contact.role}
                fullWidth
                onChange={(e) => {
                  setContact({ ...contact, role: e.target.value });
                }}
              >
                {roles.map((r, index) => (
                  <option key={index} value={r}>
                    {r}
                  </option>
                ))}
              </Select>
            ) : (
              <Text>{roles[0]}</Text>
            )}
          </InputContainer>
          <InputContainer>
            <Text
              semibold
              extraStyling={css`
                margin-bottom: 0.3rem;
              `}
            >
              Contact
            </Text>
            {contacts.length > 1 ? (
              <Select
                className="contact-select"
                defaultValue={contact.contact.id}
                fullWidth
                onChange={(e) => {
                  const selectedContact = contacts.find(
                    (c) => c.id.toString() === e.target.value
                  );

                  selectedContact &&
                    setContact({ ...contact, contact: selectedContact });
                }}
              >
                {contacts.map((c, index) => (
                  <option key={index} value={c.id}>
                    {c.contact} ({c.role})
                  </option>
                ))}
              </Select>
            ) : (
              <Text>{roles[0]}</Text>
            )}
          </InputContainer>
          <InputContainer>
            <TextInput
              className="comments-input"
              label="Comments"
              defaultValue={contact.comments ? contact.comments : ""}
              onValueChange={(value) =>
                setContact({ ...contact, comments: value })
              }
              multiline
            />
          </InputContainer>
        </AddContactContentContainer>
      }
      cancel={() => {
        close();
      }}
      action={() => {
        originalContact ? edit(contact) : add(contact);
        close();
      }}
      extraStyling={css`
        min-width: 25rem;
      `}
    />
  );
};
