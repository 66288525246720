import { ExecutionsTimeUnit, executionsTimeUnits } from "model/Time";

export const round = (number: number, decimals: number) => {
  // Round to x decimals if applicable (doesn't add zeros after comma if it's not needed)
  let multiplier = 1;

  for (let i = 0; i < decimals; i++) {
    multiplier = multiplier * 10;
  }

  return Math.round((number + Number.EPSILON) * multiplier) / multiplier;
};

export const getExecutionsTimeUnit = (unitId: ExecutionsTimeUnit): string =>
  (
    executionsTimeUnits.find((unit) => unit.id === unitId) ||
    executionsTimeUnits[ExecutionsTimeUnit.Second]
  ).name;

export const convertExecutions = (number: number) => {
  let string = number.toString();

  if (number > 999) {
    // Convert 234654 to 234.65k
    number = number / 1000;
    number = round(number, 1);
    string = number.toString() + "k";
  }

  return string;
};

export const getExecutionsString = (
  executionsPerTimeUnit: number,
  executionsTimeUnit: ExecutionsTimeUnit
) => `${executionsPerTimeUnit}${getExecutionsTimeUnit(executionsTimeUnit)}`;
