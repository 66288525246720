import IconComponent from "components/common/Icon";
import { List } from "components/common/List";
import { EditButton } from "components/common/List/common";
import SearchInput from "components/common/SearchInput";
import { BaseCustomer } from "model/Customer";
import { Icon } from "model/Icon";
import { Subscription } from "model/User";
import React, { useState } from "react";
import AuthenticationStore from "stores/AuthenticationStore";
import styled from "styled-components";
import { Text, Button, IconButton, $textLight } from "styles/common";

interface Props {
  customers: BaseCustomer[] | undefined;
  defaultSubscription: Subscription | undefined;
  viewAccess: boolean;
  editAccess: boolean;
  loading: boolean;
  authenticationStore: AuthenticationStore,
  onAdd(): void;
  onEdit(customer: BaseCustomer): void;
  onCustomerSwitch(customerId: number): void;
  retryDataLoading(): void;
}

const Container = styled.div`
  margin-bottom: 1rem;
`;

const HeaderContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-bottom: 1rem;
`;

const headers = ["Customer", "", ""];

export const CustomersList = ({
  customers,
  defaultSubscription,
  viewAccess,
  editAccess,
  loading,
  authenticationStore,
  onAdd,
  onEdit,
  onCustomerSwitch,
  retryDataLoading,
}: Props) => {
  const [searchList, setSearchList] = useState<BaseCustomer[] | undefined>(
    undefined
  );

  const getSearchItems = (filter: string) => {
    if (customers) {
      if (filter === "") {
        setSearchList(undefined);
      } else {
        const filteredList = customers.filter((customer) =>
          customer.name.toLowerCase().includes(filter.toLowerCase())
        );
        if (filteredList.length > 0) {
          setSearchList(filteredList);
        } else {
          setSearchList([]);
        }
      }
    }
  };

  const renderSearch = (
    <SearchInput
      className="customers-search-input"
      onValueChange={(value) => {
        getSearchItems(value);
      }}
    />
  );

  const getCustomerCells = (list: BaseCustomer[]) =>
    list.map((customer: BaseCustomer) => {
      const switchButton = (authenticationStore.userPermissionsInfo?.subscriptions.find(s => s.customerId === customer.id) ?
        <IconButton
          className="login-button"
          onClick={() => {
            onCustomerSwitch(customer.id);
          }}
        >
          <IconComponent name={Icon.user} height="12px" color={$textLight} />
          <Text small>Switch to customer</Text>
        </IconButton>
      : <></>);

      const editButton = (authenticationStore.userPermissionsInfo?.subscriptions.find(s => s.customerId === customer.id) ?
        <EditButton
          className="edit-customer-button"
          onClick={() => onEdit(customer)}
        />
      : <></>);

      const baseCells = [
        customer.name,
        customer.id !== defaultSubscription?.customerId ? switchButton : <></>,
      ];

      return {
        cells: editAccess ? [...baseCells, editButton] : baseCells,
      };
    });

  return (
    <Container>
      <HeaderContainer>
        {customers && customers.length > 0 && renderSearch}

        {customers && editAccess && (
          <Button
            className="add-customer-button"
            onClick={() => {
              onAdd();
            }}
          >
            Create new customer
          </Button>
        )}
      </HeaderContainer>

      <List
        className="customers-list"
        headers={headers}
        data={
          searchList
            ? getCustomerCells(searchList)
            : customers
              ? getCustomerCells(customers)
              : undefined
        }
        selectedRow={customers?.findIndex(
          (customer) => customer.id === defaultSubscription?.customerId
        )}
        customColumnWidths={editAccess ? [20, 3, 1] : [20, 3]}
        hover
        access={viewAccess}
        noDataText="There are no customers yet."
        smallEmpty
        loading={loading}
        searchMode={searchList !== undefined}
        retryDataLoading={retryDataLoading}
      />
    </Container>
  );
};
