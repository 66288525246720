import { List } from "components/common/List";
import { format, isValid } from "date-fns";
import { getReportIncidentStatus, ReportIncident } from "model/Report";
import React from "react";
import styled from "styled-components";

interface Props {
  incidents: ReportIncident[] | undefined;
  access: boolean;
  retryDataLoading(): void;
}

const Container = styled.div`
  margin-bottom: 1rem;
`;

// const BreachText = styled(Text)<{ breached?: boolean }>`
//   ${(props) =>
//     props.breached !== undefined &&
//     css`
//       color: ${props.breached ? $statusFail : "#91ba67"};
//     `}
// `;

const headers = [
  "Number",
  "Priority",
  "Description",
  "Created by",
  "Created (CET)",
  "Started (CET)",
  "Resolved (CET)",
  "Status",
  // "Response Time",
  // "Resolution Time",
];

export const ReportIncidentsList = ({
  incidents,
  access,
  retryDataLoading,
}: Props) => {
  return (
    <Container>
      <List
        headers={headers}
        data={
          incidents
            ? incidents.map((incident: ReportIncident) => ({
                cells: [
                  incident.ticket,
                  incident.priority,
                  incident.description,
                  incident.createdBy,
                  incident.created && isValid(incident.created)
                    ? format(incident.created, "yyyy-MM-dd HH:MM")
                    : "-",
                  incident.started && isValid(incident.started)
                    ? format(incident.started, "yyyy-MM-dd HH:MM")
                    : "-",
                  incident.resolved && isValid(incident.resolved)
                    ? format(incident.resolved, "yyyy-MM-dd HH:MM")
                    : "-",
                  getReportIncidentStatus(incident.status).name,
                  // <BreachText
                  //   breached={
                  //     incident.status !== ReportIncidentStatus.Open
                  //       ? incident.responseTimeBreached
                  //       : undefined
                  //   }
                  // >
                  //   {incident.responseTime ? incident.responseTime : "-"}
                  // </BreachText>,
                  // <BreachText
                  //   breached={
                  //     incident.status !== ReportIncidentStatus.Open
                  //       ? incident.resolutionTimeBreached
                  //       : undefined
                  //   }
                  // >
                  //   {incident.resolutionTime ? incident.resolutionTime : "-"}
                  // </BreachText>,
                ],
              }))
            : undefined
        }
        customColumnWidths={[1, 1, 5, 2, 3, 3, 3, 2 /*2, 2*/]}
        hover
        access={access}
        noDataText="You don't have any incidents for this month."
        smallEmpty
        onRowClick={(rowId: number) => {
          incidents &&
            window.open(
              `https://horizon.cegeka.com/collaboration/ticket/${incidents[rowId].ticket}`
            );
        }}
        retryDataLoading={retryDataLoading}
      />
    </Container>
  );
};
