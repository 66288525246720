import { CrudModal } from "components/common/CrudModal";
import TextInput from "components/common/TextInput";
import { SettingsLogicalGroup } from "model/LogicalGroup";
import React, { FunctionComponent, useState } from "react";
import styled, { css } from "styled-components";
import { Select, Text } from "styles/common";

interface Props {
  logicalGroups: SettingsLogicalGroup[];
  selectedParent: SettingsLogicalGroup | undefined;
  add(name: string, parent: number | undefined): void;
  close(): void;
}

const AddContactContentContainer = styled.div``;

const InputContainer = styled.div`
  margin-bottom: 1rem;
`;

const SelectedLocation = styled.div`
  background-color: #f6f6f6;
  margin: 0 0.25rem;
  padding: 0.6rem;
  border-radius: 0 0 0.3rem 0.3rem;
`;

export const emptyNameErrorText = "Please fill in a name.";

export const SettingLogicalGroupModal: FunctionComponent<Props> = ({
  logicalGroups,
  selectedParent,
  add,
  close,
}) => {
  const [name, setName] = useState<string>("");
  const [parent, setParent] = useState<number | undefined>(selectedParent?.id);

  let locationPaths: {
    groupId: number;
    path: string;
  }[] = [];

  const [nameError, setNameError] = useState(false);

  // Recursively get all options for parent group select (only groups without technical components)
  const getOptionsFromLevel = (
    logicalGroupLevel: SettingsLogicalGroup[],
    path?: string
  ): JSX.Element[] => {
    return logicalGroupLevel.map((group) => {
      const option = (
        <option key={group.id} value={group.id}>
          {group.name}
        </option>
      );

      locationPaths.push({
        groupId: group.id,
        path: `${path ? path : ""}${group.name}`,
      });

      if (group.logicalGroups !== undefined && group.logicalGroups !== null) {
        const subGroups = getOptionsFromLevel(
          group.logicalGroups,
          `${path ? path : ""}${group.name} / `
        );
        return (
          <>
            {option}
            {subGroups}
          </>
        );
      } else {
        return group.systemGroup ||
          (group.technicalComponents !== undefined &&
            group.technicalComponents !== null) ? (
          <></>
        ) : (
          option
        );
      }
    });
  };

  return (
    <CrudModal
      className="add-logical-group-modal"
      title={"Add group"}
      content={
        <AddContactContentContainer>
          <InputContainer>
            <TextInput
              className="group-name-input"
              label="Group name"
              defaultValue={name}
              mandatory
              error={nameError}
              errorText={emptyNameErrorText}
              onValueChange={(value) => setName(value)}
            />
          </InputContainer>
          <InputContainer>
            <Text
              semibold
              extraStyling={css`
                margin-bottom: 0.3rem;
              `}
            >
              Parent group
            </Text>
            {logicalGroups.filter(l => !l.systemGroup).length > 0 ? (
              <Select
                className="parent-group-select"
                defaultValue={parent}
                fullWidth
                onChange={(e) => {
                  setParent(
                    e.target.value === undefined
                      ? e.target.value
                      : parseInt(e.target.value)
                  );
                }}
              >
                <option key={"none"} value={undefined}>
                  Root
                </option>
                {getOptionsFromLevel(logicalGroups, "")}
              </Select>
            ) : (
              <Text>Root</Text>
            )}
            {parent ? (
              <SelectedLocation>
                <Text
                  small
                  semibold
                  extraStyling={css`
                    margin-bottom: 0.3rem;
                  `}
                >
                  Selected Location:
                </Text>
                <Text small>
                  {locationPaths.find((path) => path.groupId === parent)
                    ?.path || "Root"}
                </Text>
              </SelectedLocation>
            ) : (
              <></>
            )}
          </InputContainer>
        </AddContactContentContainer>
      }
      cancel={() => {
        close();
      }}
      action={() => {
        const emptyName = name === "";

        if (emptyName) {
          setNameError(true);
        } else {
          add(name, parent);
          close();
        }
      }}
      extraStyling={css`
        min-width: 25rem;
      `}
    />
  );
};
