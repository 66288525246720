import { ItOperationLocation } from "model/ItOperation";
import { GeneralTimeFrame } from "model/Time";
import { formatAPIDate } from "utils/Date";
import request, { ApiResponse } from "../../utils/ApiHandlers";
import { BaseConfig } from "../../utils/Variables";

export class ItOperationApi {
  private static instance: ItOperationApi;

  private constructor() {}

  static getInstance(fresh?: boolean) {
    if (!ItOperationApi.instance || fresh) {
      ItOperationApi.instance = new ItOperationApi();
    }
    return ItOperationApi.instance;
  }

  getItOperationData = async (
    environmentId: number,
    timeFrame: GeneralTimeFrame
  ): Promise<ApiResponse<ItOperationLocation[]>> =>
    request.get(
      `${BaseConfig.getBaseApiUrl()}itoperations/${environmentId}?startDate=${formatAPIDate(
        timeFrame.start
      )}&endDate=${formatAPIDate(timeFrame.end)}`
    );
}

export default ItOperationApi.getInstance();
