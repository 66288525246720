/* eslint-disable camelcase */

import { IPublicClientApplication } from "@azure/msal-browser";
import { BaseConfig } from "./Variables";

interface ErrorData {
  status: number;
  title?: string;
  detail?: string;
  traceId?: string;
  type?: string;
}

export interface ApiResponseSuccess<T> {
  success: true;
  data: T;
}

export interface ApiResponseFailed {
  success: false;
  errorData: ErrorData;
}

export type ApiResponse<T> = ApiResponseSuccess<T> | ApiResponseFailed;

export class ApiHandlers {
  private static instance: ApiHandlers;
  private msalInstance: IPublicClientApplication | undefined;

  private constructor() {
  }

  static getInstance(fresh = false) {
    if (!ApiHandlers.instance || fresh) {
      ApiHandlers.instance = new ApiHandlers();
    }
    return ApiHandlers.instance;
  }

  public setMsalInstance(msalInstance: IPublicClientApplication)
  {
    this.msalInstance = msalInstance;
  }

  private getJsonResponse = async (rawResponse: Response) => {
    try {
      const response = !rawResponse ? null : await rawResponse.json();
      return !response ? null : response;
    } catch (error) {
      return rawResponse;
    }
  };

  private getFileResponse = async (rawResponse: Response) => {
    try {
      const response = !rawResponse ? null : await rawResponse.blob();
      return !response ? null : response;
    } catch (error) {
      return rawResponse;
    }
  };

  private getResponse = async (
    method: string,
    url: string,
    init: RequestInit = {},
    file: boolean = false
  ): Promise<Response> => {
    return await fetch(encodeURI(url), {
      ...init,
      method,
      headers: file ? await this.getFileHeaders() : await this.getHeaders(),
    });
  };

  fetch = async <T>(
    method: string,
    url: string,
    init: RequestInit = {}
  ): Promise<ApiResponse<T>> => {
    try {
      const rawResponse = await this.getResponse(method, url, init);
      const response = await this.getJsonResponse(rawResponse);
      const { ok, status } = rawResponse;

      return ok
        ? { success: true, data: response }
        : { success: false, errorData: { status } };
    } catch (error: any) {
      return { success: false, errorData: error };
    }
  };

  fetchFile = async (
    method: string,
    url: string,
    init: RequestInit = {}
  ): Promise<ApiResponse<any>> => {
    try {
      const rawResponse = await this.getResponse(method, url, init, true);
      const response = await this.getFileResponse(rawResponse);
      const { ok, status } = rawResponse;

      return ok && status === 200
        ? { success: true, data: response }
        : { success: false, errorData: { status } };
    } catch (error: any) {
      return { success: false, errorData: error };
    }
  };

  async get<T>(url: string): Promise<ApiResponse<T>> {
    return this.fetch<T>("GET", url, undefined);
  }

  async getFile(url: string): Promise<ApiResponse<Blob>> {
    return this.fetchFile("GET", url, undefined);
  }

  private stringifyIfObject = (obj: string | any) =>
    typeof obj === "object" ? JSON.stringify(obj) : obj;

  async post<T>(url: string, body?: any): Promise<ApiResponse<T>> {
    return this.fetch<T>("POST", url, { body: this.stringifyIfObject(body) });
  }

  async uploadFile<T>(url: string, file: File): Promise<ApiResponse<T>> {
    const data = new FormData();
    data.append("file", file);

    return this.fetchFile("PUT", url, {
      body: data,
    });
  }

  async put<T>(url: string, body: string | any): Promise<ApiResponse<T>> {
    return this.fetch<T>("PUT", url, {
      body: this.stringifyIfObject(body),
    });
  }

  async delete<T>(url: string): Promise<ApiResponse<T>> {
    return this.fetch<T>("DELETE", url, undefined);
  }

  async getHeaders(): Promise<HeadersInit> {
    if (this.msalInstance) {
      const activeAccount = this.msalInstance.getActiveAccount();
      const accounts = this.msalInstance.getAllAccounts();
  
      if (!activeAccount && accounts.length === 0) {
        console.log("Error: There is no signed in user");
      }
      const request = {
        scopes: [BaseConfig.getAuth().scopeUrl],
        account: activeAccount || accounts[0],
      };
  
      const authResult = await this.msalInstance.acquireTokenSilent(request);

      return {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${authResult.accessToken}`,
      } as HeadersInit;
    }

    return {
      Accept: "application/json",
      "Content-Type": "application/json",
    } as HeadersInit;
  }

  getResponseHeaders = (headers: Headers) => {
    let headerObj: any = {};
    const keys = headers.keys();
    let header = keys.next();
    while (header.value) {
      headerObj[header.value] = headers.get(header.value);
      header = keys.next();
    }
    return headerObj;
  };

  async getFileHeaders(): Promise<HeadersInit> {
    if (this.msalInstance) {
      const activeAccount = this.msalInstance.getActiveAccount();
      const accounts = this.msalInstance.getAllAccounts();
  
      if (!activeAccount && accounts.length === 0) {
        console.log("Error: There is no signed in user");
      }
      const request = {
        scopes: [BaseConfig.getAuth().scopeUrl],
        account: activeAccount || accounts[0],
      };
  
      const authResult = await this.msalInstance.acquireTokenSilent(request);
  
      return {
        Accept: "application/json, text/plain, */*",
        Authorization: `Bearer ${authResult.accessToken}`,
      } as HeadersInit;
    }

    return {
      Accept: "application/json, text/plain, */*",
    } as HeadersInit;
  }
}

export default ApiHandlers.getInstance();
