import { format, sub } from "date-fns";
import { GlobalTimeFrame, UsageTimeFrame } from "model/Time";

export const weekDays = [
  "Sunday",
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
];

export const months = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

export const getLastHoursLabels = (hours: number, date?: Date) => {
  let hour = date ? date.getHours() : new Date().getHours();
  let hoursList = [];

  for (let i = 0; i < hours; i++) {
    hoursList.push(`${hour < 10 ? "0" : ""}${hour}:00`);
    if (hour > 0) {
      hour--;
    } else {
      hour = 23;
    }
  }

  return hoursList.reverse();
};

export const getDaysAgo = (date: Date, numberOfDaysAgo: number) =>
  sub(date, {
    days: numberOfDaysAgo,
  });

export const getMonthsAgo = (date: Date, numberOfMonthsAgo: number) =>
  sub(date, {
    months: numberOfMonthsAgo,
  });

export const getLastDaysLabels = (days: number, date?: Date) => {
  let day = date ? date.getDay() : new Date().getDay();
  let daysList = [];

  for (let i = 0; i < days; i++) {
    daysList.push(weekDays[day]);
    if (day > 0) {
      day--;
    } else {
      day = 6;
    }
  }

  return daysList.reverse();
};

export const getLastWeekLabels = (date?: Date) => {
  let day = date ? date.getDay() : new Date().getDay();
  let daysList = [];

  for (let i = 0; i < 7; i++) {
    daysList.push(weekDays[day]);
    if (day > 0) {
      day--;
    } else {
      day = 6;
    }
  }

  return daysList.reverse();
};

export const getLastMonthLabels = (date?: Date) => {
  let day = date ? date : new Date();
  let daysList = [];

  for (let i = 0; i < 30; i++) {
    daysList.push(`${day.getDate()}`);
    day.setDate(day.getDate() - 1);
  }

  return daysList.reverse();
};

export const getLastQuarterLabels = (date?: Date) => {
  let month = date ? date : new Date();
  let monthsList = [];

  for (let i = 0; i < 4; i++) {
    monthsList.push(`${months[month.getMonth()]}`);
    month.setMonth(month.getMonth() - 1);
  }

  return monthsList.reverse();
};

export const getFlowTimeDate = (date: Date) => format(date, "dd/MM/yyyy");

export const getFlowTimeHours = (date: Date) => format(date, "hh:mm:ss");

export const getGlobalStartDate = (
  date: Date,
  timeFrame: GlobalTimeFrame
): Date => {
  switch (timeFrame) {
    case GlobalTimeFrame.ThreeDays:
      return getDaysAgo(date, 3);
    case GlobalTimeFrame.Week:
      return getDaysAgo(date, 7);
    case GlobalTimeFrame.Month:
      return getMonthsAgo(date, 1);
    case GlobalTimeFrame.Quarter:
      return getMonthsAgo(date, 3);
    default:
      return getDaysAgo(date, 1); // Hours
  }
};

export const getUsageStartDate = (
  date: Date,
  timeFrame: UsageTimeFrame
): Date => {
  switch (timeFrame) {
    case UsageTimeFrame.TwoMonths:
      return getMonthsAgo(date, 2);
    case UsageTimeFrame.Quarter:
      return getMonthsAgo(date, 3);
    default:
      return getMonthsAgo(date, 1); // Month
  }
};

export const isFirstDayOfMonth = (date: Date) => {
  var testDate = new Date(date.getTime()),
    month = testDate.getMonth();

  testDate.setDate(testDate.getDate() - 1);
  return testDate.getMonth() !== month;
};

export const isLastDayOfMonth = (date: Date) => {
  var testDate = new Date(date.getTime()),
    month = testDate.getMonth();

  testDate.setDate(testDate.getDate() + 1);
  return testDate.getMonth() !== month;
};

export const formatAPIDate = (date: Date): string =>
  format(date, "yyyy-MM-dd HH:mm:ss");
