import { ExtraStylingType } from "model/Styles";
import React, { FunctionComponent, useState, useEffect } from "react";
import GeneralStore from "stores/GeneralStore";
import styled, { css } from "styled-components";
import { Text, Select, Span, inputStyling, $statusFail } from "styles/common";
import { PhoneNumberUtil } from "google-libphonenumber";
import { inject, observer } from "mobx-react";
import { defaultCountryPhoneCode, PhoneNumber } from "model/Phone";
import { validateNumber } from "utils/Validation";

interface Props {
  label: string;
  defaultValue: PhoneNumber | undefined;
  mandatory?: boolean;
  error?: boolean;
  errorText?: string;
  className?: string;
  onValueChange(phoneNumber: PhoneNumber): void;
  extraStyling?: ExtraStylingType;
  generalStore: GeneralStore;
}

const Container = styled.div<{ extraStyling: ExtraStylingType }>`
  ${(props) => props.extraStyling}
`;

const Label = styled(Text)`
  margin-bottom: 0.3rem;
`;

const InputContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.5rem;
`;

const Input = styled.input`
  ${inputStyling}
  width: 100%;
`;

const Error = styled(Text)`
  margin: 0.15rem 0 0 0rem;
  font-size: 0.8rem;
`;

const PhoneInput: FunctionComponent<Props> = ({
  label,
  defaultValue,
  mandatory = false,
  error = false,
  errorText = undefined,
  className = "",
  onValueChange,
  extraStyling = css``,
  generalStore,
}) => {
  const [phoneNumber, setPhoneNumber] = useState<PhoneNumber | undefined>(
    defaultValue
  );

  useEffect(() => {
    if (!generalStore.countryPhoneCodes) {
      const phoneUtil = PhoneNumberUtil.getInstance();
      const countryCodes = phoneUtil.getSupportedRegions();

      generalStore.setCountryPhoneCodes(
        countryCodes.map((country: string) => ({
          country: country,
          calCode: phoneUtil.getCountryCodeForRegion(country).toString(),
        }))
      );
    }

    if (!phoneNumber) {
      setPhoneNumber({
        code: defaultCountryPhoneCode.calCode,
        number: "",
      });
    }
  }, [generalStore, phoneNumber]);

  return (
    <Container className={className} extraStyling={extraStyling}>
      <Label semibold>
        {label}
        {mandatory ? (
          <Span className="mandatory-star" color={$statusFail}>
            *
          </Span>
        ) : (
          ""
        )}
      </Label>
      {phoneNumber && (
        <InputContainer>
          <Text>+</Text>
          {generalStore.countryPhoneCodes && (
            <Select
              defaultValue={defaultCountryPhoneCode.country}
              highlight
              onChange={(e) => {
                const countryPhoneCode = generalStore.getCountryPhoneCode(
                  e.target.value
                );
                const newPhoneNumber = {
                  ...phoneNumber,
                  code: countryPhoneCode ? countryPhoneCode.calCode : "",
                };
                setPhoneNumber(newPhoneNumber);
                onValueChange(newPhoneNumber);
              }}
            >
              {generalStore.countryPhoneCodes.map((countryPhoneCode, index) => (
                <option key={index} value={countryPhoneCode.country}>
                  ({countryPhoneCode.country}) {countryPhoneCode.calCode}
                </option>
              ))}
            </Select>
          )}
          <Input
            value={phoneNumber ? phoneNumber.number : ""}
            onChange={(e) => {
              if (validateNumber(e.target.value)) {
                const newPhoneNumber = {
                  ...phoneNumber,
                  number: e.target.value,
                };
                setPhoneNumber(newPhoneNumber);
                onValueChange(newPhoneNumber);
              }
            }}
          />
        </InputContainer>
      )}
      {error && errorText && (
        <Error className="error-text" small semibold color={$statusFail}>
          {errorText}
        </Error>
      )}
    </Container>
  );
};

export default inject("generalStore")(
  observer(PhoneInput as FunctionComponent<Omit<Props, "generalStore">>)
);
