import { Icon } from "model/Icon";
import { ExtraStylingType } from "model/Styles";
import React, { FunctionComponent } from "react";
import styled, { css } from "styled-components";
import { $textLight, inputStyling } from "styles/common";
import IconComponent from "../Icon";

interface Props {
  className?: string;
  onValueChange(value: string): void;
  extraStyling?: ExtraStylingType;
}

const Container = styled.div`
  position: relative;
  margin: 0 0.75rem;
`;

const Search = styled.input<{ extraStyling: ExtraStylingType }>`
  ${inputStyling}
  padding-right: 2rem;
`;

const iconStyling = css`
  position: absolute;
  right: 0.6rem;
  top: 50%;
  transform: translateY(-50%);
`;

const SearchInput: FunctionComponent<Props> = ({
  className = "",
  onValueChange,
  extraStyling = css``,
}) => {
  return (
    <Container>
      <Search
        className={className}
        name="search"
        placeholder="Search"
        onChange={(e) => {
          onValueChange(e.target.value);
        }}
        extraStyling={extraStyling}
      />
      <IconComponent
        name={Icon.search}
        extraStyling={iconStyling}
        color={$textLight}
      />
    </Container>
  );
};

export default SearchInput as FunctionComponent<Props>;
