import IconComponent from "components/common/Icon";
import { DeleteButton, EditButton } from "components/common/List/common";
import { SettingsLogicalGroup } from "model/LogicalGroup";
import { Icon } from "model/Icon";
import React, { FunctionComponent, useState } from "react";
import styled, { css } from "styled-components";
import { Text, IconButton, $textDark, $blue4, $lightRed } from "styles/common";

export interface Props {
  item: SettingsLogicalGroup;
  index: number;
  levelCount: number;
  levelIndex: number;
  selectedLogicalGroup: SettingsLogicalGroup | undefined;
  editAccess: boolean;
  deleteAccess: boolean;
  setSelectedLogicalGroup(logicalGroup: SettingsLogicalGroup | undefined): void;
  deleteLogicalGroup(logicalGroup: SettingsLogicalGroup): void;
  moveUpLogicalGroup(logicalGroup: SettingsLogicalGroup): void;
  moveDownLogicalGroup(logicalGroup: SettingsLogicalGroup): void;
  renameLogicalGroup(logicalGroup: SettingsLogicalGroup, name: string): void;
}

const ItemContainer = styled.div<{ firstLevel?: boolean }>`
  position: relative;

  ${(props) =>
    props.firstLevel &&
    css`
      margin-bottom: 0.5rem;
    `}
`;

const Hook = styled.div<{ selected: boolean }>`
  width: 0.75rem;
  margin-right: 0.25rem;
  height: 1px;
  background-color: ${(props) => (props.selected ? $blue4 : "#d8eaf4")};
`;

const ItemBar = styled.div<{ selected: boolean; firstLevel?: boolean }>`
  padding: 0.3rem 0.75rem 0.3rem 0rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  cursor: pointer;
  transition: 0.2s;

  ${(props) =>
    props.selected
      ? css`
          background-color: ${$blue4};

          p,
          span {
            color: #fff !important;
          }

          svg {
            fill: #fff !important;
          }
        `
      : css`
          &:hover {
            background-color: #d8eaf4;
          }
        `}

  ${(props) =>
    props.firstLevel &&
    css`
      padding-left: 0.3rem;
    `}
`;

const ItemNameContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const ItemName = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.5rem;
  cursor: pointer;
`;

const ItemActionsContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.5rem;
`;

const SubItemsContainer = styled.div<{ opened: boolean; firstLevel?: boolean }>`
  transition: 2s;
  margin-left: ${(props) => (props.firstLevel ? `0.8rem` : `1.5rem`)};
  border-left: 1px solid #d8eaf4;
  display: ${(props) => (props.opened ? "block" : "none")};
`;

const NoData = styled(Text)`
  padding: 0.3rem 0.75rem 0.3rem 0.9rem;
  margin-bottom: 0.25rem;
`;

const DeleteButtonContainer = styled.div`
  margin-left: 1rem;
`;

const SettingGroupWorkflowTreeItem: FunctionComponent<Props> = ({
  item,
  index,
  levelCount,
  levelIndex,
  selectedLogicalGroup,
  editAccess,
  deleteAccess,
  setSelectedLogicalGroup,
  deleteLogicalGroup,
  moveUpLogicalGroup,
  moveDownLogicalGroup,
  renameLogicalGroup
}) => {
  const [opened, setOpened] = useState(false);

  const firstLevel = levelIndex === 0;
  const lastLevel =
    item.logicalGroups === null || item.logicalGroups === undefined;
  const notLastLevelWithParent = (lastLevel && firstLevel) || !lastLevel;
  const selected =
    selectedLogicalGroup !== undefined && selectedLogicalGroup.id === item.id;
  const childCount = item.logicalGroups
    ? item.logicalGroups.length
    : item.technicalComponents
      ? item.technicalComponents.length
      : 0;

  const disableMoveUp = index === 0;
  const disableMoveDown = index === levelCount - 1;

  const handleItemClick = () => {
    if (selectedLogicalGroup === item && opened) {
      setSelectedLogicalGroup(undefined);
    } else {
      if (!opened) {
        setSelectedLogicalGroup(item);
      }
    }

    setOpened(!opened);
  };

  return (
    <ItemContainer
      className={`logical-group-tree-item logical-group-tree-item-level${levelIndex}`}
    >
      <ItemBar
        selected={selected}
        firstLevel={firstLevel}
        onClick={handleItemClick}
        className="logical-group-tree-item-bar"
      >
        <ItemNameContainer>
          {!firstLevel && <Hook selected={selected} />}
          <ItemName>
            <IconComponent
              name={
                item.systemGroup
                  ? Icon.unassigned
                  : notLastLevelWithParent
                    ? opened
                      ? Icon.minus
                      : Icon.plus
                    : Icon.block
              }
              className={`expand-icon ${opened ? "icon-minus" : "icon-plus"}`}
              color={
                item.systemGroup
                  ? $blue4
                  : notLastLevelWithParent
                    ? firstLevel && !item.logicalGroups
                      ? $lightRed
                      : $blue4
                    : "rgba(	76, 159, 206, 0.2)"
              }
              onClick={e => {
                e.stopPropagation();
                setOpened(!opened);
              }}
            />
            <Text dark semibold={firstLevel}>
              {item.name}
            </Text>
            <Text small>({childCount})</Text>
          </ItemName>
        </ItemNameContainer>
        <ItemActionsContainer>
          {editAccess && !item.systemGroup && (
            <>
              <IconButton
                className="move-up-logical-group-button"
                disabled={disableMoveUp}
                hoverColor={"#000"}
                onClick={(e) => {
                  e.stopPropagation();
                  !disableMoveUp && moveUpLogicalGroup(item);
                }}
              >
                <IconComponent
                  name={Icon.arrowdown}
                  height="12px"
                  color={$textDark}
                  extraStyling={
                    index === 0
                      ? css`
                          transform: rotate(180deg);
                          cursor: default;
                          opacity: 0.5;
                        `
                      : css`
                          transform: rotate(180deg);
                        `
                  }
                />
              </IconButton>
              <IconButton
                className="move-down-logical-group-button"
                disabled={disableMoveDown}
                hoverColor={"#000"}
                onClick={(e) => {
                  e.stopPropagation();
                  !disableMoveDown && moveDownLogicalGroup(item);
                }}
              >
                <IconComponent
                  name={Icon.arrowdown}
                  height="12px"
                  color={$textDark}
                  extraStyling={
                    index === levelCount - 1
                      ? css`
                          cursor: default;
                          opacity: 0.5;
                        `
                      : css``
                  }
                />
              </IconButton>
            </>
          )}
          {editAccess && !item.systemGroup && (<EditButton onClick={() => {
            renameLogicalGroup(item, 'Test');
          }}></EditButton>)}
          {deleteAccess && !item.systemGroup && (
            <DeleteButtonContainer>
              <DeleteButton
                className="delete-logical-group-button"
                onClick={() => {
                  if (selectedLogicalGroup === item) {
                    setSelectedLogicalGroup(undefined);
                  }
                  deleteLogicalGroup(item);
                }}
              />
            </DeleteButtonContainer>
          )}
        </ItemActionsContainer>
      </ItemBar>
      <SubItemsContainer
        opened={opened}
        firstLevel={firstLevel}
        className="logical-group-tree-item-items"
      >
        {item.logicalGroups ? (
          item.logicalGroups.map(
            (logicalGroup: SettingsLogicalGroup, index: number) => (
              <SettingGroupWorkflowTreeItem
                key={index}
                index={index}
                item={logicalGroup}
                levelCount={item.logicalGroups ? item.logicalGroups.length : 0}
                levelIndex={levelIndex + 1}
                selectedLogicalGroup={selectedLogicalGroup}
                editAccess={editAccess}
                deleteAccess={deleteAccess}
                setSelectedLogicalGroup={setSelectedLogicalGroup}
                deleteLogicalGroup={deleteLogicalGroup}
                moveUpLogicalGroup={moveUpLogicalGroup}
                moveDownLogicalGroup={moveDownLogicalGroup}
                renameLogicalGroup={renameLogicalGroup}
              />
            )
          )
        ) : item.technicalComponents ? (
          <></>
        ) : notLastLevelWithParent && !item.systemGroup ? (
          <NoData>There are no children under this logical group.</NoData>
        ) : (
          <></>
        )}
      </SubItemsContainer>
    </ItemContainer>
  );
};

export default SettingGroupWorkflowTreeItem as FunctionComponent<Props>;
