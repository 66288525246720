import { ExecutionSpeedStatus, ExecutionSuccessStatus } from "model/Status";
import {
  $statusFail,
  $statusProblematic,
  $statusSuccess,
  $statusSuccessSlow,
  $statusUnknownOrNoStatus,
} from "styles/common";

export const getSuccessStatusColor = (status: ExecutionSuccessStatus) =>
  status === ExecutionSuccessStatus.Fail
    ? $statusFail
    : status === ExecutionSuccessStatus.Success
    ? $statusSuccess
    : status === ExecutionSuccessStatus.Problematic
    ? $statusProblematic
    : $statusUnknownOrNoStatus;

export const getSpeedStatusColor = (status: ExecutionSpeedStatus) =>
  status === ExecutionSpeedStatus.Fail
    ? $statusFail
    : status === ExecutionSpeedStatus.SuccessOnTime
    ? $statusSuccess
    : status === ExecutionSpeedStatus.SuccessSlow
    ? $statusSuccessSlow
    : $statusUnknownOrNoStatus;
