import { UsageOfDate, UsageOfMonth } from "model/Usage";
import { formatAPIDate } from "utils/Date";
import request, { ApiResponse } from "../../utils/ApiHandlers";
import { BaseConfig } from "../../utils/Variables";

export class UsageApi {
  private static instance: UsageApi;

  private constructor() {}

  static getInstance(fresh?: boolean) {
    if (!UsageApi.instance || fresh) {
      UsageApi.instance = new UsageApi();
    }
    return UsageApi.instance;
  }

  getUnitConsumptionData = async (
    startDate: Date,
    endDate: Date
  ): Promise<ApiResponse<UsageOfDate[]>> =>
    request.get(
      `${BaseConfig.getBaseApiUrl()}usage/consumption?startDate=${formatAPIDate(
        startDate
      )}&endDate=${formatAPIDate(endDate)}`
    );

  getUsagePerMonthData = async (): Promise<ApiResponse<UsageOfDate[]>> =>
    request.get(`${BaseConfig.getBaseApiUrl()}usage/months`);

  getSelectedMonthData = async (
    date: Date
  ): Promise<ApiResponse<UsageOfMonth>> =>
    request.get(
      `${BaseConfig.getBaseApiUrl()}usage/months/detail?date=${formatAPIDate(
        date
      )}`
    );
}

export default UsageApi.getInstance();
