import {
  BaseCustomer,
  Customer,
  CustomerDetailMetaData,
} from "../../model/Customer";
import request, { ApiResponse } from "../../utils/ApiHandlers";
import { BaseConfig } from "../../utils/Variables";

export class CustomerManagementApi {
  private static instance: CustomerManagementApi;

  private constructor() {}

  static getInstance(fresh?: boolean) {
    if (!CustomerManagementApi.instance || fresh) {
      CustomerManagementApi.instance = new CustomerManagementApi();
    }
    return CustomerManagementApi.instance;
  }

  getCustomers = async (): Promise<ApiResponse<BaseCustomer[]>> =>
    request.get(`${BaseConfig.getBaseApiUrl()}customers`);

  getCustomerDetailMetaData = async (): Promise<
    ApiResponse<CustomerDetailMetaData>
  > => request.get(`${BaseConfig.getBaseApiUrl()}customers/metadata`);

  getCustomerDetail = async (
    customerId: number
  ): Promise<ApiResponse<Customer>> =>
    request.get(`${BaseConfig.getBaseApiUrl()}customers/${customerId}`);

  addCustomer = async (customer: Customer): Promise<ApiResponse<void>> =>
    request.post(
      `${BaseConfig.getBaseApiUrl()}customers`,
      JSON.stringify(customer)
    );

  editCustomer = async (customer: Customer): Promise<ApiResponse<void>> =>
    request.put(
      `${BaseConfig.getBaseApiUrl()}customers/${customer.id}`,
      JSON.stringify(customer)
    );

  deleteCustomer = async (customerId: number): Promise<ApiResponse<void>> =>
    request.delete(
      `${BaseConfig.getBaseApiUrl()}customers/delete/${customerId}`
    );
}

export default CustomerManagementApi.getInstance();
