import Card from "components/common/Card";
import { ApplicationsList } from "components/Applications/ApplicationsList";
import { inject, observer } from "mobx-react";
import { ApplicationEnvironment } from "model/Environment";
import React, { FunctionComponent, useEffect, useState } from "react";
import ApplicationStore from "stores/ApplicationStore";
import styled, { css } from "styled-components";
import { $blue4, Text } from "styles/common";
import { Prompt } from "react-router";
import { Route } from "model/Navigation";
import IconComponent from "components/common/Icon";
import { Icon } from "model/Icon";
import { Modal, ModalType, NoAccessModal } from "components/common/Modal";
import Loader from "components/common/Loader";
import AuthenticationStore from "stores/AuthenticationStore";
import { PermissionArea, PermissionType } from "model/Claim";

interface Props {
  applicationStore: ApplicationStore;
  authenticationStore: AuthenticationStore;
}

const EnvironmentHeader = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.8rem;
`;

const EnvironmentName = styled.span`
  color: ${$blue4};
`;

const NoEnvironments = styled(Text)`
  margin-top: 1rem;
  font-size: 1.125rem;
`;

const Applications: FunctionComponent<Props> = ({
  applicationStore,
  authenticationStore,
}) => {
  const [dataLoad, setDataLoad] = useState(false);

  const accessViewApplication = authenticationStore.userHasPermission(
    PermissionArea.Application,
    PermissionType.View
  );

  useEffect(() => {
    applicationStore.getApplicationData().finally(() => {
      setDataLoad(true);
    });
  }, [applicationStore]);

  const handleDataReload = () => {
    applicationStore.getApplicationData();
  };

  const renderEnvironments =
    applicationStore.applicationEnvironments &&
    applicationStore.applicationEnvironments.map(
      (applicationEnvironment: ApplicationEnvironment, index: number) => (
        <Card
          key={index}
          header={
            <EnvironmentHeader>
              <IconComponent name={Icon.application} height={"18px"} />
              <Text dark large semibold uppercase>
                Applications for&nbsp;
                <EnvironmentName>{applicationEnvironment.name}</EnvironmentName>
              </Text>
            </EnvironmentHeader>
          }
          collapsable
        >
          <ApplicationsList
            applications={applicationEnvironment.applications}
          />
        </Card>
      )
    );

  const renderEmptyData = (
    <Card
      extraContentStyling={css`
        text-align: center;
        margin: 1rem 0 2rem;
      `}
    >
      <img src="/static/media/no-data-icon.fd032bb9.svg" alt="no data icon" />
      {!dataLoad ? (
        <Loader component />
      ) : (
        <NoEnvironments large dark>
          You have no environments yet.
        </NoEnvironments>
      )}
    </Card>
  );

  const renderNoDataLoaded = (
    <Card
      extraContentStyling={css`
        text-align: center;
        margin: 1rem 0 2rem;
      `}
    >
      <img src="/static/media/no-data-icon.fd032bb9.svg" alt="no data icon" />
      {!accessViewApplication ? (
        <NoAccessModal />
      ) : !dataLoad ? (
        <Loader component />
      ) : (
        <Modal type={ModalType.DataLoadFailed} action={handleDataReload} />
      )}
    </Card>
  );

  return (
    <>
      <Prompt
        message={(location) => {
          if (!location.pathname.startsWith(Route.Applications))
            // Reset data when leaving page
            applicationStore.reset();

          return true;
        }}
      />

      {accessViewApplication && applicationStore.applicationEnvironments
        ? applicationStore.applicationEnvironments.length > 0
          ? renderEnvironments
          : renderEmptyData
        : renderNoDataLoaded}
    </>
  );
};

export default inject(
  "applicationStore",
  "authenticationStore"
)(
  observer(
    Applications as FunctionComponent<
      Omit<Props, "applicationStore" | "authenticationStore">
    >
  )
);
