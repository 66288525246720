const movePlaceInArray = (array: any[], oldIndex: number, newIndex: number) => {
  if (newIndex >= array.length) {
    var k = newIndex - array.length + 1;
    while (k--) {
      array.push(undefined);
    }
  }
  array.splice(newIndex, 0, array.splice(oldIndex, 1)[0]);
  return array;
};

export const moveUpInArray = (item: any, array: any[]) => {
  const currentIndex = array.indexOf(item);
  return movePlaceInArray(array, currentIndex, currentIndex - 1);
};

export const moveDownInArray = (item: any, array: any[]) => {
  const currentIndex = array.indexOf(item);
  return movePlaceInArray(array, currentIndex, currentIndex + 1);
};

const sumOfNumbers = (accumulator: number, a: number) => {
  return accumulator + a;
};

export const getSumOfNumbersArray = (array: number[]) => {
  return array.reduce(sumOfNumbers, 0);
};
