import { ExtraStylingType } from "model/Styles";
import React, { FunctionComponent } from "react";
import styled, { css } from "styled-components";
import { Text, Span, $statusFail } from "styles/common";

interface Props {
  items: LegendItem[];
  vertical?: boolean;
  small?: boolean;
  extraStyling?: ExtraStylingType;
}

export interface LegendItem {
  explanation?: string;
  boldValue?: string;
  disclaimer?: boolean;
  color: string;
}

const Container = styled.div<{
  vertical: boolean;
  extraStyling: ExtraStylingType;
}>`
  display: flex;

  ${(props) =>
    props.vertical
      ? css`
          flex-direction: column;
        `
      : css`
          flex-direction: row;
        `}

  ${(props) => props.extraStyling}
`;

const Item = styled.div<{ vertical: boolean }>`
  display: flex;
  flex-direction: row;
  align-items: center;

  ${(props) =>
    !props.vertical &&
    css`
      margin-right: 1rem;
    `}
`;

const LegendBadge = styled.div<{ color: string }>`
  width: 12px;
  height: 12px;
  display: inline-block;
  border-radius: 12px;
  margin-right: 0.5rem;
  flex: 0 0 12px;
  background-color: ${(props) => props.color};
`;

const LegendText = styled(Text)<{ small: boolean }>`
  line-height: ${(props) => (props.small ? "1.4rem" : "1.5rem")};
`;

const Legend: FunctionComponent<Props> = ({
  items,
  vertical = false,
  small = false,
  extraStyling = css``,
}) => {
  return (
    <Container vertical={vertical} extraStyling={extraStyling}>
      {items.map((legendItem: LegendItem, index: number) => (
        <Item key={index} vertical={vertical}>
          <LegendBadge color={legendItem.color} />
          <LegendText large={!small} small={small}>
            {legendItem.explanation && (
              <span>
                {legendItem.explanation}
                {legendItem.disclaimer && <Span color={$statusFail}>*</Span>}
              </span>
            )}
            {legendItem.boldValue && (
              <span style={{ fontWeight: 600, marginLeft: "0.25rem" }}>
                {legendItem.boldValue}
              </span>
            )}
          </LegendText>
        </Item>
      ))}
    </Container>
  );
};

export default Legend;
