import Page from "components/common/Page";
import React, { FunctionComponent, useEffect } from "react";
import { BrowserRouter, Redirect, Route, Switch } from "react-router-dom";
import { Route as NavigationRoute } from "model/Navigation";

import General from "screens/General";
import Integrations from "./screens/Integrations";
import ItOperations from "screens/ItOperations";
import Applications from "screens/Applications";
import Usage from "screens/Usage";
import Reports from "screens/Reports";
import ContactDetails from "screens/settings/ContactDetails";
import ContactSchedule from "screens/settings/ContactSchedule";
import GroupWorkflow from "screens/settings/GroupWorkflow";
import CustomerManagement from "screens/CustomerManagement";
import { inject, observer } from "mobx-react";
import AuthenticationStore from "stores/AuthenticationStore";
import NavigationStore from "stores/NavigationStore";
import Executions from "screens/Executions";

export interface Props {
  authenticationStore: AuthenticationStore;
  navigationStore: NavigationStore;
  dataLoaded: boolean;
}

const Routes: FunctionComponent<Props> = ({
  authenticationStore,
  navigationStore,
  dataLoaded,
}) => {
  // Set navigation items based on user roles
  useEffect(() => {
    if (
      authenticationStore.userPermissionsInfo &&
      authenticationStore.userPermissionsInfo.permissions.length > 0
    ) {
      navigationStore.setHorizonNavigation(
        authenticationStore.userHasRouteOptionAccess
      );
      navigationStore.setNavigationTabs(
        authenticationStore.userHasRoutePermission,
        authenticationStore.userHasRouteOptionAccess
      );
    }
  }, [
    authenticationStore.userHasRoutePermission,
    authenticationStore.userHasRouteOptionAccess,
    authenticationStore.userPermissionsInfo,
    navigationStore,
  ]);

  const redirect = () => {
    if (navigationStore.navigationTabs && navigationStore.redirectionRoute) {
      return <Redirect to={navigationStore.redirectionRoute.route} />;
    }
  };

  const PrivateRoute = ({ ...props }) => (
    <Route
      path={props.path}
      render={() => {
        const navigationItem = navigationStore.getNavigationItem(props.path);

        return navigationItem
          ? authenticationStore.userHasRoutePermission(
              navigationItem.permissionAreas
            ) &&
            authenticationStore.userHasRouteOptionAccess(
              navigationItem.accessOptions
            )
            ? props.children
            : redirect()
          : false;
      }}
    />
  );

  return (
    <BrowserRouter>
      <Page loading={!dataLoaded}>
        <Switch>
          <PrivateRoute path={NavigationRoute.CustomerManagement}>
            <CustomerManagement />
          </PrivateRoute>
          {/* <PrivateRoute
            path={NavigationRoute.UserManagement}
          >
            <UserManagement />
          </PrivateRoute> */}
          <PrivateRoute path={NavigationRoute.ContactSchedule}>
            <ContactSchedule />
          </PrivateRoute>
          <PrivateRoute path={NavigationRoute.GroupWorkflow}>
            <GroupWorkflow />
          </PrivateRoute>
          <PrivateRoute path={NavigationRoute.Reports}>
            <Reports />
          </PrivateRoute>
          <PrivateRoute path={NavigationRoute.ContactDetails}>
            <ContactDetails />
          </PrivateRoute>
          <PrivateRoute path={NavigationRoute.Usage}>
            <Usage />
          </PrivateRoute>
          <PrivateRoute path={NavigationRoute.Applications}>
            <Applications />
          </PrivateRoute>
          <PrivateRoute path={NavigationRoute.ItOperations}>
            <ItOperations />
          </PrivateRoute>
          <PrivateRoute path={NavigationRoute.Integrations}>
            <Integrations />
          </PrivateRoute>
          <PrivateRoute path={NavigationRoute.Executions}>
            <Executions />
          </PrivateRoute>
          <PrivateRoute path={NavigationRoute.General}>
            <General />
          </PrivateRoute>
        </Switch>
      </Page>
    </BrowserRouter>
  );
};

export default inject(
  "authenticationStore",
  "navigationStore"
)(
  observer(
    Routes as FunctionComponent<
      Omit<Props, "authenticationStore" | "navigationStore">
    >
  )
);
