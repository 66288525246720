import React, { FunctionComponent } from "react";
import * as icons from "assets/platformcomponenttypeicons";

import styled, { css } from "styled-components";
import { ExtraStylingType } from "model/Styles";

export interface Props {
  name: string;
  size?: string;
  extraStyling?: ExtraStylingType;
}

const Image = styled.img<{
  size: string;
  extraStyling: ExtraStylingType;
}>`
  height: ${(props) => props.size};
  ${(props) => props.extraStyling};
`;

const PlatformComponentTypeIconComponent: FunctionComponent<Props> = ({
  name,
  size = "1.3rem",
  extraStyling = css``,
}) => {
  const iconName = name.toString().toLowerCase().replace(/ /g, "");
  //@ts-ignore
  const src = icons[iconName] ? icons[iconName] : icons["unknown"];

  return (
    <Image
      src={src}
      alt={`Icon ${name}`}
      size={size}
      extraStyling={extraStyling}
    />
  );
};

export default PlatformComponentTypeIconComponent as FunctionComponent<Props>;
