import Card from "components/common/Card";
import IconComponent from "components/common/Icon";
import { inject, observer } from "mobx-react";
import { Icon } from "model/Icon";
import React, { FunctionComponent, useEffect } from "react";
import SettingStore from "stores/SettingStore";
import { css } from "styled-components";
import {
  $blue4,
  $textLight,
  StyledReactTooltip,
  TooltipText,
  Text,
  Link,
} from "styles/common";
import { Header, HeaderTitle, CardHeader, HeaderExplanation } from "./common";
import { ContactsList } from "components/Settings/ContactsList";
import { Prompt } from "react-router";
import { Route } from "model/Navigation";
import NavigationStore from "stores/NavigationStore";
import AuthenticationStore from "stores/AuthenticationStore";
import { PermissionArea, PermissionType } from "model/Claim";
import { NoAccessModal } from "components/common/Modal";

interface Props {
  settingStore: SettingStore;
  navigationStore: NavigationStore;
  authenticationStore: AuthenticationStore;
}

const ContactDetails: FunctionComponent<Props> = ({
  settingStore,
  navigationStore,
  authenticationStore,
}) => {
  const accessViewContact = authenticationStore.userHasPermission(
    PermissionArea.Contact,
    PermissionType.View
  );

  const accessEditContact = authenticationStore.userHasPermission(
    PermissionArea.Contact,
    PermissionType.Edit
  );

  const accessDeleteContact = authenticationStore.userHasPermission(
    PermissionArea.Contact,
    PermissionType.Delete
  );

  useEffect(() => {
    accessViewContact && !settingStore.contacts && settingStore.getContacts();
  }, [accessViewContact, settingStore]);

  const handleDataReload = () => {
    accessViewContact && settingStore.getContacts();
  };

  return (
    <>
      <Prompt
        message={(location) => {
          if (!location.pathname.startsWith(Route.ContactDetails))
            // Reset data when leaving page
            settingStore.resetContacts();

          return true;
        }}
      />

      <Header>
        <HeaderTitle dark semibold large>
          Contact details
        </HeaderTitle>
        <HeaderExplanation>
          {!accessViewContact || accessEditContact || accessDeleteContact
            ? "Manage the"
            : "The"}{" "}
          contacts for my organization
        </HeaderExplanation>
      </Header>

      <Card
        header={
          <CardHeader>
            <p>Contacts</p>
            <div data-for="users-info-tooltip" data-tip="">
              <IconComponent name={Icon.info} color={$blue4} />
            </div>
          </CardHeader>
        }
      >
        {!accessViewContact && <NoAccessModal />}

        <ContactsList
          contacts={settingStore.contacts}
          viewAccess={accessViewContact}
          editAccess={accessEditContact}
          deleteAccess={accessDeleteContact}
          deleteContact={settingStore.deleteContact}
          addContact={settingStore.addContact}
          editContact={settingStore.editContact}
          retryDataLoading={handleDataReload}
        />

        {authenticationStore.userPermissionsInfo?.options
          .allowEscalationManagement && (
          <Text
            extraStyling={css`
              margin: 2rem 0 0.5rem 0;
            `}
          >
            Switch to{" "}
            <Link
              onClick={() => {
                navigationStore.navigate(Route.ContactSchedule);
              }}
            >
              Define contact schedule
            </Link>{" "}
            to{" "}
            {!accessViewContact || accessEditContact || accessDeleteContact
              ? "define"
              : "see"}{" "}
            who we engage in case of incidents or changes.
          </Text>
        )}
      </Card>

      <StyledReactTooltip
        id="users-info-tooltip"
        effect="solid"
        place="right"
        type="light"
        border={false}
        textColor={$textLight}
        backgroundColor={"#fff"}
        arrowColor={"transparent"}
      >
        <TooltipText>
          <Text>
            List of relevant contacts and departments for my organisation.
          </Text>
          <Text>
            Includes all contacts which are relevant for the service and Cegeka
            might have to engage.
          </Text>
        </TooltipText>
      </StyledReactTooltip>
    </>
  );
};

export default inject(
  "settingStore",
  "navigationStore",
  "authenticationStore"
)(
  observer(
    ContactDetails as FunctionComponent<
      Omit<Props, "settingStore" | "navigationStore" | "authenticationStore">
    >
  )
);
