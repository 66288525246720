import CalendarHeatMap, {
  calendarWidth,
} from "components/Integrations/CalendarHeatMap";
import Card, { CardSpacer, FilterHeader } from "components/common/Card";
import IntegrationsHeatMap from "components/Integrations/IntegrationsHeatMap";
import Pills from "components/common/Pills";
import React, { FunctionComponent } from "react";
import styled, { css } from "styled-components";
import {
  $statusFail,
  $statusSuccess,
  Filter,
  Link,
  Select,
  Text,
} from "styles/common";
import ApplicationsRuntime from "components/Applications/ApplicationsRuntime";
import { Prompt } from "react-router";
import { inject, observer } from "mobx-react";
import NavigationStore from "stores/NavigationStore";
import { Route } from "model/Navigation";
import Legend from "components/common/Legend";
import { GlobalTimeFrame, globalTimeFrames } from "model/Time";
import GeneralStore from "stores/GeneralStore";
import ApplicationStore from "stores/ApplicationStore";
import IntegrationStore from "stores/IntegrationStore";
import TotalExecutionsChart from "components/Integrations/TotalExecutionsChart";
import AuthenticationStore from "stores/AuthenticationStore";
import { PermissionArea, PermissionType } from "model/Claim";
import { NoAccessModal } from "components/common/Modal";

interface Props {
  generalStore: GeneralStore;
  applicationStore: ApplicationStore;
  integrationStore: IntegrationStore;
  navigationStore: NavigationStore;
  authenticationStore: AuthenticationStore;
}

const AreaChartContainer = styled.div`
  margin-bottom: 1rem;
`;

const ApplicationsHeader = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  position: relative;
  width: 100%;
`;

const ApplicationsHeaderTitle = styled.p`
  margin-right: 1.5rem;
`;

const General: FunctionComponent<Props> = ({
  generalStore,
  applicationStore,
  integrationStore,
  navigationStore,
  authenticationStore,
}) => {
  const accessViewApplication = authenticationStore.userHasPermission(
    PermissionArea.Application,
    PermissionType.View
  );

  const accessViewIntegration = authenticationStore.userHasPermission(
    PermissionArea.Integration,
    PermissionType.View
  );

  const renderIntegrationsFilter = () => (
    <Filter>
      <Text large semibold dark>
        My integrations on
      </Text>
      <Select
        defaultValue={generalStore.currentEnvironment}
        highlight
        disabled={!accessViewIntegration}
        onChange={(e) => {
          generalStore.setCurrentEnvironment(parseInt(e.target.value));
        }}
      >
        {generalStore.environments &&
          generalStore.environments.map((environment, index) => (
            <option key={index} value={environment.id}>
              {environment.name}
            </option>
          ))}
      </Select>
    </Filter>
  );

  const renderApplicationsRuntimeCard = () => (
    <Card
      header={
        <ApplicationsHeader>
          <ApplicationsHeaderTitle>Applications</ApplicationsHeaderTitle>

          <Legend
            items={[
              { explanation: "Up", color: $statusSuccess },
              { explanation: "Down", color: $statusFail },
            ]}
            small
          />

          <Link
            large
            onClick={() => {
              navigationStore.navigate(Route.Applications);
            }}
            extraStyling={css`
              position: absolute;
              right: 0;
            `}
          >
            Go to details
          </Link>
        </ApplicationsHeader>
      }
      extraStyling={css`
        min-height: 8rem;
      `}
    >
      {accessViewApplication ? <ApplicationsRuntime /> : <NoAccessModal />}
    </Card>
  );

  const renderIntegrationsCard = () =>
    generalStore.timeFrame ? (
      <Card
        header={
          <FilterHeader space>
            <p>My integrations</p>
            <Pills
              pills={globalTimeFrames}
              active={generalStore.timeFrame.id}
              setActive={(id: GlobalTimeFrame) => {
                generalStore.setTimeFrame(id);
              }}
            />
          </FilterHeader>
        }
      >
        {!accessViewIntegration && <NoAccessModal />}
        <CardSpacer />

        <IntegrationsHeatMap
          onLeaveWidget={() => {
            navigationStore.navigate(Route.Integrations);
          }}
          widget={true}
          access={accessViewIntegration}
        />

        <CardSpacer />

        <Text large semibold dark style={{ marginBottom: "1.5rem" }}>
          Total executions
        </Text>

        <AreaChartContainer>
          <TotalExecutionsChart access={accessViewIntegration} />
        </AreaChartContainer>
      </Card>
    ) : (
      <></>
    );

  const renderCalendarCard = () => (
    <Card
      header={<p>Overall success rate</p>}
      extraStyling={css`
        min-width: calc(${calendarWidth}px + 3rem);
        min-height: 8rem;
      `}
    >
      {accessViewIntegration ? <CalendarHeatMap /> : <NoAccessModal />}
    </Card>
  );

  return (
    <>
      <Prompt
        message={(location) => {
          if (!location.pathname.startsWith(Route.General)) {
            // Reset data when leaving page
            applicationStore.resetApplicationsRuntime();
            integrationStore.resetTotalExecutions();
            generalStore.resetCalendarSuccessRate();
          }
          return true;
        }}
      />

      {renderIntegrationsFilter()}

      {renderApplicationsRuntimeCard()}

      {renderIntegrationsCard()}

      {renderCalendarCard()}
    </>
  );
};

export default inject(
  "generalStore",
  "applicationStore",
  "integrationStore",
  "navigationStore",
  "authenticationStore"
)(
  observer(
    General as FunctionComponent<
      Omit<
        Props,
        | "generalStore"
        | "applicationStore"
        | "integrationStore"
        | "navigationStore"
        | "authenticationStore"
      >
    >
  )
);
