import React, {
  useState,
  useEffect,
  FunctionComponent,
  useCallback,
} from "react";
import ReactFlow, {
  ReactFlowProvider,
  isNode,
  Position,
  useStoreState,
  useStoreActions,
  Node,
  Edge,
  Controls,
  FlowElement,
} from "react-flow-renderer";
import dagre from "dagre";
import CustomNode from "../CustomNode";
import styled, { css } from "styled-components";
import CustomEdge from "components/Integrations/CustomEdge";
import IntegrationStore from "stores/IntegrationStore";
import IconComponent from "components/common/Icon";
import { Icon } from "model/Icon";
import { Button, smallText, Text } from "styles/common";
import { LocationGroupNodeData } from "model/Node";
import { ExtraStylingType } from "model/Styles";
import { LogicalGroupLayoutPositionSave } from "model/LogicalGroup";

interface DiagramProps {
  nodes: Node[];
  edges: Edge[];
  connectionHovering?: boolean;
  height?: string;
  onNodeMove?: () => void;
  interactive?: boolean;
  onInteractivity?: () => void;
  onSave(
    layout: LogicalGroupLayoutPositionSave[]
  ): Promise<boolean> | undefined;
  integrationStore: IntegrationStore;
}

interface Props extends DiagramProps {
  width?: string;
  extraStyling?: ExtraStylingType;
}

const FlowDiagramContainer = styled.div<{
  width: string;
  interactive: boolean;
  extraStyling?: ExtraStylingType;
}>`
  border: 1px solid #efefef;
  border-radius: 0.2rem;
  max-width: ${(props) => props.width};

  ${(props) =>
    !props.interactive &&
    css`
      overflow: scroll;
    `}

  ${(props) => props.extraStyling}
`;

const DiagramContainer = styled.div`
  position: relative;
`;

const SaveActions = styled.div`
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 0.625rem;
  left: 0.625rem;
  box-shadow: 0 0 2px 1px rgb(0 0 0 / 8%);
`;

const SaveButton = styled(IconComponent)<{ disable: boolean }>`
  width: 1rem;
  height: 1rem;
  padding: 0.5rem;
  transition: 0.2s;
  z-index: 100;
  position: relative;
  transition: 0.2s;

  &:after {
    color: #333;
    ${smallText}
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 2.5rem;
    width: max-content;
    opacity: 0;
    transition: 0.2s;
  }

  ${(props) =>
    props.disable
      ? css`
          svg {
            fill: rgba(0, 0, 0, 0.3);
          }

          &:after {
            content: none;
          }
        `
      : css`
          cursor: pointer;

          &:hover {
            background-color: #f4f4f4;

            &:after {
              opacity: 1;
            }
          }
        `}
`;

const Modal = styled.div`
  position: absolute;
  top: 0;
  left: 2.5rem;
  text-align: center;
  z-index: 11;
  width: 230px;
  background-color: #fff;
  color: #5f7787;
  font-size: 0.9375rem;
  padding: 1rem 3rem;
  border-radius: 0.2rem;
  display: flex;
  flex-direction: column;
  box-shadow: 0 0 2px 1px rgb(0 0 0 / 8%);
  z-index: 100;
`;

const ModalActions = styled.div``;

const dagreGraph = new dagre.graphlib.Graph();
dagreGraph.setDefaultEdgeLabel(() => ({}));

const nodeTypes = {
  customNode: CustomNode,
};

const edgeTypes = {
  customEdge: CustomEdge,
};

const getLayoutedElements = (
  elements: FlowElement[],
  nodesWithSize: Node<LocationGroupNodeData>[],
  setGraphHeight: (height: number) => void,
  setGraphWidth: (width: number) => void,
  direction = "TB"
) => {
  const isHorizontal = direction === "LR";

  // Basic graph layouting settings
  dagreGraph.setGraph({
    rankdir: direction,
    edgesep: 250,
    nodesep: 50,
    ranksep: 50,
    marginx: 64,
    marginy: 32,
  });

  // Set all optimal node & edge positions according to dagre
  elements.forEach((element: any) => {
    if (isNode(element)) {
      // Use node size to determine optimal position
      const nodeWithSize = nodesWithSize.find(
        (node: any) => node.id === element.id
      );

      nodeWithSize &&
        dagreGraph.setNode(element.id, {
          width: nodeWithSize.__rf.width,
          height: nodeWithSize.__rf.height,
        });
    } else {
      dagreGraph.setEdge(element.source, element.target);
    }
  });

  // Create layout
  dagre.layout(dagreGraph);

  // Set graph size
  const graph = dagreGraph.graph();
  graph.height && setGraphHeight(graph.height);
  graph.width && setGraphWidth(graph.width);

  const positionedElements = elements.map((element: any) => {
    if (isNode(element)) {
      // Set handle positions
      element.targetPosition = isHorizontal ? Position.Left : Position.Top;
      element.sourcePosition = isHorizontal ? Position.Right : Position.Bottom;

      // Add positioning to nodes according to dagre if no positions were yet saved
      // React-flow-renderer draws the nodes on these positions
      if (
        (element.position.x === 0 || element.position.x === null) &&
        (element.position.y === 0 || element.position.y === null)
      ) {
        const nodeWithData = nodesWithSize.find(
          (node: any) => node.id === element.id
        );

        if (nodeWithData) {
          const nodeWithPosition = dagreGraph.node(element.id);

          element.position = {
            x:
              nodeWithPosition.x -
              nodeWithData.__rf.width / 2 +
              Math.random() / 1000,
            y: nodeWithPosition.y - nodeWithData.__rf.height / 2,
          };
        }
      }
    }

    return element;
  });

  return positionedElements;
};

const nodeHasDimension = (node: Node) => {
  return node.__rf && node.__rf.width !== null && node.__rf.height !== null;
};

const Diagram: FunctionComponent<DiagramProps> = ({
  nodes,
  edges,
  height,
  connectionHovering,
  onNodeMove,
  interactive,
  onInteractivity,
  onSave,
  integrationStore,
}) => {
  // Node states are needed to get the width and height of the custom nodes
  const nodesWithData = useStoreState((store) => store.nodes);
  const setNodesData = useStoreActions((actions) => actions.setElements);

  const [layoutIsSet, setLayoutIsSet] = useState(false);
  const [elementsLayout, setElementsLayout] = useState<FlowElement[]>([]);

  const [originalNodesWithData, setOriginalNodesWithData] = useState<
    Node<LocationGroupNodeData>[] | undefined
  >(undefined);

  const [showSaveModal, setShowSaveModal] = useState(false);
  const [showResetModal, setShowResetModal] = useState(false);

  const [enableSaveButtons, setEnableSaveButtons] = useState(false);

  const [graphHeight, setGraphHeight] = useState(0);
  const [graphWidth, setGraphWidth] = useState(0);

  const handleMouseOver = useCallback(() => {
    const edgesContainer = document.getElementsByClassName(
      "react-flow__edges"
    )[0] as HTMLElement;

    edgesContainer.style.zIndex = "100";
  }, []);

  const handleMouseLeave = useCallback(() => {
    const edgesContainer = document.getElementsByClassName(
      "react-flow__edges"
    )[0] as HTMLElement;

    edgesContainer.style.zIndex = "2";
  }, []);

  useEffect(() => {
    // Reload diagram after integration filters changed
    setLayoutIsSet(false);
  }, [nodes]);

  useEffect(() => {
    if (!layoutIsSet) {
      // Fill in widths and heights of nodes (in nodesWithData)
      setNodesData([...nodes]);
      if (nodesWithData.length > 0 && nodesWithData.every(nodeHasDimension)) {
        // Once nodes' widths and heights are known, create the diagram with automatic layouting using Dagre
        setOriginalNodesWithData([...nodesWithData]);

        setElementsLayout(
          getLayoutedElements(
            [...nodes, ...edges],
            nodesWithData,
            (height: number) => setGraphHeight(height),
            (width: number) => setGraphWidth(width)
          )
        );
        setLayoutIsSet(true);
      }
    }

    // Show connections between logical groups on top of nodes when hovering over them
    // Used for connections that may be partly hidden behind a node
    if (connectionHovering) {
      const connections = document.getElementsByClassName("react-flow__edge");
      for (let connection of connections) {
        connection.addEventListener("mouseover", handleMouseOver);
        connection.addEventListener("mouseleave", handleMouseLeave);
      }

      return () => {
        const connections = document.getElementsByClassName("react-flow__edge");
        for (let connection of connections) {
          connection.removeEventListener("mouseover", handleMouseOver);
          connection.removeEventListener("mouseleave", handleMouseLeave);
        }
      };
    }
  }, [
    nodesWithData,
    connectionHovering,
    handleMouseOver,
    handleMouseLeave,
    nodes,
    edges,
    layoutIsSet,
    setNodesData,
    integrationStore.showSidePanel,
  ]);

  const SaveModal = () => (
    <Modal>
      <Text style={{ marginBottom: "1rem" }}>
        Do you want to save this diagram layout? The saved layout will be
        overwritten.
      </Text>
      <ModalActions>
        <Button onClick={handleLayoutSave}>Save</Button>
        <Button simple onClick={() => setShowSaveModal(false)}>
          Cancel
        </Button>
      </ModalActions>
    </Modal>
  );

  const ResetModal = () => (
    <Modal>
      <Text style={{ marginBottom: "1rem" }}>
        Do you want to reset the diagram layout to the saved layout?
      </Text>
      <ModalActions>
        <Button onClick={handleLayoutReset}>Reset</Button>
        <Button simple onClick={() => setShowResetModal(false)}>
          Cancel
        </Button>
      </ModalActions>
    </Modal>
  );

  const openSaveModal = () => {
    enableSaveButtons && setShowSaveModal(true);
  };

  const openResetModal = () => {
    enableSaveButtons && setShowResetModal(true);
  };

  const handleLayoutSave = () => {
    onSave(
      elementsLayout
        .filter((element: FlowElement) => isNode(element))
        .map((element) => {
          const nodeWithData = nodesWithData.find(
            (node: any) => node.id === element.id
          );

          return {
            logicalGroupId: +element.id,
            x: nodeWithData ? Math.round(nodeWithData.__rf.position.x) : 0,
            y: nodeWithData ? Math.round(nodeWithData.__rf.position.y) : 0,
          };
        })
    )?.then((success) => {
      if (success) {
        setOriginalNodesWithData([...nodesWithData]);

        setEnableSaveButtons(false);
        setShowSaveModal(false);
      }
    });
  };

  const handleLayoutReset = () => {
    setElementsLayout((elements: (Node | Edge)[]) =>
      // Duplicating the original array that contains the startpositions for x and y does not work;
      // the library blocks this as if it doesn't see that the variable changed
      // Changing any other property does not trigger the re-positioning either; only changing the position values works
      elements.map((element: Node | Edge) => {
        if (isNode(element) && originalNodesWithData) {
          const originalPosition = originalNodesWithData.find(
            (node) => node.id === element.id
          );

          if (originalPosition) {
            // The values in the position object need to get a "new" value for the library to put the node back in it's original place;
            // use originalPosition for this (linking once is enough to keep resetting)
            if (
              (originalPosition.position.x === 0 ||
                originalPosition.position.x === null) &&
              (originalPosition.position.y === 0 ||
                originalPosition.position.y === null)
            ) {
              originalPosition.position.x = originalPosition.__rf.position.x;
              originalPosition.position.y = originalPosition.__rf.position.y;
            }

            return originalPosition;
          }
        }

        return { ...element };
      })
    );
    setEnableSaveButtons(false);
    setShowResetModal(false);
  };

  const handleNodeMove = () => {
    setEnableSaveButtons(true);
    onNodeMove && onNodeMove();
  };

  return (
    <DiagramContainer>
      <SaveActions>
        <SaveButton
          name={Icon.save}
          height="1rem"
          disable={!enableSaveButtons}
          onClick={openSaveModal}
          extraStyling={css`
            border-bottom: 1px solid #eee;
            &:after {
              content: "Save layout";
            }
          `}
        />
        <SaveButton
          name={Icon.reset}
          height="1rem"
          disable={!enableSaveButtons}
          onClick={openResetModal}
          extraStyling={css`
            &:after {
              content: "Reset layout";
            }
          `}
        />
        {showSaveModal ? <SaveModal /> : showResetModal && <ResetModal />}
      </SaveActions>
      <ReactFlow
        style={{
          height: height ? height : `${graphHeight}px`,
          width: interactive ? "auto" : `${graphWidth}px`,
        }}
        elements={elementsLayout}
        onConnect={() => {}}
        onElementsRemove={() => {}}
        nodesDraggable={interactive}
        nodesConnectable={false}
        zoomOnScroll={interactive}
        zoomOnPinch={false}
        zoomOnDoubleClick={false}
        selectNodesOnDrag={false}
        paneMoveable={interactive}
        nodeTypes={nodeTypes}
        edgeTypes={edgeTypes}
        onNodeDrag={handleNodeMove}
      />
      <Controls
        showInteractive={false}
        onZoomIn={onInteractivity}
        onZoomOut={onInteractivity}
        onFitView={onInteractivity}
      />
    </DiagramContainer>
  );
};

const FlowDiagram: FunctionComponent<Props> = ({
  nodes,
  edges,
  height,
  width = "100%",
  onNodeMove = () => {},
  connectionHovering = false,
  interactive = false,
  onInteractivity = () => {},
  onSave,
  extraStyling = css``,
  integrationStore,
}) => {
  return (
    <FlowDiagramContainer
      className="layoutflow"
      width={width}
      interactive={interactive}
      extraStyling={extraStyling}
    >
      <ReactFlowProvider>
        <Diagram
          nodes={nodes}
          edges={edges}
          height={height}
          onNodeMove={onNodeMove}
          connectionHovering={connectionHovering}
          interactive={interactive}
          onInteractivity={onInteractivity}
          onSave={onSave}
          integrationStore={integrationStore}
        />
      </ReactFlowProvider>
    </FlowDiagramContainer>
  );
};

export default FlowDiagram;
