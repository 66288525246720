export const generateReportMonths = (startDate: string): Date[] => {
  let months = [];
  let date = new Date(startDate);
  let today = new Date();

  while (
    !(
      date.getMonth() === today.getMonth() &&
      date.getFullYear() === today.getFullYear()
    )
  ) {
    months.push(new Date(date));
    date.setMonth(date.getMonth() + 1);
  }
  months.push(new Date(date));
  return months.reverse();
};
