import React, { FunctionComponent, useEffect, useState } from "react";
import { Provider } from "mobx-react";
import { MsalAuthenticationTemplate, useMsal } from "@azure/msal-react";
import {
  AuthenticationResult,
  EventMessage,
  EventType,
  InteractionType,
} from "@azure/msal-browser";
import stores from "stores/AllStores";
import { RunConfig } from "utils/Variables";
import { GlobalTimeFrame } from "model/Time";
import Loader from "components/common/Loader";
import ApiHandlers from "utils/ApiHandlers";
import Routes from "Routes";

declare global {
  interface Window {
    runConfig: RunConfig;
  }
}

const AuthenticationError = ({ error }: any) => {
  console.error(error)
  return <p>Something went wrong when trying to authenticate.</p>;
};

const AuthenticationLoading = () => {
  return <Loader customText="Authenticating" />;
};

export const App: FunctionComponent = () => {
  const [dataLoadInProgress, setDataLoadInProgress] = useState(false);
  const [dataLoaded, setDataLoaded] = useState(false);

  const authRequest = {
    scopes: ["openId", "profile"],
  };

  const { instance, accounts } = useMsal();

  useEffect(() => {
    if (accounts.length > 0 && instance && !instance.getActiveAccount()) {
      // Account selection logic is app dependent. Adjust as needed for different use cases.
      const accounts = instance.getAllAccounts();
      if (accounts.length > 0) {
        instance.setActiveAccount(accounts[0]);
      }

      instance.addEventCallback((event: EventMessage) => {
        if (event.eventType === EventType.LOGIN_SUCCESS && event.payload) {
          const payload = event.payload as AuthenticationResult;
          const account = payload.account;
          instance.setActiveAccount(account);
        }
      });

      ApiHandlers.setMsalInstance(instance);
    }
  }, [instance, accounts]);

  useEffect(() => {
    if (instance.getActiveAccount() && !dataLoadInProgress && !dataLoaded) {
      setDataLoadInProgress(true);
      ApiHandlers.setMsalInstance(instance);
      stores.authenticationStore.getUserPermissionsInfo();
      stores.generalStore.getEnvironments();
      stores.generalStore.setTimeFrame(GlobalTimeFrame.Week);
    }
  }, [instance, accounts, dataLoaded, dataLoadInProgress]);

  useEffect(() => {
    if (!dataLoaded && stores.authenticationStore.userPermissionsInfo) {
      setDataLoadInProgress(false);
      setDataLoaded(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stores.authenticationStore.userPermissionsInfo, dataLoaded]);

  return (
    <>
      <MsalAuthenticationTemplate
        interactionType={InteractionType.Redirect}
        authenticationRequest={authRequest}
        errorComponent={AuthenticationError}
        loadingComponent={AuthenticationLoading}
      >
        {accounts.length > 0 && (
          <Provider {...stores}>
            <Routes dataLoaded={dataLoaded} />
          </Provider>
        )}
      </MsalAuthenticationTemplate>

      {/* <UnauthenticatedTemplate>
      </UnauthenticatedTemplate> */}
    </>
  );
};

export default App;
