import React from "react";
import { IntegrationLogicalGroup } from "model/LogicalGroup";
import styled, { css } from "styled-components";
import { $blue4, Text } from "styles/common";
import IconComponent from "components/common/Icon";
import { Icon } from "model/Icon";

interface Props {
  breadCrumbs: IntegrationLogicalGroup[];
  onClick(breadCrumb: IntegrationLogicalGroup): void;
  goToHighestLevel(): void;
}

const BreadCrumbsContainer = styled.div`
  margin: 0 2rem 0 0;
  height: 2rem;
  flex: 1;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
`;

const BreadCrumb = styled(Text)<{ last: boolean }>`
  padding-left: 0.5rem;
  line-height: 1.5rem;
  display: flex;

  &:before {
    content: "";
    background: url(/static/media/separator.d2fd30e9.svg) no-repeat 50% 50%;
    padding: 0;
    min-width: 0.5rem;
    height: 1.5rem;
    margin-right: 0.5rem;
    display: inline-block;
  }

  ${(props) =>
    !props.last &&
    css`
      cursor: pointer;
      &:hover {
        opacity: 0.8;
      }
    `}
`;

export const BreadCrumbs = ({
  breadCrumbs,
  onClick,
  goToHighestLevel,
}: Props) => (
  <BreadCrumbsContainer className="breadcrumbs">
    {breadCrumbs.length > 0 && (
      <IconComponent
        className="breadcrumb-home"
        name={Icon.group}
        color={$blue4}
        onClick={() => goToHighestLevel()}
        extraStyling={css`
          cursor: pointer;
          &:hover {
            opacity: 0.8;
          }
        `}
      />
    )}
    {breadCrumbs.map((breadCrumb, index) => (
      <BreadCrumb
        className={`breadcrumb-${index + 1}`}
        key={index}
        large
        onClick={() => {
          if (index < breadCrumbs.length - 1) {
            onClick(breadCrumb);
          }
        }}
        last={index === breadCrumbs.length - 1}
      >
        {breadCrumb.name}
      </BreadCrumb>
    ))}
  </BreadCrumbsContainer>
);
