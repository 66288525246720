import styled, { css } from "styled-components";
import ReactTooltip from "react-tooltip";
import { ExtraStylingType } from "model/Styles";

export const $grey = "#cbd7df";
export const $lightRed = "#e95766";
export const $darkRed = "#ba4551";

export const $blue6 = "#74b4d9";
export const $blue5 = "#0d435a";
export const $blue4 = "#4c9fce";
export const $blue3 = "#5bc6cb";
export const $blue2 = "#90d5df";
export const $blue1 = "#c8e9ef";

export const $statusFail = $lightRed;
export const $statusFailTransparent = "rgba(233, 87, 102, 0.4)";
export const $statusInProgress = $grey;
export const $statusInProgressTransparent = "rgba(203, 215, 223, 0.4)";
export const $statusSuccessGreen = "#b5e680";
export const $statusSuccessGreenTransparent = "rgba(181, 230, 128, 0.4)";
// export const $statusSuccessSlow = "#e5e380";
// export const $statusSuccessSlowTransparent = "rgba(229, 227, 128, 0.4)";
export const $statusSuccess = "rgb(125, 205, 210)";
export const $statusSuccessTransparent = "rgba(125, 205, 210, 0.4)";
export const $statusSuccessSlow = "rgb(200, 233, 239)";
export const $statusSuccessSlowTransparent = "rgba(200, 233, 239, 0.4)";
export const $statusProblematic = "#ffbb4d";
export const $statusProblematicTransparent = "rgba(255, 187, 77, 0.4)";
export const $statusProblematicCalendar = "#ffbb4d";
export const $statusProblematicCalendarTransparent = "rgba(255, 187, 77, 0.4)";
export const $statusTotal = $blue4;
export const $statusTotalTransparent = "rgba(76, 159, 206, 0.4)";
export const $statusNoValue = "#eee";
export const $statusNoValueTransparent = "rgba(238,238,238,0.4)";
export const $statusUnknownOrNoStatus = $statusSuccess;

export const chartColorPalette = [$blue4, $blue5, $blue2];
export const reportTicketStatusColorPalette = [
  $blue1,
  $blue4,
  $statusSuccess,
  $statusSuccessGreen,
];

export const $textLight = "#5f7787";
export const $textDark = $blue5;

export const largeText = css`
  font-size: 1rem;
`;

export const mediumText = css`
  font-size: 0.9375rem;
`;

export const smallText = css`
  font-size: 0.875rem;
`;

export const regularText = css`
  font-weight: 400;
`;

export const semiBoldText = css`
  font-weight: 600;
`;

export const boldText = css`
  font-weight: 700;
`;

export const darkText = css`
  color: ${$textDark};
`;

export const lightText = css`
  color: ${$textLight};
`;

interface TextProps {
  small?: boolean;
  large?: boolean;
  semibold?: boolean;
  bold?: boolean;
  dark?: boolean;
  uppercase?: boolean;
  color?: string;
  extraStyling?: ExtraStylingType;
}

export const textStyling = css<TextProps>`
  ${(props) => (props.small ? smallText : props.large ? largeText : mediumText)}

  ${(props) =>
    props.semibold ? semiBoldText : props.bold ? boldText : regularText}

${(props) => (props.dark ? darkText : lightText)}

${(props) =>
    props.uppercase &&
    css`
      text-transform: uppercase;
    `}

  ${(props) =>
    props.color &&
    css`
      color: ${props.color};
    `}

    ${(props) => (props.extraStyling ? props.extraStyling : css``)}
`;

export const Text = styled.p<TextProps>`
  ${textStyling}
`;

export const Span = styled.span<TextProps>`
  ${textStyling}
`;

export const Link = styled.a<{
  small?: boolean;
  large?: boolean;
  extraStyling?: ExtraStylingType;
}>`
  ${(props) => (props.small ? smallText : props.large ? largeText : mediumText)}
  color: ${$blue4};
  cursor: pointer;

  &:hover {
    color: #2c77a1;
    text-decoration: underline;
  }

  ${(props) => (props.extraStyling ? props.extraStyling : css``)}
`;

export const inputStyling = css`
  ${mediumText}
  color: #495057;
  display: block;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.875rem;
  line-height: 1.5;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid ${$grey};
  border-radius: 0.25rem;
  box-shadow: none;
  box-sizing: border-box;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

  &:focus {
    background-color: #fff;
    border-color: ${$blue4};
    outline: 0;
    box-shadow: 0 0 6px 0 rgb(76 159 206 / 30%);
  }
`;

export const Search = styled.input`
  ${inputStyling}
  margin: 0 0.75rem;
`;

export const Select = styled.select<{
  highlight?: boolean;
  fullWidth?: boolean;
  extraStyling?: ExtraStylingType;
}>`
  ${inputStyling}

  padding-right: 3.25rem;
  font-weight: ${(props) => (props.highlight ? 600 : 400)};
  color: ${(props) => (props.highlight ? $blue4 : $textLight)};

  -webkit-appearance: none;
  -moz-appearance: none;

  background-image: linear-gradient(45deg, transparent 50%, #aaa 50%),
    linear-gradient(135deg, #aaa 50%, transparent 50%),
    linear-gradient(to right, #ccc, #ccc);
  background-position: calc(100% - 20px) calc(1em + 1px),
    calc(100% - 15px) calc(1em + 1px), calc(100% - 2.5em) 0.4em;
  background-size: 5px 5px, 5px 5px, 1px 1.5em;
  background-repeat: no-repeat;

  ${(props) =>
    props.fullWidth
      ? css`
          width: 100%;
          max-width: 100%;
        `
      : css``}

  ${(props) => (props.extraStyling ? props.extraStyling : css``)}
`;

export const Button = styled.a<{
  small?: boolean;
  simple?: boolean;
  disabled?: boolean;
  textColor?: string;
  color?: string;
  white?: boolean;
  hoverColor?: string;
  extraStyling?: ExtraStylingType;
}>`
  box-shadow: none;
  display: inline-block;
  text-align: center;
  vertical-align: middle;
  border: 1px solid transparent;
  height: max-content;
  padding: ${(props) => (props.small ? "0.25rem 0.5rem" : "0.375rem 1rem")};
  font-size: ${(props) => (props.small ? "0.875rem" : "0.9375rem")};
  line-height: 1.5;
  border-radius: ${(props) => (props.small ? "0.2rem" : "0.25rem")};
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  user-select: none;

  ${(props) =>
    props.small &&
    css`
      width: max-content;
    `}

  ${(props) =>
    props.white &&
    css`
      border-color: ${$textLight};
    `}

  ${(props) =>
    props.disabled
      ? css`
          pointer-events: none;

          ${props.white
          ? css`
                opacity: 0.5;
              `
          : css`
                color: #fff;
                background-color: ${$grey};
                border-color: ${$grey};
              `}
        `
      : css`
          cursor: pointer;

          ${props.simple
          ? css`
                color: ${$textDark};

                &:hover {
                  color: ${$textLight};
                }
              `
          : props.white
            ? css`
                color: ${$textDark};
                background-color: #fff;

                &:hover {
                  background-color: ${$grey};
                }
              `
            : css`
                color: ${props.textColor ? props.textColor : "#fff"};
                background-color: ${props.color ? props.color : $blue4};
                border-color: ${props.color ? props.color : $blue4};

                &:hover {
                  background-color: ${props.hoverColor
                ? props.hoverColor
                : "#348dbf"};
                  border-color: ${props.hoverColor
                ? props.hoverColor
                : "#348dbf"};
                }
              `}
        `}

  ${(props) => props.extraStyling && props.extraStyling}
`;

export const Filter = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 1.75rem 0;
  justify-content: center;
  gap: 1rem;
`;

export const StatusCircle = styled.span<{ color: string; size?: string }>`
  width: ${(props) => (props.size ? props.size : "0.5rem")};
  min-width: ${(props) => (props.size ? props.size : "0.5rem")};
  height: ${(props) => (props.size ? props.size : "0.5rem")};
  min-height: ${(props) => (props.size ? props.size : "0.5rem")};
  border-radius: 50%;
  margin-right: 0.5rem;
  display: inline-block;

  ${(props) =>
    css`
      background-color: ${props.color};
    `}
`;

// Set class to get higher specificity than standard ReactToolTip styling
export const StyledReactTooltip = styled(ReactTooltip).attrs({
  className: "custom-tooltip",
})`
  &.custom-tooltip {
    padding: 0.5rem 0.75rem;
    background-color: #fefefe;
    border-radius: 0.1rem;
    border: none;
    box-shadow: 0px 1px 1px 1px rgba(0, 0, 0, 0.15);
    display: flex;
    flex-direction: row;
    align-items: center;
    width: fit-content;

    &.show {
      opacity: 1;
    }
  }
`;

export const TooltipText = styled.div`
  display: flex;
  flex-direction: column;
`;

export const InfoIconWrapper = styled.div<{ extraStyling?: ExtraStylingType }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.75rem;

  ${(props) => (props.extraStyling ? props.extraStyling : css``)}
`;

interface IconButtonProps {
  disabled?: boolean;
  hoverColor?: string;
}

export const iconButtonStyling = css<IconButtonProps>`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.5rem;
  width: max-content;
  user-select: none;
  transition: 0.2s;

  ${(props) =>
    props.disabled
      ? css`
          opacity: 0.6;
        `
      : css`
          cursor: pointer;

          &:hover {
            p {
              color: ${props.hoverColor ? props.hoverColor : $blue4};
            }

            svg {
              fill: ${props.hoverColor ? props.hoverColor : $blue4};
            }
          }
        `}
`;

export const IconButton = styled.div<IconButtonProps>`
  ${iconButtonStyling}
`;

export const HeaderWithAdd = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 1.5rem;
`;
