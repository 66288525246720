import {
  NewSettingsContact,
  SettingsIncidentsContactSchedule,
  SettingsContact,
  SettingsIncidentsContactScheduleBase,
  SimpleSettingsContact,
  SettingsChangeRequestContactSchedule,
} from "model/Contact";
import { SettingsLogicalGroup } from "model/LogicalGroup";
import { SettingsTechnicalComponent } from "model/PlatformComponent";
import { NewUser, SettingsUser } from "model/User";
import request, { ApiResponse } from "../../utils/ApiHandlers";
import { BaseConfig } from "../../utils/Variables";

export class SettingApi {
  private static instance: SettingApi;

  private constructor() {}

  static getInstance(fresh?: boolean) {
    if (!SettingApi.instance || fresh) {
      SettingApi.instance = new SettingApi();
    }
    return SettingApi.instance;
  }

  // USER MANAGEMENT

  getUsers = async (): Promise<ApiResponse<SettingsUser[]>> =>
    request.get(`${BaseConfig.getBaseApiUrl()}settings/users`);

  deleteUser = async (userId: number): Promise<ApiResponse<void>> =>
    request.delete(
      `${BaseConfig.getBaseApiUrl()}settings/user/delete/${userId}`
    );

  resetPassword = async (userId: number): Promise<ApiResponse<void>> =>
    request.get(
      `${BaseConfig.getBaseApiUrl()}settings/user/reset-password/${userId}`
    );

  toggleEnable = async (userId: number): Promise<ApiResponse<void>> =>
    request.get(
      `${BaseConfig.getBaseApiUrl()}settings/user/toggle-enabled/${userId}`
    );

  toggleAdminRole = async (userId: number): Promise<ApiResponse<void>> =>
    request.get(
      `${BaseConfig.getBaseApiUrl()}settings/user/toggle-admin-role/${userId}`
    );

  sendInvitation = async (userId: number): Promise<ApiResponse<void>> =>
    request.get(
      `${BaseConfig.getBaseApiUrl()}settings/user/send-invitation/${userId}`
    );

  addUser = async (user: NewUser): Promise<ApiResponse<void>> =>
    request.put(
      `${BaseConfig.getBaseApiUrl()}settings/user/add`,
      JSON.stringify(user)
    );

  // CONTACT DETAILS

  getContacts = async (): Promise<ApiResponse<SettingsContact[]>> =>
    request.get(`${BaseConfig.getBaseApiUrl()}settings/contact`);

  deleteContact = async (contactId: number): Promise<ApiResponse<void>> =>
    request.delete(
      `${BaseConfig.getBaseApiUrl()}settings/contact?contactId=${contactId}`
    );

  addContact = async (
    contact: NewSettingsContact
  ): Promise<ApiResponse<void>> =>
    request.post(
      `${BaseConfig.getBaseApiUrl()}settings/contact`,
      JSON.stringify(contact)
    );

  editContact = async (contact: SettingsContact): Promise<ApiResponse<void>> =>
    request.put(
      `${BaseConfig.getBaseApiUrl()}settings/contact`,
      JSON.stringify(contact)
    );

  // CONTACT SCHEDULE

  getScheduleContacts = async (): Promise<
    ApiResponse<SimpleSettingsContact[]>
  > =>
    request.get(
      `${BaseConfig.getBaseApiUrl()}settings/contact-schedule/contacts`
    );

  getIncidentsContactSchedule = async (): Promise<
    ApiResponse<SettingsIncidentsContactSchedule>
  > =>
    request.get(
      `${BaseConfig.getBaseApiUrl()}settings/contact-schedule/incidents`
    );

  editIncidentsContactSchedule = async (
    schedule: SettingsIncidentsContactScheduleBase
  ): Promise<ApiResponse<void>> =>
    request.put(
      `${BaseConfig.getBaseApiUrl()}settings/contact-schedule/incidents/edit`,
      JSON.stringify(schedule)
    );

  getChangeRequestsContactSchedule = async (): Promise<
    ApiResponse<SettingsChangeRequestContactSchedule>
  > =>
    request.get(
      `${BaseConfig.getBaseApiUrl()}settings/contact-schedule/change-requests`
    );

  editChangeRequestsContactSchedule = async (
    schedule: SettingsChangeRequestContactSchedule
  ): Promise<ApiResponse<void>> =>
    request.put(
      `${BaseConfig.getBaseApiUrl()}settings/contact-schedule/change-requests/edit`,
      JSON.stringify(schedule)
    );

  // GROUP WORKFLOW

  getAllLogicalGroups = async (): Promise<
    ApiResponse<SettingsLogicalGroup[]>
  > =>
    request.get(
      `${BaseConfig.getBaseApiUrl()}settings/group-workflow/logical-groups`
    );

  getAllTechnicalComponents = async (): Promise<
    ApiResponse<SettingsTechnicalComponent[]>
  > =>
    request.get(
      `${BaseConfig.getBaseApiUrl()}settings/group-workflow/technical-components`
    );

  editGroupWorkflow = async (
    logicalGroups: SettingsLogicalGroup[]
  ): Promise<ApiResponse<void>> =>
    request.put(
      `${BaseConfig.getBaseApiUrl()}settings/group-workflow`,
      JSON.stringify(logicalGroups)
    );
}

export default SettingApi.getInstance();
