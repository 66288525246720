import { action, makeObservable, observable } from "mobx";
import { UsageOfDate, UsageOfMonth } from "model/Usage";
import { UsageApi } from "services/apis/UsageApi";
import LoaderStore from "./LoaderStore";

class UsageStore {
  @observable
  unitConsumptionData: UsageOfDate[] | undefined = undefined;

  @observable
  usagePerMonthData: UsageOfDate[] | undefined = undefined;

  @observable
  selectedMonthUsage: UsageOfMonth | undefined = undefined;

  constructor(public api: UsageApi, private loaderStore: LoaderStore) {
    makeObservable(this);
  }

  @action
  getUnitConsumptionData = async (startDate: Date, endDate: Date) => {
    this.loaderStore.setLoading();
    const response = await this.api.getUnitConsumptionData(startDate, endDate);
    if (response.success) {
      const { data } = response;
      this.unitConsumptionData = data;
    } else {
      //const { errorData } = response;
      console.log(
        "Something went wrong when trying to fetch unit consumption data"
      );
    }
    this.loaderStore.stopLoading();
  };

  @action
  getUsagePerMonthData = async () => {
    this.loaderStore.setLoading();
    const response = await this.api.getUsagePerMonthData();
    if (response.success) {
      const { data } = response;
      this.usagePerMonthData = data;
    } else {
      //const { errorData } = response;
      console.log(
        "Something went wrong when trying to fetch usage per month data"
      );
    }
    this.loaderStore.stopLoading();
  };

  @action
  getSelectedMonthData = async (date: Date) => {
    this.loaderStore.setLoading();
    const response = await this.api.getSelectedMonthData(date);
    if (response.success) {
      const { data } = response;
      this.selectedMonthUsage = data;
    } else {
      //const { errorData } = response;
      console.log(
        "Something went wrong when trying to fetch usage of month data"
      );
    }
    this.loaderStore.stopLoading();
  };

  @action
  reset = () => {
    this.unitConsumptionData = undefined;
    this.usagePerMonthData = undefined;
    this.selectedMonthUsage = undefined;
  };
}

export default UsageStore;
