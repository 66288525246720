import React, { FunctionComponent } from "react";
import styled, { css } from "styled-components";
import { Icon } from "model/Icon";
import { Text } from "styles/common";
import IconComponent from "components/common/Icon";

interface Props {
  customText?: string;
  component?: boolean;
}

const Container = styled.div<{ component: boolean }>`
  z-index: ${(props) => (props.component ? 104 : 105)};
  display: flex;
  justify-content: center;
  align-items: center;

  ${(props) =>
    props.component
      ? css`
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
        `
      : css`
          position: fixed;
          left: 0;
          top: 0;
          width: 100vw;
          height: 100vh;
          background-color: rgba(0, 0, 0, 0.1);
          box-shadow: 0px 1px 1px 1px rgba(0, 0, 0, 0.15);
        `}
`;

const Content = styled.div<{ component: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  ${(props) =>
    props.component
      ? css``
      : css`
          padding: 1rem;
          background-color: #fff;
          border-radius: 0.2rem;
        `}
`;

const LoadingText = styled(Text)`
  margin: 0.5rem 0 0 0;¨
`;

const Loader: FunctionComponent<Props> = ({
  customText,
  component = false,
}) => {
  return (
    <Container component={component}>
      <Content component={component}>
        <IconComponent
          name={Icon.loader}
          height={component ? "45px" : "60px"}
        />
        {!component && (
          <LoadingText dark>{customText ? customText : "Loading"}</LoadingText>
        )}
      </Content>
    </Container>
  );
};

export default Loader as FunctionComponent<Props>;
