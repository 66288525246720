import IconComponent from "components/common/Icon";
import { Icon } from "model/Icon";
import React, { FunctionComponent, useEffect, useState } from "react";
import styled, { css } from "styled-components";
import { $statusFail, $statusSuccess, Text } from "styles/common";
import { splitArray } from "utils/common";
import { BasicApplicationDatum } from "model/Application";
import { Modal, ModalType } from "components/common/Modal";
import ApplicationStore from "stores/ApplicationStore";
import { inject, observer } from "mobx-react";
import GeneralStore from "stores/GeneralStore";
import Loader from "components/common/Loader";

interface Props {
  applicationStore: ApplicationStore;
  generalStore: GeneralStore;
  loaded?: boolean; // For unit tests
}

const Container = styled.div`
  margin-bottom: 0.5rem;
  width: 100%;
`;

const ApplicationsContainer = styled.div<{ columns?: boolean }>`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: 1rem 0;
  position: relative;
`;

const ApplicationsColumn = styled.div`
  width: 24%;
`;

const Application = styled.div<{ backgroundColor: string; empty?: boolean }>`
  width: calc(100% - 1.5em);
  padding: 0.6rem 0.75rem;
  transition: 0.2s;
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: ${(props) => props.backgroundColor};

  ${(props) =>
    css`
      height: 1rem;
    `}
`;

const EmptyCell = styled.div`
  background-color: rgb(238, 238, 238);
  background-size: 200px 100%;
  background-repeat: no-repeat;
  border-radius: 4px;
  display: inline-block;
  line-height: 1;
  height: 1rem;
  margin: 0 0.25rem;
  width: 100%;
  box-sizing: border-box;
`;

const Runtime = styled.div`
  margin-right: 1rem;
  width: 1rem;
  height: 1rem;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

const Success = styled.div`
  display: block;
  width: 0.75rem;
  height: 0.75rem;
  box-sizing: border-box;
  background-color: ${$statusSuccess};
  border-radius: 50%;
`;

const ApplicationsRuntime: FunctionComponent<Props> = ({
  applicationStore,
  generalStore,
  loaded = false,
}) => {
  const [dataLoad, setDataLoad] = useState(loaded);
  const columns = 4;

  useEffect(() => {
    applicationStore.getApplicationsRuntime().finally(() => {
      setDataLoad(true);
    });
  }, [applicationStore, generalStore.currentEnvironment]);

  const handleDataReload = () => {
    if (!generalStore.currentEnvironment) {
      generalStore.getEnvironments().then(() => {
        applicationStore.getApplicationsRuntime();
      });
    } else applicationStore.getApplicationsRuntime();
  };

  return (
    <Container>
      <ApplicationsContainer columns>
        {splitArray(
          [
            ...(applicationStore.applicationsRuntime
              ? applicationStore.applicationsRuntime
              : []),
          ],
          columns
        ).map((items, columnIndex) => {
          if (
            (applicationStore.applicationsRuntime &&
              applicationStore.applicationsRuntime.length === 0) ||
            !applicationStore.applicationsRuntime
          ) {
            return (
              <ApplicationsColumn key={columnIndex}>
                {[...Array(3)].map((item, itemIndex) => (
                  <Application
                    key={itemIndex}
                    backgroundColor={
                      (columnIndex % 2 === 0 && itemIndex % 2 === 0) ||
                      (columnIndex % 2 !== 0 && itemIndex % 2 !== 0)
                        ? "#edf6fb"
                        : "tranparent"
                    }
                    empty
                  >
                    <EmptyCell />
                  </Application>
                ))}
              </ApplicationsColumn>
            );
          }
          return (
            <ApplicationsColumn key={columnIndex}>
              {items.map((item: BasicApplicationDatum, itemIndex: number) => (
                <Application
                  key={itemIndex}
                  backgroundColor={
                    (columnIndex % 2 === 0 && itemIndex % 2 === 0) ||
                    (columnIndex % 2 !== 0 && itemIndex % 2 !== 0)
                      ? "#edf6fb"
                      : "tranparent"
                  }
                >
                  <Runtime>
                    {item.up ? (
                      <Success />
                    ) : (
                      <IconComponent name={Icon.cross} color={$statusFail} />
                    )}
                  </Runtime>
                  <Text>{item.name}</Text>
                </Application>
              ))}
            </ApplicationsColumn>
          );
        })}

        {!dataLoad ? (
          <Loader component />
        ) : applicationStore.applicationsRuntime &&
          applicationStore.applicationsRuntime.length === 0 ? (
          <Modal customText="You don't have any applications for this environment yet." />
        ) : (
          !applicationStore.applicationsRuntime && (
            <Modal type={ModalType.DataLoadFailed} action={handleDataReload} />
          )
        )}
      </ApplicationsContainer>
    </Container>
  );
};

export default inject(
  "generalStore",
  "applicationStore"
)(
  observer(
    ApplicationsRuntime as FunctionComponent<
      Omit<Props, "generalStore" | "applicationStore">
    >
  )
);
