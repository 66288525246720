import { action, makeObservable, observable } from "mobx";
import { ReportMonth } from "model/Report";
import { generateReportMonths } from "utils/Report";
import { ReportApi } from "../services/apis/ReportApi";
import AuthenticationStore from "./AuthenticationStore";
import LoaderStore from "./LoaderStore";

class ReportStore {
  @observable
  monthsList: Date[] | undefined = undefined;

  @observable
  currentMonthData: ReportMonth | undefined = undefined;

  constructor(
    public api: ReportApi,
    private authenticationStore: AuthenticationStore,
    private loaderStore: LoaderStore
  ) {
    makeObservable(this);
  }

  @action
  getReportStartDate = async () => {
    // this.monthsList = generateReportMonths("2021-08-02T12:45:07.193Z");

    if (this.authenticationStore.currentSubscription !== undefined) {
      this.loaderStore.setLoading();
      const response = await this.api.getReportStartDate(
        this.authenticationStore.currentSubscription.customerId
      );
      if (response.success) {
        const { data } = response;
        this.monthsList = generateReportMonths(data);
      } else {
        //const { errorData } = response;
        console.log("Something went wrong when trying to fetch report months");
      }
      this.loaderStore.stopLoading();
    }
  };

  @action
  getReportOfMonth = async (month: Date) => {
    // this.currentMonthData = reportCurrentMonthDataMockData;

    if (this.authenticationStore.currentSubscription !== undefined) {
      this.loaderStore.setLoading();
      const response = await this.api.getReportOfMonth(
        this.authenticationStore.currentSubscription.customerId,
        month
      );
      if (response.success) {
        const { data } = response;
        this.currentMonthData = data;
      } else {
        //const { errorData } = response;
        console.log(
          "Something went wrong when trying to fetch report month data"
        );
      }
      this.loaderStore.stopLoading();
    }
  };

  @action
  reset = () => {
    this.monthsList = undefined;
    this.currentMonthData = undefined;
  };
}

export default ReportStore;
