import Legend from "components/common/Legend";
import Loader from "components/common/Loader";
import { Modal, ModalType } from "components/common/Modal";
import { inject, observer } from "mobx-react";
import { TotalExecutions } from "model/Integration";
import { ExecutionSpeedStatus, executionSpeedStatuses } from "model/Status";
import { GlobalTimeFrame } from "model/Time";
import React, { FunctionComponent, useEffect, useState } from "react";
import { Line } from "react-chartjs-2";
import GeneralStore from "stores/GeneralStore";
import IntegrationStore from "stores/IntegrationStore";
import styled, { css } from "styled-components";
import { $textLight } from "styles/common";
import {
  getLastDaysLabels,
  getLastHoursLabels,
  getLastMonthLabels,
  getLastQuarterLabels,
  getLastWeekLabels,
} from "utils/Date";

interface Props {
  integrationStore: IntegrationStore;
  generalStore: GeneralStore;
  access: boolean;
  loaded?: boolean; // For unit tests
}

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
`;

const ChartContainer = styled.div`
  width: 85%;
  height: 13rem;
  position: relative;
`;

const TotalExecutionsChart: FunctionComponent<Props> = ({
  integrationStore,
  generalStore,
  access,
  loaded,
}) => {
  const [dataLoad, setDataLoad] = useState(loaded);

  const hasSlowStatus =
    integrationStore.totalExecutions &&
    integrationStore.totalExecutions.length > 0 &&
    integrationStore.totalExecutions.find(
      (data) => data.status === ExecutionSpeedStatus.SuccessSlow
    );

  useEffect(() => {
    access &&
      integrationStore.getTotalExecutionsData().finally(() => {
        setDataLoad(true);
      });
  }, [
    generalStore.currentEnvironment,
    generalStore.timeFrame,
    integrationStore,
    access,
  ]);

  const handleDataReload = () => {
    access && integrationStore.getTotalExecutionsData();
  };

  return (
    <Container>
      <ChartContainer>
        <Line
          //@ts-ignore
          translate={undefined}
          data={{
            labels: generalStore.timeFrame
              ? generalStore.timeFrame.id === GlobalTimeFrame.Hours
                ? getLastHoursLabels(24)
                : generalStore.timeFrame.id === GlobalTimeFrame.ThreeDays
                ? getLastDaysLabels(3)
                : generalStore.timeFrame.id === GlobalTimeFrame.Week
                ? getLastWeekLabels()
                : generalStore.timeFrame.id === GlobalTimeFrame.Month
                ? getLastMonthLabels()
                : getLastQuarterLabels()
              : getLastHoursLabels(24),
            datasets: integrationStore.totalExecutions
              ? [
                  ...integrationStore.totalExecutions.map(
                    (dataSet: TotalExecutions, index: number) => {
                      const status = executionSpeedStatuses[dataSet.status];

                      const addExplanation =
                        status.status !== ExecutionSpeedStatus.Fail &&
                        integrationStore.totalExecutions &&
                        !!integrationStore.totalExecutions.find(
                          (totalExecutionData) =>
                            totalExecutionData.status ===
                            ExecutionSpeedStatus.SuccessSlow
                        );
                      // Label has to be unique! Otherwise a 'visible'-error will occur from the library
                      return {
                        label: `${status.name}${
                          addExplanation ? ` (${status.explanation})` : ""
                        }`,
                        data: dataSet.data,
                        borderColor: status.color,
                        backgroundColor: status.transparentColor,
                        fill: index === 0 ? true : index - 1,
                      };
                    }
                  ),
                ]
              : [],
          }}
          options={{
            maintainAspectRatio: false,
            //@ts-ignore
            interaction: {
              intersect: false,
              mode: "index",
            },
            scales: {
              //@ts-ignore
              x: {
                grid: {
                  display: false,
                },
              },
              y: {
                stacked: true,
                min: 0,
              },
            },
            elements: {
              line: {
                tension: 0.3,
              },
            },
            plugins: {
              legend: {
                display: false,
              },
              tooltip: {
                enabled: false,
                external: function (context: any) {
                  // Tooltip Element
                  var tooltipEl = document.getElementById(
                    "chartjs-tooltip-total-executions"
                  );

                  // Create element on first render
                  if (!tooltipEl) {
                    tooltipEl = document.createElement("div");
                    tooltipEl.id = "chartjs-tooltip-total-executions";
                    tooltipEl.innerHTML = "<div></div>";
                    document.body.appendChild(tooltipEl);
                  }

                  // Hide if no tooltip
                  var tooltipModel = context.tooltip;
                  if (tooltipModel.opacity === 0) {
                    tooltipEl.style.display = "none";
                    return;
                  }

                  // Set caret Position
                  tooltipEl.classList.remove("above", "below", "no-transform");
                  if (tooltipModel.yAlign) {
                    tooltipEl.classList.add(tooltipModel.yAlign);
                  } else {
                    tooltipEl.classList.add("no-transform");
                  }

                  function getBody(bodyItem: any) {
                    return bodyItem.lines;
                  }

                  // Set tooltip content
                  if (tooltipModel.body) {
                    var bodyLines = tooltipModel.body.map(getBody).reverse();

                    // Title
                    let innerHtml = `<p style="font-weight: 700;">${tooltipModel.title}</p>`;

                    // Content
                    let total: number = 0;
                    let htmlValueLines: string = "";

                    bodyLines.forEach(function (body: any, i: number) {
                      const statusColors = [
                        ...tooltipModel.labelColors,
                      ].reverse();
                      var colors = statusColors[i];
                      htmlValueLines += `<div class="block" style="margin-bottom: 0.25rem; display: flex; flex-direction: row; align-items: center; width: 100%">`;
                      htmlValueLines += `<div style="width: 0.8rem; min-width: 0.8rem; height: 0.8rem; min-height: 0.8rem; background-color: ${colors.borderColor}; margin-right: 0.5rem"></div>`;
                      const label = body[0].split(":")[0];
                      const value = body[0].split(":")[1];
                      htmlValueLines += `<p>${label}: <span style="font-weight: 700">${value}</span></p>`;
                      htmlValueLines += `</div>`;
                      total += parseInt(value.replace(',','').replace('.',''));
                    });

                    innerHtml += `<p style="margin-bottom: 0.5rem">Total: <span style="font-weight: 700">${total.toLocaleString('en-US', {maximumFractionDigits:0})}</span></p>`;
                    innerHtml += htmlValueLines;

                    var tableRoot = tooltipEl.querySelector("div");
                    if (tableRoot) {
                      tableRoot.innerHTML = innerHtml;
                    }
                  }

                  var position = context.chart.canvas.getBoundingClientRect();

                  // Display, position, and set styles for font
                  tooltipEl.style.display = "flex";
                  tooltipEl.style.position = "absolute";
                  tooltipEl.style.left =
                    position.left +
                    window.pageXOffset +
                    tooltipModel.caretX +
                    "px";
                  tooltipEl.style.top =
                    position.top +
                    window.pageYOffset +
                    tooltipModel.caretY +
                    "px";
                  tooltipEl.style.padding =
                    tooltipModel.padding + "px " + tooltipModel.padding + "px";
                  tooltipEl.style.borderRadius = "0.1rem";
                  tooltipEl.style.display = "flex";
                  tooltipEl.style.flexDirection = "row";
                  tooltipEl.style.boxShadow =
                    "0px 1px 1px 1px rgba(0, 0, 0, 0.15)";
                  tooltipEl.style.backgroundColor = "#fefefe";
                  tooltipEl.style.padding = "0.5rem 0.75rem";
                  tooltipEl.style.pointerEvents = "none";
                  tooltipEl.style.color = $textLight;
                },
              },
            },
          }}
        />

        {access &&
          (!dataLoad ? (
            <Loader component />
          ) : integrationStore.totalExecutions &&
            integrationStore.totalExecutions.length === 0 ? (
            <Modal
              customText="You don't have any integrations for this environment yet."
              background
            />
          ) : (
            !integrationStore.totalExecutions && (
              <Modal
                type={ModalType.DataLoadFailed}
                action={handleDataReload}
                background
              />
            )
          ))}
      </ChartContainer>

      {access && integrationStore.totalExecutions && (
        <Legend
          items={
            integrationStore.totalExecutions.length > 0
              ? [
                  ...integrationStore.totalExecutions.map(
                    (dataSet: TotalExecutions) => {
                      const status = executionSpeedStatuses[dataSet.status];

                      return {
                        boldValue: undefined,
                        explanation: `${status.name}${
                          hasSlowStatus && status.explanation
                            ? ` (${status.explanation})`
                            : ""
                        }`,
                        color: status.color,
                      };
                    }
                  ),
                ].reverse()
              : []
          }
          vertical
          small
          extraStyling={css`
            min-width: 10rem;
            margin-left: 1.5rem;
          `}
        />
      )}
    </Container>
  );
};

export default inject(
  "generalStore",
  "integrationStore"
)(
  observer(
    TotalExecutionsChart as FunctionComponent<
      Omit<Props, "generalStore" | "integrationStore">
    >
  )
);
