import { ReportSLA } from "./Sla";

export interface ReportSectionBasicData {
  total: number;
  open: number;
  inProgress: number;
  resolved: number;
  closed: number;
}

export enum ReportIncidentStatus {
  Open = 0,
  Closed = 1,
  Resolved = 2,
}

export interface ReportIncidentStatusType {
  status: ReportIncidentStatus;
  name: string;
}

export const reportIncidentStatuses = [
  {
    status: ReportIncidentStatus.Open,
    name: "Open",
  },
  { status: ReportIncidentStatus.Closed, name: "Closed" },
  { status: ReportIncidentStatus.Resolved, name: "Resolved" },
];

export const getReportIncidentStatus = (status: ReportIncidentStatus) =>
  reportIncidentStatuses.find(
    (incidentStatus) => incidentStatus.status === status
  ) || reportIncidentStatuses[0];

export interface ReportIncident {
  ticket: string;
  priority: string;
  description: string;
  createdBy: string;
  created: Date;
  started: Date | undefined;
  resolved: Date | undefined;
  status: ReportIncidentStatus;
  responseTime: string | undefined;
  resolutionTime: string | undefined;
  responseTimeBreached: boolean;
  resolutionTimeBreached: boolean;
}

export interface ReportChangeRequest {
  ticket: string;
  title: string;
  requestedBy: string;
  workOrder: string;
  hours: string;
  canDispute: boolean;
}

export interface ReportProActiveWorkItem {
  ticket: string;
  date: Date;
  task: string;
  closed: boolean;
}

export interface ReportMonthIncidents extends ReportSectionBasicData {
  resolvedList: ReportIncident[];
  openList: ReportIncident[];
}

export interface ReportMonthProActiveWork extends ReportSectionBasicData {
  list: ReportProActiveWorkItem[];
}

export interface ReportMonthChangeRequests extends ReportSectionBasicData {
  disputeEmail: string;
  list: ReportChangeRequest[];
}

export interface ReportMonth {
  numberOfTickets: number;
  openedTickets: number;
  closedTickets: number;
  incidents: ReportMonthIncidents;
  sla: ReportSLA;
  proActiveWork: ReportMonthProActiveWork;
  changeRequests: ReportMonthChangeRequests;
}
