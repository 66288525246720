import { ExtraStylingType } from "model/Styles";
import React, { FunctionComponent, useState } from "react";
import styled, { css } from "styled-components";
import {
  Text,
  Span,
  $statusFail,
  $blue4,
  $textLight,
  StyledReactTooltip,
  textStyling,
} from "styles/common";
import IconComponent from "components/common/Icon";
import { Icon } from "model/Icon";

interface Radio {
  value: string;
  label: string;
}

interface Props {
  label?: string;
  groupName: string;
  radios: Radio[];
  defaultValue: string;
  vertical?: boolean;
  mandatory?: boolean;
  infoTooltip?: string;
  infoTooltipContent?: any;
  className?: string;
  onValueChange(value: string): void;
  extraStyling?: ExtraStylingType;
  extraLabelStyling?: ExtraStylingType;
  extraRadioLabelStyling?: ExtraStylingType;
}

const Container = styled.div<{ extraStyling: ExtraStylingType }>`
  ${(props) => props.extraStyling}
`;

const LabelContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.5rem;
`;

const Label = styled(Text)<{ extraLabelStyling: ExtraStylingType }>`
  margin-bottom: 0.5rem;

  ${(props) => props.extraLabelStyling}
`;

const RadiosContainer = styled.div<{ vertical: boolean }>`
  display: flex;

  ${(props) =>
    props.vertical
      ? css`
          flex-direction: column;
        `
      : css`
          flex-direction: row;
          flex-wrap: wrap;
          column-gap: 1rem;
          row-gap: 0.3rem;
        `}
`;

const RadioContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.25rem;
`;

const RadioLabel = styled.label<{ extraRadioLabelStyling: ExtraStylingType }>`
  ${textStyling}
  min-width: max-content;

  ${(props) => props.extraRadioLabelStyling}
`;

const RadioButtonGroup: FunctionComponent<Props> = ({
  label,
  groupName,
  radios,
  defaultValue,
  vertical = false,
  mandatory = false,
  infoTooltip,
  infoTooltipContent,
  className = "",
  onValueChange,
  extraStyling = css``,
  extraLabelStyling = css``,
  extraRadioLabelStyling = css``,
}) => {
  const [value, setValue] = useState(defaultValue);

  return (
    <Container className={className} extraStyling={extraStyling}>
      <LabelContainer>
        {label && (
          <Label semibold extraLabelStyling={extraLabelStyling}>
            {label}
            {mandatory ? (
              <Span className="mandatory-star" color={$statusFail}>
                *
              </Span>
            ) : (
              ""
            )}
          </Label>
        )}

        {infoTooltip && (
          <>
            <div
              className="radio-button-tooltip"
              data-for={infoTooltip}
              data-tip=""
            >
              <IconComponent name={Icon.info} color={$blue4} />
            </div>
            <StyledReactTooltip
              id={infoTooltip}
              effect="solid"
              place="right"
              type="light"
              border={false}
              textColor={$textLight}
              backgroundColor={"#fff"}
              arrowColor={"transparent"}
            >
              {infoTooltipContent && infoTooltipContent}
            </StyledReactTooltip>
          </>
        )}
      </LabelContainer>

      <RadiosContainer vertical={vertical}>
        {radios.map((radio, index) => {
          return (
            <RadioContainer key={index}>
              <input
                className="radio-input"
                type="radio"
                id={`${groupName}${radio.value}`}
                name={groupName}
                value={radio.value}
                checked={value === radio.value}
                onChange={() => {
                  setValue(radio.value);
                  onValueChange(radio.value);
                }}
              />
              <RadioLabel
                className="radio-label"
                htmlFor={`${groupName}${radio.value}`}
                extraRadioLabelStyling={extraRadioLabelStyling}
              >
                {radio.label}
              </RadioLabel>
            </RadioContainer>
          );
        })}
      </RadiosContainer>
    </Container>
  );
};

export default RadioButtonGroup as FunctionComponent<Props>;
