import { action, makeObservable, observable } from "mobx";
import { Execution } from "model/Execution";
import { ExecutionDetails } from "model/ExecutionDetails";
import { ExecutionSearchFilter } from "model/ExecutionSearch";
import { ExecutionApi } from "services/apis/ExecutionApi";
import GeneralStore from "./GeneralStore";
import LoaderStore from "./LoaderStore";

class ExecutionStore {
  @observable
  executionsData: Execution[] | undefined = [];

  @observable
  pagedExecutions: Execution[][] | undefined = [];

  @observable
  executionDetailsData: ExecutionDetails[] | undefined = [];
  
  @observable
  searchFilter: ExecutionSearchFilter | undefined;

  constructor(
    public api: ExecutionApi,
    private generalStore: GeneralStore,
    private loaderStore: LoaderStore
  ) {
    makeObservable(this);
  }

  @action
  getExecutionsData = async () => {
    if (
      this.generalStore.currentEnvironment !== undefined &&
      this.generalStore.timeFrame
    ) {
      this.loaderStore.setLoading();

      const response = await this.api.getExecutionData(
        this.generalStore.currentEnvironment,
        this.generalStore.timeFrame
      );
      if (response.success) {
        const { data } = response;
        this.setRawExecutionsData(data);
      } else {
        console.log("Something went wrong when trying to fetch integrations");
      }

      this.loaderStore.stopLoading();
    }
  };

  @action
  setRawExecutionsData = (executions: Execution[]) => {
    this.executionsData = executions;
  }

  @action
  setExecutionsData = (executions: Execution[][]) => {
    this.pagedExecutions = executions;
  }

  @action
  setSearchFilter = (searchFilter: ExecutionSearchFilter) => {
    this.searchFilter = searchFilter;
  }

  @action
  getExecutionDetailsData = async (transactionId: number) => {
    this.loaderStore.setLoading();

    const response = await this.api.getExecutionDetails(transactionId);
    if (response.success) {
      const { data } = response;
      this.executionDetailsData = data;
    } else {
      console.log("Something went wrong when trying to fetch integrations");
    }

    this.loaderStore.stopLoading();
  }

  @action
  reset() {
    // this.executionsData?.forEach(e => e.details = undefined);
    this.executionsData = undefined;
    this.pagedExecutions = undefined;
    this.executionDetailsData = undefined;
    this.searchFilter = undefined;
  }
}


export default ExecutionStore;
