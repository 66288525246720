import Loader from "components/common/Loader";
import { Modal, ModalType } from "components/common/Modal";
import { Icon } from "model/Icon";
import { ExtraStylingType } from "model/Styles";
import React from "react";
import styled, { css } from "styled-components";
import { $blue4, Text } from "styles/common";
import IconComponent from "../Icon";
import { ListHeaderText, NoSearchResults, noSearchResultsText } from "./common";

export type ListHeader = JSX.Element | string;
export type ListCell = JSX.Element | number | string;

export interface ListDataRow {
  cells: ListCell[];
  color?: string;
  selected?: boolean;
  details?: any;
}

interface Props {
  className?: string;
  headers: ListHeader[];
  data: ListDataRow[] | undefined;
  customColumnWidths?: number[];
  colorIndicator?: boolean;
  hover?: boolean;
  selectedRow?: number;
  reverseBackground?: boolean;
  noBackground?: boolean;
  headerBackground?: boolean;
  blockClicking?: number[];
  noDataText: string;
  smallEmpty?: boolean;
  fullWidth?: boolean;
  access?: boolean;
  loading?: boolean;
  searchMode?: boolean;
  hasDetails?: boolean;
  onRowClick?(item: any): void;
  retryDataLoading(): void;
  extraStyling?: ExtraStylingType;
  alignments?: (string | undefined)[];
  cellPadding?: (string | undefined)[];
}

const ListContainer = styled.div<{ extraStyling: ExtraStylingType }>`
  display: flex;
  flex-direction: column;
  width: 100%;

  ${(props) => props.extraStyling}
`;

const Header = styled.div<{ headerBackground?: boolean; fullWidth: boolean }>`
  min-height: 50px;
  position: relative;
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  padding: ${(props) => (props.fullWidth ? "" : "0 1.5rem")};

  ${(props) =>
    props.headerBackground &&
    css`
      background-color: #e3eaee;
      padding: 0;
      min-height: auto;
      padding: 0.5rem 1.5rem;
    `}
`;

const HeaderItem = styled.div<{ flex?: number }>`
  padding: 0 0.25rem;
  flex: ${(props) => (props.flex ? props.flex : "1 1")};
`;

const Rows = styled.div`
  position: relative;
`;

const Row = styled.div<{
  hover?: boolean;
  selected?: boolean;
  clickable?: boolean;
  reverseBackground: boolean;
  noBackground: boolean;
  fullWidth: boolean;
}>`
  padding: ${(props) => (props.fullWidth ? "0.5rem 0" : "0.5rem 1.5rem")};
  min-height: 0;
  position: relative;
  display: flex;
  flex-wrap: nowrap;
  align-items: center;

  ${(props) =>
    !props.noBackground
      ? !props.reverseBackground
        ? css`
            &:nth-of-type(odd) {
              background-color: #edf6fb;
            }
          `
        : css`
            &:nth-of-type(even) {
              background-color: #edf6fb;
            }
          `
      : css``}

  ${(props) =>
    props.selected
      ? css`
          &&& {
            background-color: ${$blue4};
          }

          p,
          span {
            color: #fff !important;
          }

          svg {
            fill: #fff !important;
          }
        `
      : css`
          ${props.hover &&
        css`
            &:hover {
              background-color: #d8eaf4;
            }
          `}

          ${props.clickable &&
        css`
            cursor: pointer;
          `}
        `}
`;

const cellStyling = css<{ flex?: number, textAlign?: string, paddingLeft?: string }>`
  padding: 0 ${(props) => (props.paddingLeft ? props.paddingLeft : "0.25rem")};
  position: relative;
  width: "100%";
  flex: ${(props) => (props.flex ? props.flex : "1 1")};
  text-align: ${(props) => (props.textAlign ? props.textAlign : "left")};
`;

const TextCell = styled(Text)`
  ${cellStyling}
`;

const Cell = styled.div`
  ${cellStyling}
`;

const ColorIndicatorContainer = styled.div`
  flex: 0 0 auto;
  width: 4px;
  padding: 0px;
`;

const ColorIndicator = styled.div<{ color: string }>`
  top: 0;
  bottom: 0;
  width: 4px;
  position: absolute;
  left: 0;

  ${(props) =>
    css`
      background-color: ${props.color};
    `}
`;

const EmptyCell = styled.div<{ flex?: number }>`
  background-color: rgb(238, 238, 238);
  background-size: 200px 100%;
  background-repeat: no-repeat;
  border-radius: 4px;
  display: inline-block;
  line-height: 1;
  height: 1rem;
  margin: 0 0.25rem;
  width: 100%;
  box-sizing: border-box;

  flex: ${(props) => (props.flex ? props.flex : "1 1")};
`;

export const List = ({
  className = "",
  headers,
  data,
  customColumnWidths,
  colorIndicator,
  hover = false,
  selectedRow,
  reverseBackground = false,
  noBackground = false,
  headerBackground = false,
  blockClicking = [],
  noDataText,
  smallEmpty = false,
  fullWidth = false,
  access = true,
  loading = false,
  searchMode = false,
  hasDetails = false,
  onRowClick,
  retryDataLoading,
  extraStyling = css``,
  alignments = [],
  cellPadding = []
}: Props) => {
  const renderNoDataRows = (
    <>
      {[...Array(smallEmpty ? 4 : 10)].map((row, rowIndex) => {
        return (
          <Row
            key={rowIndex}
            reverseBackground
            noBackground={noBackground}
            fullWidth={fullWidth}
          >
            {[...Array(headers.length)].map((cell, cellIndex) => {
              return (
                <EmptyCell
                  key={cellIndex}
                  flex={
                    customColumnWidths && customColumnWidths[cellIndex]
                      ? customColumnWidths[cellIndex]
                      : undefined
                  }
                />
              );
            })}
          </Row>
        );
      })}
    </>
  );

  return (
    <ListContainer
      className={`list-items ${className}`}
      extraStyling={extraStyling}
    >
      <Header headerBackground={headerBackground} fullWidth={fullWidth}>
        {colorIndicator && <ColorIndicatorContainer />}
        {hasDetails ? <HeaderItem flex={0.05}></HeaderItem> : <></>}
        {headers.map((header: ListHeader, index: number) => (
          <HeaderItem
            key={index}
            flex={
              customColumnWidths && customColumnWidths[index]
                ? customColumnWidths[index]
                : undefined
            }
          >
            {typeof header === "string" ? (
              <ListHeaderText>{header}</ListHeaderText>
            ) : (
              header
            )}
          </HeaderItem>
        ))}
      </Header>
      <Rows>
        {access && data ? (
          data.length > 0 ? (
            data.map((row: ListDataRow, index: number) => {
              return (
                <>
                  <Row
                    className={`list-item ${selectedRow === index ? "selected-list-item" : ""
                      }`}
                    key={index}
                    selected={selectedRow === index}
                    hover={hover && !blockClicking.includes(index)}
                    clickable={!!onRowClick && !blockClicking.includes(index)}
                    reverseBackground={reverseBackground}
                    noBackground={noBackground}
                    fullWidth={fullWidth}
                    onClick={() => {
                      !blockClicking.includes(index) &&
                        onRowClick &&
                        onRowClick(index);
                    }}
                  >
                    {colorIndicator && row.color && (
                      <ColorIndicatorContainer className="color-indicator">
                        <ColorIndicator color={row.color} />
                      </ColorIndicatorContainer>
                    )}
                    {hasDetails ? <div style={{ paddingRight: "0.15rem", paddingLeft: "0.5rem" }}>
                      {hasDetails ? <IconComponent name={row.details ? Icon.arrowdown : Icon.arrowright} color={"#5f7787"} /> : <></>}
                    </div> : <></>}
                    {row.cells.length > 0 &&
                      row.cells.map((cell: ListCell, cellIndex: number) => {
                        return typeof cell === "string" ||
                          typeof cell === "number" ? (
                          <TextCell
                            key={cellIndex}
                            flex={
                              customColumnWidths && customColumnWidths[cellIndex]
                                ? customColumnWidths[cellIndex]
                                : undefined
                            }
                            paddingLeft={
                              cellPadding && cellPadding[cellIndex]
                                ? cellPadding[cellIndex]
                                : undefined
                            }
                            textAlign={alignments && alignments[cellIndex] ? alignments[cellIndex] : undefined}
                          >
                            {cell}
                          </TextCell>
                        ) : (
                          <Cell
                            key={cellIndex}
                            flex={
                              customColumnWidths && customColumnWidths[cellIndex]
                                ? customColumnWidths[cellIndex]
                                : undefined
                            }
                            textAlign={alignments && alignments[cellIndex] ? alignments[cellIndex] : undefined}
                          >
                            {cell}
                          </Cell>
                        );
                      })}
                  </Row>
                  {row.details ?
                    <Row
                      className={`list-item`}
                      key={index + '-details'}
                      selected={selectedRow === index}
                      hover={false}
                      clickable={false}
                      reverseBackground={reverseBackground}
                      noBackground={true}
                      fullWidth={false}
                    >
                      {colorIndicator && row.color && (
                        <ColorIndicatorContainer className="color-indicator">
                          <ColorIndicator color={row.color} />
                        </ColorIndicatorContainer>
                      )}
                      <div style={{ width: "100%" }}>
                        {row.details}
                      </div>
                    </Row> : <></>}
                </>
              );
            })
          ) : searchMode ? (
            <NoSearchResults>{noSearchResultsText}</NoSearchResults>
          ) : (
            <>
              {renderNoDataRows}
              {loading ? (
                <Loader component />
              ) : (
                <Modal customText={noDataText} />
              )}
            </>
          )
        ) : (
          <>
            {renderNoDataRows}
            {access &&
              (loading ? (
                <Loader component />
              ) : (
                <Modal
                  type={ModalType.DataLoadFailed}
                  action={retryDataLoading}
                />
              ))}
          </>
        )}
      </Rows>
    </ListContainer>
  );
};
