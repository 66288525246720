import { CrudModal } from "components/common/CrudModal";
import PhoneInput from "components/common/PhoneInput";
import TextInput from "components/common/TextInput";
import { SettingsContact } from "model/Contact";
import React, { FunctionComponent, useState } from "react";
import styled, { css } from "styled-components";
import { validateEmail } from "utils/Validation";

interface Props {
  editContact?: SettingsContact;
  setShow(show: boolean): void;
  action(contact: SettingsContact): void;
}

const AddUserContentContainer = styled.div``;

export const numberErrorText =
  "Please fill in either a phone or a mobile number";

export const emailErrorText = "Please fill in a valid e-mail address";

export const SettingContactModal: FunctionComponent<Props> = ({
  editContact,
  setShow,
  action,
}) => {
  const [contact, setContact] = useState<SettingsContact>(
    editContact
      ? editContact
      : {
          id: 0,
          contact: "",
          role: "",
          phone: undefined,
          mobile: undefined,
          email: "",
          deletable: true,
        }
  );

  const [numberError, setNumberError] = useState(false);
  const [emailError, setEmailError] = useState(false);

  return (
    <CrudModal
      className="setting-contact-modal"
      title={!!editContact ? "Edit contact" : "Add a contact"}
      content={
        <AddUserContentContainer>
          <TextInput
            className="contact-input"
            label="Contact"
            defaultValue={contact.contact}
            mandatory
            onValueChange={(value) =>
              setContact({ ...contact, contact: value })
            }
            extraStyling={css`
              margin-bottom: 1rem;
            `}
          />
          <TextInput
            className="role-input"
            label="Role"
            defaultValue={contact.role}
            mandatory
            onValueChange={(value) => setContact({ ...contact, role: value })}
            extraStyling={css`
              margin-bottom: 1rem;
            `}
          />
          <PhoneInput
            className="phone-input"
            label="Phone"
            defaultValue={contact.phone}
            error={numberError}
            errorText={numberErrorText}
            onValueChange={(value) => {
              setNumberError(false);

              setContact({
                ...contact,
                phone: value,
              });
            }}
            extraStyling={css`
              margin-bottom: 1rem;
            `}
          />
          <PhoneInput
            className="mobile-input"
            label="Mobile"
            defaultValue={contact.mobile}
            error={numberError}
            errorText={numberErrorText}
            onValueChange={(value) => {
              setNumberError(false);

              setContact({
                ...contact,
                mobile: value,
              });
            }}
            extraStyling={css`
              margin-bottom: 1rem;
            `}
          />
          <TextInput
            className="email-input"
            label="E-mail"
            defaultValue={contact.email}
            mandatory
            error={emailError}
            errorText={emailErrorText}
            onValueChange={(value) => {
              setEmailError(false);
              setContact({ ...contact, email: value });
            }}
            extraStyling={css`
              margin-bottom: 1rem;
            `}
          />
        </AddUserContentContainer>
      }
      cancel={() => {
        setShow(false);
      }}
      disableAction={
        contact.contact === "" || contact.role === "" || contact.email === ""
      }
      action={() => {
        let error = false;

        if (
          (!contact.phone || contact.phone.number === "") &&
          (!contact.mobile || contact.mobile.number === "")
        ) {
          error = true;
          setNumberError(true);
        }

        if (!validateEmail(contact.email)) {
          error = true;
          setEmailError(true);
        }

        if (!error) {
          action(contact);
          setShow(false);
        }
      }}
      extraStyling={css`
        min-width: 25rem;
      `}
    />
  );
};
