import Card from "components/common/Card";
import { NoAccessModal } from "components/common/Modal";
import IntegrationsFirstLevelDiagram from "components/Integrations/IntegrationsFirstLevelDiagram";
import IntegrationsHeatMap from "components/Integrations/IntegrationsHeatMap";
import { inject, observer } from "mobx-react";
import { PermissionArea, PermissionType } from "model/Claim";
import { Route } from "model/Navigation";
import { globalTimeFrames } from "model/Time";
import React, { FunctionComponent } from "react";
import { Prompt } from "react-router";
import AuthenticationStore from "stores/AuthenticationStore";
import GeneralStore from "stores/GeneralStore";
import IntegrationStore from "stores/IntegrationStore";
import { Filter, Select, Text } from "styles/common";

interface Props {
  generalStore: GeneralStore;
  integrationStore: IntegrationStore;
  authenticationStore: AuthenticationStore;
}

const Integrations: FunctionComponent<Props> = ({
  generalStore,
  integrationStore,
  authenticationStore,
}) => {
  const accessViewIntegration = authenticationStore.userHasPermission(
    PermissionArea.Integration,
    PermissionType.View
  );

  const renderIntegrationsFilter = () => (
    <Filter>
      <Text large semibold dark>
        My integrations on
      </Text>
      <Select
        defaultValue={generalStore.currentEnvironment}
        highlight
        disabled={!accessViewIntegration}
        onChange={(e) => {
          generalStore.setCurrentEnvironment(parseInt(e.target.value));
        }}
      >
        {generalStore.environments &&
          generalStore.environments.map((environment, index) => (
            <option key={index} value={environment.id}>
              {environment.name}
            </option>
          ))}
      </Select>
      <Text large semibold dark>
        in the
      </Text>
      {generalStore.timeFrame && (
        <Select
          defaultValue={generalStore.timeFrame.id}
          highlight
          disabled={!accessViewIntegration}
          onChange={(e) => {
            generalStore.setTimeFrame(parseInt(e.target.value));
          }}
        >
          {globalTimeFrames.map((timeFrame, index) => (
            <option key={index} value={timeFrame.id}>
              {timeFrame.name.toLowerCase()}
            </option>
          ))}
        </Select>
      )}
    </Filter>
  );

  return (
    <>
      <Prompt
        message={(location) => {
          if (!location.pathname.startsWith(Route.Integrations))
            // Reset data when leaving page
            integrationStore.reset();

          return true;
        }}
      />

      {renderIntegrationsFilter()}

      <Card>
        {!accessViewIntegration && <NoAccessModal />}

        <IntegrationsHeatMap access={accessViewIntegration} />

        {accessViewIntegration && integrationStore.flowDiagramMode && (
          <IntegrationsFirstLevelDiagram />
        )}
      </Card>
    </>
  );
};

export default inject(
  "integrationStore",
  "generalStore",
  "authenticationStore"
)(
  observer(
    Integrations as FunctionComponent<
      Omit<Props, "integrationStore" | "generalStore" | "authenticationStore">
    >
  )
);
