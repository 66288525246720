export enum Icon {
  doublearrow = "doublearrow",
  arrowdown = "arrowdown",
  bell = "bell",
  cart = "cart",
  user = "user",
  gear = "gear",
  cross = "cross",
  circle = "circle",
  group = "group",
  list = "list",
  arrowleft = "arrowleft",
  arrowright = "arrowright",
  doublearrowleft = "doublearrowleft",
  doublearrowright = "doublearrowright",
  calendarstart = "calendarstart",
  calendarend = "calendarend",
  save = "save",
  reset = "reset",
  plus = "plus",
  minus = "minus",
  history = "history",
  refresh = "refresh",
  location = "location",
  application = "application",
  info = "info",
  dispute = "dispute",
  check = "check",
  delete = "delete",
  mail = "mail",
  locked = "locked",
  unlocked = "unlocked",
  unchecked = "unchecked",
  edit = "edit",
  portal = "portal",
  loader = "loader",
  search = "search",
  lock = "lock",
  block = "block",
  unassigned = "unassigned",
}
