import { Environment } from "model/Environment";
import { CalendarSuccessRateData } from "model/Integration";
import request, { ApiResponse } from "../../utils/ApiHandlers";
import { BaseConfig } from "../../utils/Variables";

export class GeneralApi {
  private static instance: GeneralApi;

  private constructor() {}

  static getInstance(fresh?: boolean) {
    if (!GeneralApi.instance || fresh) {
      GeneralApi.instance = new GeneralApi();
    }
    return GeneralApi.instance;
  }

  getEnvironments = async (): Promise<ApiResponse<Environment[]>> =>
    request.get(`${BaseConfig.getBaseApiUrl()}dashboard/environments`);

  getCalendarSuccessRateData = async (
    environmentId: number
  ): Promise<ApiResponse<CalendarSuccessRateData>> =>
    request.get(
      `${BaseConfig.getBaseApiUrl()}dashboard/success-rates/${environmentId}`
    );
}

export default GeneralApi.getInstance();
