export enum Claim {
  Name = "name",
  Role = "role",
  Permission = "permission",
  Subscription = "subscription",
}

export enum PermissionArea {
  Customer = "customer",
  Integration = "integration",
  Execution = "execution",
  Application = "application",
  Usage = "usage",
  Report = "report",
  Contact = "contact",
}

export enum PermissionType {
  View = "view",
  Edit = "edit",
  Delete = "delete",
}
