import IconComponent from "components/common/Icon";
import { Icon } from "model/Icon";
import React from "react";
import styled, { css } from "styled-components";
import {
  IconButton,
  Text,
  $statusFail,
  $textLight,
  smallText,
  semiBoldText,
} from "styles/common";

interface Props {
  className?: string;
  disabled?: boolean;
  onClick(): void;
}

export const NoSearchResults = styled(Text)`
  opacity: 0.6;
  margin-left: 1.75rem;
`;

export const noSearchResultsText = "No search results";

export const ListHeaderText = styled(Text)`
  ${smallText}
  ${semiBoldText}
`;

export const CenteredCellContainer = styled.div<{ space?: boolean }>`
  margin: 0 auto;
  width: max-content;

  ${(props) =>
    props.space
      ? css`
          padding-right: 3rem;
        `
      : css``}
`;

export const DeleteButton = ({
  className = "",
  disabled = false,
  onClick,
}: Props) => (
  <IconButton
    className={className}
    hoverColor={$statusFail}
    disabled={disabled}
    onClick={(e) => {
      e.stopPropagation();
      onClick();
    }}
  >
    <IconComponent name={Icon.delete} height="12px" color={$textLight} />
    <Text small>Delete</Text>
  </IconButton>
);

export const EditButton = ({
  className = "",
  disabled = false,
  onClick,
}: Props) => (
  <IconButton
    className={className}
    disabled={disabled}
    onClick={(e) => {
      e.stopPropagation();
      onClick();
    }}
  >
    <IconComponent name={Icon.edit} height="12px" color={$textLight} />
    <Text small>Edit</Text>
  </IconButton>
);
