import { $grey, $textDark, $textLight } from "styles/common";

// Hover over application --> highlight related platform components
export const highlightApplicationRelations = (
  applicationId: string,
  show: boolean
) => {
  const platformComponents = document.getElementsByClassName(
    `platform-component ${applicationId}`
  ) as HTMLCollectionOf<HTMLElement>;

  if (platformComponents.length > 0) {
    for (let platformComponent of platformComponents) {
      const isTile = platformComponent.parentElement?.className.includes(
        "platform-component-tile"
      );
      if (isTile) {
        platformComponent.style.border = show
          ? `1px solid ${$textLight}`
          : "1px solid transparent";
      } else {
        platformComponent.style.backgroundColor = show ? $grey : "transparent";
        platformComponent.getElementsByTagName("p")[0].style.color = show
          ? $textDark
          : $textLight;
      }
    }
  }
};

// Hover over platform component --> highlight related applications
export const highlightRelatedApplications = (
  applicationIds: string[],
  show: boolean,
  reset?: boolean
) => {
  if (reset) {
    // Remove previously hightlighted applications (in case an item was clicked)
    const applicationComponents = document.getElementsByClassName(
      "application"
    ) as HTMLCollectionOf<HTMLElement>;
    if (applicationComponents.length > 0) {
      for (let applicationComponent of applicationComponents) {
        applicationComponent.style.backgroundColor = "transparent";
        applicationComponent.style.color = $textLight;
      }
    }
  }

  // Highlight related applications
  applicationIds.forEach((applicationId) => {
    const applicationComponent = document.getElementsByClassName(
      `application ${applicationId}`
    )[0] as HTMLElement;

    if (applicationComponent) {
      applicationComponent.style.backgroundColor = show ? $grey : "transparent";
      applicationComponent.style.color = show ? $textDark : $textLight;
    }
  });
};
