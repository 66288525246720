import { action, makeObservable, observable } from "mobx";
import { Environment } from "model/Environment";
import { CalendarSuccessRateData } from "model/Integration";
import { CountryPhoneCode } from "model/Phone";
import { GeneralTimeFrame, getTimeJump, GlobalTimeFrame } from "model/Time";
import { GeneralApi } from "services/apis/GeneralApi";
import { getGlobalStartDate } from "utils/Date";
import LoaderStore from "./LoaderStore";

class GeneralStore {
  @observable
  countryPhoneCodes: CountryPhoneCode[] | undefined = undefined;

  @observable
  environments: Environment[] | undefined = undefined;

  @observable
  currentEnvironment: number | undefined = undefined;

  @observable
  timeFrame: GeneralTimeFrame | undefined = undefined;

  @observable
  calendarSuccessRate: CalendarSuccessRateData | undefined = undefined;

  constructor(public api: GeneralApi, private loaderStore: LoaderStore) {
    makeObservable(this);
  }

  @action
  setCountryPhoneCodes = async (codes: CountryPhoneCode[]) => {
    this.countryPhoneCodes = codes;
  };

  getCountryPhoneCode = (country: string) =>
    this.countryPhoneCodes &&
    this.countryPhoneCodes.find(
      (countryPhoneCode) => countryPhoneCode.country === country
    );

  @action
  getEnvironments = async () => {
    this.loaderStore.setLoading();

    const response = await this.api.getEnvironments();

    if (response.success) {
      const { data } = response;
      if (data.length > 0) {
        this.environments = data;
        this.currentEnvironment = data[0].id;
      }
    } else {
      //const { errorData } = response;
      console.log("Something went wrong when trying to fetch environments");
    }

    this.loaderStore.stopLoading();
  };

  @action
  setCurrentEnvironment = (environmentId: number) => {
    this.currentEnvironment = environmentId;
  };

  getCurrentEnvironmentName = () => {
    return (
      this.environments?.find((env) => env.id === this.currentEnvironment)
        ?.name || ""
    );
  };

  @action
  setTimeFrame = (timeFrame: GlobalTimeFrame) => {
    let end = new Date();
    let start = getGlobalStartDate(end, timeFrame);
    let timeJump = getTimeJump(timeFrame);
    this.timeFrame = {
      id: timeFrame,
      timeJump: timeJump,
      start: start,
      end: end,
    };
  };

  @action
  getCalendarSuccessRateData = async () => {
    if (this.currentEnvironment !== undefined) {
      this.loaderStore.setLoading();

      const response = await this.api.getCalendarSuccessRateData(
        this.currentEnvironment
      );
      if (response.success) {
        const { data } = response;
        this.calendarSuccessRate = {
          ...data,
          years: data.years ? new Map(Object.entries(data.years)) : new Map([]),
        };
      } else {
        //const { errorData } = response;
        console.log(
          "Something went wrong when trying to fetch calendar success rates"
        );
      }

      this.loaderStore.stopLoading();
    }
  };

  @action
  resetCalendarSuccessRate = () => {
    this.calendarSuccessRate = undefined;
  };
}

export default GeneralStore;
