import Legend, { LegendItem } from "components/common/Legend";
import { ExtraStylingType } from "model/Styles";
import React, { FunctionComponent, useEffect, useState } from "react";
import { Pie } from "react-chartjs-2";
import styled, { css } from "styled-components";
import {
  $textLight,
  reportTicketStatusColorPalette,
  StatusCircle,
  Text,
  Button,
  $statusFail,
} from "styles/common";
import { getSumOfNumbersArray } from "utils/Array";

interface Data {
  open: number;
  inProgress: number;
  resolved: number;
  closed: number;
}

interface Props {
  title: string;
  color: string;
  data: Data | undefined;
  access: boolean;
  retryDataLoading(): void;
  extraStyling?: ExtraStylingType;
}

const Container = styled.div<{
  extraStyling: ExtraStylingType;
}>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 1.5rem;

  ${(props) => props.extraStyling};
`;

const Content = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  position: relative;
`;

const size = "5rem";

const PieContainer = styled.div`
  position: relative;
  min-width: ${size};
  width: ${size};
  max-width: ${size};
`;

const LegendContainer = styled.div`
  margin-left: 1rem;
`;

const NoDataContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 1rem;
`;

const NoDataImage = styled.div`
  min-width: ${size};
  width: ${size};
  max-width: ${size};
  height: ${size};
  display: flex;
  align-items: center;
  justify-content: center;
`;

const FailedContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
  text-align: center;
`;

const emptyPieSize = "5rem";

const EmptyPie = styled.div`
  width: ${emptyPieSize};
  min-width: ${emptyPieSize};
  max-width: ${emptyPieSize};
  height: 5rem;
  min-height: ${emptyPieSize};
  max-height: ${emptyPieSize};
  border-radius: 50%;
  background-color: #f3f3f3;
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
`;

export const ReportPieChart: FunctionComponent<Props> = ({
  title,
  color,
  data,
  access,
  retryDataLoading,
  extraStyling = css``,
}) => {
  const [legendItems, setLegendItems] = useState<LegendItem[] | undefined>(
    undefined
  );

  useEffect(() => {
    setLegendItems([
      {
        explanation: "Open:",
        boldValue: data && data.open.toString(),
        color: reportTicketStatusColorPalette[0],
      },
      {
        explanation: "In progress:",
        boldValue: data && data.inProgress.toString(),
        color: reportTicketStatusColorPalette[1],
      },
      {
        explanation: "Resolved:",
        boldValue: data && data.resolved.toString(),
        disclaimer: true,
        color: reportTicketStatusColorPalette[2],
      },
      {
        explanation: "Closed:",
        boldValue: data && data.closed.toString(),
        disclaimer: true,
        color: reportTicketStatusColorPalette[3],
      },
    ]);
  }, [data]);

  const renderPieChart = () => {
    const dataValues = data
      ? [data.open, data.inProgress, data.resolved, data.closed]
      : [];

    const dataIsEmpty = getSumOfNumbersArray(dataValues) === 0;

    return (
      data && (
        <Content>
          {dataIsEmpty && <EmptyPie />}
          <PieContainer>
            <Pie
              data={{
                labels: ["Open", "In progress", "Resolved*", "Closed*"],
                datasets: [
                  {
                    data: dataValues,
                    backgroundColor: reportTicketStatusColorPalette,
                    hoverBackgroundColor: reportTicketStatusColorPalette,
                    weight: 15,
                  },
                ],
              }}
              options={{
                layout: {
                  padding: 0,
                },
                plugins: {
                  legend: {
                    display: false,
                  },
                  tooltip: {
                    enabled: false,
                    external: function (context: any) {
                      // Tooltip Element
                      var tooltipEl = document.getElementById(
                        "chartjs-tooltip-report-pie"
                      );

                      // Create element on first render
                      if (!tooltipEl) {
                        tooltipEl = document.createElement("div");
                        tooltipEl.id = "chartjs-tooltip-report-pie";
                        tooltipEl.innerHTML = "<div></div>";
                        document.body.appendChild(tooltipEl);
                      }

                      // Hide if no tooltip
                      var tooltipModel = context.tooltip;
                      if (tooltipModel.opacity === 0) {
                        tooltipEl.style.display = "none";
                        return;
                      }

                      // Set caret Position
                      tooltipEl.classList.remove(
                        "above",
                        "below",
                        "no-transform"
                      );
                      if (tooltipModel.yAlign) {
                        tooltipEl.classList.add(tooltipModel.yAlign);
                      } else {
                        tooltipEl.classList.add("no-transform");
                      }

                      function getBody(bodyItem: any) {
                        return bodyItem.lines;
                      }

                      // Set tooltip content
                      if (tooltipModel.body) {
                        var bodyLines = tooltipModel.body.map(getBody);

                        let innerHtml = "";

                        // Content
                        bodyLines.forEach(function (body: any, i: number) {
                          const statusColors = [...tooltipModel.labelColors];
                          var colors = statusColors[i];
                          innerHtml += `<div class="block" style="display: flex; flex-direction: row; align-items: center; width: 100%">`;
                          innerHtml += `<div style="width: 0.8rem; min-width: 0.8rem; height: 0.8rem; min-height: 0.8rem; background-color: ${colors.backgroundColor}; margin-right: 0.5rem"></div>`;
                          let label = body[0].split(":")[0];
                          if (label.includes("*")) {
                            label = label.replace(
                              "*",
                              `<span style="color: ${$statusFail}">*</span>`
                            );
                          }
                          const value = body[0].split(":")[1];
                          innerHtml += `<p>${label}: <span style="font-weight: 700">${value}</span></p>`;
                          innerHtml += `</div>`;
                        });

                        var tableRoot = tooltipEl.querySelector("div");
                        if (tableRoot) {
                          tableRoot.innerHTML = innerHtml;
                        }
                      }

                      var position =
                        context.chart.canvas.getBoundingClientRect();

                      // Display, position, and set styles for font
                      tooltipEl.style.display = "flex";
                      tooltipEl.style.position = "absolute";
                      tooltipEl.style.left =
                        position.left +
                        window.pageXOffset +
                        tooltipModel.caretX +
                        "px";
                      tooltipEl.style.top =
                        position.top +
                        window.pageYOffset +
                        tooltipModel.caretY +
                        "px";
                      tooltipEl.style.padding =
                        tooltipModel.padding +
                        "px " +
                        tooltipModel.padding +
                        "px";
                      tooltipEl.style.borderRadius = "0.1rem";
                      tooltipEl.style.display = "flex";
                      tooltipEl.style.flexDirection = "row";
                      tooltipEl.style.boxShadow =
                        "0px 1px 1px 1px rgba(0, 0, 0, 0.15)";
                      tooltipEl.style.backgroundColor = "#fefefe";
                      tooltipEl.style.padding = "0.5rem 0.75rem";
                      tooltipEl.style.pointerEvents = "none";
                      tooltipEl.style.color = $textLight;
                    },
                  },
                },
                //@ts-ignore
                borderWidth: 0,
                animation: { duration: 0 },
              }}
            />
          </PieContainer>

          {legendItems && (
            <LegendContainer>
              <Legend small vertical items={legendItems} />
            </LegendContainer>
          )}
        </Content>
      )
    );
  };

  const renderNoData = (
    <NoDataContainer>
      <NoDataImage>
        <img src="/static/media/empty-pie-chart.svg" alt="Pie has no data" />
      </NoDataImage>
      {access && (
        <FailedContainer>
          <Text>Failed to load data</Text>
          <Button small onClick={retryDataLoading}>
            Retry
          </Button>
        </FailedContainer>
      )}
    </NoDataContainer>
  );

  return (
    <Container extraStyling={extraStyling}>
      <Text dark semibold>
        {data && <StatusCircle color={color} size="12px" />}
        {title}
      </Text>

      {data ? renderPieChart() : renderNoData}
    </Container>
  );
};
