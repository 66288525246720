import { Execution } from "model/Execution";
import { ExecutionDetails } from "model/ExecutionDetails";
import { GeneralTimeFrame } from "model/Time";
import { formatAPIDate } from "utils/Date";
import request, { ApiResponse } from "../../utils/ApiHandlers";
import { BaseConfig } from "../../utils/Variables";

export class ExecutionApi {
  private static instance: ExecutionApi;

  private constructor() { }

  static getInstance(fresh?: boolean) {
    if (!ExecutionApi.instance || fresh) {
      ExecutionApi.instance = new ExecutionApi();
    }
    return ExecutionApi.instance;
  }

  getExecutionData = async (
    environmentId: number,
    timeFrame: GeneralTimeFrame
  ): Promise<ApiResponse<Execution[]>> =>
    request.get(
      `${BaseConfig.getBaseApiUrl()}executions/overview/${environmentId}?startDate=${formatAPIDate(
        timeFrame.start
      )}&endDate=${formatAPIDate(timeFrame.end)}`
    );

  getExecutionDetails = async (
    transactionId: number
  ): Promise<ApiResponse<ExecutionDetails[]>> =>
    request.get(
      `${BaseConfig.getBaseApiUrl()}executions/detail/${transactionId}`
    )
}

export default ExecutionApi.getInstance();
