import Card from "components/common/Card";
import { List } from "components/common/List";
import { DeleteButton } from "components/common/List/common";
import { Switch } from "components/common/Switch";
import { CustomerWorkOrderModal } from "components/Customers/CustomerCrud/CustomerWorkOrderModal";
import { CustomerWorkOrder } from "model/Customer";
import React, { FunctionComponent, useState } from "react";
import styled, { css } from "styled-components";
import { Text, Button } from "styles/common";

interface Props {
  allowContactChasing: boolean;
  workOrders: CustomerWorkOrder[];
  workOrdersError: boolean;
  editSupport(
    allowContactChasing: boolean,
    workOrders: CustomerWorkOrder[]
  ): void;
  setWorkOrdersError(set: boolean): void;
}

const Container = styled.div`
  flex: 1;
`;

const WorkOrdersTitle = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0.5rem;
`;

export const emptyWorkOrdersErrorText =
  "Please add at least one workorder when chasing is enabled";

const getCustomerWorkOrderCells = (
  workOrders: CustomerWorkOrder[],
  deleteWorkOrder: (workOrderIndex: number) => void
) =>
  workOrders.map((workOrder: CustomerWorkOrder, index: number) => {
    return {
      cells: [
        <Text>{workOrder.workOrder}</Text>,
        <Text>{workOrder.info}</Text>,
        <DeleteButton
          className="delete-workorder-button"
          onClick={() => {
            deleteWorkOrder(index);
          }}
        />,
      ],
    };
  });

export const CustomerSupport: FunctionComponent<Props> = ({
  allowContactChasing,
  workOrders,
  workOrdersError,
  editSupport,
  setWorkOrdersError,
}) => {
  const [showWorkOrderModal, setShowWorkOrderModal] = useState(false);

  const handleChangeAllowContactChasing = () => {
    setWorkOrdersError(false);
    editSupport(!allowContactChasing, workOrders);
  };

  const handleAddWorkOrderClick = () => {
    setShowWorkOrderModal(true);
  };

  const handleAddWorkOrder = (workOrder: CustomerWorkOrder) => {
    setWorkOrdersError(false);
    editSupport(allowContactChasing, [...workOrders, workOrder]);
  };

  const handleDeleteWorkOrder = (workOrderIndex: number) => {
    editSupport(
      allowContactChasing,
      workOrders.filter((wo, i) => i !== workOrderIndex)
    );
  };

  const renderWorkOrderModal = (
    <CustomerWorkOrderModal
      close={() => setShowWorkOrderModal(false)}
      add={handleAddWorkOrder}
    />
  );

  return (
    <Container>
      <Card
        header={<p>Support</p>}
        error={workOrdersError}
        errorText={emptyWorkOrdersErrorText}
        extraStyling={css`
          height: 100%;
        `}
      >
        <Text large semibold dark style={{ marginBottom: "1.5rem" }}>
          Contact chasing
        </Text>

        <Switch
          className="switch-allow-contact-chasing"
          label="Allow chasing customer contacts in best effort"
          value={allowContactChasing}
          onChange={handleChangeAllowContactChasing}
          extraStyling={css`
            margin-bottom: 1rem;
          `}
        />

        <WorkOrdersTitle>
          <Text large semibold dark>
            Workorders
          </Text>

          <Button
            className="add-workorder-button"
            onClick={handleAddWorkOrderClick}
          >
            Add workorder
          </Button>
        </WorkOrdersTitle>

        <List
          headers={["Workorder", "Info", ""]}
          data={
            workOrders
              ? getCustomerWorkOrderCells(workOrders, handleDeleteWorkOrder)
              : []
          }
          smallEmpty
          hover
          customColumnWidths={[3, 3, 1]}
          noDataText={"You haven't defined any workorders yet."}
          retryDataLoading={() => {}}
          extraStyling={css`
            margin-bottom: 1rem;
          `}
        />
      </Card>

      {showWorkOrderModal && renderWorkOrderModal}
    </Container>
  );
};
