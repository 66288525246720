import React from "react";
import styled from "styled-components";
import { format } from "date-fns";
import { $statusSuccess, StatusCircle, Text } from "styles/common";
import { Units } from "..";
import { UsageOfMonth } from "model/Usage";
import UsageTree from "components/Usage/UsageTree";

interface Props {
  data: UsageOfMonth | undefined;
}

const Container = styled.div`
  width: 100%;
  margin-top: 1rem;
`;

const MonthContainer = styled.div``;

const MonthInformation = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding-right: 0.5rem;
  margin-bottom: 1.5rem;
`;

const MonthInfoLeft = styled.div`
  display: flex;
  flex-direction: row;
  gap: 3rem;
  align-items: center;
`;

const MonthStatus = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const IntegrationUnitsContainer = styled.div`
  margin-bottom: 1rem;
  width: 100%;
`;

export const MonthDetail = ({ data }: Props) => (
  <Container>
    {data ? (
      <MonthContainer id="month-usage-details">
        <MonthInformation>
          <MonthInfoLeft>
            <Text large semibold dark>
              Showing details for month{" "}
              <span className="month">
                {format(new Date(data.date), "yyyy-MM")}
              </span>
            </Text>
            <MonthStatus>
              <StatusCircle color={$statusSuccess} />
              <Text dark>
                Service was active for {data.uptime}% of the time
              </Text>
            </MonthStatus>
          </MonthInfoLeft>
          <Text semibold>
            Total: <Units>{data.units}</Units> units
          </Text>
        </MonthInformation>
        <IntegrationUnitsContainer>
          <UsageTree data={data.environments} />
        </IntegrationUnitsContainer>
      </MonthContainer>
    ) : (
      <Text>No month is selected</Text>
    )}
  </Container>
);
