import IconComponent from "components/common/Icon";
import { Icon } from "model/Icon";
import React, { FunctionComponent } from "react";
import styled, { css } from "styled-components";
import { $blue4 } from "styles/common";

export interface PillType {
  id: number;
  name: string;
  icon?: Icon;
}

interface Props {
  pills: PillType[];
  active: number;
  setActive(index: number): void;
}

const Container = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
`;

const Pill = styled.a<{ active?: boolean }>`
  padding: 0.125rem 1rem;
  font-size: 0.9375rem;
  border-radius: 30px;
  display: block;
  color: ${(props) => (props.active ? "#fff" : $blue4)};
  cursor: pointer;
  line-height: 1.5rem;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  &:hover {
    color: #2c77a1;

    svg {
      fill: #2c77a1;
    }
  }

  ${(props) =>
    props.active &&
    css`
      background-color: ${$blue4};

      &:hover {
        color: #fff;

        svg {
          fill: #fff;
        }
      }
    `}
`;

const Pills: FunctionComponent<Props> = ({ pills, active, setActive }) => {
  return (
    <Container>
      {pills.map((pill: PillType, index: number) => (
        <Pill
          className="pill"
          key={index}
          active={active === pill.id}
          onClick={() => {
            setActive(pill.id);
          }}
        >
          {pill.icon && (
            <IconComponent
              name={pill.icon}
              color={active === pill.id ? "#fff" : $blue4}
              extraStyling={css`
                margin-right: 0.4rem;
              `}
            />
          )}
          {pill.name}
        </Pill>
      ))}
    </Container>
  );
};

export default Pills;
