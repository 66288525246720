import { action, makeObservable, observable } from "mobx";
import {
  NewSettingsContact,
  SettingsIncidentsContactSchedule,
  SettingsContact,
  SimpleSettingsContact,
  SettingsChangeRequestContactSchedule,
} from "model/Contact";
import { SettingsLogicalGroup } from "model/LogicalGroup";
import { SettingsTechnicalComponent } from "model/PlatformComponent";
import { NewUser, SettingsUser } from "model/User";
import { SettingApi } from "services/apis/SettingApi";
import {
  logicalGroupsMockData,
  technicalComponentsMockData,
} from "mockData/settings";
import LoaderStore from "./LoaderStore";

class SettingStore {
  @observable
  users: SettingsUser[] | undefined = undefined;

  @observable
  contacts: SettingsContact[] | undefined = undefined;

  @observable
  scheduleContacts: SimpleSettingsContact[] | undefined = undefined;

  @observable
  incidentsContactSchedule: SettingsIncidentsContactSchedule | undefined =
    undefined;

  @observable
  changeRequestsContactSchedule:
    | SettingsChangeRequestContactSchedule
    | undefined = undefined;

  @observable
  allLogicalGroups: SettingsLogicalGroup[] | undefined = undefined;

  @observable
  allTechnicalComponents: SettingsTechnicalComponent[] | undefined = undefined;

  constructor(public api: SettingApi, private loaderStore: LoaderStore) {
    makeObservable(this);
  }

  // USER MANAGEMENT

  @action
  getUsers = async () => {
    // this.users = usersMockData;

    this.loaderStore.setLoading();
    const response = await this.api.getUsers();
    if (response.success) {
      const { data } = response;
      this.users = data;
    } else {
      //const { errorData } = response;
      console.log("Something went wrong when trying to fetch users");
    }
    this.loaderStore.stopLoading();
  };

  @action
  deleteUser = async (userId: number): Promise<boolean> => {
    this.loaderStore.setLoading();
    const response = await this.api.deleteUser(userId);
    if (response.success) {
      this.loaderStore.stopLoading();
      return true;
    } else {
      //const { errorData } = response;
      console.log("Something went wrong when trying to delete user");
      this.loaderStore.stopLoading();
      return false;
    }
  };

  @action
  resetPassword = async (userId: number): Promise<boolean> => {
    this.loaderStore.setLoading();
    const response = await this.api.resetPassword(userId);
    if (response.success) {
      this.loaderStore.stopLoading();
      return true;
    } else {
      //const { errorData } = response;
      console.log(
        "Something went wrong when trying to send reset password e-mail"
      );
      this.loaderStore.stopLoading();
      return false;
    }
  };

  @action
  toggleEnable = async (userId: number): Promise<boolean> => {
    this.loaderStore.setLoading();
    const response = await this.api.toggleEnable(userId);
    if (response.success) {
      this.loaderStore.stopLoading();
      return true;
    } else {
      //const { errorData } = response;
      console.log(
        "Something went wrong when trying to toggle user's enabled status"
      );
      this.loaderStore.stopLoading();
      return false;
    }
  };

  @action
  toggleAdminRole = async (userId: number): Promise<boolean> => {
    this.loaderStore.setLoading();
    const response = await this.api.toggleAdminRole(userId);
    if (response.success) {
      this.loaderStore.stopLoading();
      return true;
    } else {
      //const { errorData } = response;
      console.log(
        "Something went wrong when trying to toggle user's admin status"
      );
      this.loaderStore.stopLoading();
      return false;
    }
  };

  @action
  sendInvitation = async (userId: number): Promise<boolean> => {
    this.loaderStore.setLoading();
    const response = await this.api.sendInvitation(userId);
    if (response.success) {
      this.loaderStore.stopLoading();
      return true;
    } else {
      //const { errorData } = response;
      console.log("Something went wrong when trying to send invitation");
      this.loaderStore.stopLoading();
      return false;
    }
  };

  @action
  addUser = async (user: NewUser): Promise<boolean> => {
    this.loaderStore.setLoading();
    const response = await this.api.addUser(user);
    if (response.success) {
      this.getUsers();
      return true;
    } else {
      //const { errorData } = response;
      console.log("Something went wrong when trying to add user");
      this.loaderStore.stopLoading();
      return false;
    }
  };

  // CONTACT DETAILS

  @action
  getContacts = async () => {
    // this.contacts = contactsMockData;

    this.loaderStore.setLoading();
    const response = await this.api.getContacts();
    if (response.success) {
      const { data } = response;
      this.contacts = data;
    } else {
      //const { errorData } = response;
      console.log("Something went wrong when trying to fetch contacts");
    }
    this.loaderStore.stopLoading();
  };

  @action
  deleteContact = async (contactId: number): Promise<boolean> => {
    this.loaderStore.setLoading();
    const response = await this.api.deleteContact(contactId);
    if (response.success) {
      this.loaderStore.stopLoading();
      this.getContacts();
      return true;
    } else {
      //const { errorData } = response;
      console.log("Something went wrong when trying to delete contact");
      this.loaderStore.stopLoading();
      return false;
    }
  };

  @action
  addContact = async (contact: NewSettingsContact): Promise<boolean> => {
    this.loaderStore.setLoading();
    const response = await this.api.addContact(contact);
    if (response.success) {
      this.getContacts();
      this.loaderStore.stopLoading();
      this.getContacts();
      return true;
    } else {
      //const { errorData } = response;
      console.log("Something went wrong when trying to add contact");
      this.loaderStore.stopLoading();
      return false;
    }
  };

  @action
  editContact = async (contact: SettingsContact): Promise<boolean> => {
    this.loaderStore.setLoading();
    const response = await this.api.editContact(contact);
    if (response.success) {
      this.loaderStore.stopLoading();
      this.getContacts();
      return true;
    } else {
      //const { errorData } = response;
      console.log("Something went wrong when trying to edit the contact");
      this.loaderStore.stopLoading();
      return false;
    }
  };

  // CONTACT SCHEDULE

  @action
  getScheduleContacts = async () => {
    // this.scheduleContacts = scheduleContactsMockData;

    this.loaderStore.setLoading();
    const response = await this.api.getScheduleContacts();
    if (response.success) {
      const { data } = response;
      this.scheduleContacts = data;
    } else {
      //const { errorData } = response;
      console.log("Something went wrong when trying to fetch the contacts");
    }
    this.loaderStore.stopLoading();
  };

  @action
  getIncidentsContactSchedule = async (): Promise<
    SettingsIncidentsContactSchedule | false
  > => {
    // this.incidentsContactSchedule = incidentsContactScheduleMockData;
    // return incidentsContactScheduleMockData;

    this.loaderStore.setLoading();
    const response = await this.api.getIncidentsContactSchedule();
    if (response.success) {
      const { data } = response;
      this.loaderStore.stopLoading();
      this.incidentsContactSchedule = data;
      return data;
    } else {
      //const { errorData } = response;
      this.loaderStore.stopLoading();
      console.log(
        "Something went wrong when trying to fetch the incidents contact schedule"
      );
      return false;
    }
  };

  @action
  editIncidentsContactSchedule = async (
    schedule: SettingsIncidentsContactSchedule
  ) => {
    this.loaderStore.setLoading();
    const response = await this.api.editIncidentsContactSchedule(schedule);
    if (response.success) {
      this.loaderStore.stopLoading();
      return true;
    } else {
      //const { errorData } = response;
      this.loaderStore.stopLoading();
      return false;
    }
  };

  @action
  getChangeRequestsContactSchedule = async (): Promise<
    SettingsChangeRequestContactSchedule | false
  > => {
    // this.changeRequestsContactSchedule = changeRequestsContactScheduleMockData;
    // return changeRequestsContactScheduleMockData;

    this.loaderStore.setLoading();
    const response = await this.api.getChangeRequestsContactSchedule();
    if (response.success) {
      const { data } = response;
      this.loaderStore.stopLoading();
      this.changeRequestsContactSchedule = data;
      return data;
    } else {
      //const { errorData } = response;
      this.loaderStore.stopLoading();
      console.log(
        "Something went wrong when trying to fetch the change requests contact schedule"
      );
      return false;
    }
  };

  @action
  editChangeRequestsContactSchedule = async (
    schedule: SettingsChangeRequestContactSchedule
  ) => {
    this.loaderStore.setLoading();
    const response = await this.api.editChangeRequestsContactSchedule(schedule);
    if (response.success) {
      this.loaderStore.stopLoading();
      return true;
    } else {
      //const { errorData } = response;
      this.loaderStore.stopLoading();
      return false;
    }
  };

  // GROUP WORKFLOW

  @action
  getAllLogicalGroups = async (): Promise<SettingsLogicalGroup[] | false> => {
    this.loaderStore.setLoading();
    const response = await this.api.getAllLogicalGroups();
    if (response.success) {
      const { data } = response;
      this.loaderStore.stopLoading();
      this.allLogicalGroups = data;
      return data;
    } else {
      //const { errorData } = response;
      this.loaderStore.stopLoading();
      console.log(
        "Something went wrong when trying to fetch the logical groups"
      );
      return false;
    }
  };

  @action
  getAllTechnicalComponents = async (): Promise<
    SettingsTechnicalComponent[] | false
  > => {
    this.loaderStore.setLoading();
    const response = await this.api.getAllTechnicalComponents();
    if (response.success) {
      const { data } = response;
      this.loaderStore.stopLoading();
      this.allTechnicalComponents = data;
      return data;
    } else {
      //const { errorData } = response;
      this.loaderStore.stopLoading();
      console.log(
        "Something went wrong when trying to fetch the technical components"
      );
      return false;
    }
  };

  @action editGroupWorkflow = async (logicalGroups: SettingsLogicalGroup[]) => {
    this.loaderStore.setLoading();

    const response = await this.api.editGroupWorkflow(logicalGroups);

    if (response.success) {
      this.loaderStore.stopLoading();
      return true;
    } else {
      //const { errorData } = response;
      this.loaderStore.stopLoading();
      return false;
    }
  };

  @action
  resetUsers = () => {
    this.users = undefined;
  };

  @action
  resetContacts = () => {
    this.contacts = undefined;
  };

  @action
  resetContactSchedule = () => {
    this.scheduleContacts = undefined;
    this.incidentsContactSchedule = undefined;
    this.changeRequestsContactSchedule = undefined;
  };

  @action
  resetGroupWorkflow = () => {
    this.allLogicalGroups = undefined;
    this.allTechnicalComponents = undefined;
  };

  @action
  reset = () => {
    this.users = undefined;
    this.contacts = undefined;
    this.scheduleContacts = undefined;
    this.incidentsContactSchedule = undefined;
    this.changeRequestsContactSchedule = undefined;
    this.allLogicalGroups = undefined;
    this.allTechnicalComponents = undefined;
  };
}

export default SettingStore;
