import PlatformComponentTypeIconComponent from "components/Integrations/PlatformComponentTypeIcon";
import { inject, observer } from "mobx-react";
import {
  LocationGroupNodeData,
  LocationGroupNodePlatformData,
} from "model/Node";
import { ExecutionSuccessStatus } from "model/Status";
import React, { useState } from "react";
import { FunctionComponent } from "react";
import { Handle, Position } from "react-flow-renderer";
import IntegrationStore from "stores/IntegrationStore";
import styled, { css } from "styled-components";
import {
  $statusSuccess,
  $statusFail,
  Text,
  $statusProblematic,
  StatusCircle,
  $grey,
  $textDark,
  StyledReactTooltip,
  $textLight,
  $statusUnknownOrNoStatus,
} from "styles/common";
import { highlightRelatedApplications } from "utils/Application";
import { getSuccessStatusColor } from "utils/Status";

interface Props {
  data: LocationGroupNodeData;
  integrationStore: IntegrationStore;
}

const tileSize = "14px";
const nodeWidth = "18rem";
export const maxBarItems = 10;

const Container = styled.div<{ status: number }>`
  width: ${nodeWidth};
  background-color: #f7fafc;
  border-left: 4px solid
    ${(props) =>
      props.status !== undefined ? getSuccessStatusColor(props.status) : $grey};
  border-radius: 0.2rem;
  box-shadow: 1px 1px 3px 1px rgba(0, 0, 0, 0.1);
`;

const TitleContainer = styled.div<{ status: number }>`
  padding: 0.5rem 1rem 0.5rem 1rem;
  border-radius: 0.2rem 0.2rem 0 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  border-bottom: 1px solid #ddd;

  &:hover {
    opacity: 0.8;
  }
`;

const Title = styled(Text)`
  margin: 0;
  color: rgba(0, 0, 0, 0.7);
`;

const Content = styled.div`
  border-top: none;
  padding: 0.5rem 0;
`;

const PlatformComponentsContainer = styled.div<{ showTiles: boolean }>`
  display: flex;
  flex-direction: ${(props) => (props.showTiles ? "row" : "column")};

  ${(props) =>
    props.showTiles &&
    css`
      flex-wrap: wrap;
      padding: 0.5rem 1rem;
    `}
`;

const PlatformComponentContainer = styled.div`
  position: relative;
`;

const TileContainer = styled.div`
  position: relative;
`;

const Tile = styled.div<{ status: number }>`
  width: ${tileSize};
  height: ${tileSize};
  min-width: ${tileSize};
  min-height: ${tileSize};
  background-color: ${(props) =>
    props.status === ExecutionSuccessStatus.Fail
      ? $statusFail
      : props.status === ExecutionSuccessStatus.Success
      ? $statusSuccess
      : props.status == ExecutionSuccessStatus.Problematic
      ? $statusProblematic
      : $statusUnknownOrNoStatus}};
  border: 1px solid transparent;
  border-radius: 50%;
  margin: 0.2rem;
  cursor: pointer;

  &:hover {
    opacity: 0.8;
  }
`;

const TooltipTitle = styled(Text)`
  margin: 0 0 0 0.5rem;
`;

const Bar = styled.div`
  padding: 0.4rem 1rem;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 0.5rem;
  cursor: pointer;
  box-sizing: border-box;
  transition: 0.2s;

  &:hover {
    background-color: ${$grey} !important;
    p {
      color: ${$textDark} !important;
    }
  }
`;

const PlatformComponent: FunctionComponent<{
  index: number;
  platformComponent: LocationGroupNodePlatformData;
  showTiles: boolean;
  integrationStore: IntegrationStore;
}> = ({ index, platformComponent, showTiles, integrationStore }) => {
  const [showTooltip, setShowTooltip] = useState(false);
  const [selected, setSelected] = useState(false);

  const onMouseOver = () => {
    integrationStore.setCurrentPlatformComponent(platformComponent);
    if (platformComponent.applications) {
      highlightRelatedApplications(
        platformComponent.applications,
        true,
        !!integrationStore.currentPlatformComponent // Item was clicked -> remove previous highlights first
      );
    }
  };

  const onClick = () => {
    setSelected(true);
  };

  const onMouseLeave = () => {
    if (integrationStore.currentPlatformComponent && !selected) {
      integrationStore.setCurrentPlatformComponent(undefined);
      if (platformComponent.applications) {
        highlightRelatedApplications(platformComponent.applications, false);
      }
    }
  };

  return (
    <PlatformComponentContainer
      key={index}
      onMouseOver={() => {
        onMouseOver();
        showTiles && setShowTooltip(true);
      }}
      onClick={() => {
        onClick();
      }}
      onMouseLeave={() => {
        onMouseLeave();
        showTiles && setShowTooltip(false);
      }}
    >
      {showTiles ? (
        <PlatformComponentTile
          platformComponent={platformComponent}
          showTooltip={showTooltip}
        />
      ) : (
        <PlatformComponentBar platformComponent={platformComponent} />
      )}
    </PlatformComponentContainer>
  );
};

const PlatformComponentTile: FunctionComponent<{
  platformComponent: LocationGroupNodePlatformData;
  showTooltip: boolean;
}> = ({ platformComponent, showTooltip }) => {
  return (
    <TileContainer className="platform-component-tile">
      <Tile
        className={`platform-component ${platformComponent.applications?.join(
          " "
        )}`}
        status={platformComponent.status}
        data-tip={platformComponent.name}
        data-for="tile-tooltip"
      />
      {showTooltip && (
        <StyledReactTooltip
          id="tile-tooltip"
          effect="solid"
          place="bottom"
          type="light"
          border={false}
          textColor={$textLight}
          backgroundColor={"#fff"}
          arrowColor={"transparent"}
        >
          <PlatformComponentTypeIconComponent name={platformComponent.type} />
          <TooltipTitle small>{platformComponent.name}</TooltipTitle>
        </StyledReactTooltip>
      )}
    </TileContainer>
  );
};

const PlatformComponentBar: FunctionComponent<{
  platformComponent: LocationGroupNodePlatformData;
}> = ({ platformComponent }) => {
  return (
    <Bar
      className={`platform-component-bar platform-component ${platformComponent.applications?.join(
        " "
      )}`}
    >
      <StatusCircle color={getSuccessStatusColor(platformComponent.status)} />
      <PlatformComponentTypeIconComponent name={platformComponent.type} />
      <Text small>{platformComponent.name}</Text>
    </Bar>
  );
};

const CustomNode: FunctionComponent<Props> = ({ data, integrationStore }) => {
  const showTiles: boolean = data.platformComponents.length > maxBarItems;

  const onMouseOver = () => {
    !integrationStore.currentLogicalGroup &&
      integrationStore.setCurrentLogicalGroup(data);
  };

  const onMouseLeave = () => {
    integrationStore.currentLogicalGroup &&
      integrationStore.setCurrentLogicalGroup(undefined);
  };

  return (
    data && (
      <Container status={data.status} className="node">
        <Handle
          type="target"
          position={Position.Top}
          style={{ background: "transparent", border: "none" }}
          onConnect={() => {}}
        />
        <TitleContainer
          className="node-header"
          status={data.status}
          onMouseOver={onMouseOver}
          onMouseLeave={onMouseLeave}
        >
          <Title semibold>{data.name}</Title>
        </TitleContainer>
        <Content>
          <PlatformComponentsContainer showTiles={showTiles}>
            {data.platformComponents.map(
              (
                platformComponent: LocationGroupNodePlatformData,
                index: number
              ) => {
                return (
                  <PlatformComponent
                    key={index}
                    index={index}
                    platformComponent={platformComponent}
                    showTiles={showTiles}
                    integrationStore={integrationStore}
                  />
                );
              }
            )}
          </PlatformComponentsContainer>
        </Content>
        <Handle
          type="source"
          id="a"
          position={Position.Bottom}
          style={{ background: "transparent", border: "none" }}
        />
      </Container>
    )
  );
};

export default inject("integrationStore")(
  observer(CustomNode as FunctionComponent<Omit<Props, "integrationStore">>)
);
