import * as React from "react";
import ReactDOM from "react-dom";
import { MsalProvider } from "@azure/msal-react";
import { PublicClientApplication } from "@azure/msal-browser";
import App from "./App";
// import registerServiceWorker from "./registerServiceWorker";
import "regenerator-runtime/runtime";
import "./styles.css";
import { msalConfiguration } from "msalConfig";
import ConfigLoader from "./configLoader";

console.log("logging test");
console.log(process.env);
console.log(process.env.TEST);
console.log(process.env.REACT_APP_TEST);

// Component
const AppProvider = () => (
  <ConfigLoader ready={() => 
    <MsalProvider instance={new PublicClientApplication(msalConfiguration.getMsalConfig())}>
      <App />
    </MsalProvider>
  } />
);

ReactDOM.render(<AppProvider />, document.getElementById("root"));

// registerServiceWorker();
