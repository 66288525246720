import { List } from "components/common/List";
import { DeleteButton, EditButton } from "components/common/List/common";
import { Modal, ModalColorType, ModalType } from "components/common/Modal";
import { SettingContactModal } from "components/Settings/SettingContactModal";
import { NewSettingsContact, SettingsContact } from "model/Contact";
import React, { useState } from "react";
import { css } from "styled-components";
import { Button } from "styles/common";

interface Props {
  contacts: SettingsContact[] | undefined;
  viewAccess: boolean;
  editAccess: boolean;
  deleteAccess: boolean;
  deleteContact(contactId: number): Promise<boolean>;
  addContact(contact: NewSettingsContact): Promise<boolean>;
  editContact(contact: SettingsContact): Promise<boolean>;
  retryDataLoading(): void;
}

const addButtonStyling = css`
  position: absolute;
  right: 1.5rem;
  top: 1rem;
`;

const baseHeaders = ["Contact", "Role", "Phone", "Mobile", "E-mail"];

export const ContactsList = ({
  contacts,
  viewAccess,
  editAccess,
  deleteAccess,
  deleteContact,
  addContact,
  editContact,
  retryDataLoading,
}: Props) => {
  const [showDeleteContactModal, setShowDeleteContactModal] = useState(false);
  const [showDeleteContactFailModal, setShowDeleteContactFailModal] =
    useState(false);

  const [showEditContactModal, setShowEditContactModal] = useState(false);
  const [showEditContactFailModal, setShowEditContactFailModal] =
    useState(false);

  const [showAddContactModal, setShowAddContactModal] = useState(false);
  const [showAddContactFailModal, setShowAddContactFailModal] = useState(false);

  const [actionContact, setActionContact] = useState<
    SettingsContact | undefined
  >(undefined);

  const handleDeleteContactClick = (contact: SettingsContact) => {
    if (contact.deletable) {
      setActionContact(contact);
      setShowDeleteContactModal(true);
    }
  };

  const handleDeleteContact = async (contactId: number) => {
    if (deleteAccess) {
      const deleteSuccess = await deleteContact(contactId);
      if (deleteSuccess) {
        setActionContact(undefined);
      } else {
        setShowDeleteContactFailModal(true);
      }
    }
  };

  const handleContactAddClick = () => {
    setShowAddContactModal(true);
  };

  const handleContactAdd = async (contact: NewSettingsContact) => {
    if (editAccess) {
      const addSuccess = await addContact(contact);
      !addSuccess && setShowAddContactFailModal(true);
    }
  };

  const handleEditContactClick = (contact: SettingsContact) => {
    setActionContact(contact);
    setShowEditContactModal(true);
  };

  const handleContactEdit = async (contact: SettingsContact) => {
    if (editAccess) {
      const addSuccess = await editContact(contact);
      !addSuccess && setShowEditContactFailModal(true);
    }
  };

  const renderDeleteContactModal = (
    <Modal
      type={ModalType.Action}
      colorType={ModalColorType.Warning}
      customText={
        actionContact
          ? `Are you sure you want to delete ${actionContact.contact}? This can not be undone.`
          : ""
      }
      customButtonText={"Delete"}
      fixed
      background
      action={() => {
        setShowDeleteContactModal(false);
        actionContact && handleDeleteContact(actionContact.id);
      }}
      cancel={() => {
        setActionContact(undefined);
        setShowDeleteContactModal(false);
      }}
    />
  );

  const renderFailModal = (
    <Modal
      type={ModalType.Message}
      colorType={ModalColorType.Error}
      customText={
        actionContact && showDeleteContactFailModal
          ? `Something went wrong. ${actionContact.contact} could not be deleted.`
          : showAddContactFailModal
          ? "Something went wrong. The new contact could not be added."
          : showEditContactFailModal
          ? "Something went wrong. The contact could not be edited."
          : ""
      }
      fixed
      background
      action={() => {
        setActionContact(undefined);
        showDeleteContactFailModal && setShowDeleteContactFailModal(false);
        showAddContactFailModal && setShowAddContactFailModal(false);
        showEditContactFailModal && setShowEditContactFailModal(false);
      }}
    />
  );

  const getContactCells = () =>
    contacts
      ? contacts.map((contact) => {
          const contactDataCells = [
            contact.contact,
            contact.role,
            contact.phone
              ? `+${contact.phone.code}${contact.phone.number}`
              : "",
            contact.mobile
              ? `+${contact.mobile.code}${contact.mobile.number}`
              : "",
            contact.email,
          ];

          const editButton = (
            <EditButton
              onClick={() => {
                handleEditContactClick(contact);
              }}
            />
          );

          const deleteButton = (
            <DeleteButton
              disabled={!contact.deletable}
              onClick={() => {
                handleDeleteContactClick(contact);
              }}
            />
          );

          return {
            cells:
              editAccess && deleteAccess
                ? [...contactDataCells, editButton, deleteButton]
                : editAccess
                ? [...contactDataCells, editButton]
                : deleteAccess
                ? [...contactDataCells, deleteButton]
                : contactDataCells,
          };
        })
      : undefined;

  return (
    <>
      <List
        headers={
          editAccess && deleteAccess
            ? [...baseHeaders, "", ""]
            : editAccess || deleteAccess
            ? [...baseHeaders, ""]
            : baseHeaders
        }
        data={getContactCells()}
        access={viewAccess}
        noDataText="There are no contacts for this portal yet."
        retryDataLoading={retryDataLoading}
        customColumnWidths={
          editAccess && deleteAccess
            ? [5, 5, 2, 2, 7, 1, 1]
            : editAccess || deleteAccess
            ? [5, 5, 2, 2, 7, 1]
            : [5, 5, 2, 2, 7]
        }
        hover
        extraStyling={css`
          margin-bottom: 1rem;
        `}
      />

      {editAccess && contacts && (
        <Button
          className="add-contact-button"
          onClick={handleContactAddClick}
          extraStyling={addButtonStyling}
        >
          Add contact
        </Button>
      )}

      {showDeleteContactModal && renderDeleteContactModal}
      {showAddContactModal && (
        <SettingContactModal
          setShow={setShowAddContactModal}
          action={handleContactAdd}
        />
      )}
      {showEditContactModal && (
        <SettingContactModal
          editContact={actionContact}
          setShow={setShowEditContactModal}
          action={handleContactEdit}
        />
      )}

      {(showDeleteContactFailModal ||
        showAddContactFailModal ||
        showEditContactFailModal) &&
        renderFailModal}
    </>
  );
};
