import { ExtraStylingType } from "model/Styles";
import React, { FunctionComponent, useState, useEffect } from "react";
import styled, { css } from "styled-components";
import {
  Text,
  inputStyling,
  Span,
  $statusFail,
  $blue4,
  $textLight,
  StyledReactTooltip,
  $grey,
} from "styles/common";
import IconComponent from "components/common/Icon";
import { Icon } from "model/Icon";
import { validateDecimalNumber } from "utils/Validation";

interface Props {
  label?: string;
  defaultValue: string;
  prefix?: any;
  inputActions?: any;
  mandatory?: boolean;
  multiline?: boolean;
  disabled?: boolean;
  viewMode?: boolean;
  numeric?: boolean;
  dataList?: string[];
  dataListName?: string;
  error?: boolean;
  errorText?: string;
  infoTooltip?: string;
  infoTooltipContent?: any;
  className?: string;
  onValueChange(value: string): void;
  onBlur?: (value: string) => void;
  extraStyling?: ExtraStylingType;
  extraLabelStyling?: ExtraStylingType;
}

const Container = styled.div<{ extraStyling: ExtraStylingType }>`
  ${(props) => props.extraStyling}
`;

const LabelContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.5rem;
`;

const Label = styled(Text)<{ extraLabelStyling: ExtraStylingType }>`
  margin-bottom: 0.3rem;

  ${(props) => props.extraLabelStyling}
`;

const RelativeContainer = styled.div`
  position: relative;
`;

const RowContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

const TextArea = styled.textarea`
  ${inputStyling}
  font-family: "Source Sans Pro", sans-serif;
  height: 5rem;
  width: 100%;
`;

const Input = styled.input<{ disabled: boolean; hasPrefix: boolean }>`
  ${inputStyling}
  width: 100%;

  ${(props) =>
    props.disabled &&
    css`
      background-color: #eee;
      opacity: 0.8;
    `}

  ${(props) =>
    props.hasPrefix &&
    css`
      border-radius: 0 0.25rem 0.25rem 0;
    `}
`;

const Prefix = styled.div`
  display: block;
  padding: 0.375rem 0.875rem;
  line-height: 1.5;
  background-color: ${$grey};
  background-clip: padding-box;
  border-radius: 0.25rem 0 0 0.25rem;
  box-shadow: none;
  user-select: none;
`;

const InputActions = styled.div`
  margin-left: 1rem;
`;

const Error = styled(Text)`
  margin: 0.15rem 0 0 0rem;
  font-size: 0.8rem;
`;

const TextInput: FunctionComponent<Props> = ({
  label,
  defaultValue,
  prefix,
  inputActions,
  mandatory = false,
  multiline = false,
  disabled = false,
  viewMode = false,
  numeric = false,
  dataList,
  dataListName,
  error = false,
  errorText = undefined,
  infoTooltip,
  infoTooltipContent,
  className = "",
  onValueChange,
  onBlur = () => {},
  extraStyling = css``,
  extraLabelStyling = css``,
}) => {
  const [value, setValue] = useState(defaultValue);

  const handleChange = (e: any) => {
    if (
      (numeric &&
        !isNaN(parseInt(e.target.value)) &&
        validateDecimalNumber(e.target.value)) ||
      e.target.value === "" ||
      !numeric
    ) {
      setValue(e.target.value);
      onValueChange(e.target.value);
    }
  };

  const handleBlur = (e: any) => {
    onBlur(e.target.value);
  };

  useEffect(() => {
    viewMode && setValue(defaultValue);
  }, [defaultValue, viewMode]);

  return (
    <Container className={className} extraStyling={extraStyling}>
      <LabelContainer>
        {label && (
          <Label semibold extraLabelStyling={extraLabelStyling}>
            {label}
            {mandatory && (
              <Span className="mandatory-star" color={$statusFail}>
                *
              </Span>
            )}
          </Label>
        )}
        {infoTooltip && (
          <>
            <div
              className="text-input-tooltip"
              data-for={infoTooltip}
              data-tip=""
            >
              <IconComponent name={Icon.info} color={$blue4} />
            </div>
            <StyledReactTooltip
              id={infoTooltip}
              effect="solid"
              place="right"
              type="light"
              border={false}
              textColor={$textLight}
              backgroundColor={"#fff"}
              arrowColor={"transparent"}
            >
              {infoTooltipContent && infoTooltipContent}
            </StyledReactTooltip>
          </>
        )}
      </LabelContainer>

      <RelativeContainer>
        {!label && mandatory && (
          <Span
            className="mandatory-star"
            color={$statusFail}
            extraStyling={css`
              position: absolute;
              right: -0.6rem;
            `}
          >
            *
          </Span>
        )}

        {multiline ? (
          <TextArea
            value={value}
            disabled={disabled || viewMode}
            onChange={handleChange}
            onBlur={handleBlur}
          />
        ) : (
          <RowContainer>
            {prefix && <Prefix>{prefix}</Prefix>}
            <Input
              value={value}
              disabled={disabled || viewMode}
              hasPrefix={prefix !== undefined}
              onChange={handleChange}
              onBlur={handleBlur}
              list={dataListName ? dataListName : ""}
            />
            {dataList && dataListName && (
              <datalist id={dataListName}>
                {dataList.map((item, index) => (
                  <option key={index}>{item}</option>
                ))}
              </datalist>
            )}
            {inputActions && <InputActions>{inputActions}</InputActions>}
          </RowContainer>
        )}
      </RelativeContainer>

      {error && errorText && (
        <Error className="error-text" small semibold color={$statusFail}>
          {errorText}
        </Error>
      )}
    </Container>
  );
};

export default TextInput as FunctionComponent<Props>;
