import IconComponent from "components/common/Icon";
import { List } from "components/common/List";
import { ApplicationDatum } from "model/Application";
import { Icon } from "model/Icon";
import { ExtraStylingType } from "model/Styles";
import React from "react";
import styled, { css } from "styled-components";
import { $statusFail, $statusSuccess, $textDark, Text } from "styles/common";

interface Props {
  applications: ApplicationDatum[];
}

const Container = styled.div`
  margin-bottom: 1rem;
  width: 70rem;
`;

const Status = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const Runtime = styled.div`
  margin-right: 0.5rem;
  width: 1rem;
  height: 1rem;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

const Success = styled.div`
  display: block;
  width: 0.75rem;
  height: 0.75rem;
  box-sizing: border-box;
  background-color: ${$statusSuccess};
  border-radius: 50%;
`;

const ButtonContainer = styled.div`
  width: 100%;
  align-items: center;
  display: flex;
  flex-direction: row;
`;

const IconButton = styled(IconComponent)<{
  disabled?: boolean;
  extraButtonStyling?: ExtraStylingType;
}>`
  ${(props) =>
    !props.disabled
      ? css`
          cursor: pointer;
          transition: 0.2s;

          &:hover {
            opacity: 0.6;
          }

          ${props.extraButtonStyling ? props.extraButtonStyling : css``}
        `
      : css`
          opacity: 0.4;
        `}
`;

const extraRefreshButtonStyling = css`
  transition: transform 0.3s ease-in-out;

  &:hover {
    transform: rotate(-180deg);
  }
`;

const headers = ["Name", "Type", "Location", "Status", "", ""];

export const ApplicationsList = ({ applications }: Props) => {
  const handleRefresh = () => {};

  const handleHistoryClick = () => {};

  return (
    <Container>
      <List
        headers={headers}
        data={applications.map((application: ApplicationDatum) => ({
          cells: [
            application.name,
            application.type,
            application.location,
            <Status>
              <Runtime>
                {application.up ? (
                  <Success />
                ) : (
                  <IconComponent name={Icon.cross} color={$statusFail} />
                )}
              </Runtime>
              <Text small>{application.up ? "Up" : "Down"}</Text>
            </Status>,
            <ButtonContainer>
              <IconButton
                name={Icon.refresh}
                color={$textDark}
                disabled
                onClick={handleRefresh}
                extraButtonStyling={extraRefreshButtonStyling}
              />
            </ButtonContainer>,
            <ButtonContainer>
              <IconButton
                name={Icon.history}
                color={$textDark}
                disabled
                onClick={handleHistoryClick}
              />
            </ButtonContainer>,
          ],
        }))}
        customColumnWidths={[8, 4, 12, 4, 1, 1]}
        hover
        noDataText="You have no applications for this environment yet."
        smallEmpty
        retryDataLoading={() => {}}
      />
    </Container>
  );
};
