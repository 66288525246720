import { List } from "components/common/List";
import { IntegrationLogicalGroup } from "model/LogicalGroup";
import { ExecutionSpeedStatus, executionSpeedStatuses } from "model/Status";
import React, { Fragment } from "react";
import styled from "styled-components";
import { semiBoldText, StatusCircle, Text } from "styles/common";
import { getExecutionsString, round } from "utils/Integration";
import { getSuccessStatusColor } from "utils/Status";

interface Props {
  itemsToRender: IntegrationLogicalGroup[] | undefined;
  access: boolean;
  loading: boolean;
  searchMode: boolean;
  onItemClick(item: IntegrationLogicalGroup): void;
  retryDataLoading(): void;
}

const Percentage = styled.span`
  margin-left: 0.5rem;
  ${semiBoldText}
`;

const headersWithSlow = [
  "Name",
  "Executions per time",
  "Total executions",
  "Successful executions",
  "Slow executions",
  "Failed executions",
];

const headersWithoutSlow = [
  "Name",
  "Executions per time",
  "Total executions",
  "Successful executions",
  "Failed executions",
];

export const ListItems = ({
  itemsToRender,
  searchMode,
  access,
  loading,
  onItemClick,
  retryDataLoading,
}: Props) => {
  const handleItemClick = (rowIndex: number) => {
    itemsToRender && onItemClick(itemsToRender[rowIndex]);
  };

  const renderListCells = (list: IntegrationLogicalGroup[]) =>
    list.map((item: IntegrationLogicalGroup) => {
      const failPercentage =
        item.total === 0 ? 0 : (item.fails / item.total) * 100;
      let roundedFailPercentage: number | string = round(failPercentage, 2);
      const successPercentage =
        item.total === 0 ? 0 : (item.successes / item.total) * 100;
      let roundedSuccessPercentage: number | string = round(
        successPercentage,
        2
      );
      const successSlowPercentage =
        item.successesSlow !== null
          ? item.total === 0
            ? 0
            : (item.successesSlow / item.total) * 100
          : undefined;
      let roundedSuccessSlowPercentage: number | string | undefined =
        successSlowPercentage ? round(successSlowPercentage, 2) : undefined;

      // Rounded percentage is 0%, but count is not actually 0 (fail or success count % is really low relative to the other one, e.g. 0.001%)
      // --> Adjust text so user knows
      if (roundedFailPercentage === 0 && item.fails > 0) {
        roundedFailPercentage = "> 0";
      }
      if (roundedSuccessPercentage === 0 && item.successes > 0) {
        roundedSuccessPercentage = "> 0";
      }
      if (
        item.successesSlow !== null &&
        roundedSuccessSlowPercentage &&
        roundedSuccessSlowPercentage === 0 &&
        item.successesSlow > 0
      ) {
        roundedSuccessSlowPercentage = "> 0";
      }

      return {
        cells: [
          [
            item.name,
            getExecutionsString(
              item.executionsPerTimeUnit ? item.executionsPerTimeUnit : 0,
              item.executionsTimeUnit
            ),
            item.total,
            <Text>
              <StatusCircle
                color={
                  executionSpeedStatuses[ExecutionSpeedStatus.SuccessOnTime]
                    .color
                }
              />
              {item.successes}
              <Percentage>({roundedSuccessPercentage}%)</Percentage>
            </Text>,
          ],
          item.successesSlow === null
            ? []
            : [
                <Text>
                  <StatusCircle
                    color={
                      executionSpeedStatuses[ExecutionSpeedStatus.SuccessSlow]
                        .color
                    }
                  />
                  {item.successesSlow}
                  <Percentage>({roundedSuccessSlowPercentage}%)</Percentage>
                </Text>,
              ],
          [
            <Text>
              <StatusCircle
                color={executionSpeedStatuses[ExecutionSpeedStatus.Fail].color}
              />
              {item.fails}
              <Percentage>({roundedFailPercentage}%)</Percentage>
            </Text>,
          ],
        ].flat(),
        color: getSuccessStatusColor(item.status),
      };
    });

  return (
    <Fragment>
      <List
        className="integrations-list-items"
        headers={
          itemsToRender &&
          itemsToRender.length > 0 &&
          itemsToRender[0].successesSlow !== null
            ? headersWithSlow
            : headersWithoutSlow
        }
        data={itemsToRender ? renderListCells(itemsToRender) : undefined}
        hover
        colorIndicator
        customColumnWidths={[3]}
        noDataText="You don't have any integrations for this environment yet."
        access={access}
        loading={loading}
        searchMode={searchMode}
        onRowClick={handleItemClick}
        retryDataLoading={retryDataLoading}
      />
    </Fragment>
  );
};
