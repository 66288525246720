import IconComponent from "components/common/Icon";
import { Pagination } from "components/common/Pagination";
import SearchInput from "components/common/SearchInput";
import { inject, observer } from "mobx-react";
import { Icon } from "model/Icon";
import { IntegrationLogicalGroup } from "model/LogicalGroup";
import React, {
  FunctionComponent,
  useCallback,
  useEffect,
  useState,
} from "react";
import GeneralStore from "stores/GeneralStore";
import IntegrationStore from "stores/IntegrationStore";
import styled, { css } from "styled-components";
import { Text, $blue4 } from "styles/common";
import { getFlowTimeDate, getFlowTimeHours } from "utils/Date";
import { BreadCrumbs } from "./BreadCrumbs";
import { ListItems } from "./ListItems";
import { Tiles } from "./Tiles";

interface Props {
  widget?: boolean;
  access: boolean;
  onLeaveWidget?: () => void;
  generalStore: GeneralStore;
  integrationStore: IntegrationStore;
  loaded?: boolean; // For unit tests
}

const Container = styled.div`
  margin-bottom: 0.5rem;
  position: relative;
`;

const TopBar = styled.div`
  min-height: 2rem;
  margin-bottom: 2rem;
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const ViewFilter = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const viewFilterBasicStyling = css`
  min-width: 2rem;
  min-height: 2rem;
  width: fit-content;
  border-radius: 50%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;

const viewFilterStyling = css`
  ${viewFilterBasicStyling}
  background-color: transparent;

  &:hover {
    svg {
      fill: #2c77a1;
    }
  }
`;

const viewFilterActiveStyling = css`
  ${viewFilterBasicStyling}
  background-color: ${$blue4};
`;

export enum ViewType {
  Tiles = 0,
  List = 1,
}

const TimeFrameDates = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const maxWidgetTiles = 9;
export const maxTilesPerPage = 15;
export const maxListItemsPerPage = 50;

export const seperateIntoPages = (
  levelData: IntegrationLogicalGroup[],
  viewType: ViewType
) => {
  const maxItems =
    viewType === ViewType.Tiles ? maxTilesPerPage : maxListItemsPerPage;

  let newLevelData: Array<IntegrationLogicalGroup[]> = [];
  for (let i = 0; i < levelData.length; i += maxItems) {
    newLevelData.push(levelData.slice(i, i + maxItems));
  }
  return newLevelData;
};

const IntegrationsHeatMap: FunctionComponent<Props> = ({
  widget = false,
  access,
  onLeaveWidget = () => {},
  generalStore,
  integrationStore,
  loaded = false,
}) => {
  const [dataLoad, setDataLoad] = useState(loaded);

  const [viewType, setViewType] = useState(ViewType.Tiles);

  const [itemsToRender, setItemsToRender] = useState<
    IntegrationLogicalGroup[] | undefined
  >(undefined);

  const [currentPage, setCurrentPage] = useState(0);

  const [searchList, setSearchList] = useState<
    Array<IntegrationLogicalGroup[]> | undefined
  >(undefined);

  // Initial data load + get data when environment or timeframe changes
  useEffect(() => {
    access &&
      integrationStore.getIntegrationData().finally(() => {
        if (
          integrationStore.flowDiagramMode &&
          integrationStore.openedLogicalGroup
        ) {
          integrationStore.setCurrentLogicalGroup(undefined);
          integrationStore.setCurrentPlatformComponent(undefined);
          integrationStore.setOpenedLogicalGroupAfterDataReload();

          integrationStore
            .getFlowDiagramData(integrationStore.openedLogicalGroup.id)
            .finally(() => {
              setDataLoad(true);
            });
        } else {
          setDataLoad(true);
        }
      });
  }, [
    integrationStore,
    generalStore.currentEnvironment,
    generalStore.timeFrame,
    access,
  ]);

  useEffect(() => {
    integrationStore.integrationsData &&
      integrationStore.setCurrentIntegrationLevel(
        seperateIntoPages(integrationStore.integrationsData, viewType)
      );
  }, [integrationStore, integrationStore.integrationsData, viewType]);

  const isTopLevel = useCallback(() => {
    return integrationStore.breadCrumbs.length === 0;
  }, [integrationStore.breadCrumbs.length]);

  const getItemsToRender = useCallback(() => {
    if (integrationStore.currentIntegrationLevel) {
      if (widget) {
        // Max widget items is smaller than max page count, so there is only one array
        integrationStore.currentIntegrationLevel.length > 0
          ? setItemsToRender(
              integrationStore.currentIntegrationLevel[0].slice(
                0,
                isTopLevel() ? maxWidgetTiles : maxWidgetTiles - 1
              )
            )
          : setItemsToRender([]);
      } else {
        if (searchList) {
          setItemsToRender(
            searchList.length > 0 ? searchList[currentPage] : []
          );
        } else {
          setItemsToRender(
            integrationStore.currentIntegrationLevel.length > 0
              ? integrationStore.currentIntegrationLevel[currentPage]
              : []
          );
        }
      }
    }
  }, [
    currentPage,
    integrationStore.currentIntegrationLevel,
    searchList,
    isTopLevel,
    widget,
  ]);

  useEffect(() => {
    // Get new items when page changes
    integrationStore.currentIntegrationLevel && getItemsToRender();
  }, [integrationStore.currentIntegrationLevel, currentPage, getItemsToRender]);

  useEffect(() => {
    // Get new items when search filter changes
    getItemsToRender();
  }, [searchList, getItemsToRender]);

  useEffect(() => {
    // Recalculate paging when changing viewType cause they have different max page items
    setCurrentPage(0);
    integrationStore.integrationsData &&
      integrationStore.currentIntegrationLevel &&
      integrationStore.setCurrentIntegrationLevel(
        seperateIntoPages(
          integrationStore.currentIntegrationLevel.flat(),
          viewType
        )
      );
    getItemsToRender();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [integrationStore, viewType]);

  const getSearchItems = (filter: string) => {
    if (integrationStore.currentIntegrationLevel) {
      setCurrentPage(0);
      if (filter === "") {
        setSearchList(undefined);
      } else {
        const allLevelGroups = [
          ...integrationStore.currentIntegrationLevel,
        ].flat();

        const filteredGroups = allLevelGroups.filter((logicalGroup) =>
          logicalGroup.name.toLowerCase().includes(filter.toLowerCase())
        );

        if (filteredGroups.length > 0) {
          setSearchList(seperateIntoPages(filteredGroups, viewType));
        } else {
          setSearchList([]);
        }
      }
    }
  };

  const goToHighestLevel = () => {
    integrationStore.resetCurrentFlowDiagramData();
    integrationStore.setFlowDiagramMode(false);
    integrationStore.integrationsData &&
      integrationStore.setCurrentIntegrationLevel(
        seperateIntoPages(integrationStore.integrationsData, viewType)
      );
    integrationStore.setBreadCrumbs([]);
  };

  const goToLevelData = (logicalGroup: IntegrationLogicalGroup) => {
    // Clicked a breadcrumb or higher-button in widget
    integrationStore.setCurrentIntegrationLevel(
      logicalGroup.logicalGroups
        ? seperateIntoPages(logicalGroup.logicalGroups, viewType)
        : logicalGroup.logicalGroups
    );
    const index = integrationStore.breadCrumbs.indexOf(logicalGroup);
    const newBreadCrumbs = [...integrationStore.breadCrumbs];
    newBreadCrumbs.splice(index + 1);
    integrationStore.setBreadCrumbs(newBreadCrumbs);
  };

  const goLevelUp = () => {
    if (integrationStore.breadCrumbs.length === 1) {
      goToHighestLevel();
    } else {
      goToLevelData(
        integrationStore.breadCrumbs[integrationStore.breadCrumbs.length - 2]
      );
    }
  };

  const onItemClick = (item: IntegrationLogicalGroup) => {
    if (item.logicalGroups) {
      setCurrentPage(0);
      integrationStore.setCurrentIntegrationLevel(
        seperateIntoPages(item.logicalGroups, viewType)
      );
    } else {
      integrationStore.setCurrentIntegrationLevel(undefined);
      onLeaveWidget();
      integrationStore.getFlowDiagramData(item.id);
      integrationStore.setFlowDiagramMode(true);
      integrationStore.setOpenedLogicalGroup(item);
    }
    integrationStore.setBreadCrumbs([...integrationStore.breadCrumbs, item]);
  };

  const handleDataReload = () => {
    if (access) {
      if (!generalStore.currentEnvironment) {
        generalStore.getEnvironments().then(() => {
          integrationStore.getIntegrationData();
        });
      } else integrationStore.getIntegrationData();
    }
  };

  const renderBreadCrumbs = (
    <BreadCrumbs
      breadCrumbs={integrationStore.breadCrumbs}
      onClick={(breadCrumb: IntegrationLogicalGroup) => {
        integrationStore.resetCurrentFlowDiagramData();
        integrationStore.setFlowDiagramMode(false);
        goToLevelData(breadCrumb);
      }}
      goToHighestLevel={goToHighestLevel}
    />
  );

  const renderSearch = (
    <SearchInput
      className="search-input"
      onValueChange={(value) => {
        getSearchItems(value);
      }}
    />
  );

  const renderViewFilter = (
    <ViewFilter>
      <IconComponent
        className="view-type-tiles-button"
        name={Icon.group}
        color={viewType === 0 ? "#fff" : $blue4}
        extraStyling={
          viewType === 0 ? viewFilterActiveStyling : viewFilterStyling
        }
        onClick={() => {
          setViewType(0);
        }}
      />
      <IconComponent
        className="view-type-list-button"
        name={Icon.list}
        color={viewType === 1 ? "#fff" : $blue4}
        extraStyling={
          viewType === 1 ? viewFilterActiveStyling : viewFilterStyling
        }
        onClick={() => {
          setViewType(1);
        }}
      />
    </ViewFilter>
  );

  const renderTopBar = (
    <TopBar>
      {/* Level & flow view */}
      {renderBreadCrumbs}

      {/* Only level view */}
      {!integrationStore.flowDiagramMode &&
        integrationStore.integrationsData &&
        integrationStore.integrationsData.length > 0 &&
        renderSearch}

      {/* Only level view & no/empty data view*/}
      {!integrationStore.flowDiagramMode && renderViewFilter}

      {/* Only flow view */}
      {generalStore.timeFrame && integrationStore.flowDiagramMode && (
        <TimeFrameDates>
          <IconComponent
            name={Icon.calendarstart}
            color={$blue4}
            height="1.2rem"
            extraStyling={css`
              margin-right: 0.5rem;
            `}
          />
          <Text bold large>
            {getFlowTimeDate(generalStore.timeFrame.start)}&nbsp;
          </Text>
          <Text semibold small>
            ({getFlowTimeHours(generalStore.timeFrame.start)})
          </Text>
          <Text bold style={{ margin: "0 0.6rem" }}>
            -
          </Text>
          <IconComponent
            name={Icon.calendarend}
            color={$blue4}
            height="1.2rem"
            extraStyling={css`
              margin-right: 0.5rem;
            `}
          />
          <Text bold large>
            {getFlowTimeDate(generalStore.timeFrame.end)}&nbsp;
          </Text>
          <Text semibold small>
            ({getFlowTimeHours(generalStore.timeFrame.end)})
          </Text>
        </TimeFrameDates>
      )}
    </TopBar>
  );

  const renderPagination = integrationStore.currentIntegrationLevel && (
    <Pagination
      currentPage={currentPage}
      goToPage={setCurrentPage}
      pagesCount={
        searchList
          ? searchList.length
          : integrationStore.currentIntegrationLevel.length
      }
    />
  );

  const renderListItems = (
    <ListItems
      itemsToRender={itemsToRender}
      access={access}
      loading={!dataLoad}
      searchMode={searchList !== undefined}
      onItemClick={onItemClick}
      retryDataLoading={handleDataReload}
    />
  );

  const renderTiles = (
    <Tiles
      itemsToRender={itemsToRender}
      currentPage={currentPage}
      totalItemsOnLevel={
        integrationStore.currentIntegrationLevel
          ? integrationStore.currentIntegrationLevel.flat().length
          : 0
      }
      widget={widget}
      access={access}
      loading={!dataLoad}
      searchMode={searchList !== undefined}
      isTopLevel={isTopLevel}
      goLevelUp={goLevelUp}
      onLeaveWidget={onLeaveWidget}
      onItemClick={onItemClick}
      retryDataLoading={handleDataReload}
    />
  );

  return (
    <Container className="integrations-heat-map">
      {!widget && renderTopBar}

      {!integrationStore.flowDiagramMode &&
        (viewType === ViewType.Tiles ? renderTiles : renderListItems)}

      {access &&
        !integrationStore.flowDiagramMode &&
        !widget &&
        itemsToRender &&
        itemsToRender.length > 0 &&
        renderPagination}
    </Container>
  );
};

export default inject(
  "integrationStore",
  "generalStore"
)(
  observer(
    IntegrationsHeatMap as FunctionComponent<
      Omit<Props, "integrationStore" | "generalStore">
    >
  )
);
