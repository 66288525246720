export enum GlobalTimeFrame {
  Hours = 0,
  ThreeDays = 1,
  Week = 2,
  Month = 3,
  Quarter = 4,
}

export enum TimeJump {
  Hours = 0,
  Days = 1,
  Months = 2,
}

export enum UsageTimeFrame {
  Month = 0,
  TwoMonths = 1,
  Quarter = 2,
}

export interface GlobalTimeFrameType {
  id: GlobalTimeFrame;
  timeJump: TimeJump;
  name: string;
}

export interface UsageTimeFrameType {
  id: UsageTimeFrame;
  name: string;
}

export const globalTimeFrames: GlobalTimeFrameType[] = [
  {
    id: GlobalTimeFrame.Hours,
    timeJump: TimeJump.Hours,
    name: "Last 24 hours",
  },
  {
    id: GlobalTimeFrame.ThreeDays,
    timeJump: TimeJump.Days,
    name: "Last 3 days",
  },
  { id: GlobalTimeFrame.Week, timeJump: TimeJump.Days, name: "Last week" },
  { id: GlobalTimeFrame.Month, timeJump: TimeJump.Days, name: "Last month" },
  {
    id: GlobalTimeFrame.Quarter,
    timeJump: TimeJump.Months,
    name: "Last quarter",
  },
];

export const usageTimeFrames = [
  { id: UsageTimeFrame.Month, name: "1 month" },
  { id: UsageTimeFrame.TwoMonths, name: "2 months" },
  { id: UsageTimeFrame.Quarter, name: "3 months" },
];

export interface GeneralTimeFrame {
  id: GlobalTimeFrame;
  timeJump: TimeJump;
  start: Date;
  end: Date;
}

export const getTimeJump = (globalTimeFrame: GlobalTimeFrame) =>
  globalTimeFrames.find((frame) => frame.id === globalTimeFrame)?.timeJump ||
  globalTimeFrames[0].timeJump;

export enum ExecutionsTimeUnit {
  Second = 0,
  Minute = 1,
  Hour = 2,
  Day = 3,
  Week = 4,
  Month = 5,
  Year = 6,
}

export interface ExecutionsTimeUnitType {
  id: ExecutionsTimeUnit;
  name: string;
}

export const executionsTimeUnits = [
  {
    id: ExecutionsTimeUnit.Second,
    name: "/sec",
  },
  {
    id: ExecutionsTimeUnit.Minute,
    name: "/minute",
  },
  { id: ExecutionsTimeUnit.Hour, name: "/hour" },
  {
    id: ExecutionsTimeUnit.Day,
    name: "/day",
  },
  {
    id: ExecutionsTimeUnit.Week,
    name: "/week",
  },
  {
    id: ExecutionsTimeUnit.Month,
    name: "/month",
  },
  {
    id: ExecutionsTimeUnit.Year,
    name: "/year",
  },
];
