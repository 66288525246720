import Card from "components/common/Card";
import { List } from "components/common/List";
import { Switch } from "components/common/Switch";
import { CustomerPriceTiersModal } from "components/Customers/CustomerCrud/CustomerPriceTiersModal";
import { CustomerPriceTier, CustomerPriceTierBase } from "model/Customer";
import React, { FunctionComponent, useState } from "react";
import styled, { css } from "styled-components";
import { Text, Button } from "styles/common";

interface Props {
  priceTiers: CustomerPriceTierBase[] | null;
  defaultPriceTiers: CustomerPriceTier[];
  editPriceTiers(priceTiers: CustomerPriceTierBase[] | null): void;
}

const Container = styled.div`
  flex: 1;
`;

const SwitchContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

const priceTieringHeaders = ["Tier", "From", "Until"];

const getCustomerPriceTierCells = (priceTiers: CustomerPriceTierBase[]) =>
  priceTiers.map((priceTier: CustomerPriceTierBase) => {
    return {
      cells: [
        <Text>{priceTier.name}</Text>,
        <Text>{priceTier.from}</Text>,
        <Text>{priceTier.until !== null ? priceTier.until : "-"}</Text>,
      ],
    };
  });

export const CustomerPriceTiering: FunctionComponent<Props> = ({
  priceTiers,
  defaultPriceTiers,
  editPriceTiers,
}) => {
  const [showPriceTiersModal, setShowPriceTiersModal] = useState(false);
  const handleChangeCustomPriceTierRanges = (value: boolean) => {
    editPriceTiers(
      value
        ? // Remove id from tiers (when added to DB the id's get created there)
          defaultPriceTiers.map((tier) => ({
            name: tier.name,
            from: tier.from,
            until: tier.until,
          }))
        : null
    );
  };

  const handleEditPriceTierRangesClick = () => {
    setShowPriceTiersModal(true);
  };

  const handleEditPriceTierRanges = (tiers: CustomerPriceTier[]) => {
    editPriceTiers(tiers);
  };

  const renderPriceTiersModal = (
    <CustomerPriceTiersModal
      priceTiers={priceTiers ? priceTiers : []}
      close={() => setShowPriceTiersModal(false)}
      edit={handleEditPriceTierRanges}
    />
  );

  return (
    <Container>
      <Card
        header={<p>Price tiering</p>}
        extraStyling={css`
          height: 100%;
        `}
      >
        <SwitchContainer>
          <Switch
            className="switch-custom-ranges"
            label="Use custom price ranges"
            value={!!priceTiers}
            onChange={handleChangeCustomPriceTierRanges}
            extraStyling={css`
              margin-bottom: 1rem;
            `}
          />

          {priceTiers !== undefined && (
            <Button
              className="edit-ranges-button"
              onClick={handleEditPriceTierRangesClick}
              disabled={!priceTiers}
            >
              Edit ranges
            </Button>
          )}
        </SwitchContainer>

        <List
          headers={priceTieringHeaders}
          data={getCustomerPriceTierCells(
            priceTiers
              ? priceTiers
              : defaultPriceTiers.map((tier) => ({
                  name: tier.name,
                  from: tier.from,
                  until: tier.until,
                }))
          )}
          customColumnWidths={[4, 2, 2, 1]}
          smallEmpty
          hover
          noDataText="There are no price tiers defined yet."
          retryDataLoading={() => {}}
          extraStyling={css`
            margin-bottom: 1rem;
          `}
        />
      </Card>

      {showPriceTiersModal && renderPriceTiersModal}
    </Container>
  );
};
