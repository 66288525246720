import {
  $statusFail,
  $statusFailTransparent,
  $statusInProgress,
  $statusInProgressTransparent,
  $statusNoValue,
  $statusNoValueTransparent,
  $statusProblematic,
  $statusProblematicCalendar,
  $statusProblematicCalendarTransparent,
  $statusProblematicTransparent,
  $statusSuccess,
  $statusSuccessSlow,
  $statusSuccessSlowTransparent,
  $statusSuccessTransparent,
} from "styles/common";

export enum ExecutionSpeedStatus {
  // Total executions which completed successfully
  SuccessOnTime = 0, // On Time: Successful executions which completed within expected time range
  SuccessSlow = 1, // Too Slow: Successful executions which did not completed too slow and finished outside of the time range
  // Total executions which failed
  Fail = 2,
}

export interface ExecutionSpeedStatusType {
  status: ExecutionSpeedStatus;
  name: string;
  explanation?: string;
  color: string;
  transparentColor: string;
}

export const executionSpeedStatuses: ExecutionSpeedStatusType[] = [
  {
    status: ExecutionSpeedStatus.SuccessOnTime,
    name: "Success",
    explanation: "on time",
    color: $statusSuccess,
    transparentColor: $statusSuccessTransparent,
  },
  {
    status: ExecutionSpeedStatus.SuccessSlow,
    name: "Success",
    explanation: "too slow",
    color: $statusSuccessSlow,
    transparentColor: $statusSuccessSlowTransparent,
  },
  {
    status: ExecutionSpeedStatus.Fail,
    name: "Fail",
    explanation: undefined,
    color: $statusFail,
    transparentColor: $statusFailTransparent,
  },
];

export const getExecutionSpeedStatus = (statusId: number) =>
  executionSpeedStatuses.find((status) => status.status === statusId) ||
  executionSpeedStatuses[0];

export const getExecutionSpeedStatusText = (status: number) =>
  `${executionSpeedStatuses[status].name} ${
    executionSpeedStatuses[status].explanation
      ? `(${executionSpeedStatuses[status].explanation})`
      : ""
  }`;

export enum ExecutionSuccessStatusHeatMap {
  // Total executions which completed successfully
  Success = 0,
  Problematic = 1,
  Fail = 2,
  NoValue = 3,
}

export enum ExecutionSuccessStatus {
  Success = 0,
  Problematic = 1,
  Fail = 2,
  NoValue = 3,
  InProgress = 4
}

export interface ExecutionSuccessStatusType {
  status: ExecutionSuccessStatus;
  color: string;
  transparentColor: string;
}

export const executionSuccessStatuses: ExecutionSuccessStatusType[] = [
  {
    status: ExecutionSuccessStatus.Success,
    color: $statusSuccess,
    transparentColor: $statusSuccessTransparent,
  },
  {
    status: ExecutionSuccessStatus.Problematic,
    color: $statusProblematic,
    transparentColor: $statusProblematicTransparent,
  },
  {
    status: ExecutionSuccessStatus.Fail,
    color: $statusFail,
    transparentColor: $statusFailTransparent,
  },
  {
    status: ExecutionSuccessStatus.NoValue,
    color: $statusNoValue,
    transparentColor: $statusNoValueTransparent,
  },
  {
    status: ExecutionSuccessStatus.InProgress,
    color: $statusInProgress,
    transparentColor: $statusInProgressTransparent,
  },
];

export interface ExecutionSuccessStatusHeatMapType {
  status: ExecutionSuccessStatusHeatMap;
  name: string;
  color: string;
  transparentColor: string;
}

// Name is filled in from BE
export const executionCalendarSuccessStatuses: ExecutionSuccessStatusHeatMapType[] =
  [
    {
      status: ExecutionSuccessStatusHeatMap.Success,
      name: "",
      color: $statusSuccess,
      transparentColor: $statusSuccessTransparent,
    },
    {
      status: ExecutionSuccessStatusHeatMap.Problematic,
      name: "",
      color: $statusProblematicCalendar,
      transparentColor: $statusProblematicCalendarTransparent,
    },
    {
      status: ExecutionSuccessStatusHeatMap.Fail,
      name: "",
      color: $statusFail,
      transparentColor: $statusFailTransparent,
    },
    {
      status: ExecutionSuccessStatusHeatMap.NoValue,
      name: "",
      color: $statusNoValue,
      transparentColor: $statusNoValueTransparent,
    },
  ];

export const getExecutionSuccessStatus = (statusId: number) =>
  executionSuccessStatuses.find((status) => status.status === statusId) ||
  executionSuccessStatuses[0];

export const getExecutionSuccessStatusHeatMap = (statusId: number) =>
  executionCalendarSuccessStatuses.find(
    (status) => status.status === statusId
  ) || executionCalendarSuccessStatuses[0];
