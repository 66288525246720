import { inject, observer } from "mobx-react";
import { LocationGroupLinkData } from "model/Edge";
import { getExecutionSpeedStatus } from "model/Status";
import React, { Fragment, FunctionComponent } from "react";
import {
  EdgeProps,
  getBezierPath,
  getEdgeCenter,
  getMarkerEnd,
} from "react-flow-renderer";
import IntegrationStore from "stores/IntegrationStore";
import styled from "styled-components";
import { Text } from "styles/common";

interface Props {
  integrationStore: IntegrationStore;
}

const foreignObjectSize = 40;

const LabelContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
`;

const Label = styled(Text)`
  padding: 0.2rem 0.25rem;
  background-color: rgba(255, 255, 255, 0.9);
  border-radius: 0.2rem;
  font-size: 0.7rem;
`;

const basicValues = {
  startLength: 10, // pixels
  startDuration: 1, // seconds
};
// duration = ((length / startLength) * startDuration) / speed

const CustomEdge: FunctionComponent<EdgeProps & Props> = ({
  id,
  sourceX,
  sourceY,
  targetX,
  targetY,
  sourcePosition,
  targetPosition,
  style = {},
  data,
  arrowHeadType,
  markerEndId,
  integrationStore,
}) => {
  const linkData: LocationGroupLinkData = data;

  const edgePath = getBezierPath({
    sourceX,
    sourceY,
    sourcePosition,
    targetX,
    targetY,
    targetPosition,
  });
  const markerEnd = getMarkerEnd(arrowHeadType, markerEndId);
  const [edgeCenterX, edgeCenterY] = getEdgeCenter({
    sourceX,
    sourceY,
    targetX,
    targetY,
  });

  const renderTraffic = () => {
    const path = document.querySelector(`path#${id}`) as SVGGeometryElement;
    const pathLength = path && path.getTotalLength();

    const duration =
      ((pathLength / basicValues.startLength) * basicValues.startDuration) /
      (linkData.latency * 3);

    // Show 1 to 10 animated circles depending on traffic
    let traffic = Math.round(
      (linkData.amountOfCalls / linkData.highestTraffic) * 10
    );

    if (linkData.amountOfCalls > 0 && traffic === 0) {
      // Show at least one circle when amount of calls is higher than zero
      traffic = 1;
    } else {
      return <></>
    }

    return [...Array(traffic)].map((item, index) => {
      return (
        <circle key={index} r="5" fill={status.transparentColor}>
          <animateMotion
            dur={`${duration}s`}
            begin={`${(duration / traffic) * index}s`}
            repeatCount="indefinite"
          >
            <mpath xlinkHref={`#${id}`}></mpath>
          </animateMotion>
        </circle>
      );
    });
  };

  const status = getExecutionSpeedStatus(linkData.status);

  return (
    <>
      <path
        id={id}
        style={style}
        className="react-flow__edge-path"
        d={edgePath}
        markerEnd={markerEnd}
      />
      {integrationStore.showTraffic && renderTraffic()}

      {data && (
        <Fragment>
          <foreignObject
            width={foreignObjectSize}
            height={foreignObjectSize}
            x={edgeCenterX - foreignObjectSize / 2}
            y={edgeCenterY - foreignObjectSize / 2}
            className="customEdgeLabel"
            requiredExtensions="http://www.w3.org/1999/xhtml"
          >
            <LabelContainer>
              <Label>{linkData.amountOfCalls}</Label>
            </LabelContainer>
          </foreignObject>
        </Fragment>
      )}
    </>
  );
};

export default inject("integrationStore")(
  observer(CustomEdge as FunctionComponent<Omit<Props, "integrationStore">>)
);
