import { Pagination } from "components/common/Pagination";
import SearchInput from "components/common/SearchInput";
import { inject, observer } from "mobx-react";
import { Execution } from "model/Execution";
import { ExecutionSearchFilter } from "model/ExecutionSearch";
import { ExecutionSuccessStatus } from "model/Status";
import React, {
  FunctionComponent,
  useCallback,
  useEffect,
  useState
} from "react";
import ExecutionStore from "stores/ExecutionStore";
import GeneralStore from "stores/GeneralStore";
import styled from "styled-components";
import { Select } from "styles/common";
import { ListItems } from "./ListItems";

interface Props {
  widget?: boolean;
  access: boolean;
  onLeaveWidget?: () => void;
  generalStore: GeneralStore;
  executionStore: ExecutionStore;
  loaded?: boolean; // For unit tests
}

const Container = styled.div`
  margin-bottom: 0.5rem;
  position: relative;
`;

const TopBar = styled.div`
  min-height: 2rem;
  margin-bottom: 2rem;
  position: relative;
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
`;

export const ViewFilter = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;



export enum ViewType {
  Tiles = 0,
  List = 1,
}


export const maxWidgetTiles = 9;
export const maxTilesPerPage = 15;
export const maxListItemsPerPage = 20;

export const seperateIntoPages = (
  executionData: Execution[] | undefined
) => {
  if (executionData === undefined) return [];

  const maxItems = maxListItemsPerPage;

  let newExecutionData: Array<Execution[]> = [];
  for (let i = 0; i < executionData.length; i += maxItems) {
    newExecutionData.push(executionData.slice(i, i + maxItems));
  }

  return newExecutionData;
};

const Executions: FunctionComponent<Props> = ({
  widget = false,
  access,
  onLeaveWidget = () => { },
  generalStore,
  executionStore: executionStore,
  loaded = false,
}) => {
  const [dataLoad, setDataLoad] = useState(loaded);

  const [itemsToRender, setItemsToRender] = useState<
    Execution[] | undefined
  >(undefined);

  const [currentPage, setCurrentPage] = useState(0);

  const [searchList, setSearchList] = useState<
    Array<Execution[]> | undefined
  >(undefined);

  // Initial data load + get data when environment or timeframe changes
  useEffect(() => {
    if (access) {
      // executionStore.searchFilter = undefined;
      executionStore.getExecutionsData().finally(() => {
        setDataLoad(true);
      });
    }
  }, [
    executionStore,
    generalStore.currentEnvironment,
    generalStore.timeFrame,
    access,
  ]);

  useEffect(() => {
    executionStore.executionsData &&
      executionStore.setExecutionsData(
        seperateIntoPages(executionStore.executionsData)
      );
    setCurrentPage(0);
  }, [executionStore, executionStore.executionsData]);


  const getItemsToRender = useCallback((force: boolean = false) => {
    if (executionStore.executionsData) {
      if (executionStore.searchFilter || force == true) {
        getSearchItems(force, false);
      }
      if (searchList) {
        setItemsToRender(
          searchList.length > 0 ? searchList[currentPage] : []
        );
      } else {
        setItemsToRender(
          executionStore.pagedExecutions && executionStore.pagedExecutions.length > 0
            ? executionStore.pagedExecutions[currentPage]
            : []
        );
      }
    }
  }, [
    currentPage,
    executionStore.executionsData,
    searchList,
    widget,
  ]);

  useEffect(() => {
    // Get new items when page changes
    executionStore.executionsData && getItemsToRender(true);
  }, [executionStore.executionsData, currentPage]);

  useEffect(() => {
    // Get new items when search filter changes
    getItemsToRender();
  }, [searchList, getItemsToRender]);

  const getSearchItems = (force: boolean = false, resetPages: boolean = true) => {
    if (
      // executionStore.searchFilter === filter &&
      !force) return;
    // executionStore.searchFilter = filter;
    if (executionStore.pagedExecutions) {
      if (resetPages)
        setCurrentPage(0);
      if (!executionStore.searchFilter?.hasFilterValues()) {
        setSearchList(undefined);
      } else {
        const allLevelExecutions = [
          ...executionStore.pagedExecutions,
        ].flat();

        const filteredGroups = allLevelExecutions.filter((execution) =>
          (executionStore.searchFilter?.filterString ? execution.logicalGroupName.toLowerCase().includes(executionStore.searchFilter.filterString.toLowerCase()) : true)
          && 
          (executionStore.searchFilter?.successStatusFilter !== undefined ? execution.successStatus === executionStore.searchFilter?.successStatusFilter : true)
        );

        if (filteredGroups.length > 0) {
          setSearchList(seperateIntoPages(filteredGroups));
        } else {
          setSearchList([]);
        }
      }
    }
  };

  const onItemClick = async (item: Execution) => {
    if (item.details) {
      item.details = undefined;
    }
    else {
      executionStore.executionsData?.forEach(e => e.details = undefined);
      await executionStore.getExecutionDetailsData(item.transactionId);
      item.details = executionStore.executionDetailsData;
    }

    if (executionStore.searchFilter?.hasFilterValues())
      getSearchItems(false, false);
    else {
      executionStore.setExecutionsData(seperateIntoPages(executionStore.executionsData));
    }

    getItemsToRender(true);
  };

  const handleDataReload = () => {
    if (access) {
      if (!generalStore.currentEnvironment) {
        generalStore.getEnvironments().then(() => {
          executionStore.getExecutionsData();
        });
      } else executionStore.getExecutionsData();
    }
  };

  const handleExecutionDetailsDataReload = (transactionId: number) => {
    if (access) {
      executionStore.getExecutionDetailsData(transactionId);
    }
  }

  const renderStatusDropdown = (
    <Select onChange={event => {
      var newStatus = ExecutionSuccessStatus[event.target.value as keyof typeof ExecutionSuccessStatus];
      executionStore.setSearchFilter(new ExecutionSearchFilter(executionStore.searchFilter?.filterString, newStatus));
      getSearchItems(true);
    }}>
      <option key={undefined} value={undefined}>
        All
      </option>
      {
        Object.keys(ExecutionSuccessStatus).map((key) => {
          var e: ExecutionSuccessStatus = ExecutionSuccessStatus[key as keyof typeof ExecutionSuccessStatus];
          if (isNaN(e))
            return <option key={key} value={e}>{e}</option>
        })
      }
    </Select>
  )

  const renderSearch = (
    <SearchInput
      className="search-input"
      onValueChange={(value) => {
        executionStore.setSearchFilter(new ExecutionSearchFilter(value, executionStore.searchFilter?.successStatusFilter));
        getSearchItems(true);
      }}
    />
  );

  const renderTopBar = (
    <TopBar className="executions-topbar">
      {executionStore.executionsData &&
        executionStore.executionsData.length > 0 &&
        renderSearch}
      {renderStatusDropdown}
    </TopBar>
  );

  const renderPagination = (
    <Pagination
      currentPage={currentPage}
      goToPage={setCurrentPage}
      pagesCount={
        searchList
          ? searchList.length
          : executionStore.pagedExecutions?.length ?? 0
      }
      maxPageNumbersVisible={10}
    />
  );

  const renderListItems = (
    <ListItems
      itemsToRender={itemsToRender}
      access={access}
      loading={!dataLoad}
      searchMode={searchList !== undefined && executionStore.searchFilter?.hasFilterValues() ? true : false}
      onItemClick={onItemClick}
      retryDataLoading={handleDataReload}
      retryExecutionDetailsDataLoading={handleExecutionDetailsDataReload}
    />
  );

  return (
    <Container className="executions-list">
      {!widget && executionStore.executionsData && executionStore.executionsData.length > 0 && renderTopBar}

      {renderListItems}

      {access &&
        !widget &&
        itemsToRender &&
        itemsToRender.length > 0 &&
        renderPagination}
    </Container>
  );
};

export default inject(
  "executionStore",
  "generalStore"
)(
  observer(
    Executions as FunctionComponent<
      Omit<Props, "executionStore" | "generalStore">
    >
  )
);
