import IconComponent from "components/common/Icon";
import { Icon } from "model/Icon";
import { NavigationItem } from "model/Navigation";
import React, {
  FunctionComponent,
  useState,
  useEffect,
  useCallback,
} from "react";
import NavigationStore from "stores/NavigationStore";
import styled from "styled-components";
import {
  $blue4,
  $blue5,
  $grey,
  $textLight,
  textStyling,
  Text,
} from "styles/common";

interface Props {
  navigationStore: NavigationStore;
}

interface NavigationTabsProps {
  tab: NavigationItem;
  navigationStore: NavigationStore;
}

const Container = styled.div`
  margin: 1rem 0 1.25rem 0;
  border-bottom: 1px solid ${$grey};
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
`;

const TabWrapper = styled.div`
  position: relative;
`;

const TabContainer = styled.div<{ active?: boolean }>`
  padding: 0.57rem 0.94rem;
  background-color: transparent;
  transition: background-color 0.3s ease-in;
  cursor: pointer;
  color: ${(props) => (props.active ? $blue4 : $textLight)};

  &:hover {
    background-color: rgba(76, 159, 206, 0.1);
  }
`;

const TabText = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.5rem;
`;

const ActiveLineContainer = styled.span`
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
  -webkit-justify-content: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  pointer-events: none;
  z-index: 1;
`;

const ActiveLine = styled.span<{ active?: boolean }>`
  opacity: ${(props) => (props.active ? 1 : 0)};
  transform: scale(${(props) => (props.active ? 1 : 0)});
  width: ${(props) => (props.active ? "100%" : 0)};
  transition: all 0.25s cubic-bezier(0.4, 0, 0.2, 1);
  border-top: 2px solid #4c9fce;
  transform-origin: center;
  display: flex;
  align-self: flex-end;
`;

const SubNavigationContainer = styled.div`
  position: absolute;
  inset: 0px auto auto 0px;
  transform: translate3d(0px, 36px, 0px);
  display: block;
  z-index: 1000;
  float: left;
  min-width: 10rem;
  padding: 0.5rem 0;
  margin: 0.125rem 0 0;
  font-size: 0.875rem;
  color: #5f7787;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #eaeff2;
  border-radius: 0.25rem;
  box-shadow: 0 2px 16px 0 rgb(203 215 223 / 50%);
  box-sizing: border-box;
`;

const SubNavigationItem = styled.a`
  ${textStyling}
  cursor: pointer;
  display: block;
  width: 100%;
  padding: 0.25rem 1rem;
  clear: both;
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: 0;
  box-sizing: border-box;
  text-transform: none;

  &:hover {
    color: ${$blue4};
    background-color: #eef6fb;
  }

  &:active {
    color: #fff;
    background-color: ${$blue5};
  }
`;

const Tab: FunctionComponent<NavigationTabsProps> = ({
  tab,
  navigationStore,
}) => {
  const [openSubNav, setOpenSubNav] = useState(false);

  // Close subnavigation when clicking outside of it
  const handleMouseUp = useCallback(
    (e) => {
      const tabComponent = document.getElementById(`tab${tab.name}`);

      if (
        openSubNav &&
        e.target !== tabComponent &&
        e.target.parentNode !== tabComponent
      ) {
        setOpenSubNav(false);
      }
    },
    [openSubNav, tab.name]
  );

  useEffect(() => {
    document.addEventListener("mouseup", handleMouseUp);
    return () => {
      document.removeEventListener("mouseup", handleMouseUp);
    };
  }, [handleMouseUp]);

  const handleTabClick = (item: NavigationItem) => {
    if (item.subNavigation) {
      setOpenSubNav(!openSubNav);
    } else {
      navigationStore.navigate(item.route);
    }
  };

  const isParentOfActive =
    (tab.subNavigation &&
      !!tab.subNavigation.find(
        (subNavItem) =>
          navigationStore.currentRoute &&
          subNavItem.id === navigationStore.currentRoute.id
      )) ||
    false;

  const active =
    isParentOfActive ||
    (navigationStore.currentRoute &&
      tab.id === navigationStore.currentRoute.id);

  return (
    <TabWrapper>
      <TabContainer
        className={`tab tab${tab.name}`}
        active={active}
        onMouseUp={(e) => handleTabClick(tab)}
      >
        <TabText>
          <Text semibold uppercase>
            {tab.name}
          </Text>
          {tab.subNavigation && (
            <IconComponent
              className="tab-sub-navigation-arrow"
              name={Icon.arrowdown}
              height="11px"
              color={$textLight}
            />
          )}
        </TabText>
        <ActiveLineContainer>
          <ActiveLine className="tab-highlight" active={active} />
        </ActiveLineContainer>
      </TabContainer>
      {openSubNav && tab.subNavigation && (
        <SubNavigationContainer className="tab-sub-navigation">
          {tab.subNavigation.map((item, index) => (
            <SubNavigationItem
              key={index}
              onMouseUp={(e) => handleTabClick(item)}
            >
              {item.name}
            </SubNavigationItem>
          ))}
        </SubNavigationContainer>
      )}
    </TabWrapper>
  );
};

export const NavigationTabs: FunctionComponent<Props> = ({
  navigationStore,
}) => {
  return (
    <Container>
      {navigationStore.navigationTabs &&
        navigationStore.navigationTabs.map(
          (tab: NavigationItem, index: number) => (
            <Tab key={index} tab={tab} navigationStore={navigationStore} />
          )
        )}
    </Container>
  );
};

export default NavigationTabs;
