import { action, makeObservable, observable } from "mobx";
import { TotalExecutions } from "model/Integration";
import { LocationGroupNodePlatformData } from "model/Node";
import { IntegrationApi } from "services/apis/IntegrationApi";
import LoaderStore from "./LoaderStore";
import GeneralStore from "./GeneralStore";
import {
  IntegrationLogicalGroup,
  IntegrationLogicalGroupBase,
  IntegrationLogicalGroupLevelOneData,
  LogicalGroupLayoutPosition,
  BaseLogicalGroup,
  LogicalGroupLayoutPositionSave,
} from "model/LogicalGroup";

class IntegrationStore {
  @observable
  totalExecutions: TotalExecutions[] | undefined = undefined;

  @observable
  integrationsData: IntegrationLogicalGroup[] | undefined = undefined;

  @observable
  currentIntegrationLevel: Array<IntegrationLogicalGroup[]> | undefined =
    undefined;

  @observable
  breadCrumbs: IntegrationLogicalGroup[] = [];

  @observable
  openedLogicalGroup: IntegrationLogicalGroupBase | undefined = undefined;

  @observable
  flowDiagramMode: boolean = false;

  @observable
  currentFlowDiagramData: IntegrationLogicalGroupLevelOneData | undefined =
    undefined;

  @observable
  flowDiagramLayout: LogicalGroupLayoutPosition[] | undefined = undefined;

  @observable currentLogicalGroup: BaseLogicalGroup | undefined = undefined;

  @observable currentPlatformComponent:
    | LocationGroupNodePlatformData
    | undefined = undefined;

  @observable showSidePanel: boolean = true;

  @observable showTraffic: boolean = false;

  constructor(
    public api: IntegrationApi,
    private generalStore: GeneralStore,
    private loaderStore: LoaderStore
  ) {
    makeObservable(this);
  }

  @action
  getTotalExecutionsData = async () => {
    if (
      this.generalStore.currentEnvironment !== undefined &&
      this.generalStore.timeFrame
    ) {
      this.loaderStore.setLoading();

      const response = await this.api.getTotalExecutionsData(
        this.generalStore.currentEnvironment,
        this.generalStore.timeFrame
      );

      if (response.success) {
        const { data } = response;
        this.totalExecutions = data;
      } else {
        //const { errorData } = response;
        console.log(
          "Something went wrong when trying to fetch total executions"
        );
      }

      this.loaderStore.stopLoading();
    }
  };

  @action
  getIntegrationData = async () => {
    if (
      this.generalStore.currentEnvironment !== undefined &&
      this.generalStore.timeFrame
    ) {
      this.loaderStore.setLoading();

      const response = await this.api.getIntegrationData(
        this.generalStore.currentEnvironment,
        this.generalStore.timeFrame
      );
      if (response.success) {
        const { data } = response;
        this.integrationsData = data;
      } else {
        //const { errorData } = response;
        console.log("Something went wrong when trying to fetch integrations");
      }

      this.loaderStore.stopLoading();
    }
  };

  @action
  setCurrentIntegrationLevel = (
    levelData: Array<IntegrationLogicalGroup[]> | undefined
  ) => {
    this.currentIntegrationLevel = levelData;
  };

  @action
  setBreadCrumbs = (locationGroups: IntegrationLogicalGroup[]) => {
    this.breadCrumbs = locationGroups;
  };

  @action
  setOpenedLogicalGroup = (logicalGroup: IntegrationLogicalGroupBase) => {
    this.openedLogicalGroup = logicalGroup;
  };

  @action
  findAndSetOpenedLogicalGroupInLevel = (
    logicalGroupLevel: IntegrationLogicalGroup[],
    openedLogicalGroupId: number
  ): boolean => {
    let set = false;

    const index = logicalGroupLevel.findIndex(
      (group) => group.id === openedLogicalGroupId
    );

    if (index !== -1) {
      // Found on this level
      set = true;
      this.openedLogicalGroup = logicalGroupLevel[index];
    } else {
      // Not found on this level; search child-levels
      let i = 0;

      while (!set && i < logicalGroupLevel.length) {
        const group = logicalGroupLevel[i];
        if (group.logicalGroups) {
          set = this.findAndSetOpenedLogicalGroupInLevel(
            group.logicalGroups,
            openedLogicalGroupId
          );
        }
        i++;
      }
    }

    return set;
  };

  @action
  setOpenedLogicalGroupAfterDataReload = () => {
    this.integrationsData &&
      this.openedLogicalGroup &&
      this.findAndSetOpenedLogicalGroupInLevel(
        this.integrationsData,
        this.openedLogicalGroup.id
      );
  };

  @action
  setFlowDiagramMode = (set: boolean) => {
    this.flowDiagramMode = set;
  };

  @action
  getFlowDiagramData = async (logicalGroupId: number) => {
    if (
      this.generalStore.currentEnvironment !== undefined &&
      this.generalStore.timeFrame
    ) {
      this.loaderStore.setLoading();

      const response = await this.api.getFlowDiagramData(
        logicalGroupId,
        this.generalStore.currentEnvironment,
        this.generalStore.timeFrame
      );
      if (response.success) {
        const { data } = response;
        this.currentFlowDiagramData = data;
      } else {
        //const { errorData } = response;
        console.log(
          "Something went wrong when trying to fetch logical group workflow"
        );
      }

      this.loaderStore.stopLoading();
    }
  };

  @action
  setCurrentLogicalGroup = (group: BaseLogicalGroup | undefined) => {
    this.currentLogicalGroup = group;
  };

  @action
  setCurrentPlatformComponent = (
    component: LocationGroupNodePlatformData | undefined
  ) => {
    this.currentPlatformComponent = component;
  };

  @action
  setShowSidePanel = (show: boolean) => {
    this.showSidePanel = show;
  };

  @action
  setShowTraffic = (show: boolean) => {
    this.showTraffic = show;
  };

  saveFlowDiagramLayouting = async (
    parentLogicalGroupId: number,
    layout: LogicalGroupLayoutPositionSave[]
  ) => {
    this.loaderStore.setLoading();

    const response = await this.api.saveFlowDiagramLayouting(
      parentLogicalGroupId,
      layout
    );
    if (response.success) {
      this.loaderStore.stopLoading();
      return true;
    } else {
      //const { errorData } = response;
      this.loaderStore.stopLoading();
      return false;
    }
  };

  @action
  resetTotalExecutions = () => {
    this.totalExecutions = undefined;
  };

  @action
  resetCurrentFlowDiagramData = () => {
    this.currentFlowDiagramData = undefined;
    this.openedLogicalGroup = undefined;
    this.currentLogicalGroup = undefined;
    this.currentPlatformComponent = undefined;
  };

  @action
  reset = () => {
    this.integrationsData = undefined;
    this.currentIntegrationLevel = undefined;
    this.flowDiagramMode = false;
    this.resetCurrentFlowDiagramData();
    this.breadCrumbs = [];
  };
}

export default IntegrationStore;
