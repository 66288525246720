import IconComponent from "components/common/Icon";
import { Icon } from "model/Icon";
import React from "react";
import styled, { css } from "styled-components";
import {
  $blue4,
  Text,
  Button,
  $statusFail,
  $statusProblematic,
} from "styles/common";

export enum ModalType {
  NoAccess = 0,
  DataLoadFailed = 1,
  Action = 2,
  Message = 3,
}

export enum ModalColorType {
  NoAccess = 0,
  Message = 1,
  Warning = 2,
  Error = 3,
}

interface Props {
  className?: string;
  type?: ModalType;
  colorType?: ModalColorType;
  customText?: string;
  customButtonText?: string;
  fixed?: boolean;
  background?: boolean;
  action?(): void;
  cancel?(): void;
}

const Background = styled.div<{ show: boolean; fixed: boolean }>`
  ${(props) =>
    props.show
      ? css`
          content: "";
          position: ${props.fixed ? "fixed" : "absolute"};
          left: 0;
          right: 0;
          bottom: 0;
          top: 0;
          background: hsla(0, 0%, 100%, 0.5);
          z-index: 101;
        `
      : css``}
`;

const Container = styled.div<{ color: string }>`
  position: absolute;
  left: 50%;
  top: 50%;
  text-align: center;
  width: auto;
  max-width: 30rem;
  min-width: 230px;
  padding: 1rem 3rem;
  margin-bottom: 1rem;
  border: 1px solid #cbd7df;
  border-left: 0.375rem solid #6c757d;
  border-left-color: ${(props) => props.color};
  background-color: #fff;
  border-radius: 0.25rem;
  transform: translate(-50%, -50%);
  box-sizing: border-box;
  z-index: 101;
  transition: opacity 0.15s linear;
`;

const ModalText = styled(Text)<{ actions?: boolean }>`
  padding: 0 1rem;

  ${(props) =>
    props.actions &&
    css`
      margin-bottom: 1rem;
    `}
`;

export const Modal = ({
  className = "",
  type = ModalType.Message,
  colorType = ModalColorType.Message,
  customText = "",
  customButtonText = "",
  fixed = false,
  background = false,
  action,
  cancel,
}: Props) => {
  const color =
    colorType === ModalColorType.Error || colorType === ModalColorType.NoAccess
      ? $statusFail
      : colorType === ModalColorType.Warning
      ? $statusProblematic
      : $blue4;

  return (
    <Background show={background} fixed={fixed}>
      <Container className={`modal-container ${className}`} color={color}>
        <IconComponent
          name={type === ModalType.NoAccess ? Icon.lock : Icon.info}
          height="24px"
          color={color}
          extraStyling={css`
            width: 1.5rem;
            height: 1.5rem;
            position: absolute;
            top: 50%;
            left: 0.875rem;
            margin-top: -0.75rem;
          `}
        />
        <ModalText actions={!!action}>
          {type === ModalType.DataLoadFailed
            ? "Failed to load data"
            : type === ModalType.NoAccess
            ? "You do not have access to this content"
            : customText}
        </ModalText>
        {type !== ModalType.NoAccess && type === ModalType.Action && !!cancel && (
          <Button
            simple
            className="modal-cancel-button"
            small
            onClick={cancel}
            extraStyling={css`
              margin-right: 1rem;
            `}
          >
            Cancel
          </Button>
        )}
        {type !== ModalType.NoAccess &&
        type === ModalType.Message &&
        !!action ? (
          <Button className="modal-action-button" small onClick={action}>
            {customButtonText ? customButtonText : "Close"}
          </Button>
        ) : (
          type !== ModalType.NoAccess &&
          type !== ModalType.Message &&
          !!action && (
            <Button className="modal-action-button" small onClick={action}>
              {customButtonText ? customButtonText : "Retry"}
            </Button>
          )
        )}
      </Container>
    </Background>
  );
};

export const NoAccessModal = () => (
  <Modal
    type={ModalType.NoAccess}
    colorType={ModalColorType.NoAccess}
    background
  />
);
