import { List } from "components/common/List";
import {
  SettingsIncidentsSupportWindow,
  SimpleSettingsContact,
} from "model/Contact";
import React from "react";
import {
  Text,
  Select,
  $blue4,
  StyledReactTooltip,
  $textLight,
  TooltipText,
} from "styles/common";
import { TableCheckbox } from "components/common/TableCheckbox";
import {
  ignoreText,
  ignoreValue,
  InfoIconWrapper,
} from "screens/settings/ContactSchedule";
import IconComponent from "components/common/Icon";
import { Icon } from "model/Icon";
import {
  CenteredCellContainer,
  ListHeaderText,
} from "components/common/List/common";

interface Props {
  contacts: SimpleSettingsContact[] | undefined;
  data: SettingsIncidentsSupportWindow[] | undefined;
  viewAccess: boolean;
  editAccess: boolean;
  editData(data: SettingsIncidentsSupportWindow[]): void;
  retryDataLoading(): void;
}

const headers = [
  "Support window",
  "Technical contact",
  <CenteredCellContainer space>
    <ListHeaderText>Chase</ListHeaderText>
  </CenteredCellContainer>,
  "Functional contact",
  <CenteredCellContainer space>
    <ListHeaderText>Chase</ListHeaderText>
  </CenteredCellContainer>,
];

export const SettingSupportWindowsList = ({
  contacts,
  data,
  viewAccess,
  editAccess,
  editData,
  retryDataLoading,
}: Props) => {
  return (
    <List
      headers={headers}
      data={
        contacts && data
          ? data.map((supportWindow: SettingsIncidentsSupportWindow) => {
              return {
                cells: [
                  supportWindow.technicalContact.escalation ? (
                    <InfoIconWrapper>
                      <Text dark semibold>
                        {supportWindow.name}
                      </Text>
                      <div
                        data-for="escalation-contact-info-tooltip"
                        data-tip=""
                      >
                        <IconComponent name={Icon.info} color={$blue4} />
                      </div>

                      <StyledReactTooltip
                        id="escalation-contact-info-tooltip"
                        effect="solid"
                        place="right"
                        type="light"
                        border={false}
                        textColor={$textLight}
                        backgroundColor={"#fff"}
                        arrowColor={"transparent"}
                      >
                        <TooltipText>
                          <Text>
                            This person gets contacted in case of escalations.
                          </Text>
                        </TooltipText>
                      </StyledReactTooltip>
                    </InfoIconWrapper>
                  ) : (
                    <Text dark semibold>
                      {supportWindow.name}
                    </Text>
                  ),
                  editAccess &&
                  (!supportWindow.technicalContact.mandatory ||
                    contacts.length > 1) ? (
                    <>
                      <Select
                        className="technical-contact-select"
                        defaultValue={
                          supportWindow.technicalContact.contact
                            ? supportWindow.technicalContact.contact.id
                            : supportWindow.technicalContact.mandatory
                            ? contacts[0].id
                            : ignoreValue
                        }
                        fullWidth
                        onChange={(e) => {
                          const selectedContact:
                            | SimpleSettingsContact
                            | undefined = contacts.find(
                            (contact) =>
                              contact.id.toString() === e.target.value
                          );

                          editData(
                            data.map((window) => {
                              return window.id === supportWindow.id
                                ? {
                                    ...supportWindow,
                                    technicalContact: {
                                      ...supportWindow.technicalContact,
                                      contact: selectedContact,
                                      chase:
                                        selectedContact === undefined
                                          ? false
                                          : supportWindow.technicalContact
                                              .chase,
                                    },
                                  }
                                : window;
                            })
                          );
                        }}
                      >
                        {!supportWindow.technicalContact.mandatory && (
                          <option value={ignoreValue}>{ignoreText}</option>
                        )}
                        {contacts.map((item, index) => (
                          <option key={index} value={item.id}>
                            {item.contact} ({item.role})
                          </option>
                        ))}
                      </Select>
                    </>
                  ) : (
                    <Text className="technical-contact-text">
                      {supportWindow.technicalContact.contact
                        ? `${supportWindow.technicalContact.contact.contact} (
          ${supportWindow.technicalContact.contact.role})`
                        : `${ignoreText}`}
                    </Text>
                  ),
                  supportWindow.technicalContact.chase === undefined ? (
                    ""
                  ) : (
                    <CenteredCellContainer space>
                      <TableCheckbox
                        className="technical-contact-chase-checkbox"
                        checked={supportWindow.technicalContact.chase}
                        clickable={editAccess}
                        disabled={
                          supportWindow.technicalContact.contact === undefined
                        }
                        onClick={() => {
                          editData([
                            ...data.map((window) => {
                              return window.id === supportWindow.id
                                ? {
                                    ...supportWindow,
                                    technicalContact: {
                                      ...supportWindow.technicalContact,
                                      chase:
                                        !supportWindow.technicalContact.chase,
                                    },
                                  }
                                : window;
                            }),
                          ]);
                        }}
                      />
                    </CenteredCellContainer>
                  ),
                  supportWindow.functionalContact ? (
                    editAccess &&
                    (!supportWindow.functionalContact.mandatory ||
                      contacts.length > 1) ? (
                      <>
                        <Select
                          className="functional-contact-select"
                          defaultValue={
                            supportWindow.functionalContact.contact
                              ? supportWindow.functionalContact.contact.id
                              : supportWindow.functionalContact.mandatory
                              ? contacts[0].id
                              : ignoreValue
                          }
                          fullWidth
                          onChange={(e) => {
                            const selectedContact:
                              | SimpleSettingsContact
                              | undefined = contacts.find(
                              (contact) =>
                                contact.id.toString() === e.target.value
                            );

                            editData(
                              data.map((window) => {
                                return window.id === supportWindow.id &&
                                  supportWindow.functionalContact
                                  ? {
                                      ...supportWindow,
                                      functionalContact: {
                                        ...supportWindow.functionalContact,
                                        contact: selectedContact,
                                        chase:
                                          selectedContact === undefined
                                            ? false
                                            : supportWindow.functionalContact
                                                .chase,
                                      },
                                    }
                                  : window;
                              })
                            );
                          }}
                        >
                          {!supportWindow.functionalContact.mandatory && (
                            <option value={ignoreValue}>{ignoreText}</option>
                          )}
                          {contacts.map((item, index) => (
                            <option key={index} value={item.id}>
                              {item.contact} ({item.role})
                            </option>
                          ))}
                        </Select>
                      </>
                    ) : (
                      <Text className="functional-contact-text">
                        {supportWindow.functionalContact.contact
                          ? `${supportWindow.functionalContact.contact.contact} (
          ${supportWindow.functionalContact.contact.role})`
                          : `${ignoreText}`}
                      </Text>
                    )
                  ) : (
                    ""
                  ),
                  !supportWindow.functionalContact ||
                  supportWindow.functionalContact.chase === undefined ? (
                    ""
                  ) : (
                    <CenteredCellContainer space>
                      <TableCheckbox
                        className="functional-contact-chase-checkbox"
                        checked={supportWindow.functionalContact.chase}
                        clickable={editAccess}
                        disabled={
                          supportWindow.functionalContact.contact === undefined
                        }
                        onClick={() => {
                          editData(
                            data.map((window) => {
                              return window.id === supportWindow.id &&
                                supportWindow.functionalContact
                                ? {
                                    ...supportWindow,
                                    functionalContact: {
                                      ...supportWindow.functionalContact,
                                      chase:
                                        !supportWindow.functionalContact.chase,
                                    },
                                  }
                                : window;
                            })
                          );
                        }}
                      />
                    </CenteredCellContainer>
                  ),
                ],
              };
            })
          : undefined
      }
      noBackground
      fullWidth
      smallEmpty
      customColumnWidths={[2, 3, 1, 3, 1]}
      access={viewAccess}
      noDataText="There are no support windows defined yet."
      retryDataLoading={retryDataLoading}
    />
  );
};
