import Card, { FilterHeader } from "components/common/Card";
import Pills from "components/common/Pills";
import { UsageLineChart } from "components/Usage/UsageLineChart";
import { inject, observer } from "mobx-react";
import { UsageTimeFrame, usageTimeFrames } from "model/Time";
import React, {
  FunctionComponent,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import UsageStore from "stores/UsageStore";
import { getUsageStartDate } from "utils/Date";
import { css } from "styled-components";
import UsagePerMonth from "components/Usage/UsagePerMonth";
import { Prompt } from "react-router";
import { Route } from "model/Navigation";
import AuthenticationStore from "stores/AuthenticationStore";
import { PermissionArea, PermissionType } from "model/Claim";
import { NoAccessModal } from "components/common/Modal";

interface Props {
  usageStore: UsageStore;
  authenticationStore: AuthenticationStore;
}

const Usage: FunctionComponent<Props> = ({
  usageStore,
  authenticationStore,
}) => {
  const [dataLoaded, setDataLoaded] = useState(false);

  const accessViewUsage = authenticationStore.userHasPermission(
    PermissionArea.Usage,
    PermissionType.View
  );

  const [timeFrame, setTimeFrame] = useState<UsageTimeFrame>(
    usageTimeFrames[0].id
  );

  const [startDate, setStartDate] = useState<Date | undefined>(undefined);
  const endDate = useMemo(() => new Date(), []);

  const setUsageStartDate = useCallback(() => {
    return getUsageStartDate(endDate, timeFrame);
  }, [endDate, timeFrame]);

  useEffect(() => {
    // Load data & reload when endDate changes

    const start = setUsageStartDate();
    setStartDate(start);

    accessViewUsage &&
      usageStore.getUnitConsumptionData(start, endDate).then(() => {
        !usageStore.usagePerMonthData &&
          usageStore.getUsagePerMonthData().then(() => {
            setDataLoaded(true);
          });
      });
  }, [accessViewUsage, endDate, setUsageStartDate, usageStore]);

  useEffect(() => {
    setStartDate(getUsageStartDate(endDate, timeFrame));
  }, [endDate, timeFrame]);

  const handleDataReloadConsumption = () => {
    accessViewUsage &&
      startDate &&
      usageStore.getUnitConsumptionData(startDate, endDate);
  };

  const handleDataReloadMonths = () => {
    accessViewUsage && usageStore.getUsagePerMonthData();
  };

  return (
    <>
      <Prompt
        message={(location) => {
          if (!location.pathname.startsWith(Route.Usage))
            // Reset data when leaving page
            usageStore.reset();

          return true;
        }}
      />

      <Card
        header={
          <FilterHeader space>
            <p>Integration unit consumption</p>
            <Pills
              pills={usageTimeFrames}
              active={timeFrame}
              setActive={(id: UsageTimeFrame) => {
                setTimeFrame(id);
              }}
            />
          </FilterHeader>
        }
      >
        {!accessViewUsage && <NoAccessModal />}

        {(!accessViewUsage || dataLoaded) && (
          <UsageLineChart
            data={usageStore.unitConsumptionData}
            access={accessViewUsage}
            retryDataLoading={handleDataReloadConsumption}
          />
        )}
      </Card>

      <Card
        header={<p>Usage per month</p>}
        extraContentStyling={css`
          padding-bottom: 1.5rem;
        `}
      >
        {!accessViewUsage && <NoAccessModal />}

        {(!accessViewUsage || dataLoaded) && (
          <UsagePerMonth
            data={usageStore.usagePerMonthData}
            access={accessViewUsage}
            selectedMonthData={usageStore.selectedMonthUsage}
            getSelectedMonthData={usageStore.getSelectedMonthData}
            retryDataLoading={handleDataReloadMonths}
          />
        )}
      </Card>
    </>
  );
};

export default inject(
  "usageStore",
  "authenticationStore"
)(
  observer(
    Usage as FunctionComponent<
      Omit<Props, "usageStore" | "authenticationStore">
    >
  )
);
