import IconComponent from "components/common/Icon";
import { Icon } from "model/Icon";
import { ExtraStylingType } from "model/Styles";
import React, { FunctionComponent } from "react";
import styled, { css } from "styled-components";
import { $blue4, $textLight, Text } from "styles/common";

interface Props {
  currentPage: number;
  goToPage(pageNumber: number): void;
  pagesCount: number;
  maxPageNumbersVisible?: number;
}

const PaginationContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin: 1.5rem 0;
`;

const PageNumberContainer = styled.div<{ active?: boolean }>`
  text-align: center;
  line-height: 1rem;
  display: inline-block;
  min-width: 30px;
  height: 30px;
  padding-left: 0.62rem;
  padding-right: 0.62rem;
  border-radius: 0.25rem;
  position: relative;
  padding: 0.5rem 0.75rem;
  margin: 0 0.1rem;
  text-align: center;
  box-sizing: border-box;
  user-select: none; /* standard syntax */
  -webkit-user-select: none; /* webkit (safari, chrome) browsers */
  -moz-user-select: none; /* mozilla browsers */
  -khtml-user-select: none; /* webkit (konqueror) browsers */
  -ms-user-select: none; /* IE10+ */

  ${(props) =>
    props.active
      ? css`
          background-color: ${$blue4};
        `
      : css`
          background-color: transparent;
          cursor: pointer;

          &:hover {
            background-color: #eef6fb;
          }
        `}
`;

const PageNumber = styled(Text) <{ active?: boolean }>`
  ${(props) =>
    props.active
      ? css`
          color: #dbecf5;
        `
      : css`
          color: ${$blue4};

          &:hover {
            color: ${$textLight};
          }
        `}
`;

const PaginationButtonContainer = styled.div<{
  textLeft?: boolean;
  disabled?: boolean;
  extraStyling?: ExtraStylingType;
}>`
  display: flex;
  align-items: center;
  position: relative;
  min-width: 30px;
  height: 30px;
  border: 0;
  text-align: center;
  line-height: 1rem;
  background-color: #eef6fb;
  border-radius: 0.25rem;
  padding: 0.5rem 0.62rem;
  cursor: pointer;
  box-sizing: border-box;
  user-select: none; /* standard syntax */
  -webkit-user-select: none; /* webkit (safari, chrome) browsers */
  -moz-user-select: none; /* mozilla browsers */
  -khtml-user-select: none; /* webkit (konqueror) browsers */
  -ms-user-select: none; /* IE10+ */

  &:hover {
    background-color: ${$blue4};

    p {
      color: #fff;
    }

    svg {
      fill: #fff;
    }
  }

  ${(props) =>
    props.textLeft
      ? css`
          flex-direction: row-reverse;

          svg {
            margin-left: 0.5rem;
          }
        `
      : css`
          flex-direction: row;

          svg {
            margin-right: 0.5rem;
          }
        `}

  ${(props) =>
    props.disabled &&
    css`
      background-color: transparent;
      border: 1px solid #5f7787;
      opacity: 0.5;
      pointer-events: none;

      svg {
        fill: ${$textLight};
      }
    `}

  ${(props) => props.extraStyling && props.extraStyling}
`;

const PaginationButtonText = styled(Text) <{ disabled?: boolean }>`
  font-size: 0.88rem;
  color: ${$blue4};

  ${(props) =>
    props.disabled &&
    css`
      color: ${$textLight};
    `}
`;

const PaginationButton = (props: {
  text: string;
  textLeft?: boolean;
  icon: Icon;
  disabled?: boolean;
  onClick(): void;
  extraStyling?: ExtraStylingType;
  className: string;
}) => {
  return (
    <PaginationButtonContainer
      className={props.className}
      textLeft={props.textLeft}
      disabled={props.disabled}
      onClick={props.onClick}
      extraStyling={props.extraStyling}
    >
      <IconComponent
        name={props.icon}
        color={$blue4}
        height="0.75rem"
        extraStyling={css`
          line-height: 0.75rem;
        `}
      />
      <PaginationButtonText disabled={props.disabled}>
        {props.text}
      </PaginationButtonText>
    </PaginationButtonContainer>
  );
};

const PageNumberButton = (props: {
  pageNumber: string;
  active?: boolean;
  onClick(): void;
}) => {
  return (
    <PageNumberContainer active={props.active} onClick={props.onClick}>
      <PageNumber active={props.active}>{props.pageNumber}</PageNumber>
    </PageNumberContainer>
  );
};

function getBoundaries(minimumBoundary: number, maximumBoundary: number, maxPageNumbersVisible: number, currentPage: number): { minimum: number, maximum: number } {
  if (maximumBoundary == 0) {
    return { minimum: 0, maximum: 0 };
  }
  if (maximumBoundary <= maxPageNumbersVisible) {
    return { minimum: 1, maximum: maximumBoundary };
  }

  var virtualMinimum = currentPage - Math.floor(maxPageNumbersVisible / 2) + 1;
  var virtualMaximum = currentPage + Math.floor(maxPageNumbersVisible / 2);
  if (virtualMinimum >= minimumBoundary && virtualMaximum <= maximumBoundary) {
    return { minimum: virtualMinimum, maximum: Math.min(virtualMaximum, maximumBoundary) };
  }
  if (virtualMaximum <= maximumBoundary && virtualMinimum >= minimumBoundary) {
    return { minimum: Math.min(virtualMinimum, minimumBoundary), maximum: virtualMaximum };
  }

  if (virtualMinimum < minimumBoundary) {
    var minimumDelta = minimumBoundary - virtualMinimum;
    return { minimum: minimumBoundary, maximum: Math.min(maximumBoundary, virtualMaximum + minimumDelta, maxPageNumbersVisible) };
  }

  if (virtualMaximum > maximumBoundary) {
    var maximumDelta = maximumBoundary - virtualMaximum;
    return { minimum: Math.max(minimumBoundary, virtualMinimum + maximumDelta), maximum: virtualMaximum };
  }

  return { minimum: 0, maximum: 0 };
}

export const Pagination: FunctionComponent<Props> = ({
  currentPage,
  goToPage,
  pagesCount,
  maxPageNumbersVisible
}) => {

  var minimumBoundary = 1;
  var maximumBoundary = pagesCount;

  if (maxPageNumbersVisible != null && maxPageNumbersVisible != undefined) {
    var boundaries = getBoundaries(minimumBoundary, maximumBoundary, maxPageNumbersVisible, currentPage);
    minimumBoundary = boundaries.minimum;
    maximumBoundary = boundaries.maximum;
  }

  const renderPageNumbers = () => {
    return [...Array(pagesCount)].map((x, i) => {
      return (
        <PageNumberButton
          key={i}
          pageNumber={(i + 1).toString()}
          active={currentPage === i}
          onClick={() => goToPage(i)}
        />
      );
    }
    ).filter((x, i) => {
      return i + 1 >= minimumBoundary && i + 1 <= maximumBoundary;
    });
  }

  return (
    <PaginationContainer className="pagination">
      <PaginationButton
        className="pagination-first"
        icon={Icon.doublearrowleft}
        text="First"
        disabled={currentPage === 0}
        onClick={() => goToPage(0)}
      />
      <PaginationButton
        className="pagination-previous"
        icon={Icon.arrowleft}
        text="Previous"
        disabled={currentPage === 0}
        onClick={() => goToPage(currentPage - 1)}
        extraStyling={css`
          margin: 0 0.75rem 0 0.31rem;
        `}
      />
      <div className="page-numbers">{renderPageNumbers()}</div>
      <PaginationButton
        className="pagination-next"
        icon={Icon.arrowright}
        text="Next"
        textLeft
        disabled={currentPage === pagesCount - 1}
        onClick={() => goToPage(currentPage + 1)}
        extraStyling={css`
          margin: 0 0.31rem 0 0.75rem;
        `}
      />
      <PaginationButton
        className="pagination-last"
        icon={Icon.doublearrowright}
        text="Last"
        textLeft
        disabled={currentPage === pagesCount - 1}
        onClick={() => goToPage(pagesCount - 1)}
      />
    </PaginationContainer>
  );
};
