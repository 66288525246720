import { BasicApplicationDatum } from "model/Application";
import { ApplicationEnvironment } from "model/Environment";
import request, { ApiResponse } from "../../utils/ApiHandlers";
import { BaseConfig } from "../../utils/Variables";

export class ApplicationApi {
  private static instance: ApplicationApi;

  private constructor() {}

  static getInstance(fresh?: boolean) {
    if (!ApplicationApi.instance || fresh) {
      ApplicationApi.instance = new ApplicationApi();
    }
    return ApplicationApi.instance;
  }

  getApplicationData = async (): Promise<
    ApiResponse<ApplicationEnvironment[]>
  > => request.get(`${BaseConfig.getBaseApiUrl()}application`);

  getApplicationsRuntime = async (
    environmentId: number
  ): Promise<ApiResponse<BasicApplicationDatum[]>> =>
    request.get(
      `${BaseConfig.getBaseApiUrl()}dashboard/applications/${environmentId}`
    );
}

export default ApplicationApi.getInstance();
