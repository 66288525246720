import {
  ItOperationPlatformComponent,
  ItOperationPlatformComponentType,
} from "model/PlatformComponent";
import React, { FunctionComponent, useState } from "react";
import styled, { css } from "styled-components";
import { $grey, StatusCircle, Text, Span, $blue4 } from "styles/common";
import Masonry from "react-masonry-css";
import IconComponent from "components/common/Icon";
import { Icon } from "model/Icon";
import PlatformComponentTypeIconComponent from "components/Integrations/PlatformComponentTypeIcon";
import { getSuccessStatusColor } from "utils/Status";

interface Props {
  platformComponentTypes: ItOperationPlatformComponentType[];
  currentEnvironment: string;
}

const Container = styled.div`
  .masonry-grid {
    display: flex;
    margin-left: -1rem; /* gutter size offset */
    width: 100%;
  }
  .masonry-grid-column {
    padding-left: 1rem; /* gutter size */
    background-clip: padding-box;
  }
`;

const PlatformComponentTypeContainer = styled.div<{
  status: number | undefined;
}>`
  margin-bottom 1rem;
  background-color: #f7fafc;
  box-shadow: 1px 1px 3px 1px rgba(0, 0, 0, 0.1);
  border-left 4px solid ${(props) =>
    props.status !== undefined ? getSuccessStatusColor(props.status) : $grey};
    border-radius: 0.2rem;

`;

const PlatformComponentTypeHeader = styled.div<{
  collapsed: boolean;
}>`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 0.5rem 1rem 0.5rem 1rem;
  border: 1px solid transparent;
  border-radius: 0.2rem 0.2rem 0 0;
  cursor: pointer;
  user-select: none;

  ${(props) =>
    !props.collapsed &&
    css`
      border-bottom: 1px solid #ddd;
    `}
`;

const PlatformComponentTypeName = styled.div`
  display: flex;
  flex-direction: row;
  gap: 0.75rem;
`;

const CollapseIcon = styled(IconComponent)<{ collapsed: boolean }>`
  transform: rotate(${(props) => (props.collapsed ? "180deg" : "0deg")});
`;

const PlatformComponentTypeContent = styled.div<{
  collapsed: boolean;
}>`
  padding: 0.5rem 0;
  border-radius: 0 0 0.2rem 0.2rem;

  ${(props) =>
    props.collapsed
      ? css`
          display: none;
        `
      : css`
          display: block;
        `}
`;

const PlatformComponent = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.25rem;
  padding: 0.25rem 1rem;
`;

const PlatformComponentTypeHeaderTitle = styled(Text)`
  color: rgba(0, 0, 0, 0.7);

  span {
    opacity: 0.6;
  }
`;

const NoData = styled(Text)`
  margin-bottom: 1rem;
`;

const PlatformComponentType: FunctionComponent<{
  platformComponentType: ItOperationPlatformComponentType;
}> = ({ platformComponentType }) => {
  const [collapsed, setCollapsed] = useState(false);

  const handleHeaderClick = () => {
    setCollapsed(!collapsed);
  };

  return (
    <PlatformComponentTypeContainer status={platformComponentType.status}>
      <PlatformComponentTypeHeader
        className="platform-component-type-header"
        onClick={handleHeaderClick}
        collapsed={collapsed}
      >
        <PlatformComponentTypeName>
          <PlatformComponentTypeIconComponent
            name={platformComponentType.type}
          />
          <PlatformComponentTypeHeaderTitle dark semibold>
            {platformComponentType.type}&nbsp;
            <span>({platformComponentType.platformComponents.length})</span>
          </PlatformComponentTypeHeaderTitle>
        </PlatformComponentTypeName>
        <CollapseIcon
          className="collapse-icon"
          collapsed={collapsed}
          name={Icon.arrowdown}
          height={"10px"}
        />
      </PlatformComponentTypeHeader>
      <PlatformComponentTypeContent
        className="platform-component-type-content"
        collapsed={collapsed}
      >
        {platformComponentType.platformComponents.length > 0 ? (
          platformComponentType.platformComponents.map(
            (
              platformComponent: ItOperationPlatformComponent,
              index: number
            ) => (
              <PlatformComponent key={index}>
                <StatusCircle
                  color={getSuccessStatusColor(platformComponent.status)}
                />
                <Text>{platformComponent.name}</Text>
              </PlatformComponent>
            )
          )
        ) : (
          <PlatformComponent>
            <Text small>No platform components</Text>
          </PlatformComponent>
        )}
      </PlatformComponentTypeContent>
    </PlatformComponentTypeContainer>
  );
};

export const ItOperationsList = ({
  platformComponentTypes,
  currentEnvironment,
}: Props) => {
  return (
    <Container>
      {platformComponentTypes.length > 0 ? (
        <Masonry
          breakpointCols={4}
          className="masonry-grid"
          columnClassName="masonry-grid-column"
        >
          {platformComponentTypes.map(
            (
              platformComponentType: ItOperationPlatformComponentType,
              index: number
            ) => (
              <PlatformComponentType
                key={index}
                platformComponentType={platformComponentType}
              />
            )
          )}
        </Masonry>
      ) : (
        <NoData>
          Your environment{" "}
          <Span semibold color={$blue4}>
            {currentEnvironment}
          </Span>{" "}
          does not leverage this location.
        </NoData>
      )}
    </Container>
  );
};
