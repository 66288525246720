import { LogLevel } from "@azure/msal-browser";
import config from "../config";

export class BaseConfig {
  static getBaseUrl(): string {
    //@ts-ignore
    return config.baseUrl;
  }

  static getBaseApiUrl(): string {
    //@ts-ignore
    return config.apiUrl;
  }

  static getAuth() : AuthConfig {
    //@ts-ignore
    return config.auth;
  }
}

export interface RunConfig {
  baseUrl: string;
  apiUrl: string;
  auth: AuthConfig;
  msalLogLevel: LogLevel
}

export interface AuthConfig {
  clientId: string;
  authority: string;
  redirectUrl: string;
  postLogoutRedirectUri: string;
  scopeUrl: string;
}
