import { PermissionArea } from "./Claim";

export const horizonUrl = "https://horizon.cegeka.com";

export interface HorizonRoute {
  name: string;
  route: string;
  accessOptions?: string[];
}

export const horizonRoutes = [
  // {
  //   name: "Health",
  //   route: "/health",
  // },
  // { name: "Inventory", route: "/inventory" },
  // { name: "Security", route: "/security" },
  { name: "Integration", route: "" },
  // { name: "Cost control", route: "/cost-control" },
  // {
  //   name: "Service & portfolio management",
  //   route: "/service-portfolio-management",
  // },
  {
    name: "My tickets",
    route: "/tickets",
    accessOptions: ["allowTicketManagement"],
  },
];

export enum RouteID {
  General = 0,
  Integrations = 1,
  ItOperations = 2,
  Applications = 3,
  Usage = 4,
  Reports = 5,
  Settings = 6,
  UserManagement = 7,
  ContactDetails = 8,
  ContactSchedule = 9,
  GroupWorkflow = 10,
  CustomerManagement = 11,
  Executions = 12
}

export enum Route {
  General = "/",
  Integrations = "/integrations",
  Executions = "/executions",
  ItOperations = "/it-operations",
  Applications = "/applications",
  Usage = "/usage",
  Reports = "/reports",
  Settings = "/settings",
  UserManagement = "/settings/user-management",
  ContactDetails = "/settings/contact-details",
  ContactSchedule = "/settings/contact-schedule",
  GroupWorkflow = "/settings/group-workflow",
  CustomerManagement = "/customer-management",
}

export interface NavigationItem {
  id: RouteID;
  name: string;
  route: Route;
  permissionAreas: PermissionArea[];
  accessOptions?: string[];
  subNavigation?: NavigationItem[];
}

export const navigationItems: NavigationItem[] = [
  {
    id: RouteID.CustomerManagement,
    name: "Customer Management",
    route: Route.CustomerManagement,
    permissionAreas: [PermissionArea.Customer],
  },
  {
    id: RouteID.General,
    name: "General",
    route: Route.General,
    permissionAreas: [PermissionArea.Application, PermissionArea.Integration],
  },
  {
    id: RouteID.Integrations,
    name: "Integrations",
    route: Route.Integrations,
    permissionAreas: [PermissionArea.Integration],
  },
  {
    id: RouteID.Executions,
    name: "Executions",
    route: Route.Executions,
    permissionAreas: [PermissionArea.Execution],
  },
  {
    id: RouteID.ItOperations,
    name: "IT Operations",
    route: Route.ItOperations,
    permissionAreas: [PermissionArea.Integration],
  },
  {
    id: RouteID.Applications,
    name: "Applications",
    route: Route.Applications,
    permissionAreas: [PermissionArea.Application],
  },
  {
    id: RouteID.Usage,
    name: "Usage",
    route: Route.Usage,
    permissionAreas: [PermissionArea.Usage],
    accessOptions: ["showUsage"],
  },
  {
    id: RouteID.Reports,
    name: "Reports",
    route: Route.Reports,
    permissionAreas: [PermissionArea.Report],
    accessOptions: ["showReports"],
  },
  {
    id: RouteID.Settings,
    name: "Settings",
    route: Route.Settings,
    permissionAreas: [PermissionArea.Contact, PermissionArea.Integration],
    subNavigation: [
      // {
      //   id: RouteID.UserManagement,
      //   name: "User Management",
      //   route: Route.UserManagement,
      //   permissionAreas: [],
      // },
      {
        id: RouteID.ContactDetails,
        name: "Provide contact details",
        route: Route.ContactDetails,
        permissionAreas: [PermissionArea.Contact],
      },
      {
        id: RouteID.ContactSchedule,
        name: "Define contact schedule",
        route: Route.ContactSchedule,
        permissionAreas: [PermissionArea.Contact],
        accessOptions: ["allowEscalationManagement"],
      },
      {
        id: RouteID.GroupWorkflow,
        name: "Group Workflow",
        route: Route.GroupWorkflow,
        permissionAreas: [PermissionArea.Integration],
      },
    ],
  },
];
