import { Modal, ModalType } from "components/common/Modal";
import format from "date-fns/format";
import { ExtraStylingType } from "model/Styles";
import { UsageOfDate } from "model/Usage";
import React, { FunctionComponent, useEffect, useState } from "react";
import { Line } from "react-chartjs-2";
import styled, { css } from "styled-components";
import { $blue4, $textLight } from "styles/common";
import { isLastDayOfMonth } from "utils/Date";

interface Props {
  data: UsageOfDate[] | undefined;
  access: boolean;
  retryDataLoading(): void;
  extraStyling?: ExtraStylingType;
}

const Container = styled.div<{
  extraStyling: ExtraStylingType;
}>`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  height: 15rem;
  position: relative;

  ${(props) => props.extraStyling};
`;

export const UsageLineChart: FunctionComponent<Props> = ({
  data,
  access,
  retryDataLoading,
  extraStyling = css``,
}) => {
  const [units, setUnits] = useState<number[] | undefined>(undefined);
  const [labels, setLabels] = useState<string[] | undefined>(undefined);
  const [lastDays, setLastDays] = useState<number[]>([]);

  useEffect(() => {
    if (data) {
      let unitsArray: number[] = [];
      let labelsArray: string[] = [];
      let lastDaysArray: number[] = [];

      data.forEach((datum: UsageOfDate, index: number) => {
        if (isLastDayOfMonth(new Date(datum.date))) {
          lastDaysArray.push(index);
        }

        labelsArray.push(datum.date);
        unitsArray.push(datum.units ? datum.units : 0);
      });

      setUnits(unitsArray);
      setLabels(labelsArray);
      setLastDays(lastDaysArray);
    }
  }, [data]);

  return (
    <Container extraStyling={extraStyling}>
      <Line
        data={{
          labels: labels ? labels : [],
          datasets: [
            {
              label: "",
              data: units ? units : [],
              borderColor: "rgba(76, 159, 206, 0.4)",
              pointBackgroundColor: $blue4,
            },
          ],
        }}
        options={{
          maintainAspectRatio: false,
          //@ts-ignore
          interaction: {
            intersect: false,
            mode: "index",
          },
          plugins: {
            legend: {
              display: false,
            },
            tooltip: {
              enabled: false,
              external: function (context: any) {
                // Tooltip Element
                var tooltipEl = document.getElementById(
                  "chartjs-tooltip-usage"
                );

                // Create element on first render
                if (!tooltipEl) {
                  tooltipEl = document.createElement("div");
                  tooltipEl.id = "chartjs-tooltip-usage";
                  tooltipEl.innerHTML = "<div></div>";
                  document.body.appendChild(tooltipEl);
                }

                // Hide if no tooltip
                var tooltipModel = context.tooltip;
                if (tooltipModel.opacity === 0) {
                  tooltipEl.style.display = "none";
                  return;
                }

                // Set caret Position
                tooltipEl.classList.remove("above", "below", "no-transform");
                if (tooltipModel.yAlign) {
                  tooltipEl.classList.add(tooltipModel.yAlign);
                } else {
                  tooltipEl.classList.add("no-transform");
                }

                function getBody(bodyItem: any) {
                  return bodyItem.lines;
                }

                // Set tooltip content
                if (tooltipModel.body) {
                  var bodyLines = tooltipModel.body.map(getBody).reverse();

                  let innerHtml: string = "";

                  // Invoice tag
                  if (isLastDayOfMonth(new Date(tooltipModel.title))) {
                    innerHtml += `<p style="color: #fff; margin: 0.25rem 0 0.5rem 0; padding: 0.15rem 0.5rem 0.2rem 0.5rem; background-color: ${$blue4}; border-radius: 0.2rem; width: fit-content;">Invoice</p>`;
                  }

                  // Title
                  innerHtml += `<p style="font-weight: 700;">${format(
                    new Date(tooltipModel.title),
                    "yyyy-MM-dd"
                  )}</p>`;

                  // Content
                  bodyLines.forEach(function (body: any, i: number) {
                    innerHtml += `<div class="block" style="display: flex; flex-direction: row; align-items: center; width: 100%">`;
                    const label = body[0];
                    innerHtml += `<p>${label} units</p>`;
                    innerHtml += `</div>`;
                  });

                  var tableRoot = tooltipEl.querySelector("div");
                  if (tableRoot) {
                    tableRoot.innerHTML = innerHtml;
                  }
                }

                var position = context.chart.canvas.getBoundingClientRect();

                // Display, position, and set styles for font
                tooltipEl.style.display = "flex";
                tooltipEl.style.position = "absolute";
                tooltipEl.style.left =
                  position.left +
                  window.pageXOffset +
                  tooltipModel.caretX +
                  "px";
                tooltipEl.style.top =
                  position.top +
                  window.pageYOffset +
                  tooltipModel.caretY +
                  "px";
                tooltipEl.style.padding =
                  tooltipModel.padding + "px " + tooltipModel.padding + "px";
                tooltipEl.style.borderRadius = "0.1rem";
                tooltipEl.style.display = "flex";
                tooltipEl.style.flexDirection = "row";
                tooltipEl.style.boxShadow =
                  "0px 1px 1px 1px rgba(0, 0, 0, 0.15)";
                tooltipEl.style.backgroundColor = "#fefefe";
                tooltipEl.style.padding = "0.5rem 0.75rem";
                tooltipEl.style.pointerEvents = "none";
                tooltipEl.style.color = $textLight;
              },
            },
          },
          scales: {
            //@ts-ignore
            x: {
              grid: {
                drawBorder: false,
                lineWidth: 1,
                borderDash: [10],
                color: function (context: any) {
                  // Has value (index) & label (text)
                  if (lastDays.includes(context.tick.value)) {
                    return $textLight;
                  }

                  return "rgba(0,0,0,0)";
                },
              },
              ticks: {
                color: $textLight,
                autoSkip: false,
                maxRotation: 0,
                minRotation: 0,
                //@ts-ignore
                callback: function (val: number, index: number) {
                  return lastDays.includes(index + 1) ||
                    lastDays.includes(index - 1)
                    ? labels
                      ? format(new Date(labels[index]), "MMM")
                      : ""
                    : "";
                },
              },
            },
            y: {
              title: {
                display: true,
                //@ts-ignore
                align: "start",
                text: "Units",
                color: $textLight,
                padding: 0,
              },
              ticks: {
                color: $textLight,
              },
            },
          },
        }}
      />
      {access &&
        (data && data.length === 0 ? (
          <Modal customText="You don't have any consumption data yet." />
        ) : (
          !data && (
            <Modal
              type={ModalType.DataLoadFailed}
              action={retryDataLoading}
              background
            />
          )
        ))}
    </Container>
  );
};
