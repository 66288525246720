import { ExtraStylingType } from "model/Styles";
import React, { FunctionComponent, useEffect, useState } from "react";
import styled, { css } from "styled-components";
import { $grey, lightText, smallText } from "styles/common";

interface Props {
  className?: string;
  id?: string;
  label: string;
  value: boolean;
  onChange(on: boolean): void;
  extraStyling?: ExtraStylingType;
}

const Container = styled.div<{
  extraStyling: ExtraStylingType;
}>`
  padding-left: 2.6875rem;
  position: relative;
  z-index: 1;
  display: block;
  min-height: 1.5rem;
  box-sizing: border-box;
  width: fit-content;

  ${(props) => props.extraStyling}
`;

const Checkbox = styled.input`
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  position: absolute;
  left: 0;
  z-index: -1;
  width: 1.25rem;
  height: 1.375rem;
  opacity: 0;
  overflow: visible;
`;

const Label = styled.label<{ checked: boolean }>`
  ${smallText}
  ${lightText}
  display: block;
  line-height: 1.4375rem;
  position: relative;
  margin-bottom: 0;
  vertical-align: top;

  &:before {
    left: -2.6875rem;
    width: 2.1875rem;
    pointer-events: all;
    border-radius: 0.625rem;
    transition: background-color 0.15s ease-in-out,
      border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    position: absolute;
    top: 0.125rem;
    display: block;
    height: 1.25rem;
    content: "";
    background-color: #fff;
    border: 1px solid ${$grey};
    box-shadow: none;
    box-sizing: border-box;

    ${(props) =>
      props.checked &&
      css`
        color: #b5e680;
        border-color: #b5e680;
        background-color: #b5e680;
      `}
  }

  &:after {
    top: calc(0.125rem + 2px);
    left: calc(-2.6875rem + 2px);
    width: calc(1.25rem - 4px);
    height: calc(1.25rem - 4px);
    background-color: #adb5bd;
    border-radius: 0.625rem;
    transition: transform 0.15s ease-in-out, background-color 0.15s ease-in-out,
      border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out,
      -webkit-transform 0.15s ease-in-out;
    background: no-repeat #adb5bd 50%/50% 50%;
    position: absolute;
    display: block;
    content: "";
    box-sizing: border-box;

    ${(props) =>
      props.checked &&
      css`
        background-color: #fff;
        transform: translateX(0.9375rem);
      `}
  }
`;

export const Switch: FunctionComponent<Props> = ({
  className = "",
  id = "",
  label,
  value,
  onChange,
  extraStyling = css``,
}) => {
  const [checked, setChecked] = useState(value);

  useEffect(() => {
    setChecked(value);
  }, [value]);

  return (
    <Container
      className={className}
      id={id}
      onClick={() => {
        onChange(!checked);
        setChecked(!checked);
      }}
      extraStyling={extraStyling}
    >
      <Checkbox type="checkbox" />
      <Label className="switch-label" checked={checked}>
        {label}
      </Label>
    </Container>
  );
};
