import Card from "components/common/Card";
import { ReportDoughnutChart } from "components/Reports/ReportDoughnutChart";
import { ReportIncidentsList } from "components/Reports/ReportIncidentsList";
import { ReportPieChart } from "components/Reports/ReportPieChart";
import ReportChangeRequestsList from "components/Reports/ReportChangeRequestsList";
import { inject, observer } from "mobx-react";
import { Route } from "model/Navigation";
import React, { Fragment, FunctionComponent, useEffect, useState } from "react";
import { Prompt } from "react-router";
import ReportStore from "stores/ReportStore";
import styled, { css } from "styled-components";
import {
  $grey,
  $statusFail,
  Button,
  chartColorPalette,
  Filter,
  Select,
  Span,
  StatusCircle,
  Text,
} from "styles/common";
import ReportProActiveWorkList from "components/Reports/ReportProActiveWorkList";
// import { ReportSLA } from "components/Reports/ReportSLA";
import { format } from "date-fns";
import AuthenticationStore from "stores/AuthenticationStore";
import { PermissionArea, PermissionType } from "model/Claim";
import { NoAccessModal } from "components/common/Modal";
import Loader from "components/common/Loader";

interface Props {
  reportStore: ReportStore;
  authenticationStore: AuthenticationStore;
}

const ResolvedHeader = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const Line = styled.div<{ access: boolean }>`
  position: absolute;
  top: 4.5rem;
  left: ${(props) => (props.access ? "23rem" : "33rem")};
  width: calc(100% - ${(props) => (props.access ? "23rem" : "33rem")});
  border-bottom: 1px solid ${$grey};
`;

const extraSummaryCardContentStyling = css`
  padding: 2.5rem 3rem 3.5rem 2.5rem;
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 3.5rem;
`;

const OpenedAndClosed = styled.div`
  display: flex;
  flex-direction: row;
  gap: 1rem;
  position: absolute;
  bottom: 1rem;
  left: 15rem;
`;

const MonthNote = styled(Text)`
  width: 100%;
  text-align: right;
  color: #8e969c;
  position: absolute;
  bottom: 1rem;
  right: 1.25rem;
`;

const ChangeRequestNote = styled(Text)`
  margin-top: 2rem;
  padding: 0.6rem 0.85rem;
  background-color: #dee7eb;
  border-radius: 0.2rem;
  width: max-content;
`;

const NoMonths = styled(Text)`
  margin-top: 1rem;
  font-size: 1.125rem;
`;

const Reports: FunctionComponent<Props> = ({
  reportStore,
  authenticationStore,
}) => {
  const [dataLoaded, setDataLoaded] = useState(false);

  const accessViewReport = authenticationStore.userHasPermission(
    PermissionArea.Report,
    PermissionType.View
  );

  const [selectedMonth, setSelectedMonth] = useState<Date | undefined>(
    undefined
  );

  useEffect(() => {
    if (accessViewReport && !reportStore.monthsList) {
      reportStore.getReportStartDate().finally(() => {
        if (reportStore.monthsList && reportStore.monthsList.length === 0) {
          setDataLoaded(true);
        }
      });
    }
  }, [accessViewReport, reportStore]);

  useEffect(() => {
    if (
      accessViewReport &&
      !reportStore.currentMonthData &&
      reportStore.monthsList &&
      reportStore.monthsList.length > 0
    ) {
      const lastReportMonthIndex = reportStore.monthsList.length - 1;
      setSelectedMonth(reportStore.monthsList[lastReportMonthIndex]);
      reportStore.getReportOfMonth(reportStore.monthsList[lastReportMonthIndex]).finally(() => {
        setDataLoaded(true);
      });
    }
  }, [accessViewReport, reportStore, reportStore.monthsList]);

  const handleMonthSelection = () => {
    selectedMonth && reportStore.getReportOfMonth(selectedMonth);
  };

  const handleDataReload = () => {
    accessViewReport &&
      (!reportStore.monthsList
        ? reportStore.getReportStartDate()
        : reportStore.getReportOfMonth(reportStore.monthsList[reportStore.monthsList.length - 1]));
  };

  const renderDoughnutChart = (
    <ReportDoughnutChart
      data={
        reportStore.currentMonthData
          ? [
            reportStore.currentMonthData.incidents.total,
            reportStore.currentMonthData.proActiveWork.total,
            reportStore.currentMonthData.changeRequests.total,
          ]
          : undefined
      }
      extraStyling={css`
        margin-right: 3rem;
      `}
    />
  );

  const renderPieChartIncidents = (
    <ReportPieChart
      title={
        reportStore.currentMonthData
          ? `Incidents: ${reportStore.currentMonthData.incidents.total}`
          : "Incidents"
      }
      color={chartColorPalette[0]}
      data={
        reportStore.currentMonthData
          ? reportStore.currentMonthData.incidents
          : undefined
      }
      access={accessViewReport}
      retryDataLoading={handleDataReload}
    />
  );

  const renderPieChartProActiveWork = (
    <ReportPieChart
      title={
        reportStore.currentMonthData
          ? `Pro-active work: ${reportStore.currentMonthData.proActiveWork.total}`
          : "Pro-active work"
      }
      color={chartColorPalette[1]}
      data={
        reportStore.currentMonthData
          ? reportStore.currentMonthData.proActiveWork
          : undefined
      }
      access={accessViewReport}
      retryDataLoading={handleDataReload}
    />
  );

  const renderPieChartChangeRequests = (
    <ReportPieChart
      title={
        reportStore.currentMonthData
          ? `Change requests: ${reportStore.currentMonthData.changeRequests.total}`
          : "Change requests"
      }
      color={chartColorPalette[2]}
      data={
        reportStore.currentMonthData
          ? reportStore.currentMonthData.changeRequests
          : undefined
      }
      access={accessViewReport}
      retryDataLoading={handleDataReload}
    />
  );

  return (
    <>
      <Prompt
        message={(location) => {
          if (!location.pathname.startsWith(Route.Reports))
            // Reset data when leaving page
            reportStore.reset();

          return true;
        }}
      />
      <Filter>
        <Text large semibold dark>
          Activity Report from
        </Text>
        {reportStore.monthsList && reportStore.monthsList.length > 0 && (
          <Fragment>
            <Select
              defaultValue={selectedMonth?.toString()}
              value={selectedMonth?.toString()}
              highlight
              onChange={(e) => {
                setSelectedMonth(new Date(e.target.value));
              }}
            >
              {reportStore.monthsList &&
                reportStore.monthsList.map((month, index) => (
                  <option key={index} value={month.toString()}>
                    {format(month, "yyyy-MM")}
                  </option>
                ))}
            </Select>
            <Text large semibold dark>
              until today
            </Text>
            <Button onClick={handleMonthSelection}>Show details</Button>
          </Fragment>
        )}
      </Filter>

      {reportStore.monthsList && reportStore.monthsList.length > 0 ? (
        <>
          <Card extraContentStyling={extraSummaryCardContentStyling}>
            {!dataLoaded ? (
              <Loader component />
            ) : (
              <>
                <Line access={accessViewReport} />

                {!accessViewReport && <NoAccessModal />}

                {(!accessViewReport || dataLoaded) && (
                  <>
                    {renderDoughnutChart}

                    {renderPieChartIncidents}
                    {renderPieChartProActiveWork}
                    {renderPieChartChangeRequests}

                    {reportStore.currentMonthData && (
                      <MonthNote small>
                        <Span color={$statusFail}>(*)</Span> during the selected time range
                      </MonthNote>
                    )}

                    {reportStore.currentMonthData && (
                      <OpenedAndClosed>
                        <Text small dark>
                          Opened<Span color={$statusFail}>*</Span>:{" "}
                          <Span dark semibold>
                            {reportStore.currentMonthData.openedTickets}
                          </Span>
                        </Text>
                        <Text small dark>
                          Closed<Span color={$statusFail}>*</Span>:{" "}
                          <Span dark semibold>
                            {reportStore.currentMonthData.closedTickets}
                          </Span>
                        </Text>
                      </OpenedAndClosed>
                    )}
                  </>
                )}
              </>
            )}
          </Card>

          <Card
            header={
              <ResolvedHeader>
                <StatusCircle color={chartColorPalette[0]} size="12px" />
                <Span
                  large
                  dark
                  semibold
                  uppercase
                  extraStyling={css`
                    margin-right: 0.5rem;
                  `}
                >
                  Resolved incidents and problems
                </Span>
                <Span dark small>
                  (during the selected time range)
                </Span>
              </ResolvedHeader>
            }
            collapsable
          >
            {!dataLoaded ? (
              <Loader component />
            ) : (
              <>
                {!accessViewReport && <NoAccessModal />}

                <ReportIncidentsList
                  incidents={
                    reportStore.currentMonthData
                      ? reportStore.currentMonthData.incidents.resolvedList
                      : undefined
                  }
                  access={accessViewReport}
                  retryDataLoading={handleDataReload}
                />

                {/* {accessViewReport &&
                  selectedMonth &&
                  reportStore.currentMonthData && (
                    <ReportSLA
                      result={reportStore.currentMonthData.sla.result}
                      month={selectedMonth}
                      levels={reportStore.currentMonthData.sla.levels}
                      retryDataLoading={handleDataReload}
                    />
                  )} */}
              </>
            )}
          </Card>

          <Card
            header={
              <p>
                <StatusCircle color={chartColorPalette[0]} size="12px" />
                Open incidents and problems
              </p>
            }
            collapsable
          >
            {!dataLoaded ? (
              <Loader component />
            ) : (
              <>
                {!accessViewReport && <NoAccessModal />}

                <ReportIncidentsList
                  incidents={
                    reportStore.currentMonthData
                      ? reportStore.currentMonthData.incidents.openList
                      : undefined
                  }
                  access={accessViewReport}
                  retryDataLoading={handleDataReload}
                />
              </>
            )}
          </Card>

          <Card
            header={
              <p>
                <StatusCircle color={chartColorPalette[1]} size="12px" />
                Pro-active work
              </p>
            }
            collapsable
            extraContentStyling={css`
              padding-bottom: 1.75rem;
            `}
          >
            {!dataLoaded ? (
              <Loader component />
            ) : (
              <>
                {!accessViewReport && <NoAccessModal />}

                <Text semibold>Work performed as part of the service</Text>

                <ReportProActiveWorkList
                  proActiveWorkItems={
                    reportStore.currentMonthData
                      ? reportStore.currentMonthData.proActiveWork.list
                      : undefined
                  }
                  access={accessViewReport}
                  retryDataLoading={handleDataReload}
                />
              </>
            )}
          </Card>

          <Card
            header={
              <p>
                <StatusCircle color={chartColorPalette[2]} size="12px" />
                Change requests
              </p>
            }
            collapsable
            extraContentStyling={css`
              padding-bottom: 1.75rem;
            `}
          >
            {!dataLoaded ? (
              <Loader component />
            ) : (
              <>
                {!accessViewReport && <NoAccessModal />}

                <Text semibold>
                  Additional work Cegeka has performed after your approval
                </Text>

                <ReportChangeRequestsList
                  month={selectedMonth}
                  changeRequests={
                    reportStore.currentMonthData
                      ? reportStore.currentMonthData.changeRequests.list
                      : undefined
                  }
                  disputeEmail={
                    reportStore.currentMonthData
                      ? reportStore.currentMonthData.changeRequests.disputeEmail
                      : ""
                  }
                  access={accessViewReport}
                  retryDataLoading={handleDataReload}
                />

                {accessViewReport && (
                  <ChangeRequestNote small dark>
                    Note: Change Requests are billable and processed as part of
                    the billing cycle. The list of Change Requests will be final
                    as soon as you receive your bill.
                  </ChangeRequestNote>
                )}
              </>
            )}
          </Card>
        </>
      ) : (
        <Card
          extraContentStyling={css`
            text-align: center;
            margin: 1rem 0 2rem;
          `}
        >
          <img
            src="/static/media/no-data-icon.fd032bb9.svg"
            alt="no data icon"
          />
          <NoMonths large dark>
            There are no reports available yet.
          </NoMonths>
        </Card>
      )}
    </>
  );
};

export default inject(
  "reportStore",
  "authenticationStore"
)(
  observer(
    Reports as FunctionComponent<
      Omit<Props, "reportStore" | "authenticationStore">
    >
  )
);
