import AuthenticationStore from "./AuthenticationStore";
import AuthenticationApi from "services/apis/AuthenticationApi";
import GeneralStore from "./GeneralStore";
import GeneralApi from "services/apis/GeneralApi";
import IntegrationStore from "./IntegrationStore";
import IntegrationApi from "services/apis/IntegrationApi";
import NavigationStore from "./NavigationStore";
import UsageStore from "./UsageStore";
import UsageApi from "services/apis/UsageApi";
import ApplicationStore from "./ApplicationStore";
import ApplicationApi from "services/apis/ApplicationApi";
import ItOperationStore from "./ItOperationStore";
import ItOperationApi from "services/apis/ItOperationApi";
import ReportStore from "./ReportStore";
import ReportApi from "services/apis/ReportApi";
import SettingStore from "./SettingStore";
import SettingApi from "services/apis/SettingApi";
import CustomerManagementStore from "./CustomerManagementStore";
import CustomerManagementApi from "services/apis/CustomerManagementApi";
import LoaderStore from "./LoaderStore";
import ExecutionStore from "./ExecutionStore";
import ExecutionApi from "services/apis/ExecutionApi";

export interface AllStores {
  authenticationStore: AuthenticationStore;
  loaderStore: LoaderStore;
  navigationStore: NavigationStore;
  generalStore: GeneralStore;
  integrationStore: IntegrationStore;
  executionStore : ExecutionStore;
  itOperationStore: ItOperationStore;
  applicationStore: ApplicationStore;
  usageStore: UsageStore;
  reportStore: ReportStore;
  settingStore: SettingStore;
  customerManagementStore: CustomerManagementStore;
}

export const allStores = () => {
  const loaderStore = new LoaderStore();
  const navigationStore = new NavigationStore();

  const generalStore = new GeneralStore(GeneralApi, loaderStore);

  const authenticationStore = new AuthenticationStore(
    AuthenticationApi,
    loaderStore,
    generalStore
  );

  const integrationStore = new IntegrationStore(
    IntegrationApi,
    generalStore,
    loaderStore
  );
  const executionStore = new ExecutionStore(
    ExecutionApi,
    generalStore,
    loaderStore
  );
  const itOperationStore = new ItOperationStore(
    ItOperationApi,
    generalStore,
    loaderStore
  );
  const applicationStore = new ApplicationStore(
    ApplicationApi,
    generalStore,
    loaderStore
  );
  const usageStore = new UsageStore(UsageApi, loaderStore);
  const reportStore = new ReportStore(
    ReportApi,
    authenticationStore,
    loaderStore
  );
  const settingStore = new SettingStore(SettingApi, loaderStore);
  const customerManagementStore = new CustomerManagementStore(
    CustomerManagementApi,
    loaderStore
  );

  return {
    loaderStore,
    authenticationStore,
    navigationStore,
    generalStore,
    integrationStore,
    executionStore,
    itOperationStore,
    applicationStore,
    usageStore,
    reportStore,
    settingStore,
    customerManagementStore,
  };
};

export default allStores();
