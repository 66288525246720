import IconComponent from "components/common/Icon";
import { Icon } from "model/Icon";
import React from "react";
import { css } from "styled-components";
import { $blue4, $grey } from "styles/common";

interface Props {
  checked: boolean;
  disabled?: boolean;
  clickable?: boolean;
  className?: string;
  onClick?: () => void;
}

export const TableCheckbox = ({
  checked,
  disabled = false,
  clickable,
  className = "",
  onClick,
}: Props) => {
  return (
    <IconComponent
      className={className}
      name={checked ? Icon.check : Icon.unchecked}
      color={disabled ? $grey : $blue4}
      disabled={disabled}
      onClick={(e) => {
        if (!disabled && clickable && !!onClick) {
          e.stopPropagation();
          onClick();
        }
      }}
      extraStyling={css`
        width: max-content;
        cursor: default;

        ${disabled
          ? css`
              opacity: 0.8;
            `
          : clickable
          ? css`
              cursor: pointer;
            `
          : css``}
      `}
    />
  );
};
