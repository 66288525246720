import { action, makeObservable, observable } from "mobx";
import { PermissionArea, PermissionType } from "model/Claim";
import { Subscription, UserPermissionsInfo } from "model/User";
import { AuthenticationApi } from "services/apis/AuthenticationApi";
import GeneralStore from "./GeneralStore";
import LoaderStore from "./LoaderStore";

class AuthenticationStore {
  @observable
  userPermissionsInfo: UserPermissionsInfo | undefined = undefined;

  @observable
  currentSubscription: Subscription | undefined = undefined;

  constructor(public api: AuthenticationApi, private loaderStore: LoaderStore, private generalStore: GeneralStore) {
    makeObservable(this);
  }

  @action
  getUserPermissionsInfo = async () => {
    // this.userPermissionsInfo = userPermissionsInfoMockData;
    // this.currentSubscription = this.getDefaultSubscription();

    this.loaderStore.setLoading();

    const response = await this.api.getUserPermissionsInfo();

    if (response.success) {
      const { data } = response;
      this.userPermissionsInfo = data;
      this.currentSubscription = this.getDefaultSubscription();
    } else {
      //const { errorData } = response;
      console.log("Something went wrong when trying to fetch user claims");
    }

    this.loaderStore.stopLoading();
  };

  @action
  setCurrentSubscriptions = (subscription: Subscription) => {
    this.currentSubscription = subscription;
  };

  //TODO: Improve code
  userHasRouteOptionAccess = (accessOptions: string[] | undefined) => {
    let access = true;

    accessOptions &&
      accessOptions.forEach((accessOption: string) => {
        if (this.userPermissionsInfo) {
          const options = this.userPermissionsInfo.options as unknown as {
            [key: string]: any;
          }[];
          if (!options[accessOption as any]) {
            access = false;
          }
        }
      });

    return access;
  };

  userHasPermission = (
    permissionArea: PermissionArea,
    permissionType: PermissionType
  ) => {
    let access = false;

    this.userPermissionsInfo?.permissions.forEach((permission) => {
      if (permission === `${permissionArea}.${permissionType}`) {
        access = true;
      }
    });

    return access;
  };

  userHasRoutePermission = (routePermissions: PermissionArea[]) => {
    let access = false;

    routePermissions.forEach((routePermission: PermissionArea) => {
      if (this.userHasPermission(routePermission, PermissionType.View)) {
        access = true;
        return;
      }
    });

    return access;
  };

  getDefaultSubscription = () => {
    let sub: Subscription | undefined = undefined;

    if (this.userPermissionsInfo) {
      this.userPermissionsInfo.subscriptions.forEach((subscription) => {
        if (subscription.isDefault) {
          sub = subscription;
          return;
        }
      });
    }

    return sub;
  };

  switchToCustomer = async (subscriptionId: number) => {
    this.loaderStore.setLoading();

    const response = await this.api.switchToCustomer(subscriptionId);

    if (response.success) {
      this.loaderStore.stopLoading();
      this.getUserPermissionsInfo();
      this.generalStore.getEnvironments();
      return true;
    } else {
      //const { errorData } = response;
      console.log("Something went wrong when trying to switch customer");
      this.loaderStore.stopLoading();
      return false;
    }
  };

  @action
  reset = () => {
    this.userPermissionsInfo = undefined;
    this.currentSubscription = undefined;
  };
}

export default AuthenticationStore;
