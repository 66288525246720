import { List } from "components/common/List";
import {
  SettingsIncidentsApplicationOrGroupContact,
  SimpleSettingsContact,
} from "model/Contact";
import React, { useState } from "react";
import {
  Text,
  IconButton,
  $textDark,
  $textLight,
  Button,
  $blue4,
  StyledReactTooltip,
  TooltipText,
  InfoIconWrapper,
} from "styles/common";
import { TableCheckbox } from "components/common/TableCheckbox";
import { moveDownInArray, moveUpInArray } from "utils/Array";
import IconComponent from "components/common/Icon";
import { Icon } from "model/Icon";
import { css } from "styled-components";
import { SettingApplicationGroupContactModal } from "components/Settings/SettingApplicationGroupContactModal";
import {
  CenteredCellContainer,
  DeleteButton,
  EditButton,
  ListHeaderText,
} from "components/common/List/common";

interface Props {
  contactAreas: string[] | undefined;
  supportWindows: string[] | undefined;
  contacts: SimpleSettingsContact[] | undefined;
  applicationOrGroupContacts:
    | SettingsIncidentsApplicationOrGroupContact[]
    | undefined;
  viewAccess: boolean;
  editAccess: boolean;
  deleteAccess: boolean;
  editApplicationOrGroupContacts(
    data: SettingsIncidentsApplicationOrGroupContact[]
  ): void;
  retryDataLoading(): void;
}

const baseHeaders = [
  "#",
  "Area",
  "Name",
  "Support window",
  "Technical",
  <CenteredCellContainer>
    <ListHeaderText>Chase</ListHeaderText>
  </CenteredCellContainer>,
  "Functional",
  <CenteredCellContainer>
    <ListHeaderText>Chase</ListHeaderText>
  </CenteredCellContainer>,
];

export const SettingApplicationGroupContactsList = ({
  contactAreas,
  supportWindows,
  contacts,
  applicationOrGroupContacts,
  viewAccess,
  editAccess,
  deleteAccess,
  editApplicationOrGroupContacts,
  retryDataLoading,
}: Props) => {
  const [
    showEditOrAddApplicationGroupContact,
    setShowEditOrAddApplicationGroupContact,
  ] = useState(false);
  const [selectedApplicationGroupContact, setSelectedApplicationGroupContact] =
    useState<SettingsIncidentsApplicationOrGroupContact | undefined>(undefined);

  const getApplicationOrGroupContactCells = () =>
    applicationOrGroupContacts
      ? applicationOrGroupContacts.map((applicationOrGroupContact, index) => {
          const baseCells = [
            index + 1,
            applicationOrGroupContact.area,
            applicationOrGroupContact.name,
            applicationOrGroupContact.supportWindow,
            applicationOrGroupContact.technicalContact.contact
              ? applicationOrGroupContact.technicalContact.contact.contact
              : "(ignore)",
            <CenteredCellContainer>
              <TableCheckbox
                checked={applicationOrGroupContact.technicalContact.chase}
                disabled={
                  applicationOrGroupContact.technicalContact.contact ===
                  undefined
                }
              />
            </CenteredCellContainer>,
            applicationOrGroupContact.functionalContact.contact
              ? applicationOrGroupContact.functionalContact.contact.contact
              : "(ignore)",
            <CenteredCellContainer>
              <TableCheckbox
                checked={applicationOrGroupContact.functionalContact.chase}
                disabled={
                  applicationOrGroupContact.functionalContact.contact ===
                  undefined
                }
              />
            </CenteredCellContainer>,
          ];

          const editButtons = [
            <IconButton
              disabled={index === 0}
              hoverColor={"#000"}
              onClick={(e) => {
                e.stopPropagation();
                editApplicationOrGroupContacts(
                  moveUpInArray(applicationOrGroupContact, [
                    ...applicationOrGroupContacts,
                  ])
                );
              }}
            >
              <IconComponent
                name={Icon.arrowdown}
                height="12px"
                color={$textDark}
                extraStyling={
                  index === 0
                    ? css`
                        transform: rotate(180deg);
                        cursor: default;
                        opacity: 0.5;
                      `
                    : css`
                        transform: rotate(180deg);
                      `
                }
              />
            </IconButton>,
            <IconButton
              disabled={index === applicationOrGroupContacts.length - 1}
              hoverColor={"#000"}
              onClick={(e) => {
                e.stopPropagation();
                editApplicationOrGroupContacts(
                  moveDownInArray(applicationOrGroupContact, [
                    ...applicationOrGroupContacts,
                  ])
                );
              }}
            >
              <IconComponent
                name={Icon.arrowdown}
                height="12px"
                color={$textDark}
                extraStyling={
                  index === applicationOrGroupContacts.length - 1
                    ? css`
                        cursor: default;
                        opacity: 0.5;
                      `
                    : css``
                }
              />
            </IconButton>,
            <EditButton
              className="edit-button"
              onClick={() => {
                setSelectedApplicationGroupContact(applicationOrGroupContact);
                setShowEditOrAddApplicationGroupContact(true);
              }}
            />,
          ];

          const deleteButton = (
            <DeleteButton
              className="delete-button"
              onClick={() => {
                editApplicationOrGroupContacts([
                  ...applicationOrGroupContacts.filter(
                    (c) => c !== applicationOrGroupContact
                  ),
                ]);
              }}
            />
          );

          return {
            cells:
              editAccess && deleteAccess
                ? [...baseCells, ...editButtons, deleteButton]
                : editAccess
                ? [...baseCells, ...editButtons]
                : deleteAccess
                ? [...baseCells, deleteButton]
                : baseCells,
          };
        })
      : undefined;

  const baseColumns = [2, 5, 7, 7, 7, 6, 7, 6];
  const editColumns = [1, 2, 3];
  const deleteColumn = 4;

  return (
    <>
      {contactAreas && supportWindows && contacts && (
        <>
          <InfoIconWrapper
            extraStyling={css`
              margin-bottom: 1rem;
            `}
          >
            {editAccess && (
              <Button
                className="add-button"
                disabled={
                  contactAreas.length === 0 || supportWindows.length === 0
                }
                onClick={() => setShowEditOrAddApplicationGroupContact(true)}
              >
                Add contact
              </Button>
            )}
            <div data-for="application-group-contacts-info-tooltip" data-tip="">
              <IconComponent name={Icon.info} color={$blue4} />
            </div>
          </InfoIconWrapper>
          <StyledReactTooltip
            id="application-group-contacts-info-tooltip"
            effect="solid"
            place="right"
            type="light"
            border={false}
            textColor={$textLight}
            backgroundColor={"#fff"}
            arrowColor={"transparent"}
          >
            <TooltipText>
              <Text>
                {editAccess || deleteAccess ? "Define contacts " : "Contacts"}{" "}
                for a single Application or Workflow group.
              </Text>
              <Text>Contacts are evaluated in the displayed order.</Text>
            </TooltipText>
          </StyledReactTooltip>
        </>
      )}
      <List
        headers={
          editAccess && deleteAccess
            ? [...baseHeaders, "", "", "", ""]
            : editAccess
            ? [...baseHeaders, "", "", ""]
            : deleteAccess
            ? [...baseHeaders, ""]
            : baseHeaders
        }
        data={getApplicationOrGroupContactCells()}
        access={viewAccess}
        noDataText={
          editAccess
            ? "You haven't defined any specific contacts yet."
            : "There are no specific contacts defined yet."
        }
        hover
        smallEmpty
        customColumnWidths={
          editAccess && deleteAccess
            ? [...baseColumns, ...editColumns, deleteColumn]
            : editAccess
            ? [...baseColumns, ...editColumns]
            : deleteAccess
            ? [...baseColumns, deleteColumn]
            : baseColumns
        }
        retryDataLoading={retryDataLoading}
      />

      {contactAreas &&
        supportWindows &&
        contacts &&
        showEditOrAddApplicationGroupContact && (
          <SettingApplicationGroupContactModal
            contacts={contacts}
            contactAreas={contactAreas}
            supportWindows={supportWindows}
            originalContact={selectedApplicationGroupContact}
            close={() => {
              setSelectedApplicationGroupContact(undefined);
              setShowEditOrAddApplicationGroupContact(false);
            }}
            add={(newContact: SettingsIncidentsApplicationOrGroupContact) =>
              editApplicationOrGroupContacts([
                ...(applicationOrGroupContacts
                  ? applicationOrGroupContacts
                  : []),
                newContact,
              ])
            }
            edit={(editedContact: SettingsIncidentsApplicationOrGroupContact) =>
              applicationOrGroupContacts &&
              editApplicationOrGroupContacts(
                applicationOrGroupContacts.map((c) =>
                  c.id === editedContact.id ? editedContact : c
                )
              )
            }
          />
        )}
    </>
  );
};
