import IconComponent from "components/common/Icon";
import React, { FunctionComponent } from "react";
import { List } from "components/common/List";
import styled from "styled-components";
import { ReportChangeRequest } from "model/Report";
import { $blue4, Text, iconButtonStyling } from "styles/common";
import { Icon } from "model/Icon";
import { format } from "date-fns";

interface Props {
  month: Date | undefined;
  changeRequests: ReportChangeRequest[] | undefined;
  disputeEmail: string;
  access: boolean;
  retryDataLoading(): void;
}

const Container = styled.div`
  margin-top: 1rem;
`;

const ButtonContainer = styled.a`
  ${iconButtonStyling}
`;

const ReportChangeRequestsList: FunctionComponent<Props> = ({
  month,
  changeRequests,
  disputeEmail,
  access,
  retryDataLoading,
}) => {
  const handleTicketDispute = (ticketNumber: string) => {};

  return (
    <Container>
      <List
        headers={[
          "Number",
          "Title",
          "Requested by",
          "Workorder",
          /*"Hours", */
          "",
        ]}
        data={
          changeRequests
            ? changeRequests.map((request: ReportChangeRequest) => {
                return {
                  cells: [
                    request.ticket,
                    request.title,
                    request.requestedBy,
                    request.workOrder,
                    //request.hours,
                    request.canDispute && month && disputeEmail ? (
                      <ButtonContainer
                        href={`mailto:${disputeEmail}?subject=Bill for month ${format(
                          month,
                          "yyyy-MM"
                        )}: Question regarding CR ${request.ticket}`}
                        disabled={!request.canDispute}
                        onClick={(e) => {
                          e.stopPropagation();
                          handleTicketDispute(request.ticket);
                        }}
                      >
                        <IconComponent name={Icon.dispute} color={$blue4} />
                        <Text small>Contact</Text>
                      </ButtonContainer>
                    ) : (
                      ""
                    ),
                  ],
                };
              })
            : undefined
        }
        customColumnWidths={[1, 5, 1, /*2,*/ 1]}
        hover
        access={access}
        noDataText="You don't have any change requests for this month."
        smallEmpty
        onRowClick={(rowId: number) => {
          changeRequests &&
            window.open(
              `https://horizon.cegeka.com/collaboration/ticket/${changeRequests[rowId].ticket}`
            );
        }}
        retryDataLoading={retryDataLoading}
      />
    </Container>
  );
};

export default ReportChangeRequestsList;
