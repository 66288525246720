import IconComponent from "components/common/Icon";
import { List } from "components/common/List";
import { Units } from "components/Usage/UsagePerMonth";
import { UsageDeploymentArea } from "model/DeploymentArea";
import { UsageEnvironment } from "model/Environment";
import { Icon } from "model/Icon";
import {
  UsagePlatformComponentType,
  UsagePlatformComponent,
} from "model/PlatformComponent";
import { BasicUsageTreeItem } from "model/Usage";
import React, { FunctionComponent, useState } from "react";
import styled, { css } from "styled-components";
import { Text } from "styles/common";

export interface Props {
  data: UsageEnvironment[];
}

export interface TreeItemProps {
  className?: string;
  openState: boolean;
  item: BasicUsageTreeItem;
  subItems: any;
  firstLevel?: boolean;
}

const Container = styled.div`
  margin: 1rem 0 1.5rem 0;
`;

const Environments = styled.div``;

const ItemContainer = styled.div<{ firstLevel?: boolean }>`
  position: relative;

  ${(props) =>
    props.firstLevel &&
    css`
      margin-bottom: 0.5rem;
    `}
`;

const Hook = styled.div`
  width: 0.75rem;
  margin-right: 0.25rem;
  height: 1px;
  background-color: #d8eaf4;
`;

const ItemBar = styled.div<{ firstLevel?: boolean }>`
  padding: 0.3rem 0.75rem 0.3rem 0rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  cursor: pointer;
  transition: 0.2s;

  ${(props) =>
    props.firstLevel &&
    css`
      background-color: #edf6fb;
      padding-left: 0.3rem;
    `}

  &:hover {
    background-color: #d8eaf4;
  }
`;

const ItemNameContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const ItemName = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.5rem;
  cursor: pointer;
`;

const SubItemsContainer = styled.div<{ opened: boolean; firstLevel?: boolean }>`
  transition: 2s;
  margin-left: ${(props) => (props.firstLevel ? `0.8rem` : `1.5rem`)};
  border-left: 1px solid #d8eaf4;

  ${(props) =>
    props.opened
      ? css`
          display: block;
        `
      : css`
          display: none;
        `}
`;

const NoData = styled(Text)`
  padding: 0.3rem 0.75rem 0.3rem 0.9rem;
  margin-bottom: 0.25rem;
`;

const TreeItem: FunctionComponent<TreeItemProps> = ({
  className,
  item,
  subItems,
  openState,
  firstLevel,
}) => {
  const [opened, setOpened] = useState(openState);

  const handleItemClick = () => {
    setOpened(!opened);
  };

  return (
    <ItemContainer
      firstLevel={firstLevel}
      className={`usage-tree-item ${className}`}
    >
      <ItemBar
        firstLevel={firstLevel}
        onClick={handleItemClick}
        className="usage-tree-item-bar"
      >
        <ItemNameContainer>
          {!firstLevel && <Hook />}
          <ItemName>
            <IconComponent
              name={opened ? Icon.minus : Icon.plus}
              className={`expand-icon ${opened ? "icon-minus" : "icon-plus"}`}
            />
            <Text dark semibold={firstLevel}>
              {item.name}
            </Text>
            <Text small>({item.itemCount} items)</Text>
          </ItemName>
        </ItemNameContainer>
        <Text semibold={firstLevel}>
          <Units>{item.units}</Units> units
        </Text>
      </ItemBar>
      <SubItemsContainer
        opened={opened}
        firstLevel={firstLevel}
        className="usage-tree-sub-items"
      >
        {subItems}
      </SubItemsContainer>
    </ItemContainer>
  );
};

const listHeaders = ["Name", "Type", "SLA", "Units"];

const UsageTree = ({ data }: Props) => {
  const renderPlatformComponentsList = (
    platformComponentType: UsagePlatformComponentType
  ) =>
    platformComponentType.platformComponents.length === 0 ? (
      <NoData small>No components</NoData>
    ) : (
      <List
        headers={listHeaders}
        headerBackground
        data={platformComponentType.platformComponents.map(
          (platformComponent: UsagePlatformComponent) => ({
            cells: [
              platformComponent.name,
              platformComponent.type,
              platformComponent.sla,
              <Text>{platformComponent.units} units</Text>,
            ],
          })
        )}
        customColumnWidths={[5, 2, 3, 1]}
        hover
        noDataText="You don't have any environments yet"
        retryDataLoading={() => {}}
        extraStyling={css`
          padding-left: 1rem;
          margin: 0.5rem 0 1rem 0;
          width: 53rem;
        `}
      />
    );

  const renderPlatformComponentTypes = (deploymentArea: UsageDeploymentArea) =>
    deploymentArea.platformComponentTypes.length === 0 ? (
      <NoData small>No component types</NoData>
    ) : (
      deploymentArea.platformComponentTypes.map(
        (
          platformComponentType: UsagePlatformComponentType,
          pctIndex: number
        ) => (
          <TreeItem
            key={pctIndex}
            className="usage-tree-platform-component-type"
            openState={false}
            item={platformComponentType}
            subItems={
              <div className="usage-tree-platform-components-list">
                {renderPlatformComponentsList(platformComponentType)}
              </div>
            }
          />
        )
      )
    );

  const renderDeploymentAreas = (environment: UsageEnvironment) =>
    environment.deploymentAreas.length === 0 ? (
      <NoData small>No deployment areas</NoData>
    ) : (
      environment.deploymentAreas.map(
        (deploymentArea: UsageDeploymentArea, daIndex: number) => (
          <TreeItem
            key={daIndex}
            className="usage-tree-deployment-area"
            openState={true}
            item={deploymentArea}
            subItems={
              <div className="usage-tree-platform-component-types">
                {renderPlatformComponentTypes(deploymentArea)}
              </div>
            }
          />
        )
      )
    );

  return (
    <Container>
      <Environments className="usage-tree-environments">
        {data.map((environment: UsageEnvironment, index: number) => (
          <TreeItem
            key={index}
            className="usage-tree-environment"
            openState={true}
            firstLevel={true}
            item={{ ...environment, name: `Environment "${environment.name}"` }}
            subItems={
              <div className="usage-tree-deployment-areas">
                {renderDeploymentAreas(environment)}
              </div>
            }
          />
        ))}
      </Environments>
    </Container>
  );
};

export default UsageTree as FunctionComponent<Props>;
