import IconComponent from "components/common/Icon";
import { Icon } from "model/Icon";
import { IntegrationLogicalGroup } from "model/LogicalGroup";
import { ExecutionSpeedStatus, executionSpeedStatuses } from "model/Status";
import React from "react";
import styled, { css } from "styled-components";
import {
  $textDark,
  $textLight,
  darkText,
  StatusCircle,
  Text,
} from "styles/common";
import {
  convertExecutions,
  getExecutionsString,
  round,
} from "utils/Integration";
import { getSuccessStatusColor } from "utils/Status";
import { maxTilesPerPage, maxWidgetTiles } from "..";
import Loader from "components/common/Loader";
import { Modal, ModalType } from "components/common/Modal";
import {
  NoSearchResults,
  noSearchResultsText,
} from "components/common/List/common";

interface Props {
  itemsToRender: IntegrationLogicalGroup[] | undefined;
  currentPage: number;
  totalItemsOnLevel: number;
  widget: boolean;
  access: boolean;
  loading: boolean;
  searchMode: boolean;
  isTopLevel(): boolean | undefined;
  goLevelUp(): void;
  onLeaveWidget(): void;
  onItemClick(item: IntegrationLogicalGroup): void;
  retryDataLoading(): void;
}

const TilesContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  position: relative;
  width: 100%;
  height: 100%;
`;

const tileWidth = "262px";
const baseTileHeight = 175;
const tileHeight = `${baseTileHeight}px`;
const barWidth = "0.3rem";

const basicTileStyling = css`
  min-width: ${tileWidth};
  max-width: ${tileWidth};
  min-height: ${tileHeight};
  max-height: ${tileHeight};
  margin: 0 1rem 1rem 0;
  border-radius: 0.2rem;
  position: relative;
  box-sizing: border-box;
`;

const Tile = styled.div<{ status?: number; color?: string }>`
  ${basicTileStyling}
  padding: 0.9rem 1.1rem;
  background-color: #f7fafc;
  cursor: pointer;
  transition: 0.2s;

  ${(props) =>
    props.status !== undefined
      ? css`
          box-shadow: 1px 1px 3px 1px rgba(0, 0, 0, 0.1);

          &:hover {
            .executionsBar {
              width: 1.5rem;
            }

            .totalExecutions {
              right: calc(${barWidth} + 1rem + 1.5rem);
            }

            .barCard {
              opacity: 1;
              display: block;
            }
          }
        `
      : css`
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          background-color: #f2f7f9;
          &:hover {
            opacity: 0.85;
          }
        `}
`;

const EmptyTile = styled.div`
  ${basicTileStyling}
  background-color: #fafbfc;
`;

const Title = styled(Text)`
  width: 90%;
`;

const ExecutionsPerUnit = styled.div`
  position: absolute;
  left: 1rem;
  bottom: 0.85rem;
  display: flex;
  flex-direction: row;
`;

const Executions = styled(Text)<{ color: string }>`
  color: ${(props) => props.color};
  position: absolute;
  left: 2rem;
  bottom: 0;
`;

const Total = styled.div`
  position: absolute;
  right: calc(${barWidth} + 1rem);
  bottom: 0.75rem;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  transition: 0.2s;
`;

const TotalLabel = styled(Text)`
  width: 4rem;
  text-align: right;
  line-height: 0.9rem;
`;

const TotalAmount = styled(Text)<{ color: string }>`
  color: ${(props) => props.color};
  font-size: 1.8rem;
  line-height: 1.8rem;
`;

const BarAndCardContainer = styled.div`
  position: absolute;
  top: 0;
  right: 0;
`;

const BarContainer = styled.div`
  width: ${barWidth};
  background-color: grey;
  height: ${tileHeight};
  transition: 0.2s;
  display: flex;
  flex-direction: column;
  border-radius: 0 0.2rem 0.2rem 0;
  overflow: hidden;
`;

const Bar = styled.div<{ height: number; color: string }>`
  width: 100%;
  height: ${(props) => `${props.height}px`};
  background-color: ${(props) => props.color};
  position: relative;
`;

const BarCardContainer = styled.div`
  position: absolute;
  top: 0;
`;

const BarCard = styled(Text)<{ barMiddle: number }>`
  padding: 0.25rem;
  background-color: #fff;
  box-shadow: 2px 2px 5px 1px rgba(0, 0, 0, 0.1);
  border-radius: 0.2rem;
  width: max-content;
  transition: opacity 0.2s;
  opacity: 0;
  display: none;
  position: absolute;
  left: calc(${barWidth});
  top: ${(props) => props.barMiddle}px;
  transform: translateY(-50%);
  z-index: 1000;
  align-items: center;

  &:hover {
    z-index: 1001;
  }

  span {
    ${darkText}
  }
`;

export const Tiles = ({
  itemsToRender,
  currentPage,
  totalItemsOnLevel,
  widget,
  access,
  loading,
  searchMode,
  isTopLevel,
  goLevelUp,
  onLeaveWidget,
  onItemClick,
  retryDataLoading,
}: Props) => {
  const renderEmptyTiles = [
    ...Array(widget ? maxWidgetTiles + 1 : maxTilesPerPage),
  ].map((tile, index) => <EmptyTile key={index} className="empty-tile" />);

  const renderLoader = <Loader component />;

  const renderEmptyData = (
    <>
      {renderEmptyTiles}
      {loading ? (
        renderLoader
      ) : (
        <Modal customText="You don't have any integrations for this environment yet." />
      )}
    </>
  );

  const renderNoDataLoaded = (
    <>
      {renderEmptyTiles}
      {access &&
        (loading ? (
          renderLoader
        ) : (
          <Modal type={ModalType.DataLoadFailed} action={retryDataLoading} />
        ))}
    </>
  );

  return (
    <>
      <TilesContainer className="integrations-tiles">
        {access && itemsToRender ? (
          itemsToRender.length > 0 ? (
            <>
              {widget && !isTopLevel() && (
                <Tile
                  className="tile-level-up"
                  style={{ flexDirection: "row" }}
                  onClick={() => {
                    goLevelUp();
                  }}
                >
                  <IconComponent
                    name={Icon.arrowdown}
                    color={$textDark}
                    height="0.7rem"
                    extraStyling={css`
                      transform: rotate(90deg);
                      margin-right: 0.5rem;
                    `}
                  />
                  <IconComponent
                    name={Icon.group}
                    color={$textLight}
                    height="1.2rem"
                  />
                </Tile>
              )}

              {itemsToRender.map(
                (item: IntegrationLogicalGroup, index: number) => {
                  const color = getSuccessStatusColor(item.status);

                  const failPercentage = (item.fails / item.total) * 100;
                  let roundedFailPercentage: number | string = round(
                    failPercentage,
                    2
                  );
                  const successPercentage = (item.successes / item.total) * 100;
                  let roundedSuccessPercentage: number | string = round(
                    successPercentage,
                    2
                  );
                  const successSlowPercentage =
                    item.successesSlow !== null
                      ? (item.successesSlow / item.total) * 100
                      : undefined;
                  let roundedSuccessSlowPercentage:
                    | number
                    | string
                    | undefined = successSlowPercentage
                    ? round(successSlowPercentage, 2)
                    : undefined;

                  let failBarHeight =
                    item.total === 0
                      ? 0
                      : baseTileHeight * (item.fails / item.total);
                  let successBarHeight =
                    item.total === 0
                      ? 0
                      : baseTileHeight * (item.successes / item.total);
                  let successSlowBarHeight =
                    item.successesSlow !== null && item.total !== 0
                      ? baseTileHeight * (item.successesSlow / item.total)
                      : 0;

                  // Rounded percentage is 0%, but count is not actually 0 (fail or success count % is really low relative to the other one, e.g. 0.001%)
                  // --> Adjust text so user knows
                  if (roundedFailPercentage === 0 && item.fails > 0) {
                    roundedFailPercentage = "> 0";
                  }
                  if (roundedSuccessPercentage === 0 && item.successes > 0) {
                    roundedSuccessPercentage = "> 0";
                  }
                  if (
                    item.successesSlow !== null &&
                    roundedSuccessSlowPercentage &&
                    roundedSuccessSlowPercentage === 0 &&
                    item.successesSlow > 0
                  ) {
                    roundedSuccessSlowPercentage = "> 0";
                  }

                  // Height of bar is too small to show --> Adjust to 1 pixel
                  if (failBarHeight > 0 && failBarHeight < 1) {
                    failBarHeight = 1;
                  }
                  if (successBarHeight > 0 && successBarHeight < 1) {
                    successBarHeight = 1;
                  }
                  if (successSlowBarHeight > 0 && successSlowBarHeight < 1) {
                    successSlowBarHeight = 1;
                  }

                  return (
                    <Tile
                      className="integrations-tile"
                      key={index}
                      status={item.status}
                      onClick={() => {
                        onItemClick(item);
                      }}
                    >
                      <Title large semibold>
                        <StatusCircle
                          color={color}
                          size="14px"
                          style={{
                            position: "relative",
                            top: "0.1rem",
                          }}
                        />
                        {item.name}
                      </Title>
                      <ExecutionsPerUnit>
                        <IconComponent
                          name={Icon.gear}
                          height="2.5rem"
                          color={$textLight}
                          // color={color}
                        />
                        <Executions
                          semibold
                          dark
                          color={$textLight}
                          // color={color}
                          style={{ opacity: 0.6 }}
                        >
                          {getExecutionsString(
                            item.executionsPerTimeUnit
                              ? item.executionsPerTimeUnit
                              : 0,
                            item.executionsTimeUnit
                          )}
                        </Executions>
                      </ExecutionsPerUnit>
                      <Total className="totalExecutions">
                        <TotalLabel small dark style={{ opacity: 0.7 }}>
                          Total executions
                        </TotalLabel>
                        <TotalAmount
                          semibold
                          dark
                          color={$textLight}
                          // color={color}
                          style={{ opacity: 0.9 }}
                        >
                          {convertExecutions(item.total)}
                        </TotalAmount>
                      </Total>
                      <BarAndCardContainer>
                        <BarContainer className="executionsBar">
                          <Bar
                            color={
                              executionSpeedStatuses[ExecutionSpeedStatus.Fail]
                                .color
                            }
                            height={failBarHeight}
                          />
                          <Bar
                            color={
                              executionSpeedStatuses[
                                ExecutionSpeedStatus.SuccessOnTime
                              ].color
                            }
                            height={successBarHeight}
                          />
                          <Bar
                            color={
                              executionSpeedStatuses[
                                ExecutionSpeedStatus.SuccessSlow
                              ].color
                            }
                            height={successSlowBarHeight}
                          />
                        </BarContainer>
                        {item.total !== 0 && (
                          <BarCardContainer>
                            <BarCard
                              className="barCard"
                              small
                              barMiddle={failBarHeight / 2}
                            >
                              Failed: <span>{roundedFailPercentage}%</span>
                            </BarCard>
                            <BarCard
                              className="barCard"
                              small
                              barMiddle={failBarHeight + successBarHeight / 2}
                            >
                              Success{" "}
                              {item.successesSlow !== null ? "(on time)" : ""}:{" "}
                              <span>{roundedSuccessPercentage}%</span>
                            </BarCard>
                            {item.successesSlow !== null && (
                              <BarCard
                                className="barCard"
                                small
                                barMiddle={
                                  failBarHeight +
                                  successBarHeight +
                                  successSlowBarHeight / 2
                                }
                              >
                                Success (too slow){" "}
                                <span>{roundedSuccessSlowPercentage}%</span>
                              </BarCard>
                            )}
                          </BarCardContainer>
                        )}
                      </BarAndCardContainer>
                    </Tile>
                  );
                }
              )}

              {currentPage !== 0 &&
                itemsToRender.length < maxTilesPerPage &&
                [...Array(maxTilesPerPage - itemsToRender.length)].map(
                  (emptyItem, i) => {
                    return <EmptyTile className="tile-empty" key={i} />;
                  }
                )}

              {widget && totalItemsOnLevel > maxWidgetTiles && (
                <Tile
                  className="tile-show-more"
                  onClick={() => {
                    onLeaveWidget();
                  }}
                >
                  <Text bold dark style={{ marginBottom: "0.5rem" }}>
                    Displaying {maxWidgetTiles} of {totalItemsOnLevel}
                  </Text>
                  <Text bold small>
                    Show more
                  </Text>
                </Tile>
              )}
            </>
          ) : searchMode ? (
            <NoSearchResults>{noSearchResultsText}</NoSearchResults>
          ) : (
            renderEmptyData
          )
        ) : (
          renderNoDataLoaded
        )}
      </TilesContainer>
    </>
  );
};
