import { UserPermissionsInfo } from "model/User";
import request, { ApiResponse } from "../../utils/ApiHandlers";
import { BaseConfig } from "../../utils/Variables";

export class AuthenticationApi {
  private static instance: AuthenticationApi;

  private constructor() {}

  static getInstance(fresh?: boolean) {
    if (!AuthenticationApi.instance || fresh) {
      AuthenticationApi.instance = new AuthenticationApi();
    }
    return AuthenticationApi.instance;
  }

  getUserPermissionsInfo = async (): Promise<
    ApiResponse<UserPermissionsInfo>
  > => request.get(`${BaseConfig.getBaseApiUrl()}permissions/full`);

  switchToCustomer = async (
    subscriptionId: number
  ): Promise<ApiResponse<void>> =>
    request.put(
      `${BaseConfig.getBaseApiUrl()}users/current/subscription-default?subscriptionId=${subscriptionId}`,
      JSON.stringify({})
    );
}

export default AuthenticationApi.getInstance();
