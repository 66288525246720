import { action, makeObservable, observable } from "mobx";

class LoaderStore {
  @observable
  loading: boolean = false;

  constructor() {
    makeObservable(this);
  }

  @action
  setLoading = () => {
    this.loading = true;
  };

  @action
  stopLoading = () => {
    this.loading = false;
  };
}

export default LoaderStore;
