import Card, { CardSpacer } from "components/common/Card";
import IconComponent from "components/common/Icon";
import { List } from "components/common/List";
import { EditButton, DeleteButton } from "components/common/List/common";
import { CustomerEnvironmentDeploymentAreaModal } from "components/Customers/CustomerCrud/CustomerEnvironmentDeploymentAreaModal";
import { CustomerEnvironmentModal } from "components/Customers/CustomerCrud/CustomerEnvironmentModal";
import { CustomerEnvironmentSla } from "model/Sla";
import { Icon } from "model/Icon";
import React, { FunctionComponent, useState, useEffect } from "react";
import styled, { css } from "styled-components";
import {
  $statusFail,
  Text,
  Span,
  Button,
  IconButton,
  $textLight,
  HeaderWithAdd,
  $lightRed,
} from "styles/common";
import {
  CustomerEnvironmentDeploymentAreaAreaType,
  CustomerEnvironmentDeploymentArea,
} from "model/DeploymentArea";
import { CustomerEnvironment } from "model/Environment";
import { CustomerLocation } from "model/Customer";

interface Props {
  environments: CustomerEnvironment[];
  existingLocations: CustomerLocation[];
  areaTypes: CustomerEnvironmentDeploymentAreaAreaType[];
  slas: CustomerEnvironmentSla[];
  environmentsError: boolean;
  setEnvironmentsError: (set: boolean) => void;
  editEnvironments(
    editedEnvironments: CustomerEnvironment[],
    editedLocations?: CustomerLocation[]
  ): void;
}

const Container = styled.div``;

const DeploymentAreasContainer = styled.div`
  position: relative;
`;

const ListLabel = styled(Text)`
  opacity: 0.7;
`;

export const emptyEnvironmentsErrorText = "Please add at least one environment";

export const noEnvironmentsText = "You haven't defined any environments yet.";
export const noDeploymentAreasText =
  "You haven't defined any deployment areas for this environment yet.";

const environmentsHeaders = [
  "Environment",
  "Abbreviation",
  "Areas",
  "SLA",
  "",
  "",
];

const deploymentAreasHeaders = [
  "Name + Location",
  "Parent Account",
  "Area",
  "Component pattern",
  "Filter",
  "",
  "",
  "",
];

const getCustomerEnvironmentCells = (
  environments: CustomerEnvironment[],
  slas: CustomerEnvironmentSla[],
  editEnvironment: (environment: CustomerEnvironment) => void,
  deleteEnvironment: (environment: CustomerEnvironment) => void
) =>
  environments.map((environment: CustomerEnvironment) => ({
    cells: [
      environment.name,
      environment.abbreviation,
      <Text
        className="environment-areas-number"
        color={environment.areas.length === 0 ? $lightRed : $textLight}
        semibold={environment.areas.length === 0}
      >
        {environment.areas.length}
      </Text>,
      slas.find((sla) => sla.id === environment.sla)?.name || "",
      <EditButton
        className="edit-environment-button"
        onClick={() => {
          editEnvironment(environment);
        }}
      />,
      <DeleteButton
        className="delete-environment-button"
        onClick={() => {
          deleteEnvironment(environment);
        }}
      />,
    ],
  }));

const getAreaTypePortalUrl = (
  url: string,
  parentAccount: string,
  guid: string
) => url.replace("{parentAccount}", parentAccount).replace("{guid}", guid);

const getCustomerEnvironmentDeploymentAreaCells = (
  deploymentAreas: CustomerEnvironmentDeploymentArea[],
  areaTypes: CustomerEnvironmentDeploymentAreaAreaType[],
  editDeploymentArea: (
    deploymentArea: CustomerEnvironmentDeploymentArea
  ) => void,
  deleteDeploymentArea: (
    deploymentArea: CustomerEnvironmentDeploymentArea
  ) => void
) =>
  deploymentAreas.map((deploymentArea: CustomerEnvironmentDeploymentArea) => {
    const currentAreaType = areaTypes.find(
      (areaType) => areaType.id === deploymentArea.areaType
    );

    const emptyFilter =
      currentAreaType &&
      currentAreaType.filtersBy &&
      currentAreaType.filtersBy.length > 0 &&
      deploymentArea.filter === "";

    return {
      cells: [
        <>
          <Text semibold>{deploymentArea.name}</Text>
          <Text small>{deploymentArea.location}</Text>
        </>,
        <>
          <ListLabel small>
            {currentAreaType && currentAreaType.parentAccountIdName}
          </ListLabel>
          <Text>{deploymentArea.parentAccount}</Text>
        </>,
        <>
          <ListLabel small>{currentAreaType && currentAreaType.name}</ListLabel>
          <Text>{deploymentArea.guid}</Text>
        </>,
        <Text>
          {deploymentArea.componentPattern
            ? deploymentArea.componentPattern
            : "-"}
        </Text>,
        emptyFilter ? (
          <Text>-</Text>
        ) : (
          <>
            <ListLabel small>
              {currentAreaType && currentAreaType.filtersBy
                ? currentAreaType.filtersBy.find(
                    (filterBy) => filterBy.id === deploymentArea.filterBy
                  )?.name
                : ""}
            </ListLabel>
            <Text>{deploymentArea.filter}</Text>
          </>
        ),
        <IconButton
          className="open-portal-button"
          onClick={() => {
            currentAreaType &&
              window.open(
                getAreaTypePortalUrl(
                  currentAreaType.portalUrl,
                  deploymentArea.parentAccount,
                  deploymentArea.guid
                ),
                "_blank"
              );
          }}
        >
          <IconComponent name={Icon.portal} height="12px" color={$textLight} />
          <Text small>Open portal</Text>
        </IconButton>,
        <EditButton
          className="edit-deployment-area-button"
          onClick={() => {
            editDeploymentArea(deploymentArea);
          }}
        />,
        <DeleteButton
          className="delete-deployment-area-button"
          onClick={() => {
            deleteDeploymentArea(deploymentArea);
          }}
        />,
      ],
    };
  });

export const CustomerEnvironments: FunctionComponent<Props> = ({
  environments,
  existingLocations,
  areaTypes,
  slas,
  environmentsError,
  setEnvironmentsError,
  editEnvironments,
}) => {
  const [showEnvironmentModal, setShowEnvironmentModal] = useState(false);
  const [openedEnvironment, setOpenedEnvironment] = useState<
    CustomerEnvironment | undefined
  >(undefined);
  const [selectedEnvironment, setSelectedEnvironment] = useState<
    CustomerEnvironment | undefined
  >(undefined);
  const [selectedEnvironmentListIndex, setSelectedEnvironmentListIndex] =
    useState<number | undefined>(undefined); // New environments have an id of 0, so we can't use the id when adding deployment areas

  const [showDeploymentAreaModal, setShowDeploymentAreaModal] = useState(false);
  const [openedDeploymentArea, setOpenedDeploymentArea] = useState<
    CustomerEnvironmentDeploymentArea | undefined
  >(undefined);

  useEffect(() => {
    if (environments.length > 0 && !selectedEnvironment) {
      setSelectedEnvironment(environments[0]);
      setSelectedEnvironmentListIndex(0);
    }
  }, [environments, selectedEnvironment]);

  const handleAddEnvironmentClick = () => {
    setShowEnvironmentModal(true);
  };

  const handleAddEnvironment = (environment: CustomerEnvironment) => {
    setEnvironmentsError(false);
    const newEnvironments: CustomerEnvironment[] = [...environments];
    newEnvironments.push(environment);
    editEnvironments([...newEnvironments]);
    setSelectedEnvironment(environment);
    setSelectedEnvironmentListIndex(newEnvironments.indexOf(environment));
  };

  const handleEditEnvironmentClick = (environment: CustomerEnvironment) => {
    setOpenedEnvironment(environment);
    setShowEnvironmentModal(true);
  };

  const handleEditEnvironment = (environment: CustomerEnvironment) => {
    setEnvironmentsError(false);
    const newEnvironments = [
      ...environments.map((env) => {
        return env.id === environment.id ? environment : env;
      }),
    ];
    editEnvironments([...newEnvironments]);
  };

  const handleDeleteEnvironment = (environment: CustomerEnvironment) => {
    const deleteIndex = environments.indexOf(environment);
    const newEnvironments = [...environments];
    newEnvironments.splice(deleteIndex, 1);

    editEnvironments([...newEnvironments]);

    if (selectedEnvironment !== undefined) {
      const newSelectedEnvironmentIndex =
        newEnvironments.indexOf(selectedEnvironment);

      if (newSelectedEnvironmentIndex === -1) {
        setSelectedEnvironment(undefined);
        setSelectedEnvironmentListIndex(undefined);
      } else {
        setSelectedEnvironmentListIndex(newSelectedEnvironmentIndex);
      }
    }
  };

  const handleSelectEnvironment = (rowIndex: number) => {
    const environment = environments[rowIndex];
    setSelectedEnvironment(environment);
    setSelectedEnvironmentListIndex(rowIndex);
  };

  const handleAddDeploymentArea = (
    deploymentArea: CustomerEnvironmentDeploymentArea
  ) => {
    if (
      selectedEnvironment !== undefined &&
      selectedEnvironmentListIndex !== undefined
    ) {
      const newDeploymentAreas: CustomerEnvironmentDeploymentArea[] = [
        ...selectedEnvironment.areas,
      ];
      newDeploymentAreas.push(deploymentArea);

      const newEnvironments = [...environments];
      newEnvironments[selectedEnvironmentListIndex] = {
        ...newEnvironments[selectedEnvironmentListIndex],
        areas: newDeploymentAreas,
      };

      // Check if location is a new location and is not already added to the existinglocations as a new location
      const addNewLocation: boolean =
        deploymentArea.locationId === null &&
        !!!existingLocations.find(
          (loc) => loc.id === null && loc.name === deploymentArea.location
        );

      editEnvironments(
        [...newEnvironments],
        addNewLocation
          ? [
              ...existingLocations,
              { id: deploymentArea.locationId, name: deploymentArea.location },
            ]
          : undefined
      );

      setSelectedEnvironment(newEnvironments[selectedEnvironmentListIndex]);
    }
  };

  const handleEditDeploymentAreaClick = (
    deploymentArea: CustomerEnvironmentDeploymentArea
  ) => {
    setOpenedDeploymentArea(deploymentArea);
    setShowDeploymentAreaModal(true);
  };

  const handleEditDeploymentArea = (
    deploymentArea: CustomerEnvironmentDeploymentArea
  ) => {
    if (
      selectedEnvironment !== undefined &&
      selectedEnvironmentListIndex !== undefined &&
      openedDeploymentArea !== undefined
    ) {
      const newDeploymentAreas: CustomerEnvironmentDeploymentArea[] = [
        ...selectedEnvironment.areas,
      ];

      const index = newDeploymentAreas.indexOf(openedDeploymentArea);
      newDeploymentAreas[index] = deploymentArea;

      const newEnvironments = [...environments];
      newEnvironments[selectedEnvironmentListIndex] = {
        ...newEnvironments[selectedEnvironmentListIndex],
        areas: newDeploymentAreas,
      };

      // Check if location is a new location and is not already added to the existinglocations as a new location
      const addNewLocation: boolean =
        deploymentArea.locationId === null &&
        !!!existingLocations.find(
          (loc) => loc.id === null && loc.name === deploymentArea.location
        );

      editEnvironments(
        [...newEnvironments],
        addNewLocation
          ? [
              ...existingLocations,
              { id: deploymentArea.locationId, name: deploymentArea.location },
            ]
          : undefined
      );

      setSelectedEnvironment(newEnvironments[selectedEnvironmentListIndex]);
    }
  };

  const handleDeleteDeploymentArea = (
    deploymentArea: CustomerEnvironmentDeploymentArea
  ) => {
    if (
      selectedEnvironment !== undefined &&
      selectedEnvironmentListIndex !== undefined
    ) {
      const newDeploymentAreas: CustomerEnvironmentDeploymentArea[] = [
        ...selectedEnvironment.areas,
      ];

      const index = newDeploymentAreas.indexOf(deploymentArea);
      newDeploymentAreas.splice(index, 1);

      const newEnvironments = [...environments];
      newEnvironments[selectedEnvironmentListIndex] = {
        ...newEnvironments[selectedEnvironmentListIndex],
        areas: newDeploymentAreas,
      };

      editEnvironments([...newEnvironments]);

      setSelectedEnvironment(newEnvironments[selectedEnvironmentListIndex]);
    }
  };

  const renderDeploymentAreas = selectedEnvironment !== undefined && (
    <DeploymentAreasContainer className="deployment-areas-container">
      <CardSpacer />
      <HeaderWithAdd style={{ marginBottom: "1rem" }}>
        <Text dark large semibold>
          Deployment Areas for environment "{selectedEnvironment.name}"
        </Text>
        <Button
          className="add-deployment-area-button"
          onClick={() => setShowDeploymentAreaModal(true)}
          extraStyling={css`
            top: -0.5rem;
          `}
        >
          Add
        </Button>
      </HeaderWithAdd>

      <List
        className="deployment-areas-list"
        headers={deploymentAreasHeaders}
        data={getCustomerEnvironmentDeploymentAreaCells(
          selectedEnvironment.areas,
          areaTypes,
          handleEditDeploymentAreaClick,
          handleDeleteDeploymentArea
        )}
        customColumnWidths={[4, 4, 4, 3, 3, 2, 1, 1]}
        hover
        noDataText={noDeploymentAreasText}
        smallEmpty
        retryDataLoading={() => {}}
        extraStyling={css`
          margin-bottom: 2.5rem;
        `}
      />
    </DeploymentAreasContainer>
  );

  const renderCustomerEnvironmentModal = (
    <CustomerEnvironmentModal
      originalEnvironment={openedEnvironment}
      slas={slas}
      close={() => {
        setOpenedEnvironment(undefined);
        setShowEnvironmentModal(false);
      }}
      add={handleAddEnvironment}
      edit={handleEditEnvironment}
    />
  );

  const renderDeploymentAreaModal = (
    <CustomerEnvironmentDeploymentAreaModal
      originalDeploymentArea={openedDeploymentArea}
      areaTypes={areaTypes}
      existingLocations={existingLocations}
      environment={
        selectedEnvironment !== undefined ? selectedEnvironment.name : ""
      }
      close={() => {
        setOpenedDeploymentArea(undefined);
        setShowDeploymentAreaModal(false);
      }}
      add={handleAddDeploymentArea}
      edit={handleEditDeploymentArea}
    />
  );

  return (
    <Container>
      <Card
        header={
          <HeaderWithAdd>
            <p>
              Environments
              <Span className="mandatory-star" color={$statusFail}>
                *
              </Span>
            </p>
            <Button
              className="add-environment-button"
              onClick={(e) => {
                e.stopPropagation();
                handleAddEnvironmentClick();
              }}
            >
              Add
            </Button>
          </HeaderWithAdd>
        }
        collapsable
        error={environmentsError}
        errorText={emptyEnvironmentsErrorText}
      >
        <List
          className="environments-list"
          headers={environmentsHeaders}
          data={
            environments
              ? getCustomerEnvironmentCells(
                  environments,
                  slas,
                  handleEditEnvironmentClick,
                  handleDeleteEnvironment
                )
              : []
          }
          customColumnWidths={[5, 2, 2, 4, 1, 1]}
          hover
          noDataText={noEnvironmentsText}
          smallEmpty
          selectedRow={
            selectedEnvironment !== undefined
              ? environments.indexOf(selectedEnvironment)
              : undefined
          }
          onRowClick={handleSelectEnvironment}
          retryDataLoading={() => {}}
          extraStyling={css`
            margin-bottom: 2.5rem;
          `}
        />

        {selectedEnvironment !== undefined && renderDeploymentAreas}
      </Card>

      {showEnvironmentModal && renderCustomerEnvironmentModal}
      {showDeploymentAreaModal && renderDeploymentAreaModal}
    </Container>
  );
};
