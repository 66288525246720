import { ReactComponent as Doublearrow } from "./doublearrow.svg";
import { ReactComponent as Arrowdown } from "./arrowdown.svg";
import { ReactComponent as Bell } from "./bell.svg";
import { ReactComponent as Cart } from "./cart.svg";
import { ReactComponent as User } from "./user.svg";
import { ReactComponent as Gear } from "./gear.svg";
import { ReactComponent as Cross } from "./cross.svg";
import { ReactComponent as Circle } from "./circle.svg";
import { ReactComponent as Group } from "./group.svg";
import { ReactComponent as List } from "./list.svg";
import { ReactComponent as ArrowLeft } from "./arrowleft.svg";
import { ReactComponent as ArrowRight } from "./arrowright.svg";
import { ReactComponent as DoubleArrowLeft } from "./doublearrowleft.svg";
import { ReactComponent as DoubleArrowRight } from "./doublearrowright.svg";
import { ReactComponent as CalendarStart } from "./calendarstart.svg";
import { ReactComponent as CalendarEnd } from "./calendarend.svg";
import { ReactComponent as Save } from "./save.svg";
import { ReactComponent as Reset } from "./reset.svg";
import { ReactComponent as Plus } from "./plus.svg";
import { ReactComponent as Minus } from "./minus.svg";
import { ReactComponent as History } from "./history.svg";
import { ReactComponent as Refresh } from "./refresh.svg";
import { ReactComponent as Location } from "./location.svg";
import { ReactComponent as Application } from "./application.svg";
import { ReactComponent as Info } from "./info.svg";
import { ReactComponent as Dispute } from "./dispute.svg";
import { ReactComponent as Check } from "./check.svg";
import { ReactComponent as Delete } from "./delete.svg";
import { ReactComponent as Mail } from "./mail.svg";
import { ReactComponent as Locked } from "./locked.svg";
import { ReactComponent as Unlocked } from "./unlocked.svg";
import { ReactComponent as Unchecked } from "./unchecked.svg";
import { ReactComponent as Edit } from "./edit.svg";
import { ReactComponent as Portal } from "./portal.svg";
import { ReactComponent as Loader } from "./loader.svg";
import { ReactComponent as Search } from "./search.svg";
import { ReactComponent as Lock } from "./lock.svg";
import { ReactComponent as Block } from "./block.svg";
import { ReactComponent as Unassigned } from "./unassigned.svg";

export const icons = {
  doublearrow: Doublearrow,
  arrowdown: Arrowdown,
  bell: Bell,
  cart: Cart,
  user: User,
  gear: Gear,
  cross: Cross,
  circle: Circle,
  group: Group,
  list: List,
  arrowleft: ArrowLeft,
  arrowright: ArrowRight,
  doublearrowleft: DoubleArrowLeft,
  doublearrowright: DoubleArrowRight,
  calendarstart: CalendarStart,
  calendarend: CalendarEnd,
  save: Save,
  reset: Reset,
  plus: Plus,
  minus: Minus,
  history: History,
  refresh: Refresh,
  location: Location,
  application: Application,
  info: Info,
  dispute: Dispute,
  check: Check,
  delete: Delete,
  mail: Mail,
  locked: Locked,
  unlocked: Unlocked,
  unchecked: Unchecked,
  edit: Edit,
  portal: Portal,
  loader: Loader,
  search: Search,
  lock: Lock,
  block: Block,
  unassigned: Unassigned,
};
