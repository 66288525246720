import Card, { CardSpacer } from "components/common/Card";
import { inject, observer } from "mobx-react";
import React, { FunctionComponent, useEffect, useState } from "react";
import SettingStore from "stores/SettingStore";
import styled, { css } from "styled-components";
import {
  Text,
  Link,
  Button,
  StyledReactTooltip,
  TooltipText,
  $textLight,
  $blue4,
} from "styles/common";
import { Header, HeaderExplanation, HeaderTitle } from "./common";
import { Prompt } from "react-router";
import { Route } from "model/Navigation";
import NavigationStore from "stores/NavigationStore";
import {
  SettingsChangeRequestContact,
  SettingsChangeRequestContactSchedule,
  SettingsIncidentsContactSchedule,
} from "model/Contact";
import IconComponent from "components/common/Icon";
import { Icon } from "model/Icon";
import { SettingSupportWindowsList } from "components/Settings/SettingSupportWindowsList";
import { SettingApplicationGroupContactsList } from "components/Settings/SettingApplicationGroupContactsList";
import SettingChangeRequestContactsList from "components/Settings/SettingChangeRequestContactsList";
import { PermissionArea, PermissionType } from "model/Claim";
import AuthenticationStore from "stores/AuthenticationStore";
import { NoAccessModal } from "components/common/Modal";
import { ExtraStylingType } from "model/Styles";

interface Props {
  settingStore: SettingStore;
  navigationStore: NavigationStore;
  authenticationStore: AuthenticationStore;
}

const CardHeaderSave = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  padding-right: 1.5rem;
  margin-right: 1.5rem;
  border-right: 1px solid #eaeff2;
`;

const CardHeader = styled.div`
  display: flex;
  flex-direction: column;
`;

const IncidentsContactScheduleContainer = styled.div``;

export const InfoIconWrapper = styled.div<{ extraStyling?: ExtraStylingType }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.75rem;

  ${(props) => (props.extraStyling ? props.extraStyling : css``)}
`;

const GreyCard = styled.div`
  padding: 1rem;
  background-color: #f7fafc;
  border: 1px solid #f7fafc;
  box-shadow: 0 0 2px 1px rgb(0 0 0 / 8%);
  border-radius: 0.25rem;
  margin-bottom: 1rem;
`;

export const ignoreValue = "-1";
export const ignoreText = "(ignore)";

const ContactSchedule: FunctionComponent<Props> = ({
  settingStore,
  navigationStore,
  authenticationStore,
}) => {
  const accessViewContact = authenticationStore.userHasPermission(
    PermissionArea.Contact,
    PermissionType.View
  );

  const accessEditContact = authenticationStore.userHasPermission(
    PermissionArea.Contact,
    PermissionType.Edit
  );

  const accessDeleteContact = authenticationStore.userHasPermission(
    PermissionArea.Contact,
    PermissionType.Delete
  );

  const [incidentsContactSchedule, setIncidentsContactSchedule] = useState<
    SettingsIncidentsContactSchedule | undefined
  >(undefined);

  const [changeRequestContactSchedule, setChangeRequestContactSchedule] =
    useState<SettingsChangeRequestContactSchedule | undefined>(undefined);

  const [editedIncidentContactSchedule, setEditedIncidentContactSchedule] =
    useState(false);
  const [editedChangeRequestSchedule, setEditedChangeRequestContactSchedule] =
    useState(false);

  useEffect(() => {
    accessViewContact &&
      !settingStore.scheduleContacts &&
      settingStore.getScheduleContacts().then(() => {
        !settingStore.incidentsContactSchedule &&
          settingStore
            .getIncidentsContactSchedule()
            .then((incidentsSchedule) => {
              incidentsSchedule &&
                setIncidentsContactSchedule(incidentsSchedule);
            })
            .then(() => {
              !settingStore.changeRequestsContactSchedule &&
                settingStore
                  .getChangeRequestsContactSchedule()
                  .then((changeRequestsSchedule) => {
                    changeRequestsSchedule &&
                      setChangeRequestContactSchedule(changeRequestsSchedule);
                  });
            });
      });
  }, [accessViewContact, settingStore]);

  const handleIncidentContactsDataReload = () => {
    accessViewContact &&
      settingStore.getScheduleContacts().then(() => {
        settingStore.getIncidentsContactSchedule();
      });
  };

  const handleChangeRequestContactsDataReload = () => {
    accessViewContact &&
      settingStore.getScheduleContacts().then(() => {
        settingStore.getChangeRequestsContactSchedule();
      });
  };

  const handleIncidentContactScheduleEdit = (
    schedule: SettingsIncidentsContactSchedule
  ) => {
    accessEditContact &&
      !editedIncidentContactSchedule &&
      setEditedIncidentContactSchedule(true);

    setIncidentsContactSchedule(schedule);
  };

  const handleChangeRequestContactScheduleEdit = (
    changeRequestContacts: SettingsChangeRequestContact[]
  ) => {
    accessEditContact &&
      !editedChangeRequestSchedule &&
      setEditedChangeRequestContactSchedule(true);

    changeRequestContactSchedule &&
      setChangeRequestContactSchedule({
        ...changeRequestContactSchedule,
        changeRequestContacts: changeRequestContacts,
      });
  };

  const renderSaveIncidentsButton = () => (
    <Button
      disabled={!editedIncidentContactSchedule}
      onClick={(e) => {
        e.stopPropagation();
        incidentsContactSchedule &&
          settingStore
            .editIncidentsContactSchedule(incidentsContactSchedule)
            .then(() => {
              setEditedIncidentContactSchedule(false);
            });
      }}
    >
      {editedIncidentContactSchedule ? "Save changes" : "No changes"}
    </Button>
  );

  const renderSaveChangeRequestContactsButton = () => (
    <Button
      disabled={!editedChangeRequestSchedule}
      onClick={(e) => {
        e.stopPropagation();
        changeRequestContactSchedule &&
          settingStore
            .editChangeRequestsContactSchedule(changeRequestContactSchedule)
            .then(() => {
              setEditedChangeRequestContactSchedule(false);
            });
      }}
    >
      {editedChangeRequestSchedule ? "Save changes" : "No changes"}
    </Button>
  );

  return (
    <>
      <Prompt
        message={(location) => {
          if (!location.pathname.startsWith(Route.ContactSchedule)) {
            // Reset data when leaving page
            settingStore.resetContactSchedule();

            return editedIncidentContactSchedule || editedChangeRequestSchedule
              ? "Are you sure you want to leave this page? You have unsaved changes."
              : true;
          }
          return true;
        }}
      />

      <Header>
        <HeaderTitle dark semibold large>
          Contact schedule
        </HeaderTitle>
        <HeaderExplanation>
          {!accessViewContact || accessEditContact || accessDeleteContact
            ? "Define"
            : "Definition of"}{" "}
          who Cegeka engages in case of incidents or changes
        </HeaderExplanation>
      </Header>

      <Card
        header={
          <CardHeaderSave>
            <CardHeader>
              <p>Incidents</p>
              <Text>
                How should Cegeka contact my organization in case of incidents?
              </Text>
            </CardHeader>
            {(accessEditContact || accessDeleteContact) &&
              incidentsContactSchedule &&
              renderSaveIncidentsButton()}
          </CardHeaderSave>
        }
        collapsable
      >
        <IncidentsContactScheduleContainer>
          <CardSpacer />

          <InfoIconWrapper
            extraStyling={css`
              margin-bottom: 1.5rem;
            `}
          >
            <Text large semibold dark>
              Default contacts
            </Text>
            <div data-for="default-contacts-info-tooltip" data-tip="">
              <IconComponent name={Icon.info} color={$blue4} />
            </div>
          </InfoIconWrapper>

          <StyledReactTooltip
            id="default-contacts-info-tooltip"
            effect="solid"
            place="right"
            type="light"
            border={false}
            textColor={$textLight}
            backgroundColor={"#fff"}
            arrowColor={"transparent"}
          >
            <TooltipText>
              <Text>
                {accessEditContact || accessDeleteContact
                  ? "Define who can be contacted for technical and functional issues."
                  : "Contacts for technical and functional issues."}
              </Text>
              <Text>
                Contacts for 24x7 are mandatory and will be engaged if no
                specific contact has been provided.
              </Text>
            </TooltipText>
          </StyledReactTooltip>

          <GreyCard>
            {!accessViewContact && <NoAccessModal />}

            <SettingSupportWindowsList
              data={
                incidentsContactSchedule
                  ? incidentsContactSchedule.defaultContacts
                  : undefined
              }
              contacts={settingStore.scheduleContacts}
              viewAccess={accessViewContact}
              editAccess={accessEditContact}
              editData={(data) => {
                incidentsContactSchedule &&
                  handleIncidentContactScheduleEdit({
                    ...incidentsContactSchedule,
                    defaultContacts: data,
                  });
              }}
              retryDataLoading={handleIncidentContactsDataReload}
            />
          </GreyCard>

          <CardSpacer />

          <Text
            large
            semibold
            dark
            extraStyling={css`
              margin-bottom: 1.5rem;
            `}
          >
            Specific contacts
          </Text>

          <GreyCard>
            <SettingSupportWindowsList
              data={
                incidentsContactSchedule
                  ? incidentsContactSchedule.specificContacts
                  : undefined
              }
              contacts={settingStore.scheduleContacts}
              viewAccess={accessViewContact}
              editAccess={accessEditContact}
              editData={(data) => {
                incidentsContactSchedule &&
                  handleIncidentContactScheduleEdit({
                    ...incidentsContactSchedule,
                    specificContacts: data,
                  });
              }}
              retryDataLoading={handleIncidentContactsDataReload}
            />
          </GreyCard>

          <GreyCard>
            <SettingApplicationGroupContactsList
              contactAreas={
                incidentsContactSchedule
                  ? incidentsContactSchedule.contactAreas
                  : undefined
              }
              supportWindows={
                incidentsContactSchedule
                  ? incidentsContactSchedule.supportWindows
                  : undefined
              }
              contacts={settingStore.scheduleContacts}
              applicationOrGroupContacts={
                incidentsContactSchedule
                  ? incidentsContactSchedule.applicationOrGroupContacts
                  : undefined
              }
              viewAccess={accessViewContact}
              editAccess={accessEditContact}
              deleteAccess={accessDeleteContact}
              editApplicationOrGroupContacts={(applicationOrGroupContacts) => {
                incidentsContactSchedule &&
                  handleIncidentContactScheduleEdit({
                    ...incidentsContactSchedule,
                    applicationOrGroupContacts: applicationOrGroupContacts,
                  });
              }}
              retryDataLoading={handleIncidentContactsDataReload}
            />
          </GreyCard>
        </IncidentsContactScheduleContainer>
      </Card>

      <Card
        header={
          <CardHeaderSave>
            <CardHeader>
              <p>Change requests</p>
              <Text>
                How can Cegeka collaborate with my organization to develop and
                deploy integrations?
              </Text>
            </CardHeader>

            {(accessEditContact || accessDeleteContact) &&
              changeRequestContactSchedule &&
              renderSaveChangeRequestContactsButton()}
          </CardHeaderSave>
        }
        collapsable
      >
        {!accessViewContact && <NoAccessModal />}

        <SettingChangeRequestContactsList
          contacts={settingStore.scheduleContacts}
          roles={
            changeRequestContactSchedule
              ? changeRequestContactSchedule.roles
              : undefined
          }
          changeRequestContacts={
            changeRequestContactSchedule
              ? changeRequestContactSchedule.changeRequestContacts
              : undefined
          }
          viewAccess={accessViewContact}
          editAccess={accessEditContact}
          deleteAccess={accessDeleteContact}
          editChangeRequestContacts={handleChangeRequestContactScheduleEdit}
          retryDataLoading={handleChangeRequestContactsDataReload}
        />

        <Text
          extraStyling={css`
            margin: 2rem 0 0.5rem 0;
          `}
        >
          Switch to{" "}
          <Link
            onClick={() => {
              navigationStore.navigate(Route.ContactDetails);
            }}
          >
            Provide contact details
          </Link>{" "}
          to{" "}
          {!accessViewContact || accessEditContact || accessDeleteContact
            ? "manage contacts"
            : "view contacts"}
          .
        </Text>
      </Card>
    </>
  );
};

export default inject(
  "settingStore",
  "navigationStore",
  "authenticationStore"
)(
  observer(
    ContactSchedule as FunctionComponent<
      Omit<Props, "settingStore" | "navigationStore" | "authenticationStore">
    >
  )
);
