import { inject, observer } from "mobx-react";
import React, {
  Fragment,
  FunctionComponent,
  useCallback,
  useEffect,
  useState,
} from "react";
import styled, { css } from "styled-components";
import FlowDiagram from "components/Integrations/FlowDiagram";
import { $grey, $textDark, Button, Text } from "styles/common";
import IconComponent from "components/common/Icon";
import { Icon } from "model/Icon";
import { Switch } from "components/common/Switch";
import IntegrationStore from "stores/IntegrationStore";
import { Node, Edge } from "react-flow-renderer";
import { highlightApplicationRelations } from "utils/Application";
import { ExecutionsDoughnutChart } from "./ExecutionsDoughnutChart";
import { convertLogicalGroupsToDiagramNodes } from "utils/Node";
import { convertLogicalGroupLinksToDiagramEdges } from "utils/Edge";
import PlatformComponentTypeIconComponent from "components/Integrations/PlatformComponentTypeIcon";
import { splitArray } from "utils/common";
import { Modal, ModalType } from "components/common/Modal";
import { IntegrationApplication } from "model/Application";
import { LogicalGroupLayoutPositionSave } from "model/LogicalGroup";
import GeneralStore from "stores/GeneralStore";

interface Props {
  integrationStore: IntegrationStore;
  generalStore: GeneralStore;
}

const durationSidePanelOpenClose = `0.2s`;

const diagramMinHeight = "50rem";

const Container = styled.div`
  display: flex;
  flex-direction: row;
  position: relative;
  height: 100%;
  min-height: ${diagramMinHeight};
  overflow: hidden;
  border-radius: 0.2rem;
`;

const SidePanel = styled.div<{ open: boolean }>`
  width: ${(props) => (props.open ? "calc(25rem - 3rem)" : "0")};
  height: calc(100% - 3rem - 2px);
  padding: 1.5rem;
  position: absolute;
  top: 0;
  right: 0;
  background-color: #f7fafc;
  box-shadow: -2px 0px 5px 1px rgba(0, 0, 0, 0.1);
  z-index: 99;
  transition: width ${durationSidePanelOpenClose};
  border: 1px solid #efefef;
  border-left: none;
`;

const SidePanelContent = styled.div<{ open: boolean }>`
  min-width: calc(25rem - 3rem);
  display: ${(props) => (props.open ? "flex" : "none")};
  flex-direction: column;
  height: 100%;
  min-height: ${diagramMinHeight};
`;

const LogicalGroupInfo = styled.div`
  margin-bottom: 1rem;
`;

const ItemInfo = styled.div`
  height: 12rem;
  background-color: #fdfdfd;
  padding: 1rem;
  border-radius: 0.2rem;
  border: 1px solid #f3f3f3;
`;

const ItemName = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 1rem;
`;

const InfoContainer = styled.div`
  display: flex;
  flex-direction: row;
  line-height: 1.25rem;
`;

const InfoLabel = styled(Text)`
  width: 6rem;
`;

const InfoText = styled(Text)``;

const Title = styled(Text)`
  margin-bottom: 0.75rem;
`;

const NoInfo = styled(Text)`
  opacity: 0.6;
`;

const Applications = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  flex: 1;
  justify-content: space-between;
`;

const ApplicationsColumn = styled.div`
  display: flex;
  flex-direction: column;
  width: 49%;
`;

const Application = styled(Text)`
  width: calc(100% - 1rem);
  padding: 0.3rem 0.4rem;
  cursor: pointer;
  transition: 0.2s;

  &:hover {
    background-color: ${$grey} !important;
    color: ${$textDark} !important;
  }
`;

const SidePanelActions = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  margin-bottom: 2rem;
  position: relative;
`;

const LogicalGroupContainer = styled.div`
  height: 11rem;
  padding: 1rem;
  border-radius: 0.2rem;
  background-color: #fcfcfc;
  box-shadow: 2px 2px 5px 1px rgba(0, 0, 0, 0.1);
  margin-bottom: 2rem;
`;

const applicationColumns = 2;

const IntegrationsFirstLevelDiagram: FunctionComponent<Props> = ({
  integrationStore, generalStore
}) => {
  const [nodes, setNodes] = useState<Node[]>([]);
  const [edges, setEdges] = useState<Edge[]>([]);
  const [applicationsArray, setApplicationsArray] = useState<
    IntegrationApplication[][] | undefined
  >(undefined);

  const convertData = useCallback(() => {
    if (integrationStore.currentFlowDiagramData) {
      setNodes(
        convertLogicalGroupsToDiagramNodes(
          integrationStore.currentFlowDiagramData.logicalGroups
        )
      );
      setEdges(
        convertLogicalGroupLinksToDiagramEdges(
          integrationStore.currentFlowDiagramData.links
        )
      );
      setApplicationsArray(
        splitArray(
          [...integrationStore.currentFlowDiagramData?.applications],
          applicationColumns
        )
      );
    }
  }, [integrationStore.currentFlowDiagramData]);

  useEffect(() => {
    convertData();
  }, [convertData]);

  const renderPlatformComponentInfo = () =>
    integrationStore.currentPlatformComponent && (
      <Fragment>
        <ItemName>
          <PlatformComponentTypeIconComponent
            name={integrationStore.currentPlatformComponent.type}
            extraStyling={css`
              margin-right: 0.5rem;
            `}
          />
          <Text dark semibold className="name">
            {integrationStore.currentPlatformComponent.name}
          </Text>
        </ItemName>
        <InfoContainer>
          <InfoLabel small semibold>
            Type
          </InfoLabel>
          <InfoText small>
            {integrationStore.currentPlatformComponent.type}
          </InfoText>
        </InfoContainer>
        <InfoContainer>
          <InfoLabel small semibold>
            Area
          </InfoLabel>
          <InfoText small className="area">
            {integrationStore.currentPlatformComponent.area}
          </InfoText>
        </InfoContainer>
        <InfoContainer>
          <InfoLabel small semibold>
            Location
          </InfoLabel>
          <InfoText small>
            {integrationStore.currentPlatformComponent.location}
          </InfoText>
        </InfoContainer>
        <ExecutionsDoughnutChart
          data={integrationStore.currentPlatformComponent}
          small
          animated
          extraStyling={css`
            margin: 1rem 0 0 0;
          `}
        />
      </Fragment>
    );

  const renderSidePanelButton = () => (
    <IconComponent
      id="sidepanel-button"
      name={Icon.doublearrowright}
      height="1rem"
      onClick={() => {
        integrationStore.setShowSidePanel(!integrationStore.showSidePanel);
      }}
      extraStyling={css`
        width: 1rem;
        height: 1rem;
        position: absolute;
        top: 1.5rem;
        left: 1rem;
        padding: 0.5rem;
        border-radius: 50%;
        cursor: pointer;
        transition: transform ${durationSidePanelOpenClose};
        z-index: 100;

        &:hover {
          background-color: #eee;
        }

        ${!integrationStore.showSidePanel &&
        css`
          transform: rotate(180deg);
          left: 0.5rem;
        `}
      `}
    />
  );

  const rederOpenedLogicalGroupInfo = () =>
    integrationStore.openedLogicalGroup && (
      <LogicalGroupContainer id="opened-logical-group-info">
        <InfoContainer>
          <InfoLabel small semibold>
            Name
          </InfoLabel>
          <InfoText id="opened-logical-group-name" small>
            {integrationStore.openedLogicalGroup.name}
          </InfoText>
        </InfoContainer>
        <InfoContainer>
          <InfoLabel small semibold>
            Environment
          </InfoLabel>
          <InfoText small>{generalStore.getCurrentEnvironmentName()}</InfoText>
        </InfoContainer>

        <ExecutionsDoughnutChart
          data={integrationStore.openedLogicalGroup}
          extraStyling={css`
            margin: 1rem 0 0.5rem 0;
          `}
        />
      </LogicalGroupContainer>
    );

  const renderLogicalGroupInfo = () =>
    integrationStore.currentLogicalGroup && (
      <Fragment>
        <ItemName>
          <Text dark semibold className="name">
            {integrationStore.currentLogicalGroup.name}
          </Text>
        </ItemName>

        <ExecutionsDoughnutChart
          data={integrationStore.currentLogicalGroup}
          animated
          extraStyling={css`
            margin: 1.5rem 0 0 0;
          `}
        />
      </Fragment>
    );

  const renderApplications = () => {
    return applicationsArray &&
      integrationStore.currentFlowDiagramData &&
      integrationStore.currentFlowDiagramData.applications.length > 0 ? (
      applicationsArray.map((applicationsColumn, index) => {
        return (
          <ApplicationsColumn key={index}>
            {applicationsColumn.map((application, applIndex) => {
              return (
                <Application
                  small
                  key={applIndex}
                  className={`application application${application.id}relation`}
                  onMouseOver={() => handleApplicationMouseOver(application)}
                  onMouseLeave={() => handleApplicationMouseLeave(application)}
                >
                  {application.name}
                </Application>
              );
            })}
          </ApplicationsColumn>
        );
      })
    ) : (
      <NoInfo small>There are no applications defined</NoInfo>
    );
  };

  const handleApplicationMouseOver = (application: IntegrationApplication) => {
    highlightApplicationRelations(`application${application.id}relation`, true);
  };

  const handleApplicationMouseLeave = (application: IntegrationApplication) => {
    highlightApplicationRelations(
      `application${application.id}relation`,
      false
    );
  };

  const handleInteractivity = () => {
    integrationStore.showSidePanel && integrationStore.setShowSidePanel(false);
  };

  const handleNodeMove = () => {
    integrationStore.showTraffic && integrationStore.setShowTraffic(false);
  };

  const handleLayoutSave = (
    layout: LogicalGroupLayoutPositionSave[]
  ): Promise<boolean> | undefined => {
    return (
      integrationStore.openedLogicalGroup &&
      integrationStore.saveFlowDiagramLayouting(
        integrationStore.openedLogicalGroup.id,
        layout
      )
    );
  };

  const handleDataReload = () => {
    integrationStore.openedLogicalGroup &&
      integrationStore.getFlowDiagramData(
        integrationStore.openedLogicalGroup.id
      );
  };

  return (
    <Container>
      {integrationStore.currentFlowDiagramData ? (
        integrationStore.currentFlowDiagramData.logicalGroups.length > 0 ? (
          <FlowDiagram
            nodes={nodes}
            edges={edges}
            height="90vh"
            onNodeMove={handleNodeMove}
            interactive
            onInteractivity={handleInteractivity}
            connectionHovering
            onSave={handleLayoutSave}
            extraStyling={css`
              flex: 1;
            `}
            integrationStore={integrationStore}
          />
        ) : (
          <Modal
            customText="You don't have any integrations on this level."
            background
          />
        )
      ) : (
        <Modal
          type={ModalType.DataLoadFailed}
          action={handleDataReload}
          background
        />
      )}
      <SidePanel open={integrationStore.showSidePanel}>
        {renderSidePanelButton()}

        <SidePanelContent
          id="sidepanel-content"
          open={integrationStore.showSidePanel}
        >
          <SidePanelActions>
            <Switch
              id="traffic-switch"
              label="Show traffic"
              value={integrationStore.showTraffic}
              onChange={(show) => {
                integrationStore.setShowTraffic(show);
              }}
              extraStyling={css`
                margin-right: 2rem;
              `}
            />
            <Button disabled>Show logs</Button>
          </SidePanelActions>

          <LogicalGroupInfo>
            {rederOpenedLogicalGroupInfo()}

            <Title
              large
              dark
              style={{ borderTop: "1px solid #cfcfcf", paddingTop: "1.5rem" }}
            >
              Selected item
            </Title>
            <Text small semibold style={{ margin: "1rem 0 0.5rem 0" }}>
              Information
            </Text>
            <ItemInfo id="hovered-item-info">
              {integrationStore.currentLogicalGroup ? (
                renderLogicalGroupInfo()
              ) : integrationStore.currentPlatformComponent ? (
                renderPlatformComponentInfo()
              ) : (
                <NoInfo id="hover-info" small>
                  Hover over an item to see its details
                </NoInfo>
              )}
            </ItemInfo>
          </LogicalGroupInfo>

          <Text small semibold style={{ margin: "0.5rem 0" }}>
            Application calls
          </Text>
          <Applications>{renderApplications()}</Applications>
        </SidePanelContent>
      </SidePanel>
    </Container>
  );
};

export default inject("integrationStore", "generalStore")(
  observer(
    IntegrationsFirstLevelDiagram as FunctionComponent<
      Omit<Props, "integrationStore" | "generalStore">
    >
  )
);
