import { action, makeObservable, observable } from "mobx";
import { BaseCustomer, Customer } from "model/Customer";
import { CustomerManagementApi } from "services/apis/CustomerManagementApi";
import LoaderStore from "./LoaderStore";

class CustomerManagementStore {
  @observable
  customers: BaseCustomer[] | undefined = undefined;

  constructor(
    public api: CustomerManagementApi,
    private loaderStore: LoaderStore
  ) {
    makeObservable(this);
  }

  @action
  getCustomers = async () => {
    this.loaderStore.setLoading();

    const response = await this.api.getCustomers();
    if (response.success) {
      const { data } = response;
      this.customers = data;
    } else {
      //const { errorData } = response;
      console.log("Something went wrong when trying to fetch customers");
    }

    this.loaderStore.stopLoading();
  };

  @action
  getCustomerDetailMetaData = async () => {
    this.loaderStore.setLoading();

    const response = await this.api.getCustomerDetailMetaData();
    if (response.success) {
      const { data } = response;
      this.loaderStore.stopLoading();
      return data;
    } else {
      //const { errorData } = response;
      console.log(
        "Something went wrong when trying to fetch customers meta data"
      );
      this.loaderStore.stopLoading();
      return false;
    }
  };

  @action
  getCustomerDetail = async (customerId: number) => {
    this.loaderStore.setLoading();

    const response = await this.api.getCustomerDetail(customerId);
    if (response.success) {
      const { data } = response;
      this.loaderStore.stopLoading();
      return data;
    } else {
      //const { errorData } = response;
      console.log("Something went wrong when trying to fetch customers");
      this.loaderStore.stopLoading();
      return false;
    }
  };

  @action
  addCustomer = async (customer: Customer) => {
    this.loaderStore.setLoading();

    const response = await this.api.addCustomer(customer);
    if (response.success) {
      this.loaderStore.stopLoading();
      await this.getCustomers();
      return true;
    } else {
      //const { errorData } = response;
      console.log("Something went wrong when trying to add customer");
      this.loaderStore.stopLoading();
      return false;
    }
  };

  @action
  editCustomer = async (customer: Customer) => {
    this.loaderStore.setLoading();

    const response = await this.api.editCustomer(customer);
    if (response.success) {
      this.loaderStore.stopLoading();
      await this.getCustomers();
      return true;
    } else {
      //const { errorData } = response;
      console.log("Something went wrong when trying to edit customer");
      this.loaderStore.stopLoading();
      return false;
    }
  };

  @action
  deleteCustomer = async (customerId: number) => {
    this.loaderStore.setLoading();

    const response = await this.api.deleteCustomer(customerId);
    if (response.success) {
      this.loaderStore.stopLoading();
      await this.getCustomers();
      return true;
    } else {
      //const { errorData } = response;
      console.log("Something went wrong when trying to delete customer");
      this.loaderStore.stopLoading();
      return false;
    }
  };

  @action
  reset = () => {
    this.customers = undefined;
  };
}

export default CustomerManagementStore;
