import { CrudModal } from "components/common/CrudModal";
import TextInput from "components/common/TextInput";
import { SettingsLogicalGroup } from "model/LogicalGroup";
import React, { FunctionComponent, useState } from "react";
import styled, { css } from "styled-components";

interface Props {
    logicalGroup: SettingsLogicalGroup;
    close(): void;
}

export const emptyNameErrorText = "Please fill in a name.";

const InputContainer = styled.div`
  margin-bottom: 1rem;
`;

export const SettingLogicalGroupRenameModal: FunctionComponent<Props> = ({
    logicalGroup,
    close
}) => {
    const [name, setName] = useState<string>(logicalGroup.name);
    const [nameError, setNameError] = useState(false);
    const renameLogicalGroup = (value: string) => {
        logicalGroup.name = value;
        close();
    }

    return <CrudModal
        className="add-logical-group-modal"
        title={"Rename group"}
        content={
            <div>
                <InputContainer>
                    <TextInput
                        className="group-name-input"
                        label="Group name"
                        defaultValue={name}
                        mandatory
                        error={nameError}
                        errorText={emptyNameErrorText}
                        onValueChange={(value) => setName(value)}
                    />
                </InputContainer>
            </div>
        }
        cancel={() => {
            close();
        }}
        action={() => {
            const emptyName = name === "";

            if (emptyName) {
                setNameError(true);
            } else {
                renameLogicalGroup(name);
                close();
            }
        }}
        extraStyling={css`
        min-width: 25rem;
      `}
    />
}