import { ExtraStylingType } from "model/Styles";
import React from "react";
import styled, { css } from "styled-components";
import { Text, Button } from "styles/common";

interface Props {
  className?: string;
  title: string;
  content: any;
  disableAction?: boolean;
  action(): void;
  cancel(): void;
  extraStyling?: ExtraStylingType;
}

const Background = styled.div`
  content: "";
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  background: hsla(0, 0%, 100%, 0.5);
  z-index: 101;
`;

const Container = styled.div<{ extraStyling: ExtraStylingType }>`
  position: absolute;
  left: 50%;
  top: 50%;
  width: auto;
  max-width: 30rem;
  min-width: 230px;
  padding: 1rem 1.25rem 4.5rem 1.25rem;
  border: 1px solid #cbd7df;
  background-color: #fff;
  border-radius: 0.25rem;
  transform: translate(-50%, -50%);
  box-sizing: border-box;
  z-index: 101;
  transition: opacity 0.15s linear;

  ${(props) => props.extraStyling}
`;

const Title = styled(Text)`
  margin-bottom: 1.5rem;
`;

const Actions = styled.div`
  position: absolute;
  bottom: 1rem;
  left: 0;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 1rem;
`;

export const CrudModal = ({
  className = "",
  title,
  content,
  disableAction = false,
  action,
  cancel,
  extraStyling = css``,
}: Props) => {
  return (
    <Background>
      <Container
        className={`crud-modal ${className}`}
        extraStyling={extraStyling}
      >
        <Title dark large semibold uppercase>
          {title}
        </Title>
        <form>{content}</form>
        <Actions>
          <Button
            className="crud-modal-cancel-button"
            simple
            small
            onClick={cancel}
          >
            Cancel
          </Button>
          <Button
            className="crud-modal-action-button"
            small
            disabled={disableAction}
            onClick={() => {
              !disableAction && action();
            }}
          >
            Save
          </Button>
        </Actions>
      </Container>
    </Background>
  );
};
