import { ExecutionSuccessStatus } from "./Status";

export class ExecutionSearchFilter {
    constructor(filterString: string | undefined, successStatusFilter: ExecutionSuccessStatus | undefined) {
        this.filterString = filterString;
        this.successStatusFilter = successStatusFilter;
    }

    filterString?: string | undefined;
    successStatusFilter?: ExecutionSuccessStatus | undefined;

    hasFilterValues(): boolean {
        return this.filterString !== undefined && this.filterString !== "" || this.successStatusFilter !== undefined;
    }
}