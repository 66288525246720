import { inject, observer } from "mobx-react";
import React, { FunctionComponent, useEffect, useState } from "react";
import { Prompt } from "react-router";
import { Route } from "model/Navigation";
import CustomerManagementStore from "stores/CustomerManagementStore";
import Card from "components/common/Card";
import { CustomersList } from "components/Customers/CustomersList";
import { Customer, CustomerDetailMetaData } from "model/Customer";
import { CustomerCrud } from "components/Customers/CustomerCrud";
import AuthenticationStore from "stores/AuthenticationStore";
import { PermissionArea, PermissionType } from "model/Claim";
import { NoAccessModal } from "components/common/Modal";

interface Props {
  customerManagementStore: CustomerManagementStore;
  authenticationStore: AuthenticationStore;
}

const CustomerManagement: FunctionComponent<Props> = ({
  customerManagementStore,
  authenticationStore,
}) => {
  const [dataLoad, setDataLoad] = useState(false);

  const accessViewCustomer = authenticationStore.userHasPermission(
    PermissionArea.Customer,
    PermissionType.View
  );

  const accessEditCustomer = authenticationStore.userHasPermission(
    PermissionArea.Customer,
    PermissionType.Edit
  );

  const accessDeleteCustomer = authenticationStore.userHasPermission(
    PermissionArea.Customer,
    PermissionType.Delete
  );

  const [selectedCustomerId, setSelectedCustomerId] = useState<
    number | undefined
  >(undefined);

  const [selectedCustomerDetail, setSelectedCustomerDetail] = useState<
    Customer | undefined
  >(undefined);

  const [customerDetailMetaData, setCustomerDetailMetaData] = useState<
    CustomerDetailMetaData | undefined
  >(undefined);

  const [editedCustomer, setEditedCustomer] = useState(false);

  useEffect(() => {
    accessViewCustomer &&
      customerManagementStore.getCustomers().finally(() => {
        setDataLoad(true);
      });
  }, [accessViewCustomer, customerManagementStore]);

  const handleDataReload = () => {
    accessViewCustomer && customerManagementStore.getCustomers();
  };

  const handleOpenCustomerCrud = (customerId?: number) => {
    if (accessEditCustomer) {
      customerManagementStore.getCustomerDetailMetaData().then((metaData) => {
        metaData && setCustomerDetailMetaData(metaData);

        // Edit mode
        if (customerId) {
          customerManagementStore
            .getCustomerDetail(customerId)
            .then((customer) => {
              customer && setSelectedCustomerDetail(customer);
            });
        }
      });
    }
  };

  const resetCrud = () => {
    setCustomerDetailMetaData(undefined);
    setSelectedCustomerDetail(undefined);
    setSelectedCustomerId(undefined);
  };

  return (
    <>
      <Prompt
        message={(location) => {
          if (!location.pathname.startsWith(Route.CustomerManagement)) {
            if (editedCustomer) {
              return "Are you sure you want to leave this page? You have unsaved changes.";
            } else {
              // Reset data when leaving page
              customerManagementStore.reset();
              return true;
            }
          }

          return true;
        }}
      />
      {customerDetailMetaData &&
      (selectedCustomerId !== undefined ? !!selectedCustomerDetail : true) &&
      accessViewCustomer &&
      accessEditCustomer ? (
        <CustomerCrud
          customerDetailMetaData={customerDetailMetaData}
          originalCustomer={selectedCustomerDetail}
          deleteAccess={accessDeleteCustomer}
          cancel={() => {
            resetCrud();
          }}
          addCustomer={(customer: Customer) => {
            customerManagementStore.addCustomer(customer).then((success) => {
              if (success) {
                resetCrud();
              }
            });
          }}
          editCustomer={(customer: Customer) => {
            customerManagementStore.editCustomer(customer).then((success) => {
              if (success) {
                resetCrud();

                authenticationStore.getUserPermissionsInfo();
              }
            });
          }}
          deleteCustomer={(customerId: number) => {
            customerManagementStore
              .deleteCustomer(customerId)
              .then((success) => {
                if (success) {
                  resetCrud();
                }
              });
          }}
          setEdited={() => setEditedCustomer(true)}
        />
      ) : (
        <Card>
          {!accessViewCustomer && <NoAccessModal />}

          <CustomersList
            authenticationStore={authenticationStore}
            customers={customerManagementStore.customers}
            defaultSubscription={authenticationStore.getDefaultSubscription()}
            viewAccess={accessViewCustomer}
            editAccess={accessEditCustomer}
            loading={!dataLoad}
            onAdd={() => {
              handleOpenCustomerCrud();
            }}
            onEdit={(customer) => {
              setSelectedCustomerId(customer.id);
              handleOpenCustomerCrud(customer.id);
            }}
            onCustomerSwitch={(customerId: number) => {
              if (authenticationStore.userPermissionsInfo) {
                const subscription =
                  authenticationStore.userPermissionsInfo.subscriptions.find(
                    (sub) => sub.customerId === customerId
                  );
                subscription &&
                  authenticationStore.switchToCustomer(
                    subscription.subscriptionId
                  );
              }
            }}
            retryDataLoading={handleDataReload}
          />
        </Card>
      )}
    </>
  );
};

export default inject(
  "customerManagementStore",
  "authenticationStore"
)(
  observer(
    CustomerManagement as FunctionComponent<
      Omit<Props, "customerManagementStore" | "authenticationStore">
    >
  )
);
