import React, { FunctionComponent } from "react";
import { List } from "components/common/List";
import styled from "styled-components";
import { ReportProActiveWorkItem } from "model/Report";
import { format } from "date-fns";

interface Props {
  proActiveWorkItems: ReportProActiveWorkItem[] | undefined;
  access: boolean;
  retryDataLoading(): void;
}

const Container = styled.div`
  margin-top: 1rem;
`;

const ReportProActiveWorkList: FunctionComponent<Props> = ({
  proActiveWorkItems,
  access,
  retryDataLoading,
}) => {
  return (
    <Container>
      <List
        headers={["Number", "Date", "Task", "Status"]}
        data={
          proActiveWorkItems
            ? proActiveWorkItems.map((item: ReportProActiveWorkItem) => {
                return {
                  cells: [
                    item.ticket,
                    format(item.date, "yyyy-MM-dd"),
                    item.task,
                    item.closed ? "Closed" : "Open",
                  ],
                };
              })
            : undefined
        }
        customColumnWidths={[1, 1, 6, 1]}
        hover
        access={access}
        noDataText="There are no pro active work items for this month."
        smallEmpty
        onRowClick={(rowId: number) => {
          proActiveWorkItems &&
            window.open(
              `https://horizon.cegeka.com/collaboration/ticket/${proActiveWorkItems[rowId].ticket}`
            );
        }}
        retryDataLoading={retryDataLoading}
      />
    </Container>
  );
};

export default ReportProActiveWorkList;
