import styled from "styled-components";
import { Text } from "styles/common";

export const Header = styled.div`
  margin-bottom: 1.5rem;
`;

export const HeaderTitle = styled(Text)`
  font-size: 1.25rem;
`;

export const HeaderExplanation = styled(Text)`
  max-width: 50rem;
`;

export const CardHeader = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.75rem;
  width: 100%;
  position: relative;
`;
