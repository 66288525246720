import { ExtraStylingType } from "model/Styles";
import React, { FunctionComponent } from "react";
import { Doughnut } from "react-chartjs-2";
import styled, { css } from "styled-components";
import { $textLight, chartColorPalette, Text } from "styles/common";

interface Props {
  data: number[] | undefined;
  extraStyling?: ExtraStylingType;
}

const Container = styled.div<{
  extraStyling: ExtraStylingType;
}>`
  display: flex;
  flex-direction: row;
  align-items: center;

  ${(props) => props.extraStyling};
`;

const size = "9rem";

const DoughnutContainer = styled.div`
  position: relative;
  display: flex;
  min-width: ${size};
  width: ${size};
  max-width: ${size};
`;

const MiddleContent = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  text-align: center;
`;

const NoDataImage = styled.div`
  min-width: ${size};
  width: ${size};
  max-width: ${size};
  height: ${size};
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const ReportDoughnutChart: FunctionComponent<Props> = ({
  data,
  extraStyling = css``,
}) => {
  const renderDoughnutChart = data && (
    <DoughnutContainer>
      <Doughnut
        data={{
          labels: ["Incidents", "Pro-active work", "Change requests"],
          datasets: [
            {
              data: data,
              backgroundColor: chartColorPalette,
              hoverBackgroundColor: chartColorPalette,
              weight: 15,
            },
          ],
        }}
        options={{
          layout: {
            padding: 0,
          },
          plugins: {
            legend: {
              display: false,
            },
            tooltip: {
              enabled: false,
              external: function (context: any) {
                // Tooltip Element
                var tooltipEl = document.getElementById(
                  "chartjs-tooltip-report-doughnut"
                );

                // Create element on first render
                if (!tooltipEl) {
                  tooltipEl = document.createElement("div");
                  tooltipEl.id = "chartjs-tooltip-report-doughnut";
                  tooltipEl.innerHTML = "<div></div>";
                  document.body.appendChild(tooltipEl);
                }

                // Hide if no tooltip
                var tooltipModel = context.tooltip;
                if (tooltipModel.opacity === 0) {
                  tooltipEl.style.display = "none";
                  return;
                }

                // Set caret Position
                tooltipEl.classList.remove("above", "below", "no-transform");
                if (tooltipModel.yAlign) {
                  tooltipEl.classList.add(tooltipModel.yAlign);
                } else {
                  tooltipEl.classList.add("no-transform");
                }

                function getBody(bodyItem: any) {
                  return bodyItem.lines;
                }

                // Set tooltip content
                if (tooltipModel.body) {
                  var bodyLines = tooltipModel.body.map(getBody);

                  let innerHtml = "";

                  // Content
                  bodyLines.forEach(function (body: any, i: number) {
                    const statusColors = [...tooltipModel.labelColors];
                    var colors = statusColors[i];
                    innerHtml += `<div class="block" style="display: flex; flex-direction: row; align-items: center; width: 100%">`;
                    innerHtml += `<div style="width: 0.8rem; min-width: 0.8rem; height: 0.8rem; min-height: 0.8rem; background-color: ${colors.backgroundColor}; margin-right: 0.5rem"></div>`;
                    const label = body[0].split(":")[0];
                    const value = body[0].split(":")[1];
                    innerHtml += `<p>${label}: <span style="font-weight: 700">${value}</span></p>`;
                    innerHtml += `</div>`;
                  });

                  var tableRoot = tooltipEl.querySelector("div");
                  if (tableRoot) {
                    tableRoot.innerHTML = innerHtml;
                  }
                }

                var position = context.chart.canvas.getBoundingClientRect();

                // Display, position, and set styles for font
                tooltipEl.style.display = "flex";
                tooltipEl.style.position = "absolute";
                tooltipEl.style.left =
                  position.left +
                  window.pageXOffset +
                  tooltipModel.caretX +
                  "px";
                tooltipEl.style.top =
                  position.top +
                  window.pageYOffset +
                  tooltipModel.caretY +
                  "px";
                tooltipEl.style.padding =
                  tooltipModel.padding + "px " + tooltipModel.padding + "px";
                tooltipEl.style.borderRadius = "0.1rem";
                tooltipEl.style.display = "flex";
                tooltipEl.style.flexDirection = "row";
                tooltipEl.style.boxShadow =
                  "0px 1px 1px 1px rgba(0, 0, 0, 0.15)";
                tooltipEl.style.backgroundColor = "#fefefe";
                tooltipEl.style.padding = "0.5rem 0.75rem";
                tooltipEl.style.pointerEvents = "none";
                tooltipEl.style.color = $textLight;
              },
            },
          },
          //@ts-ignore
          cutout: 60,
          borderWidth: 0,
          animation: { duration: 0 },
        }}
      />

      <MiddleContent>
        <Text
          dark
          semibold
          style={{
            fontSize: "2rem",
          }}
        >
          {data && data.reduce((a, b) => a + b, 0)}
        </Text>
        <Text small dark semibold>
          tickets
        </Text>
      </MiddleContent>
    </DoughnutContainer>
  );

  const renderNoData = (
    <NoDataImage>
      <img
        src="/static/media/empty-donut-chart.144c5be4.svg"
        alt="Donut has no data"
        width="144px"
        height="144px"
      />
    </NoDataImage>
  );

  return (
    <Container extraStyling={extraStyling}>
      {data
        ? data.length > 0
          ? renderDoughnutChart
          : renderNoData
        : renderNoData}
    </Container>
  );
};
