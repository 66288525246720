import IconComponent from "components/common/Icon";
import { Icon } from "model/Icon";
import { ExtraStylingType } from "model/Styles";
import React, { FunctionComponent, useState } from "react";

import styled, { css } from "styled-components";
import {
  Text,
  semiBoldText,
  darkText,
  $grey,
  $statusFail,
} from "styles/common";

export interface Props {
  header?: any;
  darkBorder?: boolean;
  collapsable?: boolean;
  error?: boolean;
  errorText?: string;
  extraStyling?: ExtraStylingType;
  extraContentStyling?: ExtraStylingType;
}

export const FilterHeader = styled.div<{ space?: boolean }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;

  ${(props) =>
    props.space &&
    css`
      justify-content: space-between;
    `}
`;

export const CardRowContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 1.875rem;
`;

const Container = styled.div<{
  darkBorder: boolean;
  extraStyling?: ExtraStylingType;
}>`
  background-color: hsla(0, 0%, 100%, 0.9);
  margin-bottom: 1.875rem;
  box-shadow: 0 2px 16px 0 rgb(203 215 223 / 29%);
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-clip: border-box;
  border: 1px solid ${(props) => (props.darkBorder ? $grey : "#eaeff2")};
  border-radius: calc(0.25rem - 1px) calc(0.25rem - 1px) 0 0;

  ${(props) => props.extraStyling}
`;

const Header = styled.div<{ collapsable: boolean }>`
  padding: 1.5rem 1.5rem 1.5rem 1.5rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  ${(props) =>
    props.collapsable &&
    css`
      cursor: pointer;
    `}

  p:first-child {
    ${darkText}
    ${semiBoldText}
    font-size: 1rem;
    text-transform: uppercase;
    display: flex;
    flex-direction: row;
    align-items: center;
  }
`;

const CollapseIcon = styled(IconComponent)<{ collapsed: boolean }>`
  transform: rotate(${(props) => (props.collapsed ? "180deg" : "0deg")});
`;

const Content = styled.div<{
  hasHeader: boolean;
  collapsed: boolean;
  extraContentStyling?: ExtraStylingType;
}>`
  flex: 1 1 auto;
  min-height: 1px;
  padding: ${(props) =>
    props.hasHeader ? "0rem 1.5rem 10px 1.5rem" : "1.5rem 1.5rem 10px 1.5rem"};

  ${(props) =>
    props.collapsed
      ? css`
          display: none;
        `
      : css`
          display: block;
        `}

  ${(props) => props.extraContentStyling}
`;

export const CardSpacer = styled.div`
  width: calc(100% + 3rem);
  position: relative;
  left: -1.5rem;
  height: 1px;
  border-bottom: 1px solid #eaeff2;
  margin-bottom: 1.5rem;
`;

const Error = styled(Text)<{ collapsable: boolean }>`
  margin: 0 0 0.4rem 0rem;
  font-size: 0.8rem;
  position: absolute;
  top: ${(props) => (props.collapsable ? "1.3rem" : "1.5rem")};
  right: ${(props) => (props.collapsable ? "3.5rem" : "1.5rem")};
`;

const Card: FunctionComponent<Props> = ({
  header,
  darkBorder = false,
  collapsable = false,
  error = false,
  errorText = "",
  extraStyling = css``,
  extraContentStyling = css``,
  children,
}) => {
  const [collapsed, setCollapsed] = useState(false);

  const handleHeaderClick = () => {
    collapsable && setCollapsed(!collapsed);
  };

  return (
    <Container
      extraStyling={extraStyling}
      className="card"
      darkBorder={darkBorder}
    >
      {header && (
        <Header
          className="card-header"
          collapsable={collapsable}
          onClick={handleHeaderClick}
        >
          {header}
          {error && (
            <Error collapsable={collapsable} small semibold color={$statusFail}>
              {errorText}
            </Error>
          )}
          {collapsable && (
            <CollapseIcon
              className="collapse-icon"
              collapsed={collapsed}
              name={Icon.arrowdown}
              height={"13px"}
            />
          )}
        </Header>
      )}
      <Content
        className="card-content"
        hasHeader={!!header}
        collapsed={collapsed}
        extraContentStyling={extraContentStyling}
      >
        {children}
      </Content>
    </Container>
  );
};

export default Card as FunctionComponent<Props>;
