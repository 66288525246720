import Card from "components/common/Card";
import Loader from "components/common/Loader";
import { Modal, ModalType, NoAccessModal } from "components/common/Modal";
import { SettingsLogicalGroup } from "model/LogicalGroup";
import React, { FunctionComponent, useState } from "react";
import styled, { css } from "styled-components";
import { Text, Button, HeaderWithAdd } from "styles/common";
import { moveDownInArray, moveUpInArray } from "utils/Array";
import { SettingLogicalGroupModal } from "../SettingLogicalGroupModal";
import { SettingLogicalGroupRenameModal } from "../SettingLogicalGroupRenameModal";
import SettingGroupWorkflowTreeItem from "./SettingGroupWorkflowTreeItem";

export interface Props {
  logicalGroups: SettingsLogicalGroup[] | undefined;
  viewAccess: boolean;
  editAccess: boolean;
  deleteAccess: boolean;
  editLogicalGroups(groups: SettingsLogicalGroup[]): void;
  selectedLogicalGroup: SettingsLogicalGroup | undefined;
  setSelectedLogicalGroup(logicalGroup: SettingsLogicalGroup | undefined): void;
  loading: boolean;
  retryDataLoading(): void;
}

const Content = styled.div`
  position: relative;
  padding-bottom: 1rem;
  height: 100%;
`;

const EmptyOrFailed = styled.div`
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

const NoData = styled(Text)`
  padding: 0.3rem 0.75rem 0.3rem 0.9rem;
  margin-bottom: 0.25rem;
`;

// Recursively (per level) find logical group and delete
const deleteLogicalGroupFromLevel = (
  logicalGroupLevel: SettingsLogicalGroup[],
  logicalGroup: SettingsLogicalGroup
): boolean => {
  let deleted = false;

  const index = logicalGroupLevel.indexOf(logicalGroup);

  if (index !== -1) {
    // Found on this level
    deleted = true;
    logicalGroupLevel.splice(index, 1);
  } else {
    // Not found on this level; search child-levels
    let i = 0;

    while (!deleted && i < logicalGroupLevel.length) {
      const group = logicalGroupLevel[i];
      if (group.logicalGroups) {
        deleted = deleteLogicalGroupFromLevel(
          group.logicalGroups,
          logicalGroup
        );
      }
      i++;
    }
  }

  return deleted;
};

// Recursively (per level) find logical group and change ranks
const moveLogicalGroupRankInLevel = (
  logicalGroupLevel: SettingsLogicalGroup[],
  logicalGroup: SettingsLogicalGroup,
  up: boolean
): boolean => {
  let moved = false;

  const index = logicalGroupLevel.indexOf(logicalGroup);

  if (index !== -1) {
    // Found on this level
    moved = true;
    up
      ? moveUpInArray(logicalGroup, logicalGroupLevel)
      : moveDownInArray(logicalGroup, logicalGroupLevel);

    logicalGroupLevel.forEach((group, index) => {
      group.rank = index;
    });
  } else {
    // Not found on this level; search child-levels
    let i = 0;

    while (!moved && i < logicalGroupLevel.length) {
      const group = logicalGroupLevel[i];
      if (group.logicalGroups) {
        moved = moveLogicalGroupRankInLevel(
          group.logicalGroups,
          logicalGroup,
          up
        );
      }
      i++;
    }
  }

  return moved;
};

// Recursively (per level) find parent and add as a child to that parent
const addLogicalGroupToParentInLevel = (
  logicalGroupLevel: SettingsLogicalGroup[],
  parentId: number,
  name: string,
  addID: number
): boolean => {
  let added = false;

  const parentIndex = logicalGroupLevel.findIndex(
    (group) => group.id === parentId
  );

  if (parentIndex !== -1) {
    // Parent found on current level
    added = true;
    const parent = logicalGroupLevel[parentIndex];

    parent.logicalGroups =
      parent.logicalGroups === undefined || parent.logicalGroups === null
        ? [
          {
            id: addID,
            rank: 0,
            name: name,
          },
        ]
        : [
          ...parent.logicalGroups,
          {
            id: addID,
            rank:
              parent.logicalGroups.length > 0
                ? parent.logicalGroups[parent.logicalGroups.length - 1].rank +
                1
                : 0,
            name: name,
          },
        ];
  } else {
    // Parent not found on current level; look at child-levels
    let i = 0;
    while (!added && i < logicalGroupLevel.length) {
      const group = logicalGroupLevel[i];
      if (group.logicalGroups) {
        added = addLogicalGroupToParentInLevel(
          group.logicalGroups,
          parentId,
          name,
          addID
        );
      }
      i++;
    }
  }

  return added;
};

const SettingGroupWorkflowTree = ({
  logicalGroups,
  viewAccess,
  editAccess,
  deleteAccess,
  editLogicalGroups,
  selectedLogicalGroup,
  setSelectedLogicalGroup,
  loading,
  retryDataLoading,
}: Props) => {
  // Temporary ID for adding new groups; Makes delete/move/add operations on this new group possible
  // Negative ID's won't exist in the DB so won't collide with existing groups
  const [addID, setAddID] = useState(-1);

  const [showAddLogicalGroupModal, setShowAddLogicalGroupModal] =
    useState(false);

  const [logicalGroupToRename, setLogicalGroupToRename] = useState<SettingsLogicalGroup>();

  const handleDeleteLogicalGroup = (logicalGroup: SettingsLogicalGroup) => {
    if (deleteAccess && logicalGroups) {
      deleteLogicalGroupFromLevel(logicalGroups, logicalGroup);
      editLogicalGroups(logicalGroups);
    }
  };

  const handleMoveUpLogicalGroup = (logicalGroup: SettingsLogicalGroup) => {
    if (editAccess && logicalGroups) {
      moveLogicalGroupRankInLevel(logicalGroups, logicalGroup, true);
      editLogicalGroups(logicalGroups);
    }
  };

  const handleMoveDownLogicalGroup = (logicalGroup: SettingsLogicalGroup) => {
    if (editAccess && logicalGroups) {
      moveLogicalGroupRankInLevel(logicalGroups, logicalGroup, false);
      editLogicalGroups(logicalGroups);
    }
  };

  const handleRenameLogicalGroup = (logicalGroup: SettingsLogicalGroup) => {
    if (editAccess && logicalGroups) {
      setLogicalGroupToRename(logicalGroup);
      editLogicalGroups(logicalGroups);
    }
  }

  const handleAddLogicalGroupClick = () => {
    setShowAddLogicalGroupModal(true);
  };

  const handleAddLogicalGroup = (name: string, parent: number | undefined) => {
    if (editAccess && logicalGroups) {
      if (parent === undefined) {
        // No parent: add new group on top level
        const newLogicalGroup = {
          id: addID,
          rank:
            logicalGroups.length > 0
              ? logicalGroups[logicalGroups.length - 1].rank + 1
              : 0,
          name: name,
        };

        logicalGroups.push(newLogicalGroup);
      } else {
        if (selectedLogicalGroup && selectedLogicalGroup.id === parent) {
          // Unselect parent because it will not be possible to add technical components to the parent anymore
          setSelectedLogicalGroup(undefined);
        }

        // Add as a child to parent group
        addLogicalGroupToParentInLevel(logicalGroups, parent, name, addID);
      }

      setAddID(addID - 1); // Update addID for possible next group add
      editLogicalGroups(logicalGroups);
    }
    setShowAddLogicalGroupModal(false);
  };

  const renderEmptyData = (
    <EmptyOrFailed>
      <img src="/static/media/no-data-icon.fd032bb9.svg" alt="no data icon" />
      {loading ? (
        <Loader component />
      ) : (
        <NoData large dark>
          You have not defined any logical groups yet.
        </NoData>
      )}
    </EmptyOrFailed>
  );

  const renderNoDataLoaded = (
    <EmptyOrFailed>
      <img src="/static/media/no-data-icon.fd032bb9.svg" alt="no data icon" />
      {viewAccess &&
        (loading ? (
          <Loader component />
        ) : (
          <Modal type={ModalType.DataLoadFailed} action={retryDataLoading} />
        ))}
    </EmptyOrFailed>
  );

  return (
    <Card
      header={
        <HeaderWithAdd>
          <p>Dashboard groups</p>

          {editAccess && (
            <Button
              className="add-group-button"
              onClick={(e) => {
                e.stopPropagation();
                handleAddLogicalGroupClick();
              }}
            >
              New group
            </Button>
          )}
        </HeaderWithAdd>
      }
      extraStyling={css`
        flex: 1;
      `}
    >
      {!viewAccess && <NoAccessModal />}

      <Content>
        {logicalGroups && logicalGroups.length > 0 ? (
          <div className="logical-groups-workflow-tree">
            {logicalGroups.map(
              (logicalGroup: SettingsLogicalGroup, index: number) => (
                <SettingGroupWorkflowTreeItem
                  key={index}
                  index={index}
                  item={logicalGroup}
                  levelCount={logicalGroups.length}
                  levelIndex={0}
                  selectedLogicalGroup={selectedLogicalGroup}
                  editAccess={editAccess}
                  deleteAccess={deleteAccess}
                  setSelectedLogicalGroup={setSelectedLogicalGroup}
                  deleteLogicalGroup={handleDeleteLogicalGroup}
                  moveUpLogicalGroup={handleMoveUpLogicalGroup}
                  moveDownLogicalGroup={handleMoveDownLogicalGroup}
                  renameLogicalGroup={handleRenameLogicalGroup}
                />
              )
            )}
          </div>
        ) : logicalGroups && logicalGroups.length === 0 ? (
          renderEmptyData
        ) : (
          renderNoDataLoaded
        )}
      </Content>

      {logicalGroupToRename && (
        <SettingLogicalGroupRenameModal logicalGroup={logicalGroupToRename} close={() => setLogicalGroupToRename(undefined)}></SettingLogicalGroupRenameModal>
      )}

      {logicalGroups && showAddLogicalGroupModal && (
        <SettingLogicalGroupModal
          logicalGroups={logicalGroups}
          add={(name, parent) => {
            handleAddLogicalGroup(name, parent);
          }}
          close={() => {
            setShowAddLogicalGroupModal(false);
          }}
          selectedParent={selectedLogicalGroup}
        />
      )}
    </Card>
  );
};

export default SettingGroupWorkflowTree as FunctionComponent<Props>;
