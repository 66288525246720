import { ExtraStylingType } from "model/Styles";
import React, { FunctionComponent } from "react";
import styled, { css } from "styled-components";
import { $blue4, textStyling } from "styles/common";

interface Props {
  label: string;
  value: boolean;
  name: string;
  disabled?: boolean;
  onValueChange(value: boolean): void;
  extraStyling?: ExtraStylingType;
}

const Container = styled.div<{ extraStyling: ExtraStylingType }>`
  position: relative;
  z-index: 1;
  display: block;
  min-height: 1.5rem;
  padding-left: 1.75rem;
  -webkit-print-color-adjust: exact;
  box-sizing: border-box;
  margin-bottom: 0.25rem;
  width: max-content;
`;

const Label = styled.label<{
  checked: boolean;
  disabled: boolean;
  small?: boolean;
}>`
  display: block;
  line-height: 1.4375rem;
  position: relative;
  margin-bottom: 0;
  vertical-align: top;
  ${textStyling}

  &:before {
    border-radius: 0.25rem;
    transition: background-color 0.15s ease-in-out,
      border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    position: absolute;
    top: 0.125rem;
    left: -1.75rem;
    display: block;
    width: 1.25rem;
    height: 1.25rem;
    pointer-events: none;
    content: "";
    background-color: #fff;
    border: 1px solid #adb5bd;
    box-shadow: none;
    box-sizing: border-box;
  }

  &:after {
    position: absolute;
    top: 0.125rem;
    left: -1.75rem;
    display: block;
    width: 1.25rem;
    height: 1.25rem;
    content: "";
    background: no-repeat 50%/50% 50%;
    box-sizing: border-box;
  }

  ${(props) =>
    props.checked &&
    css`
      &:before {
        color: ${$blue4};
        border-color: ${$blue4};
        background-color: ${$blue4};
      }

      &:after {
        background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3E%3Cpath fill='%23fff' d='M8.369.08A.196.196 0 018.532 0c.068 0 .122.027.163.08l.244.221A.29.29 0 019 .482c0 .067-.02.121-.061.161L2.83 6.67a.246.246 0 01-.173.08.246.246 0 01-.173-.08L.061 4.279A.215.215 0 010 4.118a.29.29 0 01.061-.181l.244-.22a.194.194 0 01.163-.081c.068 0 .122.027.163.081l2.016 1.988L8.369.08z'/%3E%3C/svg%3E");
      }
    `}

  ${(props) =>
    props.disabled
      ? css`
          opacity: 0.5;
        `
      : css`
          cursor: pointer;
        `}
`;

const Input = styled.input`
  box-sizing: border-box;
  padding: 0;
  position: absolute;
  left: 0;
  z-index: -1;
  width: 1.25rem;
  height: 1.375rem;
  opacity: 0;
  overflow: visible;
`;

const Checkbox: FunctionComponent<Props> = ({
  label,
  value,
  name,
  disabled = false,
  onValueChange,
  extraStyling = css``,
}) => {
  const handleChange = () => {
    !disabled && onValueChange(!value);
  };

  return (
    <Container extraStyling={extraStyling}>
      <Input
        id={name}
        type="checkbox"
        checked={value}
        onChange={handleChange}
      />
      <Label htmlFor={name} checked={value} disabled={disabled} small>
        {label}
      </Label>
    </Container>
  );
};

export default Checkbox as FunctionComponent<Props>;
