import { List } from "components/common/List";
import { format } from "date-fns";
import { Execution } from "model/Execution";
import { ExecutionDetails } from "model/ExecutionDetails";
import { executionSuccessStatuses } from "model/Status";
import React, { Fragment } from "react";
import styled from "styled-components";
import { semiBoldText, StatusCircle } from "styles/common";
import { getSuccessStatusColor } from "utils/Status";

interface Props {
  itemsToRender: Execution[] | undefined;
  access: boolean;
  loading: boolean;
  searchMode: boolean;
  onItemClick(item: Execution): void;
  retryDataLoading(): void;
  retryExecutionDetailsDataLoading?(transactionId: number): void
}

const Percentage = styled.span`
  margin-left: 0.5rem;
  ${semiBoldText}
`;

const headers = [
  "Execution date",
  "Logical Group",
  "Entrypoint",
  "Status",
];

const executionDetailsHeaders = [
  "Name",
  "Resource ID",
  "Execution date",
  "Execution duration",
  "Status",
]

export const ListItems = ({
  itemsToRender,
  searchMode,
  access,
  loading,
  onItemClick,
  retryDataLoading,
  retryExecutionDetailsDataLoading
}: Props) => {
  const handleItemClick = (rowIndex: number) => {
    itemsToRender && onItemClick(itemsToRender[rowIndex]);
  };

  const renderListCells = (list: Execution[]) =>
    list.map((item: Execution) => {
      return {
        cells: [
          format(Date.parse(item.firstTimestamp.toString()), "yyyy-MM-dd HH:mm:ss"),
          item.logicalGroupName,
          item.name,
          <StatusCircle
            color={
              executionSuccessStatuses[item.successStatus]?.color
            } />
        ],
        details: item.details ?
          <List
            className="details"
            data={renderExecutionDetailsListCells(item.details)}
            headers={
              (item.details &&
                item.details?.length > 0) ?
                executionDetailsHeaders : []
            }
            colorIndicator
            customColumnWidths={[12, 12, 4, 4, 1]}
            alignments={[undefined, undefined, undefined, undefined,"center"]}
            // cellPadding={["0", "0", "0", "0", "0"]}
            noDataText=""
            headerBackground={true}
            access={access}
            retryDataLoading={() => { if (retryExecutionDetailsDataLoading) { retryExecutionDetailsDataLoading(item.transactionId); } }}
          /> : null,
        color: executionSuccessStatuses[item.successStatus].color,
      };
    });

  const renderExecutionDetailsListCells = (list: ExecutionDetails[] | undefined) =>
    list?.map((item: ExecutionDetails) => {
      return {
        cells: [
          item.name,
          item.resourceId,
          format(Date.parse(item.executionTimestamp.toString()), "yyyy-MM-dd HH:mm:ss"),
          item.executionDuration,
          <StatusCircle
            color={
              executionSuccessStatuses[item.successStatus]
                .color
            } 
            />
        ],
        color: executionSuccessStatuses[item.successStatus].color,
      };
    });

  return (
    <Fragment>
      <List
        className="executions-list-items"
        headers={
          (itemsToRender &&
            itemsToRender.length > 0) ?
            headers : []
        }
        data={itemsToRender ? renderListCells(itemsToRender) : undefined}
        hover
        colorIndicator
        customColumnWidths={[0.4, 0.8, 1.7, 0.1]}
        alignments={[undefined, undefined, undefined, "center"]}
        noDataText="You don't have any executions for this environment yet."
        access={access}
        loading={loading}
        searchMode={searchMode}
        fullWidth={true}
        onRowClick={handleItemClick}
        retryDataLoading={retryDataLoading}
        hasDetails
      />
    </Fragment>
  );
};
