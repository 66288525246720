import { BrowserAuthOptions, Configuration, LogLevel } from "@azure/msal-browser";
import config from "./config";

// Browser check variables
// If you support IE, our recommendation is that you sign-in using Redirect APIs
// If you as a developer are testing using Edge InPrivate mode, please add "isEdge" to the if check
const ua = window.navigator.userAgent;
const msie = ua.indexOf("MSIE ");
const msie11 = ua.indexOf("Trident/");
const msedge = ua.indexOf("Edge/");
const firefox = ua.indexOf("Firefox");
const isIE = msie > 0 || msie11 > 0;
const isEdge = msedge > 0;
const isFirefox = firefox > 0; // Only needed if you need to support the redirect flow in Firefox incognito

// MSAL configuration
export class msalConfiguration {
  static getMsalConfig(): Configuration {
    return {
      //@ts-ignore
      auth: config.auth as BrowserAuthOptions,
      cache: {
        storeAuthStateInCookie: isIE || isEdge || isFirefox,
      },
      system: {
        loggerOptions: {
          loggerCallback: (level, message, containsPii) => {
            //@ts-ignore
            if (containsPii || level > (config.msalLogLevel as LogLevel)) {
              return;
            }
            switch (level) {
              case LogLevel.Error:
                console.error(message);
                return;
              case LogLevel.Info:
                console.info(message);
                return;
              case LogLevel.Verbose:
                console.debug(message);
                return;
              case LogLevel.Warning:
                console.warn(message);
                return;
              default:
                return;
            }
          },
        },
      }
    };
  }
};
