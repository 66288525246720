import { CrudModal } from "components/common/CrudModal";
import RadioButtonGroup from "components/common/RadioButtonGroup";
import TextInput from "components/common/TextInput";
import { CustomerLocation } from "model/Customer";
import {
  CustomerEnvironmentDeploymentArea,
  CustomerEnvironmentDeploymentAreaAreaType,
} from "model/DeploymentArea";
import React, { FunctionComponent, useEffect, useState } from "react";
import styled, { css } from "styled-components";
import { $grey, Select, Text, TooltipText } from "styles/common";

interface Props {
  originalDeploymentArea: CustomerEnvironmentDeploymentArea | undefined;
  areaTypes: CustomerEnvironmentDeploymentAreaAreaType[];
  existingLocations: CustomerLocation[];
  environment: string;
  close(): void;
  add(environment: CustomerEnvironmentDeploymentArea): void;
  edit(environment: CustomerEnvironmentDeploymentArea): void;
}

const Container = styled.div``;

const InputContainer = styled.div`
  margin-bottom: 1rem;
`;

const AreaTypeContainer = styled.div`
  margin-left: 0.3rem;
  padding: 0.75rem 0 0.25rem 1rem;
  border-left: 1px solid ${$grey};
  box-sizing: border-box;
`;

const FilterContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 1rem;
`;

export const emptyNameErrorText = "Please fill in a name.";
export const emptyParentAccountErrorText =
  "Please fill in a parent account name.";
export const emptyGuidErrorText = "Please fill in a guid.";
export const emptyLocationErrorText = "Please fill in a location.";

export const CustomerEnvironmentDeploymentAreaModal: FunctionComponent<Props> =
  ({
    originalDeploymentArea,
    areaTypes,
    existingLocations,
    environment,
    close,
    add,
    edit,
  }) => {
    const [deploymentArea, setDeploymentArea] =
      useState<CustomerEnvironmentDeploymentArea>(
        originalDeploymentArea
          ? originalDeploymentArea
          : {
              id: 0,
              name: "",
              location: "",
              locationId: null, // A non-existing location has to be sent to the BE with id null
              areaType: areaTypes[0].id,
              parentAccount: "",
              guid: "",
              componentPattern: "",
              filterBy: areaTypes[0].filtersBy
                ? areaTypes[0].filtersBy[0].id
                : undefined,
              filter: "",
            }
      );

    const [currentAreaType, setCurrentAreaType] = useState<
      CustomerEnvironmentDeploymentAreaAreaType | undefined
    >(undefined);

    const [nameError, setNameError] = useState(false);
    const [locationError, setLocationError] = useState(false);
    const [parentAccountError, setParentAccountError] = useState(false);
    const [guidError, setGuidError] = useState(false);

    useEffect(() => {
      setCurrentAreaType(
        areaTypes.find((areaType) => areaType.id === deploymentArea.areaType) ||
          areaTypes[0]
      );
    }, [areaTypes, deploymentArea.areaType]);

    return (
      <CrudModal
        className="customer-environment-deployment-area-modal"
        title={
          originalDeploymentArea
            ? `Edit deployment area for environment "${environment}"`
            : `Add deployment area for environment "${environment}"`
        }
        content={
          <Container>
            <InputContainer>
              <TextInput
                className="name-input"
                label="Friendly name"
                defaultValue={deploymentArea.name}
                mandatory
                error={nameError}
                errorText={emptyNameErrorText}
                infoTooltip="friendly-name-info-tooltip"
                infoTooltipContent={
                  <TooltipText>
                    <Text>A friendly name for the deployment area</Text>
                    <Text>
                      E.g. "Production on Azure", "Acceptance on Boomi", ...
                    </Text>
                  </TooltipText>
                }
                onValueChange={(value) => {
                  setNameError(false);
                  setDeploymentArea({ ...deploymentArea, name: value });
                }}
              />
            </InputContainer>

            <InputContainer style={{ flex: 1 }}>
              <TextInput
                className="location-input"
                label="Location"
                defaultValue={
                  deploymentArea.location ? deploymentArea.location : ""
                }
                dataList={existingLocations.map((location) => location.name)}
                dataListName="existing-locations-list"
                mandatory
                error={locationError}
                errorText={emptyLocationErrorText}
                infoTooltip="location-info-tooltip"
                infoTooltipContent={
                  <TooltipText>
                    <Text>A name for the location of the resources</Text>
                    <Text>
                      Location is used to group components in the "IT
                      Operations" view
                    </Text>
                  </TooltipText>
                }
                onValueChange={(value) => {
                  setLocationError(false);

                  const location =
                    existingLocations.length > 0 &&
                    existingLocations.find(
                      (location) =>
                        location.name.toLowerCase() === value.toLowerCase()
                    );
                  setDeploymentArea({
                    ...deploymentArea,
                    locationId: !!location ? location.id : null,
                    location: value,
                  });
                }}
              />
            </InputContainer>

            <InputContainer>
              <Text
                semibold
                uppercase
                extraStyling={css`
                  margin: 1.5rem 0 0.5rem 0;
                `}
              >
                Area Type
              </Text>
              <RadioButtonGroup
                className="area-type-radio-group"
                groupName="area-type"
                radios={areaTypes.map((areaType) => ({
                  value: areaType.id.toString(),
                  label: areaType.name,
                }))}
                defaultValue={deploymentArea.areaType.toString()}
                onValueChange={(areaType: string) => {
                  const selectedAreaType =
                    areaTypes.find((at) => at.id === parseInt(areaType)) ||
                    currentAreaType;

                  setCurrentAreaType(selectedAreaType);

                  // Reset values that don't exists on new area type
                  setDeploymentArea({
                    ...deploymentArea,
                    areaType: parseInt(areaType),
                    parentAccount:
                      selectedAreaType && selectedAreaType.parentAccountIdName
                        ? deploymentArea.parentAccount
                        : "",
                    guid:
                      selectedAreaType && selectedAreaType.guidName
                        ? deploymentArea.guid
                        : "",
                    componentPattern:
                      selectedAreaType && selectedAreaType.hasComponentPattern
                        ? deploymentArea.componentPattern
                        : "",
                    filterBy:
                      selectedAreaType && selectedAreaType.filtersBy
                        ? selectedAreaType.filtersBy[0].id
                        : undefined,
                    filter:
                      selectedAreaType && !!selectedAreaType.filtersBy
                        ? deploymentArea.filter
                        : "",
                  });
                }}
              />
            </InputContainer>

            <AreaTypeContainer>
              {currentAreaType && currentAreaType.parentAccountIdName && (
                <InputContainer>
                  <TextInput
                    className="parent-account-id-input"
                    label={currentAreaType.parentAccountIdName}
                    defaultValue={deploymentArea.parentAccount}
                    mandatory
                    error={parentAccountError}
                    errorText={emptyParentAccountErrorText}
                    onValueChange={(value) => {
                      setParentAccountError(false);
                      setDeploymentArea({
                        ...deploymentArea,
                        parentAccount: value,
                      });
                    }}
                  />
                </InputContainer>
              )}

              {currentAreaType && currentAreaType.guidName && (
                <InputContainer>
                  <TextInput
                    className="guid-input"
                    label={`${currentAreaType.guidName} ID`}
                    defaultValue={deploymentArea.guid}
                    mandatory
                    error={guidError}
                    errorText={emptyGuidErrorText}
                    onValueChange={(value) => {
                      setGuidError(false);
                      setDeploymentArea({
                        ...deploymentArea,
                        guid: value,
                      });
                    }}
                  />
                </InputContainer>
              )}

              {currentAreaType && currentAreaType.hasComponentPattern && (
                <InputContainer>
                  <TextInput
                    className="component-pattern-input"
                    label={`Component pattern`}
                    defaultValue={deploymentArea.componentPattern}
                    onValueChange={(value) => {
                      setDeploymentArea({
                        ...deploymentArea,
                        componentPattern: value,
                      });
                    }}
                  />
                </InputContainer>
              )}

              {currentAreaType &&
                currentAreaType.filtersBy &&
                currentAreaType.filtersBy.length > 0 && (
                  <FilterContainer>
                    <InputContainer>
                      <Text
                        semibold
                        extraStyling={css`
                          margin-bottom: 0.3rem;
                        `}
                      >
                        Filter by
                      </Text>
                      <Select
                        defaultValue={
                          deploymentArea.filterBy ? deploymentArea.filterBy : ""
                        }
                        highlight
                        disabled={currentAreaType.filtersBy.length === 1}
                        onChange={(e) => {
                          setDeploymentArea({
                            ...deploymentArea,
                            filterBy: parseInt(e.target.value),
                          });
                        }}
                        extraStyling={css`
                          width: 20rem;
                        `}
                      >
                        {currentAreaType.filtersBy.map((filterBy, index) => (
                          <option key={index} value={filterBy.id}>
                            {filterBy.name}
                          </option>
                        ))}
                      </Select>
                    </InputContainer>
                    <InputContainer style={{ flex: 1 }}>
                      <TextInput
                        className="filter-input"
                        label="Filter"
                        defaultValue={deploymentArea.filter}
                        onValueChange={(value) => {
                          setDeploymentArea({
                            ...deploymentArea,
                            filter: value,
                          });
                        }}
                      />
                    </InputContainer>
                  </FilterContainer>
                )}
            </AreaTypeContainer>
          </Container>
        }
        cancel={() => {
          close();
        }}
        action={() => {
          const nameIsEmpty = deploymentArea.name === "";
          const locationIsEmpty = deploymentArea.location === "";
          const parentAccountExistsAndIsEmpty =
            currentAreaType &&
            currentAreaType.parentAccountIdName &&
            (!deploymentArea.parentAccount ||
              deploymentArea.parentAccount === "");
          const guidExistsAndIsEmpty =
            currentAreaType &&
            currentAreaType.guidName &&
            (!deploymentArea.guid || deploymentArea.guid === "");

          if (nameIsEmpty) {
            setNameError(true);
          }
          if (locationIsEmpty) {
            setLocationError(true);
          }
          if (parentAccountExistsAndIsEmpty) {
            setParentAccountError(true);
          }
          if (guidExistsAndIsEmpty) {
            setGuidError(true);
          }

          if (
            !nameIsEmpty &&
            !locationIsEmpty &&
            !parentAccountExistsAndIsEmpty &&
            !guidExistsAndIsEmpty
          ) {
            originalDeploymentArea ? edit(deploymentArea) : add(deploymentArea);
            close();
          }
        }}
        extraStyling={css`
          min-width: 50rem;
          min-height: 60vh;
        `}
      />
    );
  };
