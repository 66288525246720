import { action, makeObservable, observable } from "mobx";
import { BasicApplicationDatum } from "model/Application";
import { ApplicationApi } from "services/apis/ApplicationApi";
import LoaderStore from "./LoaderStore";
import GeneralStore from "./GeneralStore";
import { ApplicationEnvironment } from "model/Environment";

class ApplicationStore {
  @observable
  applicationsRuntime: BasicApplicationDatum[] | undefined = undefined;

  @observable
  applicationEnvironments: ApplicationEnvironment[] | undefined = undefined;

  constructor(
    public api: ApplicationApi,
    private generalStore: GeneralStore,
    private loaderStore: LoaderStore
  ) {
    makeObservable(this);
  }

  @action
  getApplicationsRuntime = async () => {
    if (this.generalStore.currentEnvironment !== undefined) {
      this.loaderStore.setLoading();

      const response = await this.api.getApplicationsRuntime(
        this.generalStore.currentEnvironment
      );

      if (response.success) {
        const { data } = response;
        this.applicationsRuntime = data;
      } else {
        //const { errorData } = response;
        console.log("Something went wrong when trying to applications runtime");
      }

      this.loaderStore.stopLoading();
    }
  };

  @action
  getApplicationData = async () => {
    this.loaderStore.setLoading();

    const response = await this.api.getApplicationData();

    if (response.success) {
      const { data } = response;
      this.applicationEnvironments = data;
    } else {
      //const { errorData } = response;
      console.log("Something went wrong when trying to fetch applications");
    }

    this.loaderStore.stopLoading();
  };

  @action
  reset = () => {
    this.applicationEnvironments = undefined;
  };

  // General page
  @action
  resetApplicationsRuntime = () => {
    this.applicationsRuntime = undefined;
  };
}

export default ApplicationStore;
