export interface CountryPhoneCode {
  country: string;
  calCode: string;
}

export interface PhoneNumber {
  code: string;
  number: string;
}

export const defaultCountryPhoneCode: CountryPhoneCode = {
  country: "BE",
  calCode: "32",
};
