import React, {
  FunctionComponent,
  useCallback,
  useEffect,
  useState,
} from "react";
import { UsageOfDate, UsageOfMonth } from "model/Usage";
import styled from "styled-components";
import { darkText } from "styles/common";
import { MonthsList } from "./MonthsList";
import { MonthDetail } from "./MonthDetail";

interface Props {
  data: UsageOfDate[] | undefined;
  access: boolean;
  selectedMonthData: UsageOfMonth | undefined;
  getSelectedMonthData(month: Date): void;
  retryDataLoading(): void;
}

export const maxMonthsPerColumn = 3;
export const columnsCount = 2;

const Container = styled.div`
  display: flex;
  flex-direction: row;
`;
export const Units = styled.span`
  ${darkText}
`;

const UsagePerMonth: FunctionComponent<Props> = ({
  data,
  access,
  selectedMonthData,
  getSelectedMonthData,
  retryDataLoading,
}) => {
  const [selectedMonth, setSelectedMonth] = useState<UsageOfDate | undefined>(
    undefined
  );

  const handleMonthSelection = useCallback(
    (monthIndex: number) => {
      if (data && data.length > 0) {
        const monthData = data[monthIndex];
        setSelectedMonth(monthData);
        getSelectedMonthData(new Date(monthData.date));
      }
    },
    [data, getSelectedMonthData]
  );

  useEffect(() => {
    handleMonthSelection(0);
  }, [handleMonthSelection, data]);

  return (
    <Container>
      <MonthsList
        data={data}
        access={access}
        selectedMonth={
          data && selectedMonth !== undefined
            ? data.indexOf(selectedMonth)
            : undefined
        }
        onMonthSelect={handleMonthSelection}
        retryDataLoading={retryDataLoading}
      />
      <MonthDetail data={selectedMonthData} />
    </Container>
  );
};

export default UsagePerMonth;
