import { CardRowContainer } from "components/common/Card";
import { inject, observer } from "mobx-react";
import React, { FunctionComponent, useEffect, useState } from "react";
import SettingStore from "stores/SettingStore";
import styled from "styled-components";
import { Button } from "styles/common";
import { Header, HeaderExplanation, HeaderTitle } from "./common";
import { Prompt } from "react-router";
import { Route } from "model/Navigation";
import SettingGroupWorkflowTree from "components/Settings/SettingGroupWorkflowTree";
import { SettingTechnicalComponents } from "components/Settings/SettingTechnicalComponents";
import { SettingsLogicalGroup } from "model/LogicalGroup";
import { SettingsTechnicalComponent } from "model/PlatformComponent";
import GeneralStore from "stores/GeneralStore";
import AuthenticationStore from "stores/AuthenticationStore";
import { PermissionArea, PermissionType } from "model/Claim";
import NavigationStore from "stores/NavigationStore";

interface Props {
  generalStore: GeneralStore;
  settingStore: SettingStore;
  authenticationStore: AuthenticationStore;
  navigationStore: NavigationStore;
}

const SaveButtonsContainer = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  display: flex;
  flex-direction: row;
  gap: 1rem;
`;

const GroupWorkflow: FunctionComponent<Props> = ({
  generalStore,
  settingStore,
  authenticationStore,
  navigationStore,
}) => {
  const [dataLoad, setDataLoad] = useState(false);

  const accessViewIntegration = authenticationStore.userHasPermission(
    PermissionArea.Integration,
    PermissionType.View
  );

  const accessEditIntegration = authenticationStore.userHasPermission(
    PermissionArea.Integration,
    PermissionType.Edit
  );

  const [logicalGroups, setLogicalGroups] = useState<
    SettingsLogicalGroup[] | undefined
  >(undefined);

  const [technicalComponents, setTechnicalComponents] = useState<
    SettingsTechnicalComponent[] | undefined
  >(undefined);

  const [selectedLogicalGroup, setSelectedLogicalGroup] = useState<
    SettingsLogicalGroup | undefined
  >(undefined);

  const [editedLogicalGroups, setEditedLogicalGroups] = useState(false);

  useEffect(() => {
    accessViewIntegration &&
      !settingStore.allLogicalGroups &&
      settingStore.getAllLogicalGroups().then((groups) => {
        groups && setLogicalGroups(groups);
        settingStore.getAllTechnicalComponents().then((components) => {
          components && setTechnicalComponents(components);
          setDataLoad(true);
        });
      });
  }, [accessViewIntegration, settingStore]);

  const handleLogicalGroupsDataReload = () => {
    accessViewIntegration &&
      settingStore.getAllLogicalGroups().then((groups) => {
        groups && setLogicalGroups(groups);
        settingStore.getAllTechnicalComponents().then((components) => {
          components && setTechnicalComponents(components);
        });
      });
  };

  // For edit or delete
  const handleLogicalGroupsEdit = (groups: SettingsLogicalGroup[]) => {
    if (accessEditIntegration) {
      !editedLogicalGroups && setEditedLogicalGroups(true);
      setLogicalGroups([...groups]);
    }
  };

  const handleResetSelectedLogicalGroup = () => {
    setSelectedLogicalGroup(undefined);
  };

  // Recursively (per level) find logical group and edit
  const editLogicalGroupLevel = (
    logicalGroupLevel: SettingsLogicalGroup[],
    newLogicalGroup: SettingsLogicalGroup
  ): boolean => {
    let edited = false;

    const index = logicalGroupLevel.findIndex(
      (group) => group.id === newLogicalGroup.id
    );

    if (index !== -1) {
      // Found on this level
      edited = true;
      logicalGroupLevel[index] = newLogicalGroup;
    } else {
      // Not found on this level; look at child levels
      let i = 0;

      while (!edited && i < logicalGroupLevel.length) {
        const group = logicalGroupLevel[i];
        if (group.logicalGroups) {
          edited = editLogicalGroupLevel(group.logicalGroups, newLogicalGroup);
        }
        i++;
      }
    }

    return edited;
  };

  // Add or delete technical component from logical group
  const handleEditSelectedLogicalGroupTechnicalComponent = (
    technicalComponent: SettingsTechnicalComponent
  ) => {
    let newComponent = { ...technicalComponent };
    let unassigned = false;
    let wasUnassigned = false;

    if (accessEditIntegration && logicalGroups && selectedLogicalGroup) {
      // Get current components

      let newLogicalGroupTechnicalComponents =
        selectedLogicalGroup.technicalComponents
          ? [...selectedLogicalGroup.technicalComponents]
          : [];

      // Add or delete component from id list

      const found = newLogicalGroupTechnicalComponents.find(
        (compId) => compId === technicalComponent.id
      );

      if (found !== undefined) {
        // Remove from id list on logical group and update usage on component
        newLogicalGroupTechnicalComponents =
          newLogicalGroupTechnicalComponents.filter(
            (compId) => compId !== technicalComponent.id
          );

        newComponent.usage = newComponent.usage - 1;

        if (newComponent.usage === 0) {
          unassigned = true;
        }
      } else {
        // Add to id list of logical group and update usage on component
        newLogicalGroupTechnicalComponents.push(technicalComponent.id);

        if (newComponent.usage === 0) {
          wasUnassigned = true;
        }

        newComponent.usage = newComponent.usage + 1;
      }

      const newSelectedLogicalGroup = {
        ...selectedLogicalGroup,
        technicalComponents:
          newLogicalGroupTechnicalComponents.length === 0
            ? null
            : newLogicalGroupTechnicalComponents,
      };

      // Edit in states

      !editedLogicalGroups && setEditedLogicalGroups(true);

      technicalComponents &&
        setTechnicalComponents(
          [...technicalComponents].map((comp) => {
            if (comp.id === newComponent.id) {
              return newComponent;
            } else {
              return comp;
            }
          })
        );
      setSelectedLogicalGroup(newSelectedLogicalGroup);
      editLogicalGroupLevel(logicalGroups, newSelectedLogicalGroup);

      if (unassigned || wasUnassigned) {
        setLogicalGroups(
          [...logicalGroups].map((group) => {
            if (group.systemGroup) {
              let newTechnicalComponents = group.technicalComponents
                ? [...group.technicalComponents]
                : [];

              if (unassigned) {
                // Add to list of unassigned logical group
                newTechnicalComponents = [
                  ...newTechnicalComponents,
                  technicalComponent.id,
                ];
              } else {
                // Remove from list of unassigned logical group
                newTechnicalComponents = newTechnicalComponents.filter(
                  (comp) => comp !== newComponent.id
                );
              }

              return {
                ...group,
                technicalComponents: newTechnicalComponents,
              };
            } else {
              return group;
            }
          })
        );
      } else {
        setLogicalGroups([...logicalGroups]);
      }
    }
  };

  const renderSaveButtons = () => (
    <SaveButtonsContainer>
      <Button
        disabled={!editedLogicalGroups}
        onClick={(e) => {
          e.stopPropagation();
          logicalGroups &&
            settingStore.editGroupWorkflow(logicalGroups).then(() => {
              setEditedLogicalGroups(false);
              handleLogicalGroupsDataReload();
            });
        }}
      >
        {editedLogicalGroups ? "Save changes" : "No changes"}
      </Button>
      {editedLogicalGroups && (
        <Button
          disabled={!editedLogicalGroups}
          onClick={(e) => {
            e.stopPropagation();
            logicalGroups &&
              settingStore.editGroupWorkflow(logicalGroups).then(() => {
                setEditedLogicalGroups(false);
                navigationStore.navigate(Route.Integrations);
              });
          }}
        >
          Save changes & view dashboard
        </Button>
      )}
    </SaveButtonsContainer>
  );

  return (
    <>
      <Prompt
        message={(location) => {
          if (!location.pathname.startsWith(Route.ContactSchedule)) {
            // Reset data when leaving page
            settingStore.resetGroupWorkflow();

            return editedLogicalGroups
              ? "Are you sure you want to leave this page? You have unsaved changes."
              : true;
          }
          return true;
        }}
      />
      <Header style={{ position: "relative" }}>
        <HeaderTitle dark semibold large>
          Group workflow
        </HeaderTitle>
        <HeaderExplanation>
          {!accessViewIntegration ||
          accessEditIntegration
            ? "This view allows you to group technical components into a functional view. Technical components are part of the actual development and are closely monitored. Grouping components allows you to customize your dashboard."
            : "Technical components are grouped into functional views to determine the layout of your dashboard. Technical components are part of the actual development and are closely monitored."}
        </HeaderExplanation>

        {accessEditIntegration &&
          logicalGroups &&
          renderSaveButtons()}
      </Header>

      <CardRowContainer>
        <SettingGroupWorkflowTree
          logicalGroups={logicalGroups}
          viewAccess={accessViewIntegration}
          editAccess={accessEditIntegration}
          deleteAccess={accessEditIntegration}
          editLogicalGroups={handleLogicalGroupsEdit}
          selectedLogicalGroup={selectedLogicalGroup}
          setSelectedLogicalGroup={setSelectedLogicalGroup}
          loading={!dataLoad}
          retryDataLoading={handleLogicalGroupsDataReload}
        />

        <SettingTechnicalComponents
          environments={generalStore.environments}
          technicalComponents={technicalComponents}
          viewAccess={accessViewIntegration}
          editAccess={accessEditIntegration}
          selectedLogicalGroup={selectedLogicalGroup}
          resetSelectedLogicalGroup={handleResetSelectedLogicalGroup}
          editSelectedLogicalGroupTechnicalComponent={
            handleEditSelectedLogicalGroupTechnicalComponent
          }
          loading={!dataLoad}
          retryDataLoading={handleLogicalGroupsDataReload}
        />
      </CardRowContainer>
    </>
  );
};

export default inject(
  "generalStore",
  "settingStore",
  "authenticationStore",
  "navigationStore"
)(
  observer(
    GroupWorkflow as FunctionComponent<
      Omit<
        Props,
        | "generalStore"
        | "settingStore"
        | "authenticationStore"
        | "navigationStore"
      >
    >
  )
);
