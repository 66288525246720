import { action, makeObservable, observable } from "mobx";
import { PermissionArea } from "model/Claim";
import {
  HorizonRoute,
  horizonRoutes,
  NavigationItem,
  navigationItems,
  Route,
} from "model/Navigation";

class NavigationStore {
  @observable currentRoute: NavigationItem | undefined = undefined;

  @observable redirectionRoute: NavigationItem | undefined = undefined;

  @observable horizonNavigation: HorizonRoute[] | undefined = undefined;

  @observable navigationTabs: NavigationItem[] | undefined = undefined;

  constructor() {
    makeObservable(this);
  }

  @action
  setHorizonNavigation = (
    checkOptionAccess: (accessOptions: string[] | undefined) => boolean
  ) => {
    const filteredHorizonRoutes = [...horizonRoutes].filter(
      (item: HorizonRoute) => {
        return checkOptionAccess(item.accessOptions);
      }
    );

    this.horizonNavigation = filteredHorizonRoutes;
  };

  @action
  setNavigationTabs = (
    checkPermissonAccess: (permissionAreas: PermissionArea[]) => boolean,
    checkOptionAccess: (accessOptions: string[] | undefined) => boolean
  ) => {
    const filteredNavigationItems = [...navigationItems].filter(
      (item: NavigationItem) => {
        if (item.subNavigation) {
          const filteredSubNavigationItems = [...item.subNavigation].filter(
            (subItem: NavigationItem) =>
              checkPermissonAccess(subItem.permissionAreas) &&
              checkOptionAccess(subItem.accessOptions)
          );

          item.subNavigation = filteredSubNavigationItems;
        }

        return (
          checkPermissonAccess(item.permissionAreas) &&
          checkOptionAccess(item.accessOptions)
        );
      }
    );

    // Set first route
    if (filteredNavigationItems.length > 0) {
      this.setRedirectionRoute(filteredNavigationItems[0]);
      this.setCurrentRoute(filteredNavigationItems[0]);
    }

    this.navigationTabs = filteredNavigationItems;
  };

  @action
  setRedirectionRoute = (item: NavigationItem) => {
    if (item.subNavigation && item.subNavigation.length > 0) {
      this.redirectionRoute = item.subNavigation[0];
    } else {
      this.redirectionRoute = item;
    }
  };

  @action
  setCurrentRoute = (item: NavigationItem) => {
    if (item.subNavigation && item.subNavigation.length > 0) {
      this.currentRoute = item.subNavigation[0];
    } else {
      this.currentRoute = item;
    }
  };

  getNavigationItem = (route: Route): NavigationItem | undefined => {
    let navItem: NavigationItem | undefined = undefined;

    if (this.navigationTabs) {
      this.navigationTabs.forEach((item) => {
        if (item.route === route) {
          navItem = item;
          return;
        }
        if (item.subNavigation) {
          item.subNavigation.forEach((subItem) => {
            if (subItem.route === route) {
              navItem = subItem;
              return;
            }
          });
        }
      });
    }

    return navItem;
  };

  @action
  navigate = (route: Route) => {
    if (this.navigationTabs) {
      this.currentRoute = this.getNavigationItem(route);
    }
  };
}

export default NavigationStore;
