import Card, { CardRowContainer } from "components/common/Card";
import { Modal, ModalColorType, ModalType } from "components/common/Modal";
import TextInput from "components/common/TextInput";
import {
  Customer,
  CustomerDetailMetaData,
  CustomerLocation,
  CustomerOptions as CustomerOptionsType,
  CustomerPriceTierBase,
  CustomerWorkOrder,
} from "model/Customer";
import { CustomerEnvironment } from "model/Environment";
import React, { FunctionComponent, useState } from "react";
import styled, { css } from "styled-components";
import { $statusFail, Button, $darkRed, $textDark } from "styles/common";
import { CustomerEnvironments } from "./CustomerEnvironments";
import { CustomerOptions } from "./CustomerOptions";
import { CustomerPriceTiering } from "./CustomerPriceTiering";
import { CustomerSupport } from "./CustomerSupport";

interface Props {
  customerDetailMetaData: CustomerDetailMetaData;
  originalCustomer?: Customer;
  deleteAccess: boolean;
  cancel(): void;
  addCustomer(customer: Customer): void;
  editCustomer(customer: Customer): void;
  deleteCustomer(customerId: number): void;
  setEdited(): void;
}

const Container = styled.div``;

const HeaderGroup = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 1rem;
`;

const InputContainer = styled.div<{ noMargin?: boolean }>`
  ${(props) =>
    props.noMargin
      ? css``
      : css`
          margin-bottom: 1rem;
        `}
`;

export const emptyNameErrorText = "Please fill in a name.";

export const CustomerCrud: FunctionComponent<Props> = ({
  customerDetailMetaData,
  originalCustomer,
  deleteAccess,
  cancel,
  addCustomer,
  editCustomer,
  deleteCustomer,
  setEdited,
}) => {
  const [customer, setCustomer] = useState<Customer>(
    originalCustomer === undefined
      ? ({
          id: 0,
          name: "",
          environments: [],
          locations: [],
          options: {
            hasBacklogUrl: false,
            developmentBacklogUrl: null,
            allowEscalationManagement: false,
            allowTicketManagement: false,
            showReports: false,
            showUsage: false,
          },
          priceTiers: null,
          allowContactChasing: false,
          workOrders: [],
        } as Customer)
      : originalCustomer
  );

  const [editedCustomer, setEditedCustomer] = useState(false);

  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const [nameError, setNameError] = useState(false);
  const [environmentsError, setEnvironmentsError] = useState(false);
  const [optionsError, setOptionsError] = useState(false);
  const [workOrdersError, setWorkOrdersError] = useState(false);

  const handleSaveCustomer = () => {
    const emptyName = customer.name === "";
    if (emptyName) {
      setNameError(true);
    }

    const emptyEnvironments = customer.environments.length === 0;
    if (emptyEnvironments) {
      setEnvironmentsError(true);
    }

    const emptyDevelopmentBacklogUrl =
      customer.options.hasBacklogUrl &&
      customer.options.developmentBacklogUrl === null;
    if (emptyDevelopmentBacklogUrl) {
      setOptionsError(true);
    }

    let emptyWorkOrders =
      customer.workOrders.length === 0 && customer.allowContactChasing;
    if (emptyWorkOrders) {
      setWorkOrdersError(true);
    }

    if (
      !emptyName &&
      !emptyEnvironments &&
      !emptyDevelopmentBacklogUrl &&
      !emptyWorkOrders
    ) {
      originalCustomer ? editCustomer(customer) : addCustomer(customer);
    }
  };

  const handleDeleteCustomerClick = () =>
    deleteAccess && setShowDeleteModal(true);

  const handleDeleteCustomer = () => {
    deleteAccess && deleteCustomer(customer.id);
  };

  const renderCustomerDeleteModal = (
    <Modal
      className="customer-delete-modal"
      type={ModalType.Action}
      colorType={ModalColorType.Warning}
      customText={`Are you sure you want to delete this customer? This can not be undone.`}
      customButtonText={"Delete"}
      background
      action={() => {
        setShowDeleteModal(false);
        handleDeleteCustomer();
        // actionUser && handleDelete(actionUser.id);
      }}
      cancel={() => {
        // setActionUser(undefined);
        setShowDeleteModal(false);
      }}
    />
  );

  const renderCustomerHeader = (
    <Card
      darkBorder
      extraContentStyling={css`
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        padding: 1.5rem;
      `}
    >
      <InputContainer noMargin style={{ width: "25rem" }}>
        <TextInput
          className="name-input"
          label="Customer name"
          defaultValue={customer.name}
          mandatory
          error={nameError}
          errorText={emptyNameErrorText}
          onValueChange={(value) => {
            setNameError(false);

            if (!editedCustomer) {
              setEditedCustomer(true);
              setEdited();
            }

            setCustomer({ ...customer, name: value });
          }}
          extraLabelStyling={css`
            text-transform: uppercase;
            color: ${$textDark};
          `}
        />
      </InputContainer>

      <HeaderGroup>
        {!!!originalCustomer && (
          <Button
            className="cancel-button"
            simple
            onClick={() => {
              cancel();
            }}
          >
            Cancel
          </Button>
        )}

        <Button
          className="add-edit-button"
          disabled={originalCustomer && !editedCustomer}
          onClick={handleSaveCustomer}
        >
          {originalCustomer ? "Save changes" : "Create customer"}
        </Button>

        {!!originalCustomer && (
          <Button
            className="cancel-button"
            onClick={() => {
              cancel();
            }}
          >
            {editedCustomer ? "Discard changes" : "Cancel"}
          </Button>
        )}

        {deleteAccess && originalCustomer && (
          <Button
            className="delete-button"
            color={$statusFail}
            hoverColor={$darkRed}
            onClick={handleDeleteCustomerClick}
          >
            Delete customer
          </Button>
        )}
      </HeaderGroup>
    </Card>
  );

  return (
    <Container>
      {renderCustomerHeader}

      <CustomerEnvironments
        environments={customer.environments}
        existingLocations={customer.locations}
        areaTypes={customerDetailMetaData.areaTypes}
        slas={customerDetailMetaData.slas}
        environmentsError={environmentsError}
        setEnvironmentsError={setEnvironmentsError}
        editEnvironments={(
          environments: CustomerEnvironment[],
          editedLocations?: CustomerLocation[]
        ) => {
          if (!editedCustomer) {
            setEditedCustomer(true);
            setEdited();
          }

          setCustomer({
            ...customer,
            environments: environments,
            locations: editedLocations ? editedLocations : customer.locations,
          });
        }}
      />

      <CustomerOptions
        options={customer.options}
        optionsError={optionsError}
        setOptionsError={setOptionsError}
        editOptions={(options: CustomerOptionsType) => {
          if (!editedCustomer) {
            setEditedCustomer(true);
            setEdited();
          }

          setCustomer({ ...customer, options: options });
        }}
      />

      <CardRowContainer>
        <CustomerPriceTiering
          priceTiers={customer.priceTiers}
          defaultPriceTiers={customerDetailMetaData.defaultPriceTiers}
          editPriceTiers={(priceTiers: CustomerPriceTierBase[] | null) => {
            if (!editedCustomer) {
              setEditedCustomer(true);
              setEdited();
            }

            setCustomer({
              ...customer,
              priceTiers: priceTiers,
            });
          }}
        />

        <CustomerSupport
          allowContactChasing={customer.allowContactChasing}
          workOrders={customer.workOrders}
          editSupport={(
            allowContactChasing: boolean,
            workOrders: CustomerWorkOrder[]
          ) => {
            if (!editedCustomer) {
              setEditedCustomer(true);
              setEdited();
            }

            setCustomer({
              ...customer,
              allowContactChasing: allowContactChasing,
              workOrders: workOrders,
            });
          }}
          workOrdersError={workOrdersError}
          setWorkOrdersError={(set) => setWorkOrdersError(set)}
        />
      </CardRowContainer>

      {showDeleteModal && renderCustomerDeleteModal}
    </Container>
  );
};
