import { LocationGroupLinkData } from "model/Edge";
import { IntegrationLogicalGroupLevelOneLink } from "model/LogicalGroup";
import { getExecutionSpeedStatus } from "model/Status";
import { ArrowHeadType } from "react-flow-renderer";

export const convertLogicalGroupLinksToDiagramEdges = (
  links: IntegrationLogicalGroupLevelOneLink[]
) => {
  if (links.length > 0) {
    let lowestTraffic = links[0].amountOfCalls;
    let highestTraffic = 0;

    links.forEach((link) => {
      if (link.amountOfCalls < lowestTraffic) {
        lowestTraffic = link.amountOfCalls;
      }
      if (link.amountOfCalls > highestTraffic) {
        highestTraffic = link.amountOfCalls;
      }
    });

    return links.map((link: IntegrationLogicalGroupLevelOneLink) => {
      return {
        id: `e${link.from}-${link.to}`,
        source: link.from.toString(),
        target: link.to.toString(),
        type: "customEdge",
        data: {
          latency: link.latency,
          amountOfCalls: link.amountOfCalls,
          lowestTraffic: lowestTraffic,
          highestTraffic: highestTraffic,
          status: link.status,
        } as LocationGroupLinkData,
        style: { stroke: getExecutionSpeedStatus(link.status).color },
        arrowHeadType: ArrowHeadType.ArrowClosed,
      };
    });
  } else {
    return [];
  }
};
