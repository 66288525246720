import Legend, { LegendItem } from "components/common/Legend";
import { ExecutionsTimeUnit } from "model/Time";
import { ExtraStylingType } from "model/Styles";
import React, { FunctionComponent, useEffect, useState } from "react";
import { Doughnut } from "react-chartjs-2";
import styled, { css } from "styled-components";
import {
  $statusFail,
  $statusSuccess,
  $statusSuccessSlow,
  Text,
} from "styles/common";
import {
  convertExecutions,
  getExecutionsString,
  round,
} from "utils/Integration";

interface DoughnutData {
  executionsPerTimeUnit?: number;
  executionsTimeUnit?: ExecutionsTimeUnit;
  total: number;
  fails: number;
  successes: number;
  successesSlow?: number | null; // undefined: platform component; null: logical group without slow
}

interface Props {
  data: DoughnutData;
  small?: boolean;
  animated?: boolean;
  extraStyling?: ExtraStylingType;
}

const Container = styled.div<{
  extraStyling: ExtraStylingType;
}>`
  display: flex;
  flex-direction: row;
  align-items: center;

  ${(props) => props.extraStyling};
`;

const DoughnutContainer = styled.div<{ small: boolean }>`
  position: relative;
  min-width: ${(props) => (props.small ? "5rem" : "7rem")};
  width: ${(props) => (props.small ? "5rem" : "7rem")};
  max-width: ${(props) => (props.small ? "5rem" : "7rem")};
`;

const MiddleContent = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  text-align: center;
`;

const LegendContainer = styled.div`
  margin-left: 1rem;
`;

export const ExecutionsDoughnutChart: FunctionComponent<Props> = ({
  data,
  small = false,
  animated = false,
  extraStyling = css``,
}) => {
  const [legendItems, setLegendItems] = useState<LegendItem[] | undefined>(
    undefined
  );

  useEffect(() => {
    const total =
      data.fails +
      data.successes +
      (data.successesSlow !== undefined && data.successesSlow !== null
        ? data.successesSlow
        : 0);

    const successPercentage = total === 0 ? 0 : (data.successes / total) * 100;
    let roundedSuccessPercentage: number | string = round(successPercentage, 2);
    const failPercentage = total === 0 ? 0 : (data.fails / total) * 100;
    let roundedFailPercentage: number | string = round(failPercentage, 2);

    const failLegend = {
      explanation: "Fail:",
      boldValue: `${convertExecutions(data.fails)} (${roundedFailPercentage}%)`,
      color: $statusFail,
    };

    const successLegend = {
      explanation: "On time:",
      boldValue: `${convertExecutions(
        data.successes
      )} (${roundedSuccessPercentage}%)`,
      color: $statusSuccess,
    };

    if (data.successesSlow !== undefined && data.successesSlow !== null) {
      const successSlowPercentage =
        total === 0 ? 0 : (data.successesSlow / total) * 100;
      let roundedSuccessSlowPercentage: number | string = round(
        successSlowPercentage,
        2
      );

      setLegendItems([
        successLegend,
        {
          explanation: "Too slow:",
          boldValue: `${convertExecutions(
            data.successesSlow
          )} (${roundedSuccessSlowPercentage}%)`,
          color: $statusSuccessSlow,
        },
        failLegend,
      ]);
    } else {
      successLegend.explanation = "Success:";
      setLegendItems([successLegend, failLegend]);
    }
  }, [data.fails, data.successes, data.successesSlow]);

  return (
    <Container extraStyling={extraStyling}>
      <DoughnutContainer small={small}>
        <Doughnut
          data={{
            labels:
              data.successesSlow !== undefined && data.successesSlow !== null
                ? ["Success (on time)", "Success (too slow)", "Fail"]
                : ["Success", "Fail"],
            datasets: [
              {
                data:
                  data.successesSlow !== undefined &&
                  data.successesSlow !== null
                    ? [data.successes, data.successesSlow, data.fails]
                    : [data.successes, data.fails],
                backgroundColor:
                  data.successesSlow !== undefined &&
                  data.successesSlow !== null
                    ? [$statusSuccess, $statusSuccessSlow, $statusFail]
                    : [$statusSuccess, $statusFail],
                weight: 15,
              },
            ],
          }}
          options={{
            layout: {
              padding: 0,
            },
            plugins: {
              legend: {
                display: false,
              },
              tooltip: {
                enabled: false,
              },
            },
            animation: { duration: animated ? 1000 : 0 },
            //@ts-ignore
            cutout: small ? 32 : 47,
            borderWidth: 0,
          }}
        />
        <MiddleContent>
          <Text
            dark
            style={{
              fontSize: small ? "1.2rem" : "1.5rem",
            }}
          >
            {convertExecutions(data.total)}
          </Text>
          {data.executionsPerTimeUnit !== undefined &&
            data.executionsTimeUnit !== undefined && (
              <Text small dark>
                {getExecutionsString(
                  data.executionsPerTimeUnit,
                  data.executionsTimeUnit
                )}
              </Text>
            )}
        </MiddleContent>
      </DoughnutContainer>

      {legendItems && (
        <LegendContainer>
          <Legend small vertical items={legendItems} />
        </LegendContainer>
      )}
    </Container>
  );
};
