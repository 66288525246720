import { CrudModal } from "components/common/CrudModal";
import TextInput from "components/common/TextInput";
import { CustomerWorkOrder } from "model/Customer";
import React, { FunctionComponent, useState } from "react";
import styled, { css } from "styled-components";

interface Props {
  close(): void;
  add(workOrder: CustomerWorkOrder): void;
}

const Container = styled.div``;

const InputContainer = styled.div`
  margin-bottom: 1rem;
`;

export const emptyWorkOrderError = "Please fill in a workorder";
export const emptyInfoError = "Please fill in info";

export const CustomerWorkOrderModal: FunctionComponent<Props> = ({
  close,
  add,
}) => {
  const [workOrder, setWorkOrder] = useState<CustomerWorkOrder>({
    id: 0,
    workOrder: "",
    info: "",
  });

  const [workOrderError, setWorkOrderError] = useState(false);
  const [infoError, setInfoError] = useState(false);

  return (
    <CrudModal
      className="customer-workorder-modal"
      title={"Add workorder"}
      content={
        <Container>
          <InputContainer>
            <TextInput
              className="workorder-input"
              label="Workorder"
              defaultValue={workOrder.workOrder}
              mandatory
              infoTooltip={"workorder-name-tooltip"}
              infoTooltipContent="Format: CUST123.001"
              error={workOrderError}
              errorText={emptyWorkOrderError}
              onValueChange={(value) => {
                setWorkOrderError(false);
                setWorkOrder({ ...workOrder, workOrder: value });
              }}
            />
          </InputContainer>

          <InputContainer>
            <TextInput
              className="info-input"
              label="Info"
              defaultValue={workOrder.info}
              mandatory
              error={infoError}
              errorText={emptyInfoError}
              onValueChange={(value) => {
                setInfoError(false);
                setWorkOrder({ ...workOrder, info: value });
              }}
            />
          </InputContainer>
        </Container>
      }
      cancel={() => {
        close();
      }}
      action={() => {
        const emptyWorkOrder = workOrder.workOrder === "";
        const emptyInfo = workOrder.info === "";

        if (emptyWorkOrder) {
          setWorkOrderError(true);
        }

        if (emptyInfo) {
          setInfoError(true);
        }

        if (!emptyWorkOrder && !emptyInfo) {
          add(workOrder);
          close();
        }
      }}
      extraStyling={css`
        min-width: 20rem;
      `}
    />
  );
};
