import Card from "components/common/Card";
import IconComponent from "components/common/Icon";
import { List } from "components/common/List";
import { CenteredCellContainer } from "components/common/List/common";
import { NoAccessModal } from "components/common/Modal";
import SearchInput from "components/common/SearchInput";
import { TableCheckbox } from "components/common/TableCheckbox";
import { Environment } from "model/Environment";
import { Icon } from "model/Icon";
import { SettingsLogicalGroup } from "model/LogicalGroup";
import { SettingsTechnicalComponent } from "model/PlatformComponent";
import React, { useState, useEffect, useCallback } from "react";
import styled, { css } from "styled-components";
import {
  Text,
  Span,
  $statusFail,
  Select,
  $blue4,
  StyledReactTooltip,
  $textLight,
} from "styles/common";

interface Props {
  environments: Environment[] | undefined;
  technicalComponents: SettingsTechnicalComponent[] | undefined;
  viewAccess: boolean;
  editAccess: boolean;
  selectedLogicalGroup: SettingsLogicalGroup | undefined;
  resetSelectedLogicalGroup(): void;
  editSelectedLogicalGroupTechnicalComponent(
    component: SettingsTechnicalComponent
  ): void;
  loading: boolean;
  retryDataLoading(): void;
}

const SelectedLogicalGroup = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.5rem;
  margin-bottom: 1.5rem;
`;

const SelectedLogicalGroupParent = styled(Span)`
  margin-right: 0.5rem;
`;

const NoSelectedLogicalGroup = styled(Span)`
  opacity: 0.7;
`;

const HeaderContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const ActionsContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
`;

const baseHeaders = ["Name", "Type", "Usage"];
const baseColumnWidths = [5, 3, 1];

const allEnvironmentsId = -1;

export const SettingTechnicalComponents = ({
  environments,
  technicalComponents,
  viewAccess,
  editAccess,
  selectedLogicalGroup,
  resetSelectedLogicalGroup,
  editSelectedLogicalGroupTechnicalComponent,
  loading,
  retryDataLoading,
}: Props) => {
  const [
    sortedAndFilteredTechnicalComponents,
    setSortedAndFilteredTechnicalComponents,
  ] = useState<SettingsTechnicalComponent[] | undefined>(technicalComponents);

  const [selectedEnvironment, setSelectedEnvironment] =
    useState<number>(allEnvironmentsId);

  const [searchString, setSearchString] = useState<string | undefined>(
    undefined
  );

  const sortAndFilter = useCallback(
    (components: SettingsTechnicalComponent[]) => {
      if (selectedLogicalGroup && selectedLogicalGroup.systemGroup) {
        // Only show selected components for group "Unassigned"
        components = [
          ...components.filter(
            (comp) =>
              selectedLogicalGroup.technicalComponents &&
              selectedLogicalGroup.technicalComponents.includes(comp.id)
          ),
        ];
      }

      // Sort alphabetically
      components = [
        ...components.sort(
          (a: SettingsTechnicalComponent, b: SettingsTechnicalComponent) =>
            a.name < b.name ? -1 : a.name > b.name ? 1 : 0
        ),
      ];

      if (searchString !== undefined) {
        components = components.filter((component) =>
          component.name.toLowerCase().includes(searchString)
        );
      }

      // Filter on selected environment
      components = components.filter((component) => {
        return selectedEnvironment === allEnvironmentsId
          ? true
          : component.environments.includes(selectedEnvironment);
      });

      if (
        !selectedLogicalGroup ||
        (selectedLogicalGroup && !selectedLogicalGroup.systemGroup)
      ) {
        // Sort to put selected components on top
        components = [
          ...components.sort(
            (a: SettingsTechnicalComponent, b: SettingsTechnicalComponent) => {
              if (
                selectedLogicalGroup &&
                selectedLogicalGroup.technicalComponents
              ) {
                const includesA =
                  selectedLogicalGroup.technicalComponents.includes(a.id);
                const includesB =
                  selectedLogicalGroup.technicalComponents.includes(b.id);

                return Number(includesB) - Number(includesA);
              } else {
                return 1;
              }
            }
          ),
        ];
      }

      setSortedAndFilteredTechnicalComponents(components);
    },
    [searchString, selectedEnvironment, selectedLogicalGroup]
  );

  useEffect(() => {
    if (technicalComponents) {
      sortAndFilter([...technicalComponents]);
    }
  }, [
    selectedLogicalGroup,
    technicalComponents,
    selectedEnvironment,
    sortAndFilter,
  ]);

  const renderSearch = (
    <SearchInput
      className="technical-components-search-input"
      onValueChange={(value) => {
        setSearchString(value);
      }}
    />
  );

  return (
    <Card
      header={
        <HeaderContainer>
          <p>All Technical Components</p>
        </HeaderContainer>
      }
      extraStyling={css`
        flex: 1.5;
      `}
    >
      {!viewAccess && <NoAccessModal />}

      <SelectedLogicalGroup>
        <Text>
          <SelectedLogicalGroupParent semibold>
            Selected group:
          </SelectedLogicalGroupParent>
          {selectedLogicalGroup === undefined ? (
            <NoSelectedLogicalGroup>
              Select a group on the left to show
              {!viewAccess || editAccess ? " and edit" : ""} the technical
              components that belong to that group.
            </NoSelectedLogicalGroup>
          ) : (
            <Span semibold color={$blue4}>
              {selectedLogicalGroup.name}
            </Span>
          )}
        </Text>
        {selectedLogicalGroup !== undefined && (
          <IconComponent
            className="reset-logical-group-button"
            name={Icon.delete}
            height="11px"
            color={$statusFail}
            onClick={resetSelectedLogicalGroup}
          />
        )}
      </SelectedLogicalGroup>

      <ActionsContainer>
        <div>
          <Text
            semibold
            extraStyling={css`
              margin-bottom: 0.3rem;
            `}
          >
            Show components for
          </Text>
          <Select
            className="environment-select"
            defaultValue={selectedEnvironment}
            onChange={(e) => {
              setSelectedEnvironment(parseInt(e.target.value));
            }}
            extraStyling={css`
              width: 20rem;
            `}
          >
            <option key={allEnvironmentsId} value={allEnvironmentsId}>
              All environments
            </option>
            {environments &&
              environments.map((env, index) => (
                <option key={index} value={env.id}>
                  {env.name}
                </option>
              ))}
          </Select>
        </div>
        {renderSearch}
      </ActionsContainer>

      <List
        headers={
          selectedLogicalGroup && !selectedLogicalGroup.logicalGroups && !selectedLogicalGroup.systemGroup
            ? [
                <CenteredCellContainer>
                  <div
                    data-for="selected-technical-components-info-tooltip"
                    data-tip=""
                  >
                    <IconComponent name={Icon.info} color={$blue4} />
                  </div>
                  <StyledReactTooltip
                    id="selected-technical-components-info-tooltip"
                    effect="solid"
                    place="right"
                    type="light"
                    border={false}
                    textColor={$textLight}
                    backgroundColor={"#fff"}
                    arrowColor={"transparent"}
                  >
                    Selected technical components belong to the group that is
                    selected on the left.
                  </StyledReactTooltip>
                </CenteredCellContainer>,
                ...baseHeaders,
              ]
            : baseHeaders
        }
        data={
          selectedLogicalGroup && selectedLogicalGroup.logicalGroups ? [] :
          sortedAndFilteredTechnicalComponents
            ? sortedAndFilteredTechnicalComponents.map(
                (technicalComponent: SettingsTechnicalComponent) => {
                  return {
                    cells:
                      selectedLogicalGroup && !selectedLogicalGroup.logicalGroups && !selectedLogicalGroup.systemGroup
                        ? [
                            <CenteredCellContainer className="technical-component">
                              <TableCheckbox
                                className="select-technical-component-checkbox"
                                checked={
                                  selectedLogicalGroup &&
                                  selectedLogicalGroup.technicalComponents
                                    ? selectedLogicalGroup.technicalComponents.includes(
                                        technicalComponent.id
                                      )
                                    : false
                                }
                                disabled={selectedLogicalGroup === undefined}
                                clickable={editAccess}
                                onClick={() => {
                                  editSelectedLogicalGroupTechnicalComponent(
                                    technicalComponent
                                  );
                                }}
                              />
                            </CenteredCellContainer>,
                            technicalComponent.name,
                            technicalComponent.type,
                            technicalComponent.usage,
                          ]
                        : [
                            technicalComponent.name,
                            technicalComponent.type,
                            technicalComponent.usage,
                          ],
                  };
                }
              )
            : undefined
        }
        access={viewAccess}
        noDataText={selectedLogicalGroup && !selectedLogicalGroup.logicalGroups ? "There are no technical components yet." : 
          "The selected group cannot have technical components."}
        retryDataLoading={retryDataLoading}
        customColumnWidths={
          selectedLogicalGroup && !selectedLogicalGroup.logicalGroups && !selectedLogicalGroup.systemGroup
            ? [1, ...baseColumnWidths]
            : baseColumnWidths
        }
        hover
        loading={loading}
        extraStyling={css`
          margin-bottom: 1rem;
        `}
      />
    </Card>
  );
};
