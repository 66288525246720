import Card from "components/common/Card";
import { Switch } from "components/common/Switch";
import TextInput from "components/common/TextInput";
import { CustomerOptions as CustomerOptionsType } from "model/Customer";
import React, { FunctionComponent, useState } from "react";
import styled, { css } from "styled-components";
import { $textLight, Button, smallText } from "styles/common";

interface Props {
  options: CustomerOptionsType;
  optionsError: boolean;
  setOptionsError(set: boolean): void;
  editOptions(options: CustomerOptionsType): void;
}

const Row = styled.div`
  display: flex;
  flex-direction: row;
  gap: 1rem;
  align-items: center;
`;
const urlPrefixes: string[] = ["https", "http"];

const InputContainer = styled.div<{ noMargin?: boolean }>`
  ${(props) =>
    props.noMargin
      ? css``
      : css`
          margin-bottom: 1rem;
        `}
`;

const DevelopmentBacklogUrl = styled.div`
  margin: 0 0 2rem 3rem;
`;

const UrlPrefixSelect = styled.select`
  ${smallText}
  display: block;
  height: 1.5rem;
  line-height: 1.5rem;
  background-color: transparent;
  background-clip: padding-box;
  border: none;
  box-shadow: none;
  box-sizing: border-box;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

  &:focus {
    outline: 0;
  }

  padding-right: 1.25rem;
  font-weight: 600;
  color: ${$textLight};

  -webkit-appearance: none;
  -moz-appearance: none;

  background-image: linear-gradient(45deg, transparent 50%, ${$textLight} 50%),
    linear-gradient(135deg, ${$textLight} 50%, transparent 50%);
  background-position: calc(100% - 5px) calc(0.75em + 1px),
    calc(100%) calc(0.75em + 1px), calc(100% - 2.5em) 0.4em;
  background-size: 5px 5px, 5px 5px, 1px 1.5em;
  background-repeat: no-repeat;
`;

const removePrefixFromUrl = (url: string) => {
  if (url) {
    urlPrefixes.forEach((urlPrefix) => {
      url = url.replace(`${urlPrefix}://`, "");
    });
  }
  return url;
};

const getPrefixFromUrl = (url: string): string | undefined => {
  let prefix: string | undefined = undefined;

  if (url) {
    urlPrefixes.forEach((urlPrefix) => {
      if (!prefix && url.includes(urlPrefix)) {
        prefix = urlPrefix;
        return;
      }
    });
  }

  return prefix;
};

export const emptyDevelopmentBacklogUrlText =
  "Please fill in a backlog url when this option is enabled";

export const CustomerOptions: FunctionComponent<Props> = ({
  options,
  optionsError,
  setOptionsError,
  editOptions,
}) => {
  const [developmentBacklogUrlPrefix, setDevelopmentBacklogUrlPrefix] =
    useState(
      (options.developmentBacklogUrl &&
        getPrefixFromUrl(options.developmentBacklogUrl)) ||
        urlPrefixes[0]
    );

  return (
    <Card header={<p>Options</p>} collapsable>
      <Switch
        className="switch-development-backlog-url"
        label="Link to Development Backlog in portal"
        value={options.hasBacklogUrl}
        onChange={() => {
          editOptions({ ...options, hasBacklogUrl: !options.hasBacklogUrl });
        }}
        extraStyling={css`
          margin-bottom: 1rem;
        `}
      />

      <DevelopmentBacklogUrl>
        <InputContainer noMargin style={{ flex: 1 }}>
          <TextInput
            className="development-backlog-url-input"
            defaultValue={
              (options.developmentBacklogUrl &&
                removePrefixFromUrl(options.developmentBacklogUrl)) ||
              ""
            }
            disabled={!options.hasBacklogUrl}
            error={optionsError}
            errorText={emptyDevelopmentBacklogUrlText}
            prefix={
              <UrlPrefixSelect
                className="development-backlog-url-prefix-select"
                defaultValue={developmentBacklogUrlPrefix}
                disabled={!options.hasBacklogUrl}
                onChange={(e) => {
                  setDevelopmentBacklogUrlPrefix(e.target.value);

                  options.developmentBacklogUrl &&
                    editOptions({
                      ...options,
                      developmentBacklogUrl: `${
                        e.target.value
                      }://${removePrefixFromUrl(
                        options.developmentBacklogUrl
                      )}`,
                    });
                }}
              >
                {urlPrefixes.map((urlPrefix, index) => (
                  <option key={index} value={urlPrefix}>
                    {urlPrefix.toUpperCase()}://
                  </option>
                ))}
              </UrlPrefixSelect>
            }
            inputActions={
              <Row>
                <Button
                  className="copy-development-backlog-url-button"
                  disabled={
                    !options.hasBacklogUrl ||
                    (options.hasBacklogUrl &&
                      options.developmentBacklogUrl === null)
                  }
                  white
                  onClick={async () => {
                    let input = document.querySelector(
                      "div.development-backlog-url-input input"
                    );
                    input && //@ts-ignore
                      (await navigator.clipboard.writeText(input.value));
                  }}
                >
                  Copy
                </Button>
                <Button
                  className="test-development-backlog-button"
                  disabled={
                    !options.hasBacklogUrl ||
                    (options.hasBacklogUrl &&
                      options.developmentBacklogUrl === null)
                  }
                  white
                  onClick={() => {
                    options.developmentBacklogUrl &&
                      window.open(options.developmentBacklogUrl, "_blank");
                  }}
                >
                  Test
                </Button>
              </Row>
            }
            onValueChange={(urlValue) => {
              setOptionsError(false);

              editOptions({
                ...options,
                developmentBacklogUrl:
                  urlValue === ""
                    ? null
                    : `${developmentBacklogUrlPrefix}://${urlValue}`,
              });
            }}
          />
        </InputContainer>
      </DevelopmentBacklogUrl>

      <Switch
        className="switch-allow-escalation-management"
        label="Allow escalation management in portal"
        value={options.allowEscalationManagement}
        onChange={() => {
          editOptions({
            ...options,
            allowEscalationManagement: !options.allowEscalationManagement,
          });
        }}
        extraStyling={css`
          margin-bottom: 1rem;
        `}
      />

      <Switch
        className="switch-allow-ticket-management"
        label="Allow ticket management in portal (collaboration, create ticket)"
        value={options.allowTicketManagement}
        onChange={() => {
          editOptions({
            ...options,
            allowTicketManagement: !options.allowTicketManagement,
          });
        }}
        extraStyling={css`
          margin-bottom: 1rem;
        `}
      />

      <Switch
        className="switch-show-reports"
        label="Show reports in portal (incidents, changes, SLA)"
        value={options.showReports}
        onChange={() => {
          editOptions({ ...options, showReports: !options.showReports });
        }}
        extraStyling={css`
          margin-bottom: 1rem;
        `}
      />

      <Switch
        className="switch-show-usage"
        label="Show usage in portal (billing metrics)"
        value={options.showUsage}
        onChange={() => {
          editOptions({ ...options, showUsage: !options.showUsage });
        }}
        extraStyling={css`
          margin-bottom: 1rem;
        `}
      />
    </Card>
  );
};
