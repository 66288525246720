import Card from "components/common/Card";
import IconComponent from "components/common/Icon";
import { ItOperationsList } from "components/Integrations/ItOperationsList";
import { Modal, ModalType, NoAccessModal } from "components/common/Modal";
import { inject, observer } from "mobx-react";
import { Icon } from "model/Icon";
import { ItOperationLocation } from "model/ItOperation";
import { Route } from "model/Navigation";
import { globalTimeFrames } from "model/Time";
import React, { FunctionComponent, useEffect, useState } from "react";
import { Prompt } from "react-router";
import GeneralStore from "stores/GeneralStore";
import ItOperationStore from "stores/ItOperationStore";
import styled, { css } from "styled-components";
import { Filter, Text, Select } from "styles/common";
import Loader from "components/common/Loader";
import AuthenticationStore from "stores/AuthenticationStore";
import { PermissionArea, PermissionType } from "model/Claim";

interface Props {
  generalStore: GeneralStore;
  itOperationStore: ItOperationStore;
  authenticationStore: AuthenticationStore;
}

const LocationHeader = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.8rem;
`;

const NoLocations = styled(Text)`
  margin-top: 1rem;
  font-size: 1.125rem;
`;

const ItOperations: FunctionComponent<Props> = ({
  generalStore,
  itOperationStore,
  authenticationStore,
}) => {
  const [dataLoad, setDataLoad] = useState(false);

  const accessViewIntegration = authenticationStore.userHasPermission(
    PermissionArea.Integration,
    PermissionType.View
  );

  useEffect(() => {
    accessViewIntegration &&
      itOperationStore.getItOperationData().finally(() => {
        setDataLoad(true);
      });
  }, [
    accessViewIntegration,
    generalStore.currentEnvironment,
    generalStore.timeFrame,
    itOperationStore,
  ]);

  const handleDataReload = () => {
    if (accessViewIntegration) {
      if (!generalStore.currentEnvironment) {
        generalStore.getEnvironments().then(() => {
          itOperationStore.getItOperationData();
        });
      } else itOperationStore.getItOperationData();
    }
  };

  const renderIntegrationsFilter = () => (
    <Filter>
      <Text large semibold dark>
        My deployment for
      </Text>
      <Select
        defaultValue={generalStore.currentEnvironment}
        highlight
        disabled={!accessViewIntegration}
        onChange={(e) => {
          generalStore.setCurrentEnvironment(parseInt(e.target.value));
        }}
      >
        {generalStore.environments &&
          generalStore.environments.map((environment, index) => (
            <option key={index} value={environment.id}>
              {environment.name}
            </option>
          ))}
      </Select>
      <Text large semibold dark>
        and status in the
      </Text>
      {generalStore.timeFrame && (
        <Select
          defaultValue={generalStore.timeFrame.id}
          highlight
          disabled={!accessViewIntegration}
          onChange={(e) => {
            generalStore.setTimeFrame(parseInt(e.target.value));
          }}
        >
          {globalTimeFrames.map((timeFrame, index) => (
            <option key={index} value={timeFrame.id}>
              {timeFrame.name.toLowerCase()}
            </option>
          ))}
        </Select>
      )}
    </Filter>
  );

  const renderLocations = itOperationStore.itOperationLocations?.map(
    (itOperationLocation: ItOperationLocation, index: number) => (
      <Card
        key={index}
        header={
          <LocationHeader>
            <IconComponent name={Icon.location} height={"18px"} />
            <Text dark large semibold uppercase>
              {itOperationLocation.name}
            </Text>
          </LocationHeader>
        }
        collapsable
      >
        <ItOperationsList
          platformComponentTypes={itOperationLocation.platformComponentTypes}
          currentEnvironment={generalStore.getCurrentEnvironmentName()}
        />
      </Card>
    )
  );

  const renderEmptyData = (
    <Card
      extraContentStyling={css`
        text-align: center;
        margin: 1rem 0 2rem;
      `}
    >
      <img src="/static/media/no-data-icon.fd032bb9.svg" alt="no data icon" />
      {!dataLoad ? (
        <Loader component />
      ) : (
        <NoLocations large dark>
          You have no IT operations for this environment yet
        </NoLocations>
      )}
    </Card>
  );

  const renderNoDataLoaded = (
    <Card
      extraContentStyling={css`
        text-align: center;
        margin: 1rem 0 2rem;
      `}
    >
      <img src="/static/media/no-data-icon.fd032bb9.svg" alt="no data icon" />
      {!accessViewIntegration ? (
        <NoAccessModal />
      ) : !dataLoad ? (
        <Loader component />
      ) : (
        <Modal type={ModalType.DataLoadFailed} action={handleDataReload} />
      )}
    </Card>
  );

  return (
    <>
      <Prompt
        message={(location) => {
          if (!location.pathname.startsWith(Route.Integrations))
            // Reset data when leaving page
            itOperationStore.reset();

          return true;
        }}
      />

      {renderIntegrationsFilter()}

      {accessViewIntegration && itOperationStore.itOperationLocations
        ? itOperationStore.itOperationLocations.length > 0
          ? renderLocations
          : renderEmptyData
        : renderNoDataLoaded}
    </>
  );
};

export default inject(
  "generalStore",
  "itOperationStore",
  "authenticationStore"
)(
  observer(
    ItOperations as FunctionComponent<
      Omit<Props, "generalStore" | "itOperationStore" | "authenticationStore">
    >
  )
);
