import { FunctionComponent, useEffect, useState } from "react";
import styled, { css } from "styled-components";
import React from "react";
import * as d3 from "d3";
import ReactTooltip from "react-tooltip";
import {
  $statusSuccess,
  $statusProblematicCalendar,
  $statusFail,
  $textDark,
  $textLight,
  Text,
  $statusNoValue,
  StyledReactTooltip,
} from "styles/common";
import {
  ExecutionSuccessStatusHeatMap,
  ExecutionSuccessStatusHeatMapType,
  getExecutionSuccessStatusHeatMap,
} from "model/Status";
import {
  CalendarSuccessRateDataLegendItem,
  CalendarSuccessRateDatum,
} from "model/Integration";
import Legend from "components/common/Legend";
import format from "date-fns/format";
import GeneralStore from "stores/GeneralStore";
import { inject, observer } from "mobx-react";
import { Modal, ModalType } from "components/common/Modal";
import Loader from "components/common/Loader";

const cellSize = 17;
export const calendarWidth = cellSize * 53 + 80; // Max weeks in a year + some margin for text and spaces
const svgHeight = cellSize * 9;

const countDay = (i: number) => (i + 6) % 7;
const formatDay = (i: number) => "SMTWTFS"[i];
const timeWeek = d3.utcMonday;
const formatMonth = d3.utcFormat("%b");

const pathMonth = (t: any) => {
  const n = 7;
  const d = Math.max(0, Math.min(n, countDay(t.getUTCDay())));
  const w = timeWeek.count(d3.utcYear(t), t);
  return `${
    d === 0
      ? `M${w * cellSize},0`
      : d === n
      ? `M${(w + 1) * cellSize},0`
      : `M${(w + 1) * cellSize},0V${d * cellSize}H${w * cellSize}`
  }V${n * cellSize}`;
};

interface Props {
  generalStore: GeneralStore;
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

const Calendar = styled.div``;

const TooltipColor = styled.div<{ color: string }>`
  width: 0.8rem;
  height: 0.8rem;
  background-color: ${(props) => props.color};
  margin-right: 0.5rem;
`;

const HeightContainer = styled.div`
  min-height: 10rem;
  position: relative;
`;

const CalendarHeatMap: FunctionComponent<Props> = ({ generalStore }) => {
  const [dataLoad, setDataLoad] = useState(false);
  const [legendItems, setLegendItems] = useState<
    ExecutionSuccessStatusHeatMapType[] | undefined
  >(undefined);

  useEffect(() => {
    generalStore.getCalendarSuccessRateData().finally(() => {
      setDataLoad(true);
    });
  }, [generalStore, generalStore.currentEnvironment]);

  const handleDataReload = () => {
    if (!generalStore.currentEnvironment) {
      generalStore.getEnvironments().then(() => {
        generalStore.getCalendarSuccessRateData();
      });
    } else generalStore.getCalendarSuccessRateData();
  };

  useEffect(() => {
    if (
      generalStore.calendarSuccessRate &&
      generalStore.calendarSuccessRate.years.size > 0
    ) {
      const newLegend = generalStore.calendarSuccessRate.legend.map(
        (legendItem: CalendarSuccessRateDataLegendItem) => ({
          ...getExecutionSuccessStatusHeatMap(legendItem.status),
          ...legendItem,
        })
      );

      setLegendItems(newLegend);

      // Clear SVG before redrawing
      const calendar = document.getElementById("calendar");
      if (calendar) {
        calendar.innerHTML = "";
      }

      // Create svg
      const svg = d3
        .select("#calendar")
        .append("svg")
        .attr(
          "viewBox",
          `0 0 ${calendarWidth} ${
            svgHeight * generalStore.calendarSuccessRate.years.size
          }`
        )
        .attr("width", calendarWidth)
        .attr("height", svgHeight * generalStore.calendarSuccessRate.years.size)
        .attr("font-family", "sans-serif")
        .attr("font-size", 10);

      // Add groups for all years
      const year = svg
        .selectAll("g")
        .data(generalStore.calendarSuccessRate.years)
        .join("g")
        .attr(
          "transform",
          (d, i) => `translate(40.5,${svgHeight * i + cellSize * 1.5})`
        );

      // Add year labels
      year
        .append("text")
        .attr("x", -5)
        .attr("y", -5)
        .attr("font-weight", "bold")
        .attr("text-anchor", "end")
        .text(([key]: any) => key)
        .style("font-size", "0.8rem")
        .style("fill", $textDark);

      // Add day labels
      year
        .append("g")
        .attr("text-anchor", "end")
        .selectAll("text")
        .data(d3.range(7))
        .join("text")
        .attr("x", -5)
        .attr("y", (i) => (countDay(i) + 0.5) * cellSize)
        .attr("dy", "0.31em")
        .text(formatDay)
        .style("font-size", "0.7rem")
        .style("fill", $textLight);

      // Add day rectangles
      year
        .append("g")
        .selectAll("rect")
        .data(([, values]: any) => {
          // Conversion is needed for a more extensize date string so D3 to gets the correct date on mouse events
          // (hours can't be 0; otherwise mouseevent gets date plus 1 day)
          return values.map((datum: CalendarSuccessRateDatum) => {
            const fullDate = new Date(new Date(datum.date).setHours(1));
            return {
              ...datum,
              date: fullDate,
            };
          });
        })
        .enter()
        .append("rect")
        .attr("width", cellSize - 1)
        .attr("height", cellSize - 1)
        .attr("x", (d) => {
          const datum = d as CalendarSuccessRateDatum;
          return (
            timeWeek.count(
              d3.utcYear(new Date(datum.date)),
              new Date(datum.date)
            ) *
              cellSize +
            0.5
          );
        })
        .attr("y", (d) => {
          const datum = d as CalendarSuccessRateDatum;
          return countDay(new Date(datum.date).getUTCDay()) * cellSize + 0.5;
        })
        .attr("fill", (d: any) => {
          if (d.status === ExecutionSuccessStatusHeatMap.Success) {
            return $statusSuccess;
          } else if (d.status === ExecutionSuccessStatusHeatMap.Problematic) {
            return $statusProblematicCalendar;
          } else if (d.status === ExecutionSuccessStatusHeatMap.Fail) {
            return $statusFail;
          } else {
            return $statusNoValue;
          }
        })
        // Add tooltip link to days
        .attr("data-tip", (d: any) => "")
        .attr("data-for", "calendar-tooltip")
        // Hover effects on days
        .on("mouseover", function (d) {
          d3.select(this).transition().style("stroke", "black");

          const datum = d.target.__data__ as CalendarSuccessRateDatum;

          // Add values to tooltip
          const tooltipLabel = document.getElementById(
            "calendar-tooltip-label"
          );
          if (tooltipLabel) {
            tooltipLabel.innerHTML = `${format(
              new Date(datum.date),
              "yyyy/MM/dd"
            )}&nbsp-&nbsp;`;
          }

          const currentStatus = newLegend.find(
            (legendItem: CalendarSuccessRateDataLegendItem) =>
              legendItem.status === datum.status
          );

          if (currentStatus) {
            const tooltipValue = document.getElementById(
              "calendar-tooltip-value"
            );
            if (tooltipValue) {
              tooltipValue.innerHTML = `${currentStatus.name}: ${datum.value}`;
            }

            const tooltipColor = document.getElementById(
              "calendar-tooltip-color"
            );
            if (tooltipColor) {
              tooltipColor.style.backgroundColor = currentStatus.color;
            }
          }
        })
        .on("mouseout", function (d, i) {
          d3.select(this).transition().style("stroke", "none");
        });

      // Add group for each month present in that year's data
      const month = year
        .append("g")
        .selectAll("g")
        .data(([, values]: any) =>
          d3.utcMonths(
            d3.utcMonth(new Date(values[0].date)),
            new Date(values[values.length - 1].date)
          )
        )
        .enter()
        .append("g");

      // Seperation space between months
      month
        .filter((d, i) => !!i)
        .append("path")
        .attr("fill", "none")
        .attr("stroke", "#fff")
        .attr("stroke-width", 3)
        .attr("d", pathMonth);

      // Add month labels
      month
        .append("text")
        .attr(
          "x",
          (d) => timeWeek.count(d3.utcYear(d), timeWeek.ceil(d)) * cellSize + 2
        )
        .attr("y", -5)
        .text(formatMonth)
        .style("font-size", "0.75rem")
        .style("fill", $textLight);

      // Need to do this for react-tooltip to work with SVG elements
      ReactTooltip.rebuild();
    }
  }, [generalStore.calendarSuccessRate]);

  return (
    <Container>
      {legendItems && (
        <Legend
          items={legendItems.map((item) => {
            return {
              boldValue: undefined,
              explanation: item.name,
              color: item.color,
            };
          })}
          small
          extraStyling={css`
            padding: 0.5rem 0 0.6rem 0;
          `}
        />
      )}

      {generalStore.calendarSuccessRate &&
        generalStore.calendarSuccessRate.years.size > 0 && (
          <Calendar id="calendar" />
        )}

      {!dataLoad ? (
        <Loader component />
      ) : generalStore.calendarSuccessRate &&
        generalStore.calendarSuccessRate.years.size === 0 ? (
        <HeightContainer>
          <Modal customText="You don't have any data yet." />
        </HeightContainer>
      ) : (
        !generalStore.calendarSuccessRate && (
          <HeightContainer>
            <Modal
              type={ModalType.DataLoadFailed}
              action={handleDataReload}
              background
            />
          </HeightContainer>
        )
      )}

      <StyledReactTooltip
        id="calendar-tooltip"
        effect="solid"
        place="bottom"
        type="light"
        border={false}
        textColor={$textLight}
        backgroundColor={"#fff"}
        arrowColor={"transparent"}
      >
        <TooltipColor id="calendar-tooltip-color" color={$textLight} />
        <Text large id="calendar-tooltip-label" />
        <Text large bold id="calendar-tooltip-value" />
      </StyledReactTooltip>
    </Container>
  );
};

export default inject("generalStore")(
  observer(CalendarHeatMap as FunctionComponent<Omit<Props, "generalStore">>)
);
